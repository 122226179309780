import React from "react";

import ProfileSection from "../ProfileSection";
import BlockView from "ae-base/Base/BlockView";
import ContactSettings from "../../../../schemas/ContactSettings";

import Form from "ae-forms";

import { useResources } from "@aetonix/hooks";

export default function DefaultContactSettings(props){
	const { dispatch, localize } = useResources();
	const preferences = props.preferences;
	const display = props.profilesection.displaying.contactSettings;

	const title = localize("contactsettings.default");

	const defaultData = preferences.defaultContactSettings;

	const renderBody = display ? (
		<Form schema={ContactSettings} defaultData={defaultData} onSubmit={dispatch("preferences:trigger:preferences:update:contactSettings")} />
	) : null;

	return (
		<BlockView>
			<ProfileSection title={title} section="contactSettings" display={display} />
			{renderBody}
		</BlockView>
	);
}
