import React from "react";
import {
	View,
	StyleSheet,
} from "react-native";

import CallPage from "./CallPage";
import CallButton from "./CallButton";

import { useResources } from "@aetonix/hooks";

var styles = StyleSheet.create({
	onecallbutton: {
		flex: 1.5,
		justifyContent: "flex-end",
	},
});

export default function Outgoing(props) {
	const {
		localize,
		dispatch,
	} = useResources();

	var who = props.who;
	var urgency = props.urgency;
	var isUrgencyActive = urgency.active;
	var hangUpText = localize("atouchawaycalling.endcall");

	var cancelCall = isUrgencyActive ? (
		dispatch("urgencycalls:trigger:cancel:start")
	) : (
		dispatch("calling:trigger:hangup", {
			reason: "Cancelled",
		})
	);

	var renderHangupButton = isUrgencyActive ? (
		null
	) : (
		<View style={styles.onecallbutton}>
			<CallButton
				hangup
				icon={"aeto-hang_up-2"}
				buttonAction={cancelCall}
				buttonText={hangUpText}
			/>
		</View>
	);

	return (
		<CallPage who={who} state={"outgoing"}>
			{renderHangupButton}
		</CallPage>
	);
}
