import PropTypes from "prop-types";
import React from "react";
import {
	View,
	StyleSheet,
} from "react-native";
import StyledText from "ae-base/Base/StyledText";
import StyledIconButton from "ae-base/Base/StyledIconButton";
import Indicator from "ae-base/Base/Indicator";
import { useResources } from "@aetonix/hooks";

var styles = StyleSheet.create({
	bracelet: {
		padding: 12,
		borderRightWidth: 1,
		borderRightColor: "black",
		flexDirection: "row",
		alignSelf: "center",
		alignItems: "center",
	},
	braceletText: {
		flexDirection: "row",
	},
	noBracelet: {
		opacity: 0.5,
	},
});

export default function BraceletIcon(props) {
	const { localize, dispatch } = useResources();

	var bracelet = props.bracelet;
	var icon = props.icon;
	var color = "contrast";
	var handlePress = null;
	var textline1 = localize("atouchawayfooter.no");
	var textline2 = localize("atouchawayfooter.device");
	var reconnecting = localize("atouchawayfooter.reconnecting");
	var not = localize("atouchawayfooter.not");
	var connected = localize("atouchawayfooter.connected");
	var flashing = localize("atouchawayfooter.flashing");

	var braceletExtraStyle = styles.noBracelet;
	if(bracelet){
		braceletExtraStyle = null;
		if(bracelet.reconnecting){
			color = "orange";
			textline1 = reconnecting;
			textline2 = "";
			handlePress = null;
		}
		if (bracelet.exists && !bracelet.connected && !bracelet.reconnecting){
			color = "contrast";
			textline1 = not;
			textline2 = connected;
			handlePress = null;
		}
		if (bracelet.connected && !bracelet.flashing){
			color = "secondary";
			textline1 = connected;
			textline2 = "";
			handlePress = dispatch("bracelet:trigger:alert");
			if(bracelet.battery > 0)
				textline2 = bracelet.battery + "%";
		}
		if(bracelet.flashing){
			color = "alert";
			textline1 = flashing;
			textline2 = "";
			handlePress = dispatch("bracelet:trigger:clear");
		}

		if(bracelet.remoteScan)
			var renderIcon = (
				<Indicator />
			);
		 else
			renderIcon = (
				<StyledIconButton icon={icon} color={color} emphasized onPress={handlePress} />
			);

	}

	if((!bracelet.connected && !bracelet.reconnecting) || !bracelet.exists)
		return null;


	return (
		<View style={[styles.bracelet, braceletExtraStyle]}>
			{renderIcon}
			<View style={styles.braceletText}>
				<StyledText contrast bold emphasized>{textline1} </StyledText>
				<StyledText contrast bold emphasized>{textline2}</StyledText>
			</View>
		</View>
	);
}