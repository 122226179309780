/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";
import {
	View,
	StyleSheet,
	Platform,
} from "react-native";

import Notification from "../../Layout/Notification";
import StyledText from "ae-base/Base/StyledText";

import { useResources } from "@aetonix/hooks";

import Style from "ae-style";

var styles = StyleSheet.create({
	textContainer: {
		flex: 1,
	},
	textStyle: {
		color: Style.colors.white,
	},
});

export default function UpdateBanner(props){
	const { localize, dispatch } = useResources();

	const APPLE_LINK = "https://itunes.apple.com/us/app/aetonix-atouchaway-mobile-app/id916897290?mt=8";
	const GOOGLE_LINK = "https://play.google.com/store/apps/details?id=com.aetonix.mobileappprod";
	const link = Platform.OS === "ios" ? APPLE_LINK : GOOGLE_LINK;
	const appStoreLauncher = dispatch("linkopener:trigger:open", {
		uri: link,
	});

	const color = Style.colors.green;
	const content = localize("banner.update");

	return (
		<Notification
			id={"updateBanner"}
			color={color}
			iconColor={"white"}
			onPress={appStoreLauncher}
			icon={"download"}>
			<View style={styles.textContainer}>
				<StyledText style={styles.textStyle} bold emphasized>
					{content}
				</StyledText>
			</View>
		</Notification>
	);
}
