export default {
	title: "ChangePwd",
	description: "changepassword.title",
	type: "object",
	properties: {
		old_password: {
			order: 0,
			description: "profile.password.old",
			type: "string",
			inputType: "text",
			format: "password"
		},
		new_password: {
			order: 1,
			description: "profile.password.new",
			type: "string",
			inputType: "text",
			format: "password"
		},
		confirm_password: {
			order: 2,
			description: "profile.password.confirm",
			type: "string",
			inputType: "text",
			format: "password"
		},
		account_info: {
			order: 3,
			type: "object",
			inputType: "hidden"
		}
	}
};
