import React from 'react';
import { createStyled } from '@emotion/primitives-core';
import { View, StyleSheet, Text, TouchableOpacity } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faPhone, faTimes } from '@fortawesome/free-solid-svg-icons'
import { useLocalize } from '../../../hooks/useLocalize/LocalizeContext';

const styled = createStyled(StyleSheet);

const Alert = styled(View)({
    width: 600,
    height: 'auto',
    maxHeight: 400,
    maxWidth: '100%',
    backgroundColor: 'white',
    padding: 15,
    borderRadius: 10,
    shadowColor: "#e2e2e2",
	shadowOffset: { width: 0, height: 2},
	shadowOpacity: 1,
	shadowRadius: 16.00,
	elevation: 24,
    marginBottom: 15,
});

const AlertText = styled(Text)({
	fontSize: 25,
	color: "#141823",
	fontFamily: "Avenir",
	maxWidth: "100%",
    width: 'auto',
    height: 'auto',
});

const Information = styled(View)({
    flex: 1,
    overflow: 'hidden',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
});

const Actions = styled(View)({
    width: 'auto',
    height: 'auto',
    alignItems: 'center',
    flexDirection: 'row'
});

const ActionButtonAccept = styled(TouchableOpacity)({
    width: 90,
    height: 90,
    paddingBottom:15,
    justifyContent: 'center',
    alignItems: 'center',
});

const ActionButtonEnd = styled(TouchableOpacity)({
    width: 60,
    height: 90,
    justifyContent: 'center',
    paddingBottom:15,
    alignItems: 'center',
});


const Layout = styled(View)({
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
    height: 'auto',
});

export default ({ title, description, onAccept, onClose }) => { 
    const localize = useLocalize();
    return (
        <Alert>
            <Layout>
                <Information>
                    <AlertText style={{ fontWeight: 'bold', fontSize: 40 }} adjustsFontSizeToFit numberOfLines={2}>{title}</AlertText>
                    <AlertText adjustsFontSizeToFit numberOfLines={2}>{description}</AlertText>
                </Information>
                <Actions>
                    <ActionButtonAccept onPress={onAccept}>
                        <Text style={{ paddingBottom:10, fontWeight: 'bold', width: 100, color:'black' }}>{localize("meeting.alerts.return")}</Text>
                        <FontAwesomeIcon color="green" size={70} icon={faPhone} />
                    </ActionButtonAccept>
                    <ActionButtonEnd onPress={onClose} style={{ borderRightWidth: 0, marginLeft: 65 }}>
                        <Text style={{ paddingBottom:10, fontWeight: 'bold', color:'black' }} >{localize("meeting.alerts.end")}</Text>
                        <FontAwesomeIcon color="tomato" size={70} icon={faTimes} />
                    </ActionButtonEnd>
                </Actions>
            </Layout>
        </Alert>
    )
}