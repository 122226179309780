import React from 'react';
import { createStyled } from '@emotion/primitives-core'
import { variant } from 'styled-system';
import { StyleSheet, View } from 'react-native';

const styled = createStyled(StyleSheet);

export const MeetingWrapper = styled(View)({
    width: '100%',
    height: '100%',
    position: 'relative',
}, variant({
    prop: 'orientation',
    variants: {
        vertical: { flexDirection: 'column' },
        horizontal: { flexDirection: 'row' }
    }
}));

export const ToolsWrapper = styled(View)({
    flex: 0,
	flexGrow: 1,
	flexShrink: 1,
    height: '100%',
    flexDirection: 'column',
});

export const DesktopNavigationWrapper = styled(View)({
    bottom: 50,
    width: '100%',
    height: 'auto',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    pointerEvents: 'box-none',
});