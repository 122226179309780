import React from "react";

import LanguageSchema from "../../../../schemas/Language";
import Form from "ae-forms";

import Divider from "../../../Layout/Divider";
import BlockView from "ae-base/Base/BlockView";

import { useIdPrefix, useResources } from "@aetonix/hooks";

export default function Language(props) {
	const {
		dispatch,
	} = useResources();
	const idPrefix = useIdPrefix();
	var language = {
		language: props.language,
	};

	return (
		<BlockView>
			<Form id_prefix={idPrefix + "languageForm"} key={language} schema={LanguageSchema} defaultData={language} autoSubmit onSubmit={dispatch("preferences:trigger:preferences:update")} />
			<Divider />
		</BlockView>
	);
}
