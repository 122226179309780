import React from "react";
import {
	View,
} from "react-native";

import Section from "./Section";
import Appointments from "./Sections/Appointments";
import Careteam from "./Sections/Careteam";
import Client from "./Sections/Client";
import Goals from "./Sections/Goals";
import Healthassessments from "./Sections/Healthassessments";
import Healthconditions from "./Sections/Healthconditions";
import Hospitalvisits from "./Sections/Hospitalvisits";
import Othertreatments from "./Sections/Othertreatments";
import Situation from "./Sections/Situation";
import Supports from "./Sections/Supports";
import Treatments from "./Sections/Treatments";

import { useResources } from "@aetonix/hooks";

var pageMap = {
	"Appointments": Appointments,
	"CareTeam": Careteam,
	"Client": Client,
	"Goals": Goals,
	"HealthAssessments": Healthassessments,
	"HealthConditions": Healthconditions,
	"HospitalVisits": Hospitalvisits,
	"OtherTreatments": Othertreatments,
	"Situation": Situation,
	"Supports": Supports,
	"Treatments": Treatments,
};

var titleMap = {
	"Appointments": "appointments.appointmentsreferrals",
	"CareTeam": "careteam.careteam",
	"Client": "client.patientidentifiers",
	"Goals": "goals.caregoals",
	"HealthAssessments": "healthassessments.healthassessment_s",
	"HealthConditions": "healthconditions.healthissues",
	"HospitalVisits": "hospitalvisits.hospitalvisit_s",
	"OtherTreatments": "othertreatments.othertreatments",
	"Situation": "situation.situationlifestyle",
	"Supports": "supports.supportsservices",
	"Treatments": "treatments.allergiesmedications",
};

export default function CareplanView(props) {
	const {
		localize,
	} = useResources();

	var model = props.model;
	var page = model.page;
	var	section = page.slice(9);

	var Content = pageMap[section] || View;

	var manage = model.manage;
	var permissions = manage.permissions;
	var section_permission = permissions["careplan_" + section];

	var careplan = manage.careplan;
	var overlay = careplan.overlay;
	var currentSection = careplan.expanded;
	var changes = careplan.changes || {};

	var title = localize(titleMap[section]);

	return (
		<Section title={title} model={model} section={section}>
			<Content careplan={overlay} permissions={section_permission} changes={changes} currentSection={currentSection} />
		</Section>
	);
}
