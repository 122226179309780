import PropTypes from "prop-types";

/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";
import {
	View,
	StyleSheet,
	Dimensions
} from "react-native";

import Style from "ae-style";
import Spinner from "ae-base/Base/Spinner";
import Toast from "ae-base/Base/Toast";
import StatusBar from "ae-base/Base/StatusBar";

var styles = StyleSheet.create({
	container: {
		backgroundColor: Style.look.background,
		flex: 1,
		overflow: "hidden",
	},
	contrast: {
		backgroundColor: Style.look.contrast.background,
	},
	dark: {
		backgroundColor: Style.colors.darkBlue
	},
	borderRadius: {
		borderRadius: 20
	},
	blackGray: {
		backgroundColor: Style.colors.darkGreyBlack
	},
	hidden: {
		right: -420,
		position: "absolute",
		height: 0,
		width: 0,
		overflow: "hidden",
		opacity: 0,
	}
});

export default function PageContainer(props){
	var styleList = [styles.container];
	var toast = props.toast;
	if(props.contrast)
		styleList.push(styles.contrast);
	if(props.dark)
		styleList.push(styles.dark);
	if(props.hidden)
		styleList.push(styles.hidden);
	if(props.borderRadius)
		styleList.push(styles.borderRadius);
	if(props.blackGray)
		styleList.push(styles.blackGray);
	if(props.half){
		var style = {
			height: Dimensions.get("window").height / 2
		};
		styleList.push(style);
	}

	var spinner;
	var saving = props.saving;

	if (!saving) {
		spinner = null;
	} else {
		spinner = <Spinner open={saving} />;
	}

	return (
		<View style={styleList}>
			<StatusBar barStyle="dark-content" />
			{spinner}
			<Toast toast={toast} id={"main_toast"} />
			{props.children}
		</View>
	);
}

PageContainer.propTypes = {
	children: PropTypes.any
};
