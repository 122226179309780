import React from "react";
import {
	StyleSheet,
	TouchableOpacity,
	View,
} from "react-native";

import Style from "@aetonix/styles";
import StyledText from "ae-base/Base/StyledText";
import StyledIcon from "ae-base/Base/StyledIcon";
import noop from "noop3";

import { useIdPrefix } from "@aetonix/hooks";

var styles = StyleSheet.create({
	today: {
		width: Style.imageSize.minilarge,
		height: 160,
		borderRadius: 10,
		borderWidth: 3,
		borderColor: Style.colors.secondary,
		marginBottom: Style.layout.marginLarge,
	},
	grey_back: {
		backgroundColor: Style.colors.grey,
	},
	black_back: {
		backgroundColor: Style.colors.dark,
	},
	greenStatus: {
		borderColor: Style.colors.green,
	},
	yellow: {
		borderColor: Style.colors.yellow,
	},
	red: {
		borderColor: Style.colors.alert,
	},
	whiteBackground: {
		backgroundColor: "#FFFFFF",
	},

	//Squarebutton display
	button: {
		margin: Style.layout.marginSmall / 2,
		width: Style.layout.squareButton,
		height: Style.layout.squareButton,
	},
	icon: {
		justifyContent: "center",
		alignItems: "center",
		flex: 7,
	},
	text: {
		flex: 7,
		paddingHorizontal: Style.layout.paddingSmall,
		justifyContent: "center",
	},
	fit: {
		flex: 1,
	},
	centerText: {
		textAlign: "center",
	},

	contrastBackground: {
		backgroundColor: Style.look.contrast.sectionBackground,
	},
	lightBackground: {
		backgroundColor: Style.look.sectionBackground,
	},
	secondaryBackground: {
		backgroundColor: Style.look.secondaryBackground,
	},
	alertBackground: {
		backgroundColor: Style.look.alert,
	},

	disabled: {
		opacity: Style.look.disabled,
	},
});

export default function OverviewItem(props){
	var text = props.text;
	var title = props.title;
	var onpress = props.onpress;
	var status = props.status;
	var isAta = props.isAtouchAway;
	var style_overview = [styles.today];
	var managed = props.managed;
	var longString = props.longString;
	managed ? style_overview.push(styles.whiteBackground) : style_overview.push(styles.black_back);

	if(status === 0)
		style_overview.push(styles.greenStatus);
	else if(status === 1)
		style_overview.push(styles.yellow);
	else if(status === 2)
		style_overview.push(styles.red);

	return (
		<SquareButtonDisplay
			title={title}
			text={text}
			iconSize={Style.text.smallHeading}
			textSize={Style.text.smallHeading}
			style={style_overview}
			clear
			isAta={isAta}
			clearText
			contrast
			onPress={onpress}
			longString={longString}
		/>
	);
}

function SquareButtonDisplay(props) {
	const idPrefix = useIdPrefix();
	var onPress = props.onPress;
	var title = props.title || "";
	var clearText = props.clearText;
	var contrast = props.contrast;
	var secondary = props.secondary;
	var clear = props.clear;
	var disabled = props.disabled;
	var alert = props.alert;
	var textSize = props.textSize;
	var isAta = props.isAta;
	var iconSize = props.iconSize;
	var text = props.text;
	var longString = props.longString;

	var styleList = [styles.button, styles.lightBackground];
	var isContrastText = false;

	if(clear){
		isContrastText = !!contrast;
		styleList = [styles.button];
	} else	if(contrast){
		isContrastText = true;
		styleList = [styles.button, styles.contrastBackground];
	} else if(secondary){
		isContrastText = true;
		styleList = [styles.button, styles.secondaryBackground];
	} else if(alert){
		isContrastText = true;
		styleList = [styles.button, styles.alertBackground];
	}

	if (clearText) {
		var styleText = [styleText].concat({
			color: Style.colors.dark,
		});
		var styleTextLight = [styleTextLight].concat({
			color: Style.colors.light,
		});
	}

	var iconName = props.icon || "question";

	var display = (text || clearText) ? (
		<View style={styles.icon}>
			{isAta ? (<StyledText size={iconSize} style={styleTextLight}>
				{text}
			</StyledText>) :
				(<StyledText size={iconSize} style={styleText} longString={longString}>
					{text}
				</StyledText>)}
		</View>
	) : (
		<View style={styles.icon}>
			<StyledIcon name={iconName} contrast={isContrastText} size={iconSize} />
		</View>
	);

	if(disabled){
		onPress = noop;
		styleList.push(styles.disabled);
	}

	styleList.push(props.style);

	// To explicitly control text size and icon size
	styleText.push(styles.centerText);
	if (textSize && typeof textSize === "number")
		styleText = [styleText].concat({
			fontSize: textSize,
		});


	styleTextLight.push(styles.centerText);
	if (textSize && typeof textSize === "number")
		styleTextLight = [styleTextLight].concat({
			fontSize: textSize,
		});


	var children = props.children;

	return (
		<TouchableOpacity id={idPrefix + props.id} accessibilityComponentType="button" style={styleList} onPress={onPress}>
			<View style={styles.fit}>
				{children}
				{display}
				<View style={styles.text}>
					{isAta ? (<StyledText id={props.id + "_text"} small bold black compact contrast={isContrastText} style={styleTextLight}>
						{title}
					</StyledText>) : (<StyledText id={props.id + "_text"} small bold black compact contrast={isContrastText} style={styleText}>
						{title}
					</StyledText>)}
				</View>
			</View>
		</TouchableOpacity>
	);
}
