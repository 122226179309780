import React from "react";
import {
	View,
} from "react-native";
import Style from "ae-style";

import MainScroll from "../Layout/MainScroll";

import StyledText from "ae-base/Base/StyledText";
import StyledButton from "ae-base/Base/StyledButton";
import StyledIcon from "ae-base/Base/StyledIcon";

import InviteContactList from "./InviteContactList";
import ResponsiveStylesheet from "react-native-responsive-stylesheet";
import Breakpoints from "../../Breakpoints";

import { useResources } from "@aetonix/hooks";

var styles = ResponsiveStylesheet.createSized("min-direction", {
	0: {
		container: {
			position: "absolute",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			backgroundColor: "white",
		},
		title: {
			flexDirection: "row",
		},
		cancelButton: {
			flex: 2,
			flexBasis: 2,
			justifyContent: "center",
			padding: Style.layout.paddingSmall,
			backgroundColor: Style.colors.dark,
		},
		titleMessage: {
			flex: 8,
			flexBasis: 8,
			flexDirection: "row",
			alignItems: "center",
			padding: Style.layout.paddingSmall,
			backgroundColor: Style.colors.black,
		},
		titleIcon: {
			flex: 1,
			flexBasis: 1,
		},
		titleText: {
			flex: 9,
			flexBasis: 9,
			marginLeft: Style.layout.marginSmall,
		},
	},
	512: {
		cancelButton: {
			flex: 3,
			padding: Style.layout.paddingLarge,
		},
		titleMessage: {
			flex: 7,
			padding: Style.layout.paddingLarge,
		},
		titleText: {
			marginHorizontal: Style.layout.marginBordered,
		},
	},
});

export default function Calling(props) {
	const {
		localize,
		dispatch,
	} = useResources();

	var callRedirector = props.callRedirector;
	var conferenceInviter = props.conferenceInviter;
	var managedAccount = props.managedAccount;
	var availability = props.availability;
	var voiceCall = props.voiceCall;
	var managedEnd = props.managedEnd;

	var screen = props.screen;

	if(callRedirector.open){
		var backAction = dispatch("callredirector:trigger:close");
		var titleMessage = localize("inviteList.titleMessageForward");

		var renderContactList = (
			<InviteContactList conferenceInviter={conferenceInviter} callRedirector={callRedirector} managedEnd={managedEnd} availability={availability} screen={screen} />
		);
	}else{
		backAction = dispatch("conferenceinviter:trigger:close");
		titleMessage = localize("inviteList.titleMessageJoin");

		renderContactList = (
			<InviteContactList conferenceInviter={conferenceInviter} managedAccount={managedAccount} managedEnd={managedEnd} callRedirector={callRedirector} availability={availability} voiceCall={voiceCall} screen={screen} />
		);
	}

	var buttonMessage = localize("inviteList.buttonMessage");

	var showIcon = true;
	if(Breakpoints.smallerThan("mobileSmall"))
		showIcon = false;

	var renderIcon = showIcon ? (
		<View style={styles.titleIcon}>
			<StyledIcon name={"users"} contrast large />
		</View>
	) : null;

	return (
		<View style={styles.container}>
			<MainScroll>
				<View style={styles.title}>
					<View style={styles.cancelButton}>
						<StyledButton id={"backAction"} icon={"left"} onPress={backAction} title={buttonMessage} contrast clear />
					</View>
					<View style={styles.titleMessage}>
						{renderIcon}
						<View style={styles.titleText}>
							<StyledText contrast>{titleMessage}</StyledText>
						</View>
					</View>
				</View>
				{renderContactList}
			</MainScroll>
		</View>
	);
}
