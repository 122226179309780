import React from "react";

import CallCompleted from "./CallCompleted";
import CallMissed from "./CallMissed";
import CallPending from "./CallPending";

export default function CallNotification(props) {
	var notification = props.notification;
	var notificationcontent = notification.content || {};
	var notificationHangup = notificationcontent.hangup;
	var notNotificationAccepted = !notificationcontent.accept;
	var screen = props.screen;
	var  contacts = props.contacts || [];

	if (notNotificationAccepted)  {
		return (
			<CallMissed clearable notification={notification} screen={screen} contacts={contacts} />
		);
	}	else{
		if (notificationHangup) {
			return (
				<CallCompleted clearable notification={notification} screen={screen} contacts={contacts} />
			);
		}	else{
			return (
				<CallPending clearable notification={notification} screen={screen} contacts={contacts} />
			);
		}
	}
}
