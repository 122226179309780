import React from "react";

import ConferenceCallCompleted from "./ConferenceCallCompleted";
import ConferenceCallMissed from "./ConferenceCallMissed";
import ConferenceCallPending from "./ConferenceCallPending";

export default function ConferenceNotification(props) {
	var notification = props.notification;
	var notificationcontent = notification.content;
	var notificationHangup = notificationcontent.hangup;
	var notNotificationAccepted = !notificationcontent.accept;
	var screen = props.screen;
	var contacts = props.contacts || [];

	if (notNotificationAccepted)  {
		return (
			<ConferenceCallMissed clearable notification={notification} screen={screen} contacts={contacts} />
		);
	}
	else{
		if (notificationHangup) {
			return (
				<ConferenceCallCompleted clearable notification={notification} screen={screen} contacts={contacts} />
			);
		}
		else {
			return (
				<ConferenceCallPending clearable notification={notification} screen={screen}  contacts={contacts} />
			);
		}
	}
}
