import React, { forwardRef } from 'react';
import { variant } from 'styled-system';
import { createStyled } from '@emotion/primitives-core'
import { StyleSheet, View, Text, Image , Platform} from 'react-native';

const styled = createStyled(StyleSheet);

const badges = { large: 60, small: 30, list: 10 } 

const Wrapper = styled(View)({
    position: 'relative',
    height: 100,
    width: 100
}, variant({
    prop: 'size',
    variants: {
        list:  { height: 60, width: 60 },
        small: { height: 100, width: 100 },
        large: { height: 200, width: 200 }
    }
}), variant({
    variants: {
        stacked: {  marginRight: -20 }
    }
}));

const Container = styled(View)({
    overflow: 'hidden',
    backgroundColor: "#043573",
    borderRadius: 100,
    justifyContent: "center",
    alignSelf: "center",
    alignItems: "center",
    zIndex: 1,
    width: '100%',
    height: '100%'
}, variant({
    variants: {
        stacked: {
            backgroundColor: '#065B61',
            marginRight: -20,
            marginBottom: 15,
            // @reest likely better as another variant (avatar outline)
            ...Platform.select({
                native: {
                    borderWidth: 5,
                    borderStyle: "solid",
                    borderColor: 'white',
                },
                web: {
                    outlineWidth: 5,
                    outlineStyle: "solid",
                    outlineColor: 'white',
                }
            }),

        }
    }
}));

const AvatarImage = styled(Image)({
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
});

const AvatarText = styled(Text)({
    color: "#fefbff",
    fontFamily: "Avenir",
    fontSize: 32,
    fontWeight: "500",
}, variant({
    prop: 'size',
    variants: {
        list:  { fontSize: 25 },
        small: { fontSize: 32 },
        large: { fontSize: 75 }
    }
}));

export default forwardRef(({ text, size = 'small', image, isStacked = false, colour, badge = null }, ref) => (
    <Wrapper ref={ref} variant={isStacked ? 'stacked' : null} size={size}>
        <Container  variant={isStacked ? 'stacked' : null}  { ...(colour && { style: { backgroundColor: colour }}) }>
            { !!image && (
                <AvatarImage source={{ uri: image }}/>
            )}
            { text && !image && (
                <AvatarText size={size}>
                    {text}
                </AvatarText>
            )}
        </Container>
        { badge }
    </Wrapper>
));