
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";
import {
	View,
	Image
} from "react-native";
import ResponsiveStylesheet from "react-native-responsive-stylesheet";
import spinnerImg from "../../../Resources/img/secondaryspinner.gif";

import Style from "ae-style";

var styles = ResponsiveStylesheet.createSized("min-direction", {
	0: {
		base: {
			justifyContent: "center",
			alignItems: "center"
		},
		canvas: {
			width: Style.imageSize.icon,
			height: Style.imageSize.icon,
		}
	},
	512: {
		canvas: {
			width: Style.imageSize.mini,
			height: Style.imageSize.mini,
		}
	}
});

export default function MessageSpinner(props){
	var open = props.open;
	if(!open) return (
		null
	);

	return (
		<View style={styles.base}>
			<Image resizeMethod="resize" resizeMode="contain" style={styles.canvas} source={spinnerImg} />
		</View>
	);
}
