import React from "react";
import TimelineItem from "./TimelineItem";

import { useResources } from "@aetonix/hooks";

export default function BraceletDisconnect(props) {
	const {
		localize,
	} = useResources();

	var notification = props.notification;
	var devicedisconnected = localize("banner.braceletdisconnect", notification.from);
	var screen = props.screen;

	return (
		<TimelineItem content={devicedisconnected} iconName="info"  notification={notification} screen={screen} />
	);
}
