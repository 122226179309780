import React from "react";
import {
	View,
} from "react-native";

import Style from "ae-style";
import SquareButton from "ae-base/Base/SquareButton";
import StyledText from "ae-base/Base/StyledText";
import StyledIcon from "ae-base/Base/StyledIcon";
import ResponsiveStylesheet from "react-native-responsive-stylesheet";
import format from "string-template";
import Breakpoints from "../../Breakpoints";
import { parseStatus } from "../../utils/ParseStatus";
import { LONG_LANGS } from "../../utils/LongLangs";

import { useResources } from "@aetonix/hooks";

var styles = ResponsiveStylesheet.createSized("min-width", {
	0: {
		square: {
			borderStyle: "solid",
			borderRadius: 10,
			borderWidth: Style.layout.border,
			backgroundColor: "white",
			alignSelf: "center",
			width: Style.text.hugeIcon,
			height: 145,
			paddingVertical: 4,
		},
		precedingdaysContainer: {
			flexDirection: "row",
			marginBottom: Style.layout.marginMedium,
			flexWrap: "wrap",
		},
		red: {
			borderColor: Style.colors.alert,
			borderWidth: 2,
		},
		green: {
			borderColor: Style.colors.primary,
		},
		greenStatus: {
			borderColor: Style.colors.green,
			borderWidth: 2,
		},
		yellow: {
			borderColor: Style.colors.yellow,
			borderWidth: 2,
		},
		info: {
			position: "absolute",
			top: 0,
			right: 0,
			paddingHorizontal: Style.layout.marginSmall,
		},
		padded: {
			alignSelf: "center",
			flexDirection: "column-reverse",
		},
	},
});

export default function ManagedPreceedingMeasure(props) {
	const {
		localize,
		dispatch,
	} = useResources();

	var short = props.short;
	var section = props.section;
	var textFormat = props.textformat;
	const language = props.language;

	var fiveday = (short.length) ? (
		generate_buttons(short, section, textFormat, dispatch,localize, language)
	) : null;

	return (
		<View style={styles.precedingdaysContainer}>
			{fiveday}
		</View>
	);
}

function generate_buttons(short, section, textFormat, dispatch,localize, language) {
	if (!short) return;
	return short.map(function (item) {
		var id = item._id;
		var date = section ===  "managedactivity" ? new Date(item.latest_time) : new Date(item.updated_at);
		var time = time_parse(date, true);
		var created = created_date_label(time.time, time.month, time.day);
		var itemx = item.cumulative !== undefined && item.cumulative !== null ? item : item.history;
		var cumulative = format(textFormat, itemx);

		if (!cumulative && section === "managedbloodsugar") {
			textFormat = textFormat === "{mmol}" ? "{glucose}" : "{meanblood}";
			cumulative = format(textFormat, itemx);
		}
		var iconsize = Breakpoints.smallerThan("mobile") ? Style.text.standard : Style.text.emphasized;
		var checkComment = section === "managedactivity" ? item.comment : item.history.comment;
		if(section.indexOf("bloodoxygen") !== -1) checkComment = item.history.comment || item.history.exertion || item.history.oxygen;
		var history = section === "managedactivity" ? item : item.history;
		var showComment = dispatch("managedcomment:trigger:show", {
			data: history,
			type: section.replace("managed", ""),
		});

		var style_squares = [styles.square];
		if (item.improved === true && section === "managedactivity")
			style_squares.push(styles.green);

		else if (item.improved === false && section === "managedactivity")
			style_squares.push(styles.red);

		else
			style_squares.push(styles.green);


		var status = section === "managedactivity" ? item.status : item.history.status;
		if(section === "managedbloodoxygen"){
			var heartRateStatus = item.history.heartRateStatus;
			var oxygenSaturationStatus = item.history.oxygenSaturationStatus;
			status = heartRateStatus;
			if(oxygenSaturationStatus > heartRateStatus)
				status = oxygenSaturationStatus;
		}

		if(status === 0)
			style_squares.push(styles.greenStatus);
		if(status === 1)
			style_squares.push(styles.yellow);
		if(status === 2)
			style_squares.push(styles.red);

		var onpress = checkComment ? showComment : null;
		var info = checkComment ? (
			<StyledIcon style={styles.info} name="info" size={Style.text.small} />
		) : null;

		var button = (
			<SquareButton title={created.time}
				secondaryTitle={created.date}
				text={cumulative + ""}
				iconSize={iconsize}
				style={style_squares}
				key={id}
				info={info}
				onPress={onpress}
				displayCentered
			>
				<View style={{height: 16}}>
					{info}
					<StyledText style={styles.padded} size={LONG_LANGS.includes(language) ? Style.text.tiny : Style.text.small}>{parseStatus(status, localize)}</StyledText>
				</View>
			</SquareButton>
		);
		return (
			<View key={id + "button"}>
				{button}
			</View>
		);
	});
}

function created_date_label(time, month, day) {
	return {
		time: time,
		date: month + "-" + day,
	};
}

function time_parse(data, forBoxGraphic) {
	var date = new Date(data);
	var month = date.getMonth() + 1;
	var day = date.getDate();
	day = two_digit(day);
	month = two_digit(month);
	var hours = two_digit(date.getHours());
	var minutes = two_digit(date.getMinutes());
	var seconds = forBoxGraphic ? "" : ":" + two_digit(date.getSeconds());
	var time = hours + ":" + minutes + seconds;

	return {
		month: month,
		day: day,
		time: time,
	};
}

function two_digit(data) {
	var item = (data < 10)
		? "0" + data
		: data;
	return item;
}
