import React from "react";

import ChangeEmailSchema from "../../../../schemas/ChangeEmail";
import Form from "ae-forms";
import Divider from "../../../Layout/Divider";
import BlockView from "ae-base/Base/BlockView";

import { useIdPrefix, useResources } from "@aetonix/hooks";

export default function ChangeEmail(props) {
	const {
		dispatch,
	} = useResources();
	const idPrefix = useIdPrefix();

	var preferences = props.preferences;

	var loadedKey = Object.keys(preferences || {}).length;

	return (
		<BlockView>
			<Form id_prefix={idPrefix + "changeEmailForm"} key={loadedKey} schema={ChangeEmailSchema} defaultData={preferences} onSubmit={dispatch("preferences:trigger:preferences:update:email")} />
			<Divider />
		</BlockView>
	);
}
