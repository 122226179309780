export default {
	title: "UserStatus",
	description: "schemas_user_status_title",
	type: "object",
	properties: {
		available: {
			description: "profile.availability.title",
			value_description: "profile.availability",
			format: "switch",
			inputType: "switch",
			type: "boolean",
		}
	}
};
