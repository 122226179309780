export default {
	title: "ScheduleOnOff",
	description: "scheduleonline.title",
	type: "object",
	displayDependencies: {
		day: {
			properties: {
				monthSwitch: {
					enum: [false]
				}
			}
		},
		months: "monthSwitch"
	},
	properties: {
		time: {
			order: 0,
			description: "scheduleonline.time",
			type: "object",
			format: "time",
			properties: {
				hours: {
					type: "string",
					value_description: "scheduleonline.hours",
					inputType: "dropdown",
					enum: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"]
				},
				minutes: {
					type: "string",
					value_description: "scheduleonline.minutes",
					inputType: "dropdown",
					enum: ["0", "5", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55"]
				},
			}
		},
		action: {
			order: 3,
			description: "scheduleonline.action",
			type: "string",
			value_description: "schedule.action",
			inputType: "dropdown",
			enum: ["offline", "online"]
		},
		monthSwitch: {
			order: 1,
			description: "remindermaker.month",
			type: "boolean",
			format: "switch",
			inputType: "switch",
		},
		months: {
			order: 2,
			type: "string",
			description: "scheduleonline.day",
			inputType: "date"
		},
		day: {
			order: 2,
			description: "scheduleonline.day",
			type: "number",
			value_description: "schedule.day",
			inputType: "dropdown",
			enum: [
				0,
				1,
				2,
				3,
				4,
				5,
				6
			]
		}
	}
};
