export default {
	title: "AtouchawaySettings",
	description: "schemas_atouchaway_settings_title",
	type: "object",
	properties: {
		bluetooth: {
			order: 0,
			description: "atouchawaysettings.bluetooth",
			format: "switch",
            inputType: "switch",
			type: "boolean"
		},
		reminders : {
			order: 1,
			description: "atouchawaysettings.reminders",
			format: "switch",
            inputType: "switch",
			type: "boolean"
		},
	}
};
