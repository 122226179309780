import React from "react";
import {
	View,
} from "react-native";

import WorkflowActionBanner from "./WorkflowActionBanner";

export default function WorkflowActionBanners(props) {
	var actions = props.actions;
	if (!actions || actions.length === 0) return null;

	return (
		<View>
			{actions.map(function(action){
				return (
					<WorkflowActionBanner key={action._id} action={action} />
				);
			})}
		</View>
	);
}