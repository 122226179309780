import React, {
	Component,
} from "react";
import {
	View,
	StyleSheet,
	TouchableOpacity,
} from "react-native";

import Style from "ae-style";
import StyledText from "ae-base/Base/StyledText";
import StyledIcon from "ae-base/Base/StyledIcon";
import StyledButton from "ae-base/Base/StyledButton";
import VideoView from "./VideoView";
import NavBanner from "ae-base/Base/NavBanner";

import Breakpoints from "../../Breakpoints";
import { useResources } from "@aetonix/hooks";

var styles = StyleSheet.create({
	footer: {
		flexDirection: "row",
		backgroundColor: Style.colors.dark,
	},
	nameBar: {
		flexDirection: "row",
		alignItems: "center",
		backgroundColor: Style.colors.black,
		justifyContent: "space-between",
	},
	fullLength: {
		flex: 1,
		flexDirection: "column",
	},
	streams: {
		flexDirection: "row",
		width: Style.imageSize.verysmall,
		justifyContent: "flex-end",
	},
	video: {
		flex: 1,
	},
	videoHidden: {
		position: "absolute",
		width: 0,
		height: 0,
	},
});

export default function ConnectedFooter(props) {
	const { localize, dispatch } = useResources();
	var fullscreen = localize("connectedfooter.fullscreen");
	var connectedto = localize("connectedfooter.connectedto");
	var endcall = localize("connectedfooter.endcall");
	var calling = props.calling;
	var who = calling.who;
	var callgroup = calling.callgroup;

	if (props.page === "calling") return null;

	if(!who) return null;

	if(calling.state !== "connected") return null;

	var name = calling.who.lname + ", " + calling.who.fname;
	if(callgroup) name = callgroup.name;
	var hangUp = dispatch("calling:trigger:hangup");
	var openCalling = dispatch("desktop:trigger:page", {
		page: "calling",
	});
	var isConferenceCallConnected = calling.conference_state === "connected";
	var conferenceURL = calling.streams.conference;
	var remoteURL = calling.streams.remote;
	var voiceOnly = calling.streams.voiceOnly;
	var streams = [(
		<Video id={"openCalling"} onPress={openCalling} stream={remoteURL} dispatch={dispatch} />
	)];
	if(isConferenceCallConnected)
		streams.push(
			<Video id={"openCallingConference"} onPress={openCalling} stream={conferenceURL} dispatch={dispatch} />
		);

	var callContent = Breakpoints.smallerThan("mobile") ? (
		<StyledButton id={"hangUp"} title={endcall} icon="aeto-hang_up-2" onPress={hangUp} alert fullwidth />
	) : (
		<View style={styles.nameBar}>
			<TouchableOpacity style={styles.nameBar} onPress={openCalling}>
				<StyledIcon contrast name="aeto-phone" large />
				<StyledText contrast >  {connectedto} {name} </StyledText>
			</TouchableOpacity>
			<StyledButton id={"hangUp"} title={endcall} icon="aeto-hang_up-2" onPress={hangUp} alert />
		</View>
	);

	var streamStyle = [styles.streams];

	if(voiceOnly)
		streamStyle.push(styles.videoHidden);

	return (
		<View style={styles.footer}>
			<View style={styles.fullLength} >
				<NavBanner id={"NavBannerUp"} title={fullscreen} icon="directup" onPress={openCalling} />
				{callContent}
			</View>
			<View style={streamStyle} id="ae_remote_video">
				{streams}
			</View>
		</View>
	);
}

class Video extends Component{
	componentDidMount(){
		var dispatch = this.props.dispatch;
		setTimeout(dispatch("buzz:trigger:video:recalculate"), 16);
		setTimeout(dispatch("buzz:trigger:video:show"), 64);
	}

	render(){
		var props = this.props;
		var stream = props.stream;
		var onPress = props.onPress;
		var id = props.id;

		return (
			<TouchableOpacity id={id} style={styles.video} onPress={onPress}>
				<VideoView stream={stream} />
			</TouchableOpacity>
		);
	}
}
