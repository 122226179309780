/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";
import {
	View,
	StyleSheet,
} from "react-native";

import StyledText from "ae-base/Base/StyledText";
import Breakpoints from "../../../Breakpoints";

import { useResources } from "@aetonix/hooks";

const styles = StyleSheet.create({
	loginLogo: {
		alignItems: "center",
	},
	headerContainer: {
		flexDirection: "row",
	},
});

const containerStyles = [];
if (Breakpoints.smallerThan("small")){
	containerStyles.push(styles.loginLogo);
}

export default function LoginLogo(){
	const { localize } = useResources();
	const welcometo = localize("login.welcometo");

	return (
		<View style={containerStyles}>
			<StyledText id={"loginwelcome"} emphasized contrast>{welcometo}</StyledText>
			<View style={styles.headerContainer}>
				<StyledText hugeHeading contrast>{"a"}</StyledText>
				<StyledText hugeHeading contrast bold>{"Touch"}</StyledText>
				<StyledText hugeHeading contrast>{"Away"}</StyledText>
			</View>
		</View>
	);
}
