
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React, {
	PureComponent
} from "react";
import par from "par";

import BatteryBanner from "./BatteryBanner";
import BatteryChangedBanner from "./BatteryChangedBanner";
import CareplanUpdateBanner from "./CareplanUpdateBanner";
import ConnectedBanner from "./ConnectedBanner";
import DisconnectedBanner from "./DisconnectedBanner";
import UrgencyAcceptedBanner from "./UrgencyAcceptedBanner";
import UrgencyCallBanner from "./UrgencyCallBanner";
import UrgencyCancelBanner from "./UrgencyCancelBanner";
import ManagedNewBanner from "./ManagedNewBanner";
import WanderBanner from "./WanderBanner";
import IndicatorHeartRate from "./IndicatorHeartRate";
import IndicatorOxygenSaturation from "./IndicatorOxygenSaturation";
import IndicatorBloodSugar from "./IndicatorBloodSugar";
import IndicatorBloodPressure from "./IndicatorBloodPressure";
import IndicatorBodyTemperature from "./IndicatorBodyTemperature";
import IndicatorSteps from "./IndicatorSteps";
import IndicatorWeight from "./IndicatorWeight";
import MeetingStartBanner from "./MeetingStartBanner";
import useEnabledFeatures from "../../hooks/useEnabledFeatures/useEnabledFeatures";

import {
	View,
} from "react-native";

export default class Banners extends PureComponent {
	render(){
		var props = this.props;
		var banners = props.banner;
		var max = props.max;
		if(!banners || !banners.length)
			return null;
		if(max)
			banners = banners.slice(0, max);
		return (
			<View>
				{banners.map(par(format_banner, props))}
			</View>
		);
	}
}

function format_banner(props, banner, index) {

	var fallinvalidator = props.fallinvalidator;
	var wanderinvalidator = props.wanderinvalidator;

	if(banner.banner_type === "info:indicator:bpPulserate"){
		return (
			<IndicatorHeartRate key={index} banner={banner} />
		);
	}
	if(banner.banner_type === "info:indicator:heartrate"){
		return (
			<IndicatorHeartRate key={index} banner={banner} />
		);
	}
	if(banner.banner_type === "info:indicator:oxygensaturation"){
		return (
			<IndicatorOxygenSaturation key={index} banner={banner} />
		);
	}
	if(banner.banner_type === "info:indicator:bloodpressure"){
		return (
			<IndicatorBloodPressure key={index} banner={banner} />
		);
	}
	if(banner.banner_type === "info:indicator:bloodsugar"){
		return (
			<IndicatorBloodSugar key={index} banner={banner} />
		);
	}
	if(banner.banner_type === "info:indicator:bodytemperature"){
		return (
			<IndicatorBodyTemperature key={index} banner={banner} />
		);
	}
	if(banner.banner_type === "info:indicator:steps"){
		return (
			<IndicatorSteps key={index} banner={banner} />
		);
	}
	if(banner.banner_type === "info:indicator:weight"){
		return (
			<IndicatorWeight key={index} banner={banner} />
		);
	}

	if (banner.banner_type === "warning:device:wandering") {
		return (
			<WanderBanner key={index}
				wanderinvalidator={wanderinvalidator}
				banner={banner} />
		);
	}

	if (banner.banner_type === "urgency:call") {
		return (
			<UrgencyCallBanner key={index} banner={banner} />
		);
	}

	if (banner.banner_type === "urgency:cancelled") {
		return (
			<UrgencyCancelBanner key={index} banner={banner} />
		);
	}

	if (banner.banner_type === "urgency:accepted") {
		return (
			<UrgencyAcceptedBanner key={index}
				fallinvalidator={fallinvalidator}
				banner={banner} />
		);
	}

	if (banner.banner_type === "info:careplan:change") {
		return (
			<CareplanUpdateBanner key={index} banner={banner} />
		);
	}

	if(banner.banner_type === "info:manageduser:new") {
		return (
			<ManagedNewBanner key={index} banner={banner} />
		);
	}

	if (banner.banner_type === "warning:device:disconnected") {
		return (
			<DisconnectedBanner key={index} banner={banner} />
		);
	}

	if (banner.banner_type === "warning:device:connected") {
		return (
			<ConnectedBanner key={index} banner={banner} />
		);
	}

	if (banner.banner_type === "warning:device:battery") {
		return (
			<BatteryBanner key={index} banner={banner} />
		);
	}

	if (banner.banner_type === "warning:device:batterychanged") {
		return (
			<BatteryChangedBanner key={index} banner={banner} />
		);
	}
	if (banner.banner_type === "info:meeting:new") {
		return (
			<MeetingStartBanner key={index} banner={banner} />
		)
	}
}
