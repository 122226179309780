const config = require('../../configs/config.json');

export function getImageUrl(image, config) {
    const hasDefaultImage = !!image && image !== "default.png";
	if (hasDefaultImage) {
		return config.image_cdn + image;
	} else {
		return null;
	}
}

const asUserWithFullName = (user, getUserType) => ({
    ...user,
    image: getImageUrl(user.image, config),
    name: `${user?.lname ?? ''}, ${user?.fname ?? ''}`,
    userType: getUserType(user._id),
});

export function getInviteesAsUsers(invitees, people, getUserType) {

    return invitees?.reduce?.((accum, value) => {
        const person = people?.[value];

        if (!person) {
            return [...accum, { _id: value, name: 'Guest' }]
        }

        return [...accum, {
            ...person,
            userType: getUserType(person._id),
            name: `${person.lname}, ${person.fname}`,
        }];
    }, []) ?? [];
}

export function getParticipants(users, meeting, getUserType) {
    const participants = Array.from(new Set([
        ...(meeting?.participants ?? []),
        ...(meeting?.recipient ? [meeting.recipient] : []),
        ...(meeting?.invitees ?? []),
    ]));

    const isParticipant = user => participants.some(participant => participant == user._id) ?? false;
    const eligible = users?.filter?.(isParticipant)?.map?.(item => asUserWithFullName(item, getUserType)) ?? [];
    
    return eligible;
}