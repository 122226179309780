import useCallManager from '../useCallManager/useCallManager';
import { useState, useEffect, useMemo, useCallback } from 'react';
import useEligibleMembers from '../useEligibleMembers/useEligibleMembers';
import useCallEvent from '../useCallEvent/useCallEvent';
import { getInviteesAsUsers, getParticipants } from '../../libraries/useActiveMeetingManager.library';
import * as Sentry from '@sentry/react-native';
import MeetingError from '../../MeetingError';
import useMeetingSettings from '../useMeetingSettings/useMeetingSettings';

function usePinningState(members) {
	const [pinnedUser, setPinnedUser] = useState(null);
	const settings = useMeetingSettings();

	const isPinned = !!pinnedUser;

	const onPin = useCallback(userId => {
		const isUserInMeeting = members?.some?.(member => member?._id === userId) ?? false;
		const isPinnableUser = !!userId && isUserInMeeting
	
		if (!isPinnableUser) {
			return;
		}

		setPinnedUser(userId);
		settings.setLayout('teams');
	}, [members, settings.setLayout],
	);

	const onUnpin = useCallback(() => {
		setPinnedUser(null);
		settings.setLayout('grid');
	}, [settings.setLayout]);

	const isPinnedUser = useCallback(userId => {
		if (!pinnedUser || !userId) {
			return false;
		}

		return userId === pinnedUser;
	}, [pinnedUser]);

	const removePinIfUserIsMissing = useCallback((attendees, accessor = attendee => attendee?.userId) => {
		const isAttending = attendee => !! accessor(attendee) && accessor(attendee) === pinnedUser;
		const isPinnedUserInMeeting = attendees?.some?.(isAttending) ?? false;

		if (! isPinned || isPinnedUserInMeeting) {
			return;
		}

		return onUnpin();
	}, [pinnedUser, isPinned, onUnpin]);

	return {
		isPinned,
		pinnedUser,
		onPin,
		onUnpin,
		isPinnedUser,
		removePinIfUserIsMissing,
	};
}

export default (events, config, model) => {
	const [error, setError] = useState(null);
	const [meeting, setMeeting] = useState(null);
	const [isJoining, setIsJoining] = useState(false);
	const [isMinimized, setIsMinimized] = useState(false);
	const [isInMeeting, setIsInMeeting] = useState(false);
	const [isIncomingCallOpen, setIsIncomingCallOpen] = useState(false);

	const isPatientCall = !!meeting?.recipient;
	const name = `${model?.personal?.lname}, ${model?.personal?.fname}`;
	const user = useMemo(() => ({ id: model?.personal?._id, name }), [model?.personal, name]);
	const managers = !!meeting?.recipient ? model?.manage?.managers ?? model?.managerlist ?? [] : [];
	const people = useMemo(() => Object.values(model?.people || {}) ?? [], [model?.people]);
	const isAdministrator = useMemo(() => meeting?.administrators?.some?.(id => id == config?.id) ?? false, [meeting?.administrators, config?.id])

	const getUserType = useCallback(userId => {
		const isPatient = userId == meeting?.recipient;
		const isAdministrator = meeting?.administrators?.some?.(id => id == userId) ?? false;
		const isCareTeam = managers?.some?.(manager => manager?._id == userId) ?? false;

		return isPatient ? 'patient' : isAdministrator ? 'administrator' : isCareTeam ? 'care-team' : null
	}, [meeting?.recipient, meeting?.administrators, managers]);

	const allContacts = useMemo(() => Array.from(new Set([
		...(model?.contacts?.contacts || []),
		...(model?.contacts?.managed || []),
		...(model?.manage?.list || []),
		...managers,
	])), [
		model?.contacts?.contacts,
		model?.contacts?.managed,
		model?.manage?.list,
		managers,
	]);

	const settings = useMeetingSettings();

	const invitable = isAdministrator ? allContacts : settings.isRestrictedModeEnabled ? [] : isPatientCall ? managers : [];
	const invitees = useMemo(() => getInviteesAsUsers(meeting?.invitees, model?.people, getUserType), [meeting, model?.people, getUserType]);
	const members = useMemo(() => getParticipants(people, meeting, getUserType), [people, meeting, getUserType]);
	const callEvent = useCallEvent(events, config);
	const eligibleMembers = useEligibleMembers(invitable, meeting, getUserType);
	
	const pinning = usePinningState(members);

	const goToMeeting = useCallback(() => {
		setIsMinimized(false);
		events.dispatch("desktop:trigger:page", { page: "meeting" })();
        events.dispatch("atouchawaymobile:trigger:page", { page: "meeting" })();
		events.dispatch("simplified:trigger:page", { page: "meeting" })();
		events.dispatch("meetings:stop")();

		// @reest Catch all case for our meeitng sound play hack for ae-models
		setTimeout(events.dispatch("meetings:stop"), 5000); 
	}, [events.dispatch]);

	const onMinimize = useCallback(() => {
		setIsMinimized(true);
		events.dispatch("desktop:trigger:page:back")();
		events.dispatch("simplified:trigger:page", { page: "home" })();
		events.dispatch("atouchawaymobile:trigger:page", { page: "contacts" })();
	}, [events.dispatch]);

	const onAcceptMeeting = useCallback(() => {
		setError(null);
		goToMeeting();
		setIsInMeeting(true);
		setIsIncomingCallOpen(false);

		pinning.onUnpin();
		settings.setLayout(meeting?.currentAdminView ?? settings.defaultLayout);
	}, [
		goToMeeting,
		pinning.onUnpin,
		settings.setLayout,
		settings.defaultLayout,
		meeting?.currentAdminView,
	]);

	const onDeclineMeeting = useCallback(() => {
		setIsIncomingCallOpen(false);
		setIsInMeeting(false);

		const stopMeetingSounds = events.dispatch("meetings:stop")

		stopMeetingSounds();
		setTimeout(stopMeetingSounds, 5000); 

	}, [events.dispatch]);

	const onClearMeeting = useCallback(() => {
		setMeeting(null);
		setError(null);
		setIsJoining(false);
		setIsMinimized(false);
		setIsInMeeting(false);

		events.dispatch("meetings:stop")();
		pinning.onUnpin();
	}, [pinning.onUnpin, events.dispatch]);

	useEffect(() => {
		if (!isIncomingCallOpen) {
			return;
		}

		const incomingCallModalTimer = setTimeout(() => {
			if (!isInMeeting) {
				onClearMeeting();
			}

			events.dispatch("meetings:stop")();
			setIsIncomingCallOpen(false);
		}, 30000);
		
		return () => clearTimeout(incomingCallModalTimer);
	}, [isIncomingCallOpen, isInMeeting, events.dispatch, pinning.onUnpin]);

	// Necessary to get patient managers
	useEffect(() =>  {
		if (meeting?.recipient) {
			events.dispatch('managedmanagers:trigger:load')({ who: meeting?.recipient });
		}
	}, [meeting?.recipient, events.dispatch]);

	const onUpdateMeetingSettings = useCallback(({ currentAdminView }) => {
		return new Promise(async(resolve, reject) => {
			if (!meeting?._id) {
				return reject('Must include meetingId');
			}

			if (!isAdministrator) {
				return reject('Must be administrator to update meeting settings');
			}

			try {
				const savedMeeting = await events.api.meeting.updateMeetingSettings(meeting._id, { currentAdminView });

				settings.setLayout(currentAdminView);

				setMeeting(savedMeeting);
				resolve(savedMeeting);
			} catch (error) {
				Sentry.withScope(scope => {
					scope.setExtras({ action: 'update-settings',  meetingId: meeting._id });
					Sentry.captureException(error);
				});

				reject(error);
			}
		});
	}, [meeting?._id, settings.setLayout, isAdministrator]);

	const onLeaveMeeting = useCallback(meetingId => {
		return new Promise(async (resolve, reject) => {
			if (!meetingId) {
				return reject('Must include a meetingId');
			}

			try {
				await events.api.meeting.leaveMeeting(meetingId);

				events.dispatch("desktop:trigger:page:back")();
				events.dispatch("atouchawaymobile:trigger:page", { page: "contacts" })();
				events.dispatch("newreading:trigger:hide")();
				events.dispatch("simplified:trigger:page", { page: "home" })();

				onClearMeeting();

				resolve();
			} catch (error) {
				Sentry.withScope(scope => {
					scope.setExtras({ action: 'leave', meetingId });
					Sentry.captureException(error);
				});

				reject(error);
			}
		});
	}, [events.api.meeting.leaveMeeting, events.dispatch, pinning.onUnpin, onClearMeeting]);

	const onInviteToMeeting = useCallback(async (inviteeId) => {
		return new Promise(async (resolve, reject) => {
			try {
				const savedMeeting = await events.api.meeting.inviteToMeeting(meeting._id, inviteeId);

				setMeeting(savedMeeting);
				resolve(savedMeeting);
			} catch (error) {
				Sentry.withScope(scope => {
					scope.setExtras({ action: 'invite', inviteeId, meetingId: meeting._id });
					Sentry.captureException(error);
				});

				reject(error);
			}
		});
	}, [meeting?._id]);

	

	const meetingState = useCallManager(
		meeting,
		isInMeeting,
		user,
		members,
		pinning,
		onLeaveMeeting,
		getUserType,
		setError,
	);

	const onJoinMeeting = useCallback(async meetingId => {
		if (isJoining) {
			return;
		}

		if (isInMeeting) {
			await meetingState.onStopMeeting();
		}

		setIsJoining(true);

		events.api.meeting.joinMeeting(meetingId).then(meeting => {
			setIsJoining(false)
			setMeeting(meeting);

			settings.setLayout(meeting?.currentAdminView);

			onAcceptMeeting();
		}).catch(error => {
			Sentry.withScope(scope => {
				scope.setExtras({ action: 'join', meetingId })
				Sentry.captureException(error);
			});

			setIsJoining(false)
		});
	}, [
		onAcceptMeeting,
		events.api.meeting.joinMeeting,
		isJoining,
		isInMeeting,
		meetingState.onStopMeeting,
		settings.setLayout,
	]);

	const onCreateMeeting = useCallback(async (recipientId, title, participants = [], administators = []) => {

		if (isInMeeting) {
			await meetingState.onStopMeeting();
		}

		try {
			const meeting = await events.api.meeting.createMeeting(title || '', '', administators, participants, recipientId);

			setMeeting(meeting);
			onAcceptMeeting();
		} catch (error) {
			Sentry.withScope(scope => {
				scope.setExtras({ action: 'create', recipientId, title });
				Sentry.captureException(error);
			});
		}
	}, [goToMeeting, events.api.meeting.createMeeting, isInMeeting, meetingState.onStopMeeting]);

	useEffect(() => {
		const eventFn = {
			'callStartPush': () => {
				setMeeting(callEvent.meeting);
				setIsJoining(false);
				setIsIncomingCallOpen(true);

				setTimeout(events.dispatch('meetings:start'), 2000);
			},
			'callStartEvent': () => {
				setMeeting(callEvent.meeting);
				setIsJoining(false);
				setIsIncomingCallOpen(true);

				setTimeout(events.dispatch('meetings:start'), 2000);
			},
			'callUpdateEvent': () => {
				const { _id, currentAdminView, ...updatedMeeting } = callEvent.meeting;
				setMeeting(prevMeeting => ({ ...prevMeeting, ...updatedMeeting, currentAdminView }));

				// @todo I'd like to not need this condition here
				if (currentAdminView) {
					settings.setLayout(currentAdminView);
				}
			},
      'callFromWorkflow': () => {
				const { who } = callEvent;
				onCreateMeeting(who, "");
			},
			'callEndedEvent': () => {
				if (!isInMeeting) {
					onClearMeeting();
				} else {
					setError(new MeetingError('Admin disconnected', 'adminEndedCall'));
				}
			},
		};
  
		// These calls are shown as meeting alerts & banners elsewhere
		const isCreatedEvent = callEvent?.name === 'callStartPush' || callEvent?.name === 'callStartEvent';
		const shouldBlockIncomingCalls = isInMeeting && isCreatedEvent
		if (shouldBlockIncomingCalls) {
			return;
		}

		eventFn?.[callEvent?.name]?.();
	}, [callEvent]);

	return {
		error,
		meeting,
		members,
		pinning,
		invitees,
		isJoining,
		callEvent,
		invitable,
		isInMeeting,
		isMinimized,
		meetingState,
		eligibleMembers,
		isAdministrator,
		isIncomingCallOpen,
		setError,
		onMinimize,
		goToMeeting,
		getUserType,
		onJoinMeeting,
		onLeaveMeeting,
		onAcceptMeeting,
		onCreateMeeting,
		onDeclineMeeting,
		onInviteToMeeting,
		onUpdateMeetingSettings,
	};
}