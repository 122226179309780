import React from "react";
import { StyleSheet, View } from "react-native";

import ProgressBar from "react-native-progress/Bar";
import Modal from "../Layout/Modal";
import Card from "../Layout/Card";
import MessageSpinner from "../Pages/Messaging/MessageSpinner";
import Heading from "ae-base/Base/Heading";
import StyledButton from "ae-base/Base/StyledButton";
import StyledIcon from "ae-base/Base/StyledIcon";
import StyledText from "ae-base/Base/StyledText";

import Style from "ae-style";
import { useResources } from "@aetonix/hooks";

var styles = StyleSheet.create({
	adjacent: {
		flexDirection: "row",
		alignContent: "center",
		alignItems: "center",
		paddingVertical: Style.layout.paddingMedium,
	},
	stacked: {
		flexDirection: "column",
		alignContent: "center",
		justifyContent: "center",
		paddingVertical: Style.layout.paddingMedium,
	},

	iconPadding: {
		paddingHorizontal: 6,
	},
	buttonStyle: {
		marginHorizontal: Style.layout.marginSmall,
		alignSelf: "flex-end",
	},
	buttonsContainer: {
		marginTop: Style.layout.marginMedium,
		flexDirection: "row",
		justifyContent: "space-between",
		flexWrap: "wrap",
	},
	buttonContainer: {
		flexDirection: "row",
		alignSelf: "flex-end",
	},
	ButtonContainer: {
		flexDirection: "row",
		alignSelf: "flex-end",
		marginLeft: "auto",
	},
	pushButtonContainer: {
		marginTop: Style.layout.marginMedium,
		flexDirection: "row",
		alignSelf: "flex-end",
		marginLeft: "auto",
	},
	pairTextStyle: {
		textDecorationLine: "underline",
		color: "rgb(6, 91, 97)",
		margin: "auto",
	},
});

export default function FeedbackModal(props) {
	const { localize, dispatch } = useResources();
	var okText = localize("aTouchAwayResolutionWarning.confirm");
	var cancelText = localize("imagecrop.cancel");

	var feedbackModel = props.model.feedbackmodal;
	var feedbackItems = feedbackModel.progressItems;
	var title = localize(feedbackModel.title) || feedbackModel.title;

	var feedback = Object.keys(feedbackItems).map(function (feedbackItem, index) {
		var thisFeedbackItem = feedbackItems[feedbackItem];
		var type = thisFeedbackItem.type;
		var nonlocalized = thisFeedbackItem.nonlocalized || "";
		var feedbackItemMessage =
			(localize(thisFeedbackItem.message, thisFeedbackItem.messageArgs) || thisFeedbackItem.message) + nonlocalized;
		var bold = thisFeedbackItem.bold || false;
		var feedbackItemTrigger = thisFeedbackItem.trigger;
		var feedbackItemCancelTrigger = thisFeedbackItem.cancelTrigger;
		var triggerClose = feedbackItemCancelTrigger ? dispatch(feedbackItemCancelTrigger) : null;
		var feedbackItemPair = thisFeedbackItem.pair;
		var pairTrigger = feedbackItemPair ? dispatch(feedbackItemPair) : null;
		var feedbackItemCancelPair = thisFeedbackItem.cancelPair;
		var cancelPairTrigger = feedbackItemCancelPair ? dispatch(feedbackItemCancelPair) : null;

		if (type === "load") {
			var renderPairCancel = cancelPairTrigger ? (
				<View style={styles.buttonsContainer}>
					<StyledButton alert title={cancelText} style={styles.buttonStyle} onPress={cancelPairTrigger} />
				</View>
			) : null;
			var indicator =
				thisFeedbackItem.progress === 1 ? (
					<StyledIcon name="check" large style={styles.iconPadding} />
				) : (
					<MessageSpinner open />
				);
			return (
				<View key={"loadindicator"}>
					<View style={styles.adjacent}>
						{indicator}
						<StyledText>{feedbackItemMessage}</StyledText>
					</View>
					{renderPairCancel}
				</View>
			);
		} else if (type === "progress")
			return (
				<View key={"progressbar" + index} style={styles.stacked}>
					<StyledText bold={bold}>{feedbackItemMessage}</StyledText>
					<ProgressBar color={"#237480"} height={15} width={null} progress={thisFeedbackItem.progress} />
					{triggerClose ? <View style={styles.buttonsContainer}>
						<StyledButton alert title={cancelText} style={styles.buttonStyle} onPress={triggerClose} />
					</View> : null}
				</View>
			);
		 else if (type === "confirm") {
			var confirmTriggerDispatch = dispatch(feedbackItemTrigger);
			return (
				<View key={"confirmbutton"} style={styles.stacked}>
					<StyledText>{feedbackItemMessage}</StyledText>
					<View style={styles.buttonsContainer}>
						<StyledButton contrast title={okText} style={styles.buttonStyle} onPress={confirmTriggerDispatch} />
					</View>
				</View>
			);
		} else if (type === "confirmcancel") {
			var pairTitle = localize("devices.pair");
			const manualEnterTitle = localize("glucose.manual_entry");
			var cancelTriggerDispatch = dispatch(feedbackItemTrigger);
			const model = props.model;
			const manageModel = model.manage;
			const pageModel = model.page;
			const hasManagedHealthPermission = manageModel && manageModel.permissions && manageModel.permissions.observations;
			const isOnManagedHealthPage = pageModel && pageModel.indexOf("managed") !== -1;
			const workflowaction = model.workflowaction;
			const isFailed = feedbackModel.title.indexOf("failed") !== -1;
			const showManulEnterButton =
				((hasManagedHealthPermission && isOnManagedHealthPage) || workflowaction) && isFailed;

			feedbackItemMessage = showManulEnterButton
				? localize("myhealthdevice.failedandenternewreading")
				: feedbackItemMessage;

			var renderPair = pairTrigger ? (
				<StyledText contrast style={styles.pairTextStyle} onPress={pairTrigger}>
					{pairTitle}
				</StyledText>
			) : null;

			const handleManualEnter = () => {
				if (!workflowaction) triggerClose();
				dispatch("newreading:trigger:show")();
			};
			const renderManualEnter = showManulEnterButton && (
				<StyledButton contrast title={manualEnterTitle} style={styles.buttonStyle} onPress={handleManualEnter} />
			);

			const cancelOkButtonStyle = renderPair && renderManualEnter ? styles.pushButtonContainer : styles.ButtonContainer;
			return (
				<View key={"cancelbutton"} style={styles.stacked}>
					<StyledText>{feedbackItemMessage}</StyledText>
					<View style={styles.buttonsContainer}>
						<View style={styles.buttonContainer}>
							{renderPair}
							{renderManualEnter}
						</View>
						<View style={cancelOkButtonStyle}>
							<StyledButton alert title={cancelText} style={styles.buttonStyle} onPress={triggerClose} />
							<StyledButton contrast title={okText} style={styles.buttonStyle} onPress={cancelTriggerDispatch} />
						</View>
					</View>
				</View>
			);
		}
	});

	return (
		<Modal open>
			<Card scroll>
				<Heading title={title} />
				{feedback}
			</Card>
		</Modal>
	);
}