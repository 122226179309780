import language from "./shared/language";

export default {
	title: "Language",
	description: "schemas_language_title",
	type: "object",
	properties: {
		language: language
	}
};
