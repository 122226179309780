import React from "react";
import TimelineItem from "./TimelineItem";
import noop from "noop3";

import { useResources } from "@aetonix/hooks";

export default function CallPending(props) {
	const {
		localize,
		dispatch,
	} = useResources();
	var notification = props.notification;
	var screen = props.screen;
	var  contacts = props.contacts || [];
	var callpending = localize("callnotification.callpending", {
		timestamp: notification.updated_at,
	});

	var goToNotification = dispatch("calling:trigger:call", {
		who: notification.from._id,
		reason: "screen",
	});

	var isContact = contacts.find((contact) => (contact._id ===  notification.from._id));

	var openNotification = (isContact) ? goToNotification : noop;
	return (
		<TimelineItem content={callpending} iconName="phone" onPress={openNotification} clearable notification={notification} screen={screen} />
	);
}
