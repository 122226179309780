import React from 'react';
import { createStyled } from '@emotion/primitives-core'
import { StyleSheet, View } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'

const styled = createStyled(StyleSheet);

const BadgeContainer = styled(View)({
	position: 'absolute',
	right: -4,
	bottom: -4,
	zIndex: 50,
	elevation: 1000000,
	backgroundColor: 'white',
	borderRadius: 1000,
	padding: 3,
})

export default ({ icon, color, size = 25, ...rest }) => (
    <BadgeContainer>
        <FontAwesomeIcon size={size} icon={icon} color={color} {...rest} />
    </BadgeContainer>
)