import React from "react";
import { View, StyleSheet, Platform } from "react-native";

import Heading from "ae-base/Base/Heading";
import Avatar from "../../../Contacts/Avatar";
import Loader from "ae-base/Base/Loader";

import Form from "ae-forms";
import ChangePicSchema from "../../../../schemas/ChangePic";
import Breakpoints from "../../../../Breakpoints";
import { useResources } from "@aetonix/hooks";

import Style from "ae-style";

var styles = StyleSheet.create({
	image: {
		flexDirection: "row",
		marginVertical: Style.layout.marginLarge,
		justifyContent: "center",
	},
});

export default function ProfilePictureChoose(props) {
	const { dispatch, localize, api } = useResources();
	const [image, setImage] = React.useState(null);
	const [loading, setLoading] = React.useState(false);
	const { account, screen } = props;

	const changeyourprofilepicture = localize(
		"profilepicturechoose.changepictitle",
	);

	const openCropper = dispatch("managedprofilepicture:trigger:start");
	const uploadImage = async data => {
		setLoading(true);
		try {
			const response = await api.manage.picture(data.image, account._id, {});
			setImage(response.image);
		} catch (e) {
			console.error(e);
		} finally {
			setLoading(false);
		}
	};
	const onSubmit = Platform.OS === "web" ? openCropper : uploadImage;

	let sizeAvatar = "large";
	if (Breakpoints.smallerThan("mobile")) sizeAvatar = "medium";
	if (Breakpoints.smallerThan("small")) sizeAvatar = "verysmall";

	if (loading) return <Loader />;

	return (
		<View>
			<Heading title={changeyourprofilepicture} icon="camera" screen={screen} />
			<View style={styles.image}>
				<Avatar
					person={{ image: image || account.image, _id: account._id }}
					size={sizeAvatar}
				/>
			</View>
			<Form schema={ChangePicSchema} autoSubmit onSubmit={onSubmit} />
		</View>
	);
}
