import PropTypes from "prop-types";

/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";
import {
	View,
} from "react-native";
import ResponsiveStylesheet from "react-native-responsive-stylesheet";

import StyledText from "ae-base/Base/StyledText";
import BackButton from "ae-base/Base/BackButton";
import HeaderImage from "../../Effects/HeaderImage";
import Avatar from "../../Contacts/Avatar";
import ControlButtons from "./ControlButtons";
import Breakpoints from "../../../Breakpoints";
import CircleButtons from "./CircleButtons";
import ImageUrl from "ae-base/Base/ImageUrl";
import Style from "ae-style";
import { useIdPrefix, useResources } from "@aetonix/hooks";

var MAIN_BUTTONS = [
	{
		title: "managedreminders.reminders", permission: "reminders", icon: "notifications", trigger: "managedreminders:trigger:view",
	}, {
		title: "managedurgency.urgencycontact_s", permission: "urgencycontacts", icon: "contacts", trigger: "managedurgencycontacts:trigger:view",
	}, {
		title: "appointments.notes", permission: "forms", icon: "notebook", trigger: "managedforms:trigger:view:section",
	}, {
		title: "workflow.workflow", permission: "workflow", icon: "files", trigger: "managedworkflow:trigger:view",
	}, {
		title: "resources.title", permission: "resources", icon: "newspaper-o", trigger: "managedresources:trigger:view",
	},
];

var SIMPLIFIED = [
	"urgencycontacts",
	"workflow",
	"resources",
];

var styles = ResponsiveStylesheet.createSized("min-direction", {
	0: {
		center: {
			alignItems: "center",
			justifyContent: "center",
			marginTop: 10,
			marginBottom: 10,
		},
		text: {
			margin: Style.layout.paddingMedium,
		},
		backButtonPadding: {
			padding: Style.layout.paddingSmall,
			paddingLeft: Style.layout.paddingMedium,
		},
	},
	512: {
		center: {
			alignItems: "center",
			justifyContent: "center",
		},
		controls: {
			marginVertical: 18,
		},
		text: {
			marginTop: Style.layout.marginBordered,
			marginBottom: Style.layout.marginMedium,
		},
	},
});

export default function ManagedProfileHeader(props){
	const { localize, config } = useResources();
	const idPrefix = useIdPrefix();


	var contact = props.contact;
	var missed = props.missed;
	var goHome = contact.backButton;

	var manage = props.manage;
	var permissions = manage.permissions;
	var newsections = manage.newsections;
	var managedstatuses = manage.managedstatuses || {};
	var status = managedstatuses[contact._id] || {};
	const group = props.group;

	var account = manage.account || {};
	var type = account.type || [];

	var isSimplified = type.indexOf("simplified") !== -1;

	var buttons = MAIN_BUTTONS;

	if(isSimplified)
		buttons = MAIN_BUTTONS.filter(function(button){
			return SIMPLIFIED.indexOf(button.permission) === -1;
		});

	var current_status = status.current_status;

	var text = styles.text;
	var center = styles.center;
	var backbuttonSize;

	var sizeAvatar = "medium";
	var nameSize = "largeHeading";

	var name = contact.lname + ", " + contact.fname;

	if (Breakpoints.smallerThan("mobileSmall"))
		sizeAvatar = "medium";

	if (Breakpoints.smallerThan("small")) {
		sizeAvatar = "medium";
		backbuttonSize = "small";
	}

	var controlButtons = (
		<ControlButtons missed={missed} contact={contact} settings={props.settings || {}} allowMessage={contact.allowMessage} />
	);
	var backbuttonTitle = <StyledText contrast size={backbuttonSize}>{localize("managedprofileheader.goback")}</StyledText>;
	var manageduser = localize("managedprofileheader.manageduser");

	var borderColor = null;
	if (current_status === 2)
		borderColor = Style.colors.red;
	else if (current_status === 1)
		borderColor = Style.colors.yellow;
	else if (current_status === 0)
		borderColor = Style.colors.green;

	return (
		<HeaderImage source={ImageUrl(contact.image_medium, config)}>
			<View style={styles.backButtonPadding}>
				<BackButton id={idPrefix + "backButton"} title={backbuttonTitle} goHome={goHome} />
			</View>
			<Avatar person={contact} size={sizeAvatar} borderColor={borderColor} />
			{controlButtons}
			<View style={[center, text]}>
				<StyledText centered size={nameSize} bold contrast>{name}</StyledText>
				<StyledText emphasized contrast>{manageduser}</StyledText>
			</View>
			<CircleButtons buttons={buttons} testID={'button-'} group={group} permissions={permissions} contact={manage.account} newsections={newsections} />
		</HeaderImage>
	);
}

ManagedProfileHeader.propTypes = {
	missed: PropTypes.object,
};
