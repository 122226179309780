import { useState, useCallback } from 'react';
import { MENUS } from '../../libraries/menu.library';

export default () => {
	const [navigationIndex, setNavigationIndex] = useState(0);
	const [activeTray, setActiveTray] = useState(MENUS.NONE);

	const onResetNavigation = useCallback(() => {
		setActiveTray(MENUS.NONE);
	});

	const onClickNavigation = useCallback((index, item) => {
		onResetNavigation();
		setNavigationIndex(index);

		item?.onClick?.();
	}, [onResetNavigation]);

	const setMenu = useCallback(menu => {
		if (menu === activeTray) {
			return onResetNavigation();
		}
		setActiveTray(menu);
	}, [activeTray, onResetNavigation])

	return {
		navigationIndex,
		menu: activeTray,
		setMenu,
		onClickNavigation,
	}
}
