import xtend from "xtend";

import personal from "./shared/personal";
import language from "./shared/language";
import searchHidden from "./shared/searchHidden";

export default {
	title: "PersonalInfo",
	description: "schemas_personal_info_title",
	type: "object",
	properties: xtend(personal, {
		searchHidden: searchHidden,
		language: language,
	})
};
