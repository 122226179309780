/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React, { useEffect } from "react";
import {
	StyleSheet,
	View,
} from "react-native";

import StyledText from "ae-base/Base/StyledText";
import PageContainer from "../../Layout/PageContainer";

import Form from "ae-forms";
import PasswordresetSchema from "../../../schemas/Passwordreset";

import LoginSection from "./LoginSection";
import LoginLogo from "./LoginLogo";
import LoginBack from "./LoginBack";
import LoginSpacer from "./LoginSpacer";
import MainScroll from "../../Layout/MainScroll";

import { useResources } from "@aetonix/hooks";

const styles = StyleSheet.create({
	submitAlign: {
		justifyContent: "flex-start",
	},
	backButton: {
		zIndex: 1,
		position: "absolute",
		top: -10,
		left: 0,
		right: 0,
		bottom: 0,
	},
	content: {
		flexDirection: "column",
	},
});

export default function Passwordreset(){
	const { dispatch, model, localize} = useResources();
	const toast = model.toast;
	const saving = model.saving;
	const sent = model.sent;

	const passwordreset = localize("passwordreset.passwordreset");
	const message = localize("passwordreset.message");
	const send = localize("passwordreset.sendButton");
	const sentTitle = localize("passwordreset.sentTitle");
	const sentMessage = localize("passwordreset.sentMessage");

	if (sent) return (
		<PageContainer dark saving={saving} toast={toast}>
			<MainScroll>
				<View style={styles.backButton}>
					<LoginBack onPress={dispatch("app:trigger:login")} />
				</View>

				<LoginSection header>
					<LoginLogo />
				</LoginSection>

				<LoginSection contrast >
					<View style={styles.content}>
						<StyledText bold large id="passwordResetConfirm">{sentTitle}</StyledText>
						<StyledText>{sentMessage}</StyledText>
					</View>
					<LoginSpacer />
				</LoginSection>
			</MainScroll>
		</PageContainer>
	);

	else return (
		<PageContainer dark saving={saving} toast={toast}>
			<MainScroll>
				<View style={styles.backButton}>
					<LoginBack onPress={dispatch("app:trigger:login")} />
				</View>

				<LoginSection header>
					<LoginLogo />
				</LoginSection>

				<LoginSection contrast >
					<View style={styles.content}>
						<StyledText bold large id="passwordResetHeader">{passwordreset}</StyledText>
						<StyledText>{message}</StyledText>
					</View>
					<LoginSpacer>
						<Form id_prefix="passwordResetForm_" schema={PasswordresetSchema} onSubmit={dispatch("passwordreset:trigger:request")} submitStyle={styles.submitAlign} submitText={send} />
					</LoginSpacer>
				</LoginSection>
			</MainScroll>
		</PageContainer>
	);
}