import React from "react";
import { View, StyleSheet, Dimensions, TouchableOpacity } from "react-native";

import StyledText from "ae-base/Base/StyledText";
import SquareButton from "../Pages/MyHealth/Reminders/SquareButton";
import StyledButton from "ae-base/Base/StyledButton";
import Modal from "../Layout/Modal";
import Card from "../Layout/Card";
import Heading from "ae-base/Base/Heading";

import Style from "ae-style";

import { useResources } from "@aetonix/hooks";

var styles = StyleSheet.create({
	column: {
		flexDirection: "row",
		justifyContent: "space-between",
		marginVertical: Style.layout.marginMedium,
	},
	columnCenter: {
		flexDirection: "column",
		textAlign: "center",
		marginVertical: Style.layout.marginSmall,
	},
	alignCenter: {
		alignItems: "center",
	},
	margin: {
		marginVertical: 16,
	},
	circleButton: {
		justifyContent: "center",
		alignItems: "center",
	},
	selectedButton: {
		backgroundColor: Style.colors.primary,
	},
	arrowDown: {
		width: 0,
		height: 0,
		borderLeftWidth: 8,
		borderRightWidth: 8,
		borderTopWidth: 8,
		borderStyle: "solid",
		borderLeftColor: "transparent",
		borderRightColor: "transparent",
	},
	arrowDownPrimary: {
		borderTopColor: Style.colors.primary,
	},
	arrowDownAlert: {
		borderTopColor: Style.colors.alert,
	},
	cursorRadius: {
		overflow: "hidden",
		borderRadius: 12,
	},
	textCenter: {
		textAlign: "center",
	},
	rowSpace: {
		flexDirection: "row",
		justifyContent: "space-between",
	},
	numberSpace: {
		flexDirection: "row",
		justifyContent: "space-between",
		marginHorizontal: 18,
	},
});

export default function ReminderView(props) {
	const {
		localize,
		dispatch,
	} = useResources();

	var reminder = props.reminders || {};
	var responseType = reminder.responseType;
	var content = reminder.content;


	var accept = localize("atouchawayreminder.accept");
	var deny = localize("atouchawayreminder.deny");
	var title = localize("atouchawayreminder.title");

	var low = localize("atouchawayreminder.low");
	var high = localize("atouchawayreminder.high");
	var touchanumberfrom1to10 = localize("atouchawayreminder.touchanumberfrom1to10");
	var touchanumberfrom0to10 = localize("atouchawayreminder.touchanumberfrom0to10");
	var yes = localize("common.yes");
	var no = localize("common.no");

	var onAccept = dispatch("activereminder:trigger:respond", {
		action: "accept",
	});
	var onDeny = dispatch("activereminder:trigger:respond", {
		action: "deny",
	});
	var onEmotionHappy = dispatch("activereminder:trigger:respond", {
		action: "respond",
		response: "😄",
	});
	var onEmotionSad = dispatch("activereminder:trigger:respond", {
		action: "respond",
		response: "😦",
	});
	const Yes = dispatch("activereminder:trigger:respond", {
		action: "respond",
		response: "Yes",
	});
	const No = dispatch("activereminder:trigger:respond", {
		action: "respond",
		response: "No",
	});

	var renderResponse = null;
	var SCALE = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
	const ZEROSCALE = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

	var renderScaleButtons = SCALE.map(function (number) {
		var selected = false;
		return <NumberButton number={number} selected={selected} />;
	});

	var renderZeroScaleButtons = ZEROSCALE.map(function (number) {
		var selected = false;
		return <NumberButton number={number} selected={selected} />;
	});

	if (responseType === "confirm" || !responseType)
		renderResponse = (
			<View style={styles.column}>
				<SquareButton title={accept} icon="like" onPress={onAccept} contrast secondary />
				<SquareButton title={deny} icon="dislike" onPress={onDeny} contrast highlight />
			</View>
		);
	if (responseType === "emotion")
		renderResponse = (
			<View style={styles.column}>
				<SquareButton title={""} icon="smile-o" onPress={onEmotionHappy} contrast secondary />
				<SquareButton title={""} icon="frown-o" onPress={onEmotionSad} contrast highlight />
			</View>
		);
	if (responseType === "range")
		renderResponse = (
			<View>
				<View style={styles.rowSpace}>
					<ScaleCursor title={low} contrast />
					<ScaleCursor title={high} alert />
				</View>
				<View style={styles.numberSpace}>{renderScaleButtons}</View>
				<MarginVertical />
				<View style={styles.textCenter}>
					<StyledText bold black emphasized> {touchanumberfrom1to10} </StyledText>
				</View>
			</View>
		);

	if (responseType === "0range")
		renderResponse = (
			<View>
				<View style={styles.rowSpace}>
					<ScaleCursor title={low} contrast />
					<ScaleCursor title={high} alert />
				</View>
				<View style={styles.rowSpace}>{renderZeroScaleButtons}</View>
				<MarginVertical />
				<View style={styles.textCenter}>
					<StyledText bold black emphasized> {touchanumberfrom0to10} </StyledText>
				</View>
			</View>
		);

	if (responseType === "yesorno")
		renderResponse = (
			<View style={styles.column}>
				<SquareButton title={yes} icon="check" onPress={Yes} contrast secondary />
				<SquareButton title={no} icon="close" onPress={No} contrast highlight />
			</View>
		);


	return (
		<Modal open>
			<Card scroll>
				<Heading title={title} />
				<View style={styles.columnCenter}>
					<StyledText bold primary large> {content} </StyledText>
				</View>
				{renderResponse}
			</Card>
		</Modal>
	);
}

function ScaleCursor(props) {
	var title = props.title;
	var alert = props.alert;
	var contrast = props.contrast;
	var styleList = [styles.arrowDown];
	if (alert) {
		alert = true;
		contrast = false;
		styleList.push(styles.arrowDownAlert);
	}
	if (contrast) {
		alert = false;
		contrast = true;
		styleList.push(styles.arrowDownPrimary);
	}
	return (
		<View style={styles.alignCenter}>
			<View style={styles.cursorRadius}>
				<StyledButton title={title} bold contrast={contrast} alert={alert} emphasized />
			</View>
			<View style={styleList} />
		</View>
	);
}

function NumberButton(props){
	const { dispatch } = useResources();
	var number = props.number;
	var selected = props.selected;
	var contrast = false;

	var screenWidth = Dimensions.get("window").width;

	var styleList = [styles.circleButton, {
		width: screenWidth / 12,
		height: screenWidth / 16,
		borderRadius: screenWidth / 32,
	}];

	var onRangeAccept = dispatch("activereminder:trigger:respond", {
		action: "respond",
		response: number,
	});

	if (selected) {
		styleList.push(styles.selectedButton);
		contrast = true;
	}

	return (
		<TouchableOpacity accessibilityComponentType="button" style={styleList} onPress={onRangeAccept} >
			<View style={styles.textCenter}>
				<StyledText bold primary contrast={contrast} large> {number} </StyledText>
			</View>
		</TouchableOpacity>
	);
}

function MarginVertical() {
	return <View style={styles.margin} />;
}
