import React from "react";
import {
	View,
} from "react-native";
import ResponsiveStylesheet from "react-native-responsive-stylesheet";

import Style from "ae-style";

import PageContainer from "../../Layout/PageContainer";
import MainScroll from "../../Layout/MainScroll";
import BackButton from "ae-base/Base/BackButton";

import ChangeViewer from "./ChangeViewer";

import { useResources } from "@aetonix/hooks";

var styles = ResponsiveStylesheet.createSized("min-direction", {
	0: {
		back: {
			marginLeft: Style.layout.marginBordered,
			marginTop: Style.layout.marginBordered,
		},
	},
	512: {
		back: {
			marginLeft: Style.layout.marginContent,
			marginTop: Style.layout.marginContentVertical,
		},
	},
});

export default function Proposal(props) {
	const {
		localize,
		dispatch,
	} = useResources();

	var changeViewers = props.careplan.changeViewer || [];
	var careplan = props.careplan;
	var permissions = props.permissions;
	var screen = props.screen;

	var renderChanges = changeViewers.map(function(changeViewer, index){
		var renderControls = (index === 0);
		return (
			<ChangeViewer key={index} changeViewer={changeViewer} careplan={careplan} renderControls={renderControls} permissions={permissions} screen={screen} />
		);
	});
	var gobacktomanagedusersprofile = localize("proposal.gobacktomanagedusersprofile");

	return (
		<PageContainer contrast>
			<MainScroll>
				<View style={styles.back}>
					<BackButton title={gobacktomanagedusersprofile} onPress={dispatch("managedcareplanchangeviewer:trigger:hide")} />
				</View>
				{renderChanges}
			</MainScroll>
		</PageContainer>
	);
}
