/*eslint prefer-promise-reject-errors: "error"*/
import PropTypes from "prop-types";
import React, { Component, useState, useEffect } from "react";
import { Platform } from "react-native";
import { debounce } from "lodash";

import AsyncStorage from "@react-native-async-storage/async-storage";
import aeAPI from "ae-api";
import Dispatcher from "ae-dispatcher";
import mapObject from "map-object";
import memoize from "memoizee";
import noop from "noop3";
import par from "par";
import global from "global";
import Loader from "ae-base/Base/Loader";
import localizer from "ae-localizer";
import { LocalizeProvider } from "./hooks/useLocalize/LocalizeContext";
import MeetingProvider from "./MeetingProvider";
import TextSizeContext from "./hooks/useTextSize/TextSizeContext";
import { MeetingSettingsProvider } from "./Calling/context/MeetingSettingsContext";
import EnabledFeaturesContext from "./hooks/useEnabledFeatures/EnabledFeaturesContext";
import { SelfProvider } from "./hooks/useSelf/SelfContext";
import { MeasureUnitsProvider } from "@aetonix/hooks";
import moment from "moment";
import { WEEKDAY_DATE_TIME_FORMAT } from "./utils/date-utils";
import { ResourcesContext, IdPrefixProvider } from "@aetonix/hooks";

var DEFAULT_DATE_OPTIONS = {
	day: "2-digit",
	month: "short",
	year: "numeric",
	hour: "2-digit",
	minute: "2-digit",
	second: "2-digit",
	weekday: "short",
};

const getAllMeasureUnits = (model) => ({
	measureUnits: model?.personal?.measureUnits ?? "metric",
	glucometerUnits: model?.personal?.glucometerUnits,
	weightUnits: model?.personal?.weightUnits,
	temperatureUnits: model?.personal?.temperatureUnits
});

var DEFAULT_DATE_OPTIONS = {
	day: "2-digit",
	month: "short",
	year: "numeric",
	hour: "2-digit",
	minute: "2-digit",
	second: "2-digit",
	weekday: "short",
};

const withChimeMeetingProvider = (app) => (Platform.OS !== "web" ? app : <MeetingProvider>{app}</MeetingProvider>);

const withModelProviders = (model, app) => (
	<LocalizeProvider>
		<SelfProvider model={model}>
			<MeetingSettingsProvider settings={model?.groupsettings || {}}>
				<TextSizeContext.Provider value={model?.settings?.textsize ?? 0}>
					<EnabledFeaturesContext.Provider value={{ isCallingEnabled:  model?.groupsettings?.isNewCallingEnabled ?? false }}>
						<MeasureUnitsProvider value={getAllMeasureUnits(model)}>
							{ app }
						</MeasureUnitsProvider>
					</EnabledFeaturesContext.Provider>
				</TextSizeContext.Provider>
			</MeetingSettingsProvider>
		</SelfProvider>
	</LocalizeProvider>
);

const withResources = (resources, app, idPrefix) => (
	<ResourcesContext.Provider value={resources}>
		<IdPrefixProvider prefix={idPrefix}>
			{app}
		</IdPrefixProvider>
	</ResourcesContext.Provider>
);

export default function createAppComponent(factory, ConsumerComponent) {
	class AppComponent extends Component {
		constructor(props) {
			super(props);

			this.state = {
				loading: true,
				model: {},
			};
			this._loading = null;
			this.app = null;
			this.doLocalize = doLocalize.bind(this);
			this.doLocalizeFrom = localizeFrom.bind(this);
			this.doFormatDate = formatDate.bind(this);
			this.forceRerender = reRenderComponent.bind(this);
			this.doLoadImage = loadImage.bind(this);
		}

		componentDidMount(){
			var config = this.props.config;
			this._loading = create(config);
			this._loading
				.then(on_loaded.bind(this))
				.catch(console.error.bind(console));
		}

		componentWillUnmount(){
			if(this._loading)
				this._loading.then(call_destroy);
			else if(this.app)
				this.app.destroy();
		}

		render() {
			const { state } = this;
			const model = state.model;

			const language = !model.preferences ? "language" : model.preferences.language;

			if (state.loading)
				return <Apploader />;


			const resources = {
				loadImage: this.doLoadImage,
				formatDate: this.doFormatDate,
				localize: this.doLocalize,
				localizeFrom: this.doLocalizeFrom,
				config: this.app ? this.app.config : {},
				dispatch: this.state.dispatch || noop,
				listen: this.state.listen || noop,
				unlisten: this.state.unlisten || noop,
				timeoutReset: this.state.timeoutReset || noop,
				api: this.app ? this.app.api : {},
				language: this?.state?.model?.personal?.language || "en",
				events: this.state.events,
				model: model,
			};

			const appWithChime = withChimeMeetingProvider(<ConsumerComponent key={language} model={model} />);
			const appWithProviders = withModelProviders(model, appWithChime);
			const appWithResources = withResources(resources, appWithProviders, this.props.idPrefix || "");
			return appWithResources;
		}
	}

	return AppComponent;

	function create(config) {
		return Dispatcher.createDispatcher(config)
			.then(par(on_get_dispatcher, config));
	}

	function call_destroy(app) {
		return app.destroy();
	}

	function on_loaded(app) {
		this._loading = false;
		var component = this;
		component.app = app;
		var mainModel = app.models.main;

		global.$app = app;

		var timeoutReset = debounce(app.dispatch("passwordtimeout:trigger:reset"), 1000, {
			"leading": true,
			"trailing": false,
			"maxWait": 3000
		});

		component.setState({
			model: mainModel.data || {},
			dispatch: app.dispatch,
			listen: app.listen,
			unlisten: app.unlisten,
			loading: false,
			timeoutReset: timeoutReset,
			api: app.api,
			events: app.dispatcher,
		});

		var updateState = par(performUpdate, component);

		var updateEvent = mainModel.name + ":updated";

		app.dispatcher.on(updateEvent, updateState);

		if(component.props.onLoaded)
			component.props.onLoaded(app);

		if(component.props.onUpdated)
			app.dispatcher.on(updateEvent, component.props.onUpdated);
	}

	function performUpdate(component, data) {
		component.setState({
			model: data
		});
	}

	function on_get_dispatcher(config, dispatcher) {
		AsyncStorage.getItem("DEBUG_EVENTS").then(function (result) {
			if (!result) return;
			dispatcher.onAny(function (event, data) {
				if(!IGNORE_EVENT_SET.has(event))
					console.log("Event:", event, data);
			});
		});

		// Create the new API instance from the config
		var api = aeAPI(config);

		// Create the models from the factory
		var models = factory(config, api, dispatcher);

		// The actual app instance
		var app = {
			config: config,
			api: api,
			dispatcher: dispatcher,
			dispatch: memoize(dispatch),
			listen: listen,
			unlisten: unlisten,
			models: models,
			destroy: destroy
		};

		return app;

		function dispatch(event, data) {
			return function(newData){
				var finalData = (typeof data === "undefined") ? newData : data;
				dispatcher.emit(event, finalData);
				dispatcher.emit("usermetrics:trigger:track", event);
			};
		}

		function listen(event, fn) {
			dispatcher.on(event, fn);
		}

		function unlisten(event, fn) {
			dispatcher.removeListener(event, fn);
		}

		function destroy() {
			// If the app has a view, tear it down
			if (app.view) app.view.teardown();

			// If the app has a buzz instance destroy it
			if (app.buzz) app.buzz.destroy();

			// Destroy the Dispatcher
			app.dispatcher.destroy();

			var logError = console.error.bind(console, "Error tearing down model");

			// Iterate through all the models and destroy them
			mapObject(app.models, function (model) {
				model.destroy().catch(logError);
			});
		}
	}

	function doLocalize(key, args){
		var model = this.state.model;
		var localizationMap = model.localization;

		return localizer(localizationMap, key, args);
	}

	function localizeFrom(map, key, args) {
		var language = this?.state?.model?.personal?.language || "en";
		var localizedMap = map;
		if (typeof map === "object") {
			localizedMap = map[language];
			if (!localizedMap)
				localizedMap = map;

		}

		return localizer(localizedMap, key, args);
	}


	function formatDate(date, options){
		options = options || DEFAULT_DATE_OPTIONS;
		var language = this.state.model.personal.language || "en";
		if(language === "cn_s")
			language = "zh-CN";
		else if(language === "cn_t")
			language = "zh-HK";

		var dateObj = new Date(date);
		return dateObj.toLocaleDateString(language, options);
	}

	function loadImage(file) {
		return new Promise((resolve, reject) => {
			if (!isWeb()) return resolve(file.uri);
			const reader = new FileReader();
			if (file)
				reader.readAsDataURL(file);

			reader.onload = function () {
				resolve(reader.result);
			};
			reader.onerror = function (error) {
				reject(error);
			};
		});
	}

	function isWeb() {
		return Platform.OS === "web";
	}

	function reRenderComponent(data){
		debounce(this.forceUpdate(function() {
			return data;
		}), 500, {
			"leading": true,
			"trailing": false
		});
	}
}

class Apploader extends Component {
	render(){
		return (
			<Loader />
		);
	}
}

function trackEvent(event, data, userMetrics) {
	if (DO_NOT_TRACK_EVENTS.has(event)) return;

	let dataToSendToAmplitude;
	if (shouldIncludeDataInEvent(event, data))
		dataToSendToAmplitude = data;

	userMetrics.trackEvent(event, dataToSendToAmplitude);
}

const DO_NOT_TRACK_EVENTS = new Set(["passwordtimeout:trigger:reset", "keyboard:trigger:close", "keyboard:trigger:open"]);
const PHI_DATA_EVENTS = new Set([]);
const DATA_EVENTS = new Set(["desktop:trigger:page", "contactprofile:trigger:profile:open"]);
function shouldIncludeDataInEvent(event, data) {
	if (!data) return false;
	if (data.constructor.name === "SyntheticBaseEvent") return false;
	if (PHI_DATA_EVENTS.has(event)) return false;
	if (DATA_EVENTS.has(event)) return true;
	return false;
}

const IGNORE_EVENTS = [
	"passwordtimeout:trigger:reset",
	"persiststorage:delete",
];
const IGNORE_EVENT_SET = new Set(IGNORE_EVENTS);


