import React from "react";
import {
	StyleSheet,
	View,
} from "react-native";

import Style from "ae-style";
import StyledText from "ae-base/Base/StyledText";

var styles = StyleSheet.create({
	container: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
		padding: Style.layout.paddingLarge
	},
});
export default function CallMessage(props) {
	var message = props.message;

	return (
		<View style={styles.container}>
			<StyledText contrast largeHeading>{message || ""}</StyledText>
		</View>
	);
}
