import React from "react";

import PageContainer from "../../../Layout/PageContainer";
import MainScroll from "../../../Layout/MainScroll/MainScroll";
import Card from "../../../Layout/Card";
import StyledButton from "ae-base/Base/StyledButton";
import StyledText from "ae-base/Base/StyledText";
import Form from "ae-forms";
import definitionSchema from "../../../../schemas/WorkflowCreation";

import { useResources } from "@aetonix/hooks";

export default function WorkflowCreate(props) {
	const {
		localize,
		dispatch,
	} = useResources();
	var model = props.model;
	var workflow = model.manage.workflow;
	var toast = model.toast;
	var create = workflow.create;
	var cancelTitle = localize("common.cancel");

	var cardTitle = localize("workflow.createCardTitle");
	var closePage = dispatch("managedcreateworkflow:trigger:close");

	var definitionSubmit = dispatch("managedcreateworkflow:trigger:setdefinition");
	var renderCreateDetail = (
		<StyledButton title={cancelTitle} alert onPress={closePage} />
	);

	if(create.selecteddefinition) {
		var definition = create.definition.schema;

		if(definition){
			var optionalActors = definition.optionalActors || [];
			var workflowActors = definition.actors;
			var actorsToRender = (workflowActors && workflowActors !== undefined ) ? Object.keys(workflowActors).filter(function(actor) {
				return actor !== "Patient";
			}) : [];

			var requiredActors = actorsToRender.filter(actor => optionalActors.indexOf(actor) === -1);

			var actorSchemaProperties = actorsToRender.reduce(function(result, actorLabel){
				var actorLocalization = definition.actors[actorLabel];

				if(actorLocalization?.type === "ManageGroup")
					result[actorLabel] = {
						type: "object",
						properties: {
							label: {
								localization: actorLocalization,
								inputType: "output",
								displayType: "title",
							},
							patientGroups: {
								localization: localize("workflow.patientGroups"),
								type: "array",
								minItems: 1,
								ignoreObjectStyle: true,
								items: {
									type: "string",
									inputType: "dropdown",
									sources: ["manageGroups"],
									minLength: 1,
								},
							},
							people: {
								localization: localize("workflow.managers"),
								type: "array",
								minItems: 1,
								ignoreObjectStyle: true,
								items: {
									type: "string",
									inputType: "dropdown",
									sources: ["managedContactsContacts", "managedContactsManagers", "managedManageGroupManagers"],
									minLength: 1,
								},
							},
						},
					};
				else
					result[actorLabel] =  {
						localization: actorLocalization,
						type: "array",
						minItems: 1,
						items: {
							type: "string",
							inputType: "dropdown",
							sources: ["managedContactsContacts", "managedContactsManagers", "managedManageGroupManagers"],
							minLength: 1,
						},
					};

				return result;
			}, {});

			var start = definition.start;
			var defaults = null;
			if(!start.actor && start.action)
				defaults = definition.actions[start.action];

			var creationSchema = {
				"type": "object",
				"required": [
					"actors",
				],
				"properties": {
					"label": {
						"type": "string",
						"description": "workflow.label",
						"placeholder": "workflow.placeholder",
					},
					"actors": {
						"description": "workflow.actors",
						"type": "object",
						"required": requiredActors,
						"properties": actorSchemaProperties,
						"default": {},
					},
				},
			};

			if(defaults)
				creationSchema.properties.defaults = defaults;
		}

		renderCreateDetail = (
			<Form schema={creationSchema} onSubmit={dispatch("managedcreateworkflow:trigger:submit")} sources={model.formsources} onCancel={closePage} cancelText={cancelTitle} />
		);
	}

	return (
		<PageContainer contrast toast={toast}>
			<MainScroll online={model.online}>
				<Card>
					<StyledText bold>{cardTitle}</StyledText>
					<Form
						schema={definitionSchema}
						style={{flex: 1}}
						sources={model.formsources}
						autoSubmit={true}
						onSubmit={definitionSubmit}
					/>
					{renderCreateDetail}
				</Card>
			</MainScroll>
		</PageContainer>
	);
}
