
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";
import {
	View,
	StyleSheet,
} from "react-native";

import Style from "ae-style";
import OfflineBanner from "../Pages/Homepage/OfflineBanner";

var styles = StyleSheet.create({
	modal: {
		position: "absolute",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		backgroundColor: Style.colors.overlay,
		alignItems: "center",
		zIndex: 1,
		overflow: "hidden",
	},
	black: {
		backgroundColor: Style.colors.black
	},
	align: {
		justifyContent: "center",
	},
	offlineBanner: {
		width: '100%',
		position: "absolute",
		top: 0,
		left: 0,
		right: 0,
		zIndex: 1,
	},
});

export default function Modal(props){
	var open = props.open;
	var black = props.black;
	var resize = props.resize;
	const style = props.style;
	if(!open) return (
		null
	);

	var modalStyle = [styles.modal];
	if(black)
		modalStyle.push(styles.black);
	if(!resize)
		modalStyle.push(styles.align);

	if(style)
		modalStyle.push(style);

	var children = props.children;

	const reconnecting = (props.online !== undefined && props.online === false)

	return (
		<View style={modalStyle}>
			<View style={styles.offlineBanner}>
				{reconnecting && <OfflineBanner />}
			</View>
			{children}
		</View>
	);
}