import React from "react";
import {
	View,
	TouchableOpacity,
	Dimensions,
} from "react-native";
import ResponsiveStylesheet from "react-native-responsive-stylesheet";
import Hyperlink from "react-native-hyperlink";
import ImageLoad from "ae-base/Base/ImageLoad";

import MessageSpinner from "./MessageSpinner";

import StyledText from "ae-base/Base/StyledText";
import BlockView from "ae-base/Base/BlockView";
import StyledIcon from "ae-base/Base/StyledIcon";

import Style from "@aetonix/styles";
import Breakpoints from "../../../Breakpoints";

import { useResources } from "@aetonix/hooks";

var styles = ResponsiveStylesheet.createSized("min-direction", {
	0: {
		message: {
			borderRadius: 12,
			paddingHorizontal: Style.layout.paddingLarge / 2,
			paddingVertical: Style.layout.paddingMedium / 2,
			backgroundColor: Style.look.chatBackground,
			overflow: "hidden",
		},
		messageContainer: {
			flex: 3,
		},
		atouchawayMessage: {
			borderWidth: 1,
			flex: 1,
			alignItems: "flex-end",
			justifyContent: "flex-end",
			backgroundColor: Style.colors.semiDarkGrey,
		},
		atouchawayMessageAlternate: {
			alignItems: "flex-start",
			justifyContent: "flex-start",
			backgroundColor: Style.colors.semiDarkBlue,
		},
		messageAlternate: {
			backgroundColor: Style.look.chatBackgroundAlternate,
		},
		chatMessage: {
			flexDirection: "row",
			marginTop: Style.layout.marginSmall,
		},
		chatMessageAlternate: {
			flexDirection: "row-reverse",
		},
		timestamp: {
			marginHorizontal: Style.layout.marginMedium / 2,
		},
		spacer: {
			flex: 1,
		},
		timeDateStamp: {
			flexDirection: "row",
		},
		link: {
			color: Style.colors.grayblue,
		},
		pending: {
			marginLeft: 57,
			marginRight: Style.layout.marginSlim,
		},
		textContainer: {
			flexDirection: "column",
		},
		image: {
			height: 128,
			width: 128,
			borderRadius: 12,
		},
		system: {
			backgroundColor: Style.colors.lightOrange,
		},
		chatFileMessage: {
			alignItems: "flex-start",
		},
		fileButton: {
			margin: Style.layout.marginSlim,
			alignItems: "stretch",
		},
		fileContainer: {
			borderRadius: 12,
			alignItems: "center",
			justifyContent: "center",
			height: 128,
			width: 128,
		},
		fileContainerGrey: {
			backgroundColor: Style.colors.itemGray,
		},
	},
	512: {
		message: {
			paddingHorizontal: Style.layout.paddingLarge,
			paddingVertical: Style.layout.paddingMedium,
		},
		timestamp: {
			marginHorizontal: Style.layout.marginMedium,
		},
		spacer: {
			width: Style.layout.marginContent,
		},
		pending: {
			marginLeft: 98,
			marginRight: Style.layout.marginSmall,
		},
	},
});

export default function Message(props) {
	const {
		localize,
		dispatch,
	} = useResources();
	var content = props.content;
	var alternate = props.alternate;
	var timestamp = props.timestamp;
	var expiry_date = props.dateExpiry;
	var expiry = props.expiry;
	var formattedDate = props.formattedDate;
	var pending = props.pending;
	var file = props.file;
	var metadata = props.metadata;
	var isSystem = props.system;
	var isAta = props.atouchaway;

	var messageWidth = {
		width: Dimensions.get("window").width / 1.5,
	};

	var messageStyle = [styles.message];
	if(alternate)
		messageStyle.push(styles.messageAlternate);
	if(pending)
		messageStyle.push(styles.pending);

	var containerStyle = [styles.chatMessage];
	if(alternate)
		containerStyle.push(styles.chatMessageAlternate);
	if(file)
		containerStyle.push(styles.chatFileMessage);

	var displayContent = content;
	if(isSystem){
		var systemContent = localize(getSystemText(metadata), metadata.data);
		displayContent = systemContent;
	}

	var openLink = dispatch("linkopener:trigger:open");

	var narrowScreen = Dimensions.get("window").width < 650;
	var timedateSize = (Breakpoints.smallerThan("mobile") || narrowScreen) ? "tiny" : "small";

	var renderContent = (
		<Hyperlink style={messageStyle} onPress={openLink} linkStyle={styles.link} >
			<StyledText black selectable>{displayContent}</StyledText>
		</Hyperlink>
	);

	if(isAta){
		renderContent = (
			<View style={styles.messageContainer}>
				<StyledText contrast style={messageStyle} selectable={false}>{displayContent}</StyledText>
			</View>
		);
		messageStyle.push(styles.atouchawayMessage);
		if(alternate)
			messageStyle.push(styles.atouchawayMessageAlternate);
	} else
		messageStyle.push(messageWidth);


	if(file){
		var id = file.id;
		renderContent = (
			<FileItem expiry={expiry} isAta={isAta} expiryDate={expiry_date} pending={pending} key={id} file={file} />
		);
	}

	return (
		<View style={containerStyle}>
			{narrowScreen ? null : <BlockView style={styles.spacer} />}
			<View style={styles.timestamp}>
				<StyledText size={timedateSize} black={!isAta} contrast={isAta} style={styles.timestamp}>{timestamp}</StyledText>
				<StyledText size={timedateSize} black={!isAta} contrast={isAta} style={styles.timestamp}>{formattedDate}</StyledText>
			</View>
			{renderContent}
			<MessageSpinner open={pending} />
		</View>
	);
}

function FileItem(props) {
	const {
		localize,
		dispatch,
	} = useResources();

	var file = props.file;
	var fileName = file.name;
	var format = file.format;
	var pending = props.pending;
	var expiresAt = file.expires_at;
	var expiryDate = props.expiryDate;
	var isAta = props.isAta;
	var expiresDate = new Date(expiresAt);
	var currentDate = new Date();

	var expired = expiresDate <= currentDate;

	var name = (fileName.split("-").slice(-1)[0]) || "";
	var extension = name.split(".").slice(-1) || "";

	if(name.length > 9)
		name = name.slice(0, 9) + "[...]." + extension;

	var openExternal;
	if(!expired && !pending)
		openExternal = dispatch("fileviewer:trigger:view", {
			file: file,
		});

	var expiredText = localize("messaging.expired");
	var expiresText = localize("messaging.expires");
	var fileUploading = localize("messaging.fileuploading");

	var messageStyle = [styles.boxWidth];
	if(pending)
		messageStyle.push(styles.pending);

	var source = {
		uri: file.url,
	};

	var expiredSize = Breakpoints.smallerThan("mobile") ? "verySmall" : "small";
	var notExpired = !expired && !pending;

	var icon = "file";
	if(format === "video")
		icon = "video";
	else if(format === "image")
		icon = "image";

	var renderedExpiredText = expiredText;
	if(notExpired)
		renderedExpiredText = expiresText + expiryDate;

	var renderExpiry = pending ? null : (
		{ ...(isAta ? (<StyledText size={expiredSize} yellow style={styles.expired}>{renderedExpiredText}</StyledText>) : (<StyledText size={expiredSize} black style={styles.expired}>{renderedExpiredText}</StyledText>))}
	);

	var renderFile = (
		<FileIcon expired={expired} icon={icon} />
	);

	if(format === "image" && notExpired)
		renderFile = (
			<ImageLoad style={styles.image} resizeMethod="resize" resizeMode="cover" source={source} />
		);

	var renderFileName = null;
	if (pending){
		renderFile = (
			<StyledText black style={messageStyle}>{fileUploading}</StyledText>
		);
		renderFileName = (
			<StyledText size={expiredSize} grey style={messageStyle}>{name}</StyledText>
		);
	}

	return (
		<TouchableOpacity style={styles.fileButton} onPress={openExternal}>
			{renderFile}
			<View style={styles.textContainer}>
				{renderFileName}
				{renderExpiry}
			</View>
		</TouchableOpacity>
	);
}

function FileIcon(props){
	var icon = props.icon;
	var iconSize = "hugeHeading";
	var children = props.children;
	var expired = props.expired;
	var containerStyle = [styles.fileContainer, styles.boxWidth];
	if(expired)
		containerStyle.push(styles.fileContainerGrey);

	return (
		<View style={containerStyle}>
			<StyledIcon size={iconSize} name={icon} />
			{children}
		</View>
	);
}

function getSystemText(metadata){
	var type = metadata.type;
	if(type === "GroupPersonAdd")
		return "groupchat.hasbeenadded";
	else if(type === "GroupPersonLeft")
		return "groupchat.hasleft";
	else if(type === "LiveChatEnd")
		return "livechat.systemend";
	else if(type === "LiveChatAccept")
		return "livechat.systemaccept";
	else if(type === "LiveChatCreateAvailable")
		return "livechat.systemcreateavail";
	else if(type === "LiveChatCreate")
		return "livechat.systemcreate";
}
