import React, { useMemo, useCallback, useRef, useState, useEffect, useContext } from 'react';
import { createStyled } from '@emotion/primitives-core'
import { variant } from 'styled-system';
import { StyleSheet, View, FlatList, PanResponder, Pressable, Animated, TouchableOpacity, Platform } from 'react-native';
import useBoolean from '../../../hooks/useBoolean/useBoolean';
import TeamsVideoGrid from './VideoPresentation';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faCompress, faExpand } from '@fortawesome/free-solid-svg-icons';
import ResponsiveText from '../Text/ResponsiveText';
import AvatarGroup from '../Avatar/AvatarGroup';
import MeetingContext from '../../context/MeetingContext';
import useMeetingSettings from '../../hooks/useMeetingSettings/useMeetingSettings';
import { useLocalize } from "../../../hooks/useLocalize/LocalizeContext";

const minCols = 1;
const tileMargin = 4;
const tileMinWidth = 300;
const selfOpacityMax = 1;
const selfZIndex = 5;
const selfOpacityDefault = 0.1;

const SELF_WIDTH = 300;
const SELF_HEIGHT = 200;

const SELF_SIZES = { minimized: 150, regular: 300 };

const getNearestPoint = (point, points) => {
	const getPointDistance = (currentPoint) => Math.abs(point - currentPoint);

	const distanceDeltas = points.map((p) => getPointDistance(p));
	const minimumDistanceDelta = Math.min(...distanceDeltas);

	return points.find((point) => getPointDistance(point) === minimumDistanceDelta) ?? 0;
};

export const getColumnCountForWidth = (width, count) => {
	const maxColumns = 5;
	const isSingleTile = count === 1;
	if (isSingleTile) {
		return 1;
	}

	const cols = width / tileMinWidth;
	const numColumnsForMinimumFit = Math.floor(cols) > minCols ? Math.floor(cols) : minCols;
	const numColumnsForEvenSplit = Math.ceil(count / 2);

	const isWithinOptimizedTileThreshold = count >= 2 && count <= 4; // 2 - 4 tiles
	if (isWithinOptimizedTileThreshold) {
		const isScreenCapableOfFittingTwoTiles = numColumnsForMinimumFit >= 2;
		return isScreenCapableOfFittingTwoTiles ? 2 : 1;
	}

	return Math.min(numColumnsForMinimumFit, numColumnsForEvenSplit, maxColumns);
};

const getTileWidth = (columns, _length, gridWidthInPixels) => {
	const shouldUseMinimumWidth = gridWidthInPixels < tileMinWidth;
	if (shouldUseMinimumWidth) {
		return tileMinWidth;
	}

	const getBoundedWidth = (maxComputedSize, minimumComputedSize) => Math.max(Math.min(maxComputedSize, minimumComputedSize), tileMinWidth);

	const minimumComputedSize = Math.floor(gridWidthInPixels / (columns + 0.5));
	const maxWidthWithMargin = (gridWidthInPixels / columns) - (tileMargin * 2);

	return getBoundedWidth(maxWidthWithMargin, minimumComputedSize);
}

const styled = createStyled(StyleSheet);

const Container = styled(View)({
	flex: 1,
	width: '100%',
	height: '100%',
	backgroundColor: '#023020B3',
	justifyContent: 'center',
	alignItems: 'center',
	padding: 5,
	position: 'relative',
});

const SelfContainer = styled(Animated.View)({
	zIndex: selfZIndex,
	elevation: selfZIndex + 1,
	position: 'absolute',

	borderWidth: 2,
	color: 'white',
	borderRadius: 5,
	borderColor: 'white',

	minWidth: 150,
	aspectRatio: 16 / 9,

	right: 0,
	bottom: 0,
	marginRight: 20,
	marginBottom: 20,
}, variant({
	prop: 'type',
	variants: {
		minimized: { width: SELF_SIZES.minimized, aspectRatio: 16 / 9 },
		regular: { width: SELF_SIZES.regular, aspectRatio: 16 / 9 },
	}
}));

const SelfInteractive = styled(Pressable)({
	width: '100%',
	height: '100%',
});

const SelfMinimzedButton = styled(TouchableOpacity)({
	height: 30,
	width: 30,
	position: 'absolute',
	left: 0,
	top: 0,
	zIndex: selfZIndex + 1,
	marginLeft: 10,
	marginTop: 10,
})

const PlaceholderText = styled(ResponsiveText)({
	color: 'white',
	textAlign: 'center',
});

const VideoTileGridItem = styled(View)({
	margin: tileMargin,
	minWidth: 300,
	aspectRatio: 16 / 9,

	// Add outline for the grid
	borderWidth: 2,
	borderColor: 'white',
	borderStyle: 'solid',
	borderRadius: 4,
});

const VideoTileGrid = styled(FlatList)({
	width: 'auto',
	height: 'auto',
	maxWidth: '100%',
	maxHeight: '100%',
	flexGrow: 0,
});

const VideoGridEmptyStateContainer = styled(View)({
	padding: 20,
	justifyContent: 'center',
	alignItems: 'center'
});

const VideoGridEmptyState = () => {
	const localize = useLocalize();
	const { members: participants } = useContext(MeetingContext);

	return (
		<VideoGridEmptyStateContainer>
			<PlaceholderText>{localize('meeting.views.grid.default')}</PlaceholderText>
			<AvatarGroup colour="#375348" size="small" items={participants} align="center" />
		</VideoGridEmptyStateContainer>
	);
}

const SelfVideoTile = ({ renderTile, dimensions }) => {

	const {
		isSelfMinimized,
		setIsSelfMinimized,
		isSelfFocused,
		setIsSelfFocusedTrue,
		setIsSelfFocusedFalse,
	} = useMeetingSettings();

	const platformOpacityDefault = Platform.OS === 'android' ? 1 : selfOpacityDefault;

	const selfVideoRef = useRef(null);
	const opacityAnim = useRef(new Animated.Value(platformOpacityDefault)).current;

	const startOpacityAnimation = () => {

		if (Platform.OS === 'android') {
			return; // Dont animate on android
		}

		Animated.timing(opacityAnim, {
			toValue: selfOpacityDefault,
			duration: 200,
			useNativeDriver: false,
		}).start();

		setIsSelfFocusedFalse();
	}

	const endOpacityAnimation = () => {

		if (Platform.OS === 'android') {
			return; // Dont animate on android
		}

		Animated.timing(opacityAnim, {
			toValue: selfOpacityMax,
			duration: 200,
			useNativeDriver: false,
		}).start();

		setIsSelfFocusedTrue();
	}

	/* const onPanResponderGrant = (_event, gestureState) => pan.setOffset({ x: pan.x._value, y: pan.y._value });
	const onPanResponderRelease = (_event, gestureEvent) => {
		const snapPointsX = [0, dimensions.width - SELF_WIDTH];
		const snapPointsY = [0, dimensions.height - SELF_HEIGHT];

		const nearestX = getNearestPoint(pan.x._value, snapPointsX);
		const nearestY = getNearestPoint(pan.y._value, snapPointsY);

		console.log({
			container: { width: dimensions.width, height: dimensions.height },
			gesture: { dx: gestureEvent.dx, dy: gestureEvent.dy },
			points: { x: snapPointsX, y: snapPointsY },
			nearest: { nearestX, nearestY },
			nativeGesture: gestureEvent,
		})

		pan.flattenOffset();

		Animated.spring(pan, { toValue: { x: nearestX, y: nearestY }, useNativeDriver: false }).start();
	}

	const pan = useRef(new Animated.ValueXY({ x: 0, y: 0 })).current;

	const panResponder = useRef(PanResponder.create({
		onMoveShouldSetPanResponder: () => true,
		onStartShouldSetPanResponder: () => true,
		onPanResponderMove: Animated.event([null, { dx: pan.x, dy: pan.y }]),
		onPanResponderGrant,
		onPanResponderRelease,
	})).current; */

	const type = isSelfMinimized ? 'minimized' : 'regular';

	useEffect(() => {
		if (!dimensions.width || isSelfMinimized) {
			return;
		}

		const shouldAutoMinimize = SELF_SIZES[type] > (dimensions.width / 2.5);
		if (!shouldAutoMinimize) {
			return;
		}

		setIsSelfMinimized(true);
	}, [dimensions.width]);

	return (
		<SelfContainer
			testID="video-self-container"
			type={type}
			ref={selfVideoRef}
			style={{ opacity: opacityAnim/* , transform: [{ translateX: pan.x }, { translateY: pan.y }], */ }}
			/* {...panResponder.panHandlers} */
		>
			<SelfInteractive
				onHoverIn={endOpacityAnimation}
				onHoverOut={startOpacityAnimation}
				onPress={() => { setIsSelfFocusedTrue(); endOpacityAnimation() }}
				onBlur={() => { setIsSelfFocusedFalse(); startOpacityAnimation() }}
			>
				<SelfMinimzedButton onPress={() => setIsSelfMinimized(prevMin => !prevMin)}>
					<FontAwesomeIcon size={20} color='#F9F7F5' icon={isSelfMinimized ? faExpand : faCompress} />
				</SelfMinimzedButton>

				{renderTile()}
			</SelfInteractive>
		</SelfContainer>
	);
}

export default ({
	layout = 'standard',
	orientation = 'vertical',
	tiles,
	renderTile,
}) => {

	if (layout === 'teams') {
		return <TeamsVideoGrid orientation={orientation} tiles={tiles} renderTile={renderTile} />
	}

	const containerRef = useRef(null);
	const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

	const selfTile = useMemo(() => tiles?.find(tile => !!tile.isSelf) || null, [tiles]);
	const tilesWithoutSelf = useMemo(() => tiles?.filter?.(tile => !tile.isSelf) ?? [], [tiles]);
	const hasTilesToShow = tilesWithoutSelf.length > 0;

	const columns = useMemo(() => getColumnCountForWidth(dimensions.width, tilesWithoutSelf.length), [getColumnCountForWidth, dimensions.width, tilesWithoutSelf.length]);

	const gridTileWidth = useMemo(() => getTileWidth(columns, tilesWithoutSelf.length, dimensions.width), [
		getTileWidth,
		tilesWithoutSelf.length,
		dimensions.width,
	]);

	const renderItem = useCallback(({ item }) => (
		<VideoTileGridItem style={{ minWidth: gridTileWidth, width: gridTileWidth, aspectRatio: 16 / 9 }} testID="teams-video-tile-container" key={`teams-tile-container-${item.externalId}`}>
			{ renderTile(item) }
		</VideoTileGridItem>
	), [renderTile, gridTileWidth]);

	const onChangeGridLayout = useCallback(event => {
		const viewDimensions = event?.nativeEvent?.layout
		if (! viewDimensions) {
			return;
		}

		const { width, height } = viewDimensions;

		setDimensions({ width, height });
	});

	return (
		<Container ref={containerRef} testID="video-grid-container" onLayout={onChangeGridLayout}>
			{ selfTile && <SelfVideoTile renderTile={() => renderTile(selfTile)} dimensions={dimensions} /> }
			{ hasTilesToShow ? (
				<VideoTileGrid
					key={`video-tile-grid-cols-${columns}`}
					data={tilesWithoutSelf}
					numColumns={columns}
					renderItem={renderItem}
					contentContainerStyle={{ alignItems: 'center', justifyContent: 'center' }}
					keyExtractor={item => `columns-${item?.externalId}`}
				/>
			) : <VideoGridEmptyState/> }
		</Container>
	);
};