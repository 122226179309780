import PropTypes from "prop-types";
import React from "react";
import {
	View,
	StyleSheet,
	Platform,
} from "react-native";
import debounce from "debounce";
import Style from "ae-style";

import { useResources } from "@aetonix/hooks";

var styles = StyleSheet.create({
	container: {
		backgroundColor: Style.colors.black,
		flex: 1,
		position: "absolute",
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		flexDirection: "column",
	},
	contrast: {
		backgroundColor: Style.colors.primary,
	},
	center: {
		justifyContent: "center",
		alignItems: "center",
	},
});

export default function AtaContainer(props) {
	const { dispatch } = useResources();
	var styleList = [styles.container];
	if(props.contrast)
		{styleList.push(styles.contrast);}
	if(props.center)
		{styleList.push(styles.center);}

	var timeoutReset = debounce(dispatch("screensaver:trigger:clear"), 1000);

	let responderProps = {};
	if (Platform.OS === "android") {
		responderProps = {
			onStartShouldSetResponderCapture: timeoutReset,
			onMoveShouldSetResponderCapture: timeoutReset,
		};
	} else {
		responderProps = {
			onStartShouldSetResponder: () => true,
			onResponderRelease: timeoutReset,
			onResponderMove: timeoutReset,
		};
	}

	return (
		<View
			style={styleList}
			{...responderProps}
		>
			{props.children}
		</View>
	);
}

AtaContainer.propTypes = {
	children: PropTypes.any,
};