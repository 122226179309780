import React, { Component } from 'react';
import { Text } from "react-native";
import * as Sentry from '@sentry/react-native';

export default class RootErrorBoundary extends Component {
	constructor(props) {
		super(props);

		this.state = { error: null, errorInfo: null };
	}

	componentDidCatch(error, errorInfo) {
		this.setState({ error, errorInfo }, () => {
			Sentry.captureException(error);
		});
	}

	render() {
		const { children } = this.props;
		return children;
	}
}