import { useContext } from 'react';
import { SelfContext } from './SelfContext';

export default function useSelf(){
    const self = useContext(SelfContext);

    if (!self) {
        throw new Error('useSelf must be used within a SelfProvider');
    }

    return self;
}