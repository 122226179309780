import React from "react";

import Banners from "../Banners";

export default function UrgencyBanner(props) {
	var banner = props.banner || [];
	var who = props.who;

	var urgencyBanners = banner.filter(function(banners){
		return banners.banner_type === "urgency:call" && banners.from._id === who._id;
	});

	return (
		<Banners banner={urgencyBanners} />
	);
}
