
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";
import {
	View,
	StyleSheet,
} from "react-native";
import ResponsiveStylesheet from "react-native-responsive-stylesheet";
import Breakpoints from "../../../Breakpoints";
import SquareButton from "ae-base/Base/SquareButton";
import Heading from "ae-base/Base/Heading";
import NotificationIcon from "ae-base/Base/NotificationIcon";
import Style from "ae-style";

import { useResources } from "@aetonix/hooks";

var MAX_BUTTONS = 6;

var styles = StyleSheet.create({
	buttons: {
		flexDirection: "row",
		flexWrap: "wrap",
		justifyContent: "flex-start",
		alignSelf: "center",
	},
	notification: {
		position: "absolute",
		top: 0,
		right: 0,
	},
	green: {
		borderWidth: 2,
		borderColor: Style.colors.green,
	},
	red: {
		borderWidth: 2,
		borderColor: Style.colors.red,
	},
	yellow: {
		borderWidth: 2,
		borderColor: Style.colors.yellow,
	},
});

var responsiveStyles = makeIncrements(MAX_BUTTONS);

export default function ButtonGridHealth(props) {
	const {
		localize,
		dispatch,
		localizeFrom,
		language,
	} = useResources();

	var status = props.status || {};
	var title = props.title;
	var buttons = props.buttons || [];
	const customIndicatorsButtons = props.customIndicatorsButtons || [];

	var permissions = props.permissions || {};
	var contact = props.contact || {};
	var newsections = props.newsections || [];
	const group = props.group;

	var ownCareplan = props.ownCareplan;

	var who = contact._id;

	var screen = props.screen;

	if(!who) return (
		null
	);

	if (customIndicatorsButtons.length > 0)
		buttons = [...buttons, ...customIndicatorsButtons];


	let validButtons = buttons.filter(function (button) {
		var permissionName = button.permission;
		var permission = permissions[permissionName];
		if(!permission) return false;
		return true;
	});

	if(!validButtons.length) return null;

	var renderedTitle = title  ? (
		<Heading title={title} screen={screen} />
	) : null;

	var renderedButtons = validButtons.map(function(button){
		var indicator = button.indicator;
		var permissionName = button.permission;
		var trigger = button.trigger;
		var section = button.section;
		const isCustomIndicator = button.isCustomIndicator;

		var buttonStatus = (status[indicator] || {}).status;

		if(indicator === "bloodoxygen"){
			var oxygenStatus = (status.oxygensaturation || {}).status;
			var heartStatus = (status.heartrate || {}).status;
			buttonStatus = oxygenStatus >= heartStatus ? oxygenStatus : heartStatus;
		}
		if(indicator === "bloodpressure"){
			var bpStatus = (status.bloodpressure || {}).status;
			var bpPulserateStatus = (status.bpPulserate || {}).status ?? -1;
			buttonStatus = Math.max(bpStatus, bpPulserateStatus);
		}

		var hasNotification = newsections[permissionName] ? (
			<NotificationIcon style={styles.notification} title="!" />
		) : null;

		var triggerPayload = {
			who,
			group,
		};

		if(ownCareplan)
			triggerPayload.section = section;
		else
			if(permissionName.indexOf("careplan_") === 0)
				triggerPayload.section = permissionName.slice(9);


		if(typeof button.title.en === "object") {
			button.title.cn_s = button.title.en.cn_s;
			button.title.cn_t = button.title.en.cn_t;
			button.title.es = button.title.en.es;
			button.title.fr = button.title.en.fr;
			button.title.pt = button.title.en.pt;
			button.title.en = button.title.en.en;
		}

		var buttonTitle = localize(button.title) || permissionName;
		var icon = button.icon;
		var action = dispatch(trigger, triggerPayload);

		if (isCustomIndicator) {
			buttonTitle = localizeFrom(button.title, ".") || localize("myhealth.indicator");
			action = dispatch(trigger, {
				indicatorId: indicator,
				indicatorUnit: button.unit,
				who,
				group,
			});
		}

		// Default icon size is specified here.

		var textSize = 12;
		var iconSize = Style.imageSize.tiny;

		if(Breakpoints.smallerThan("small"))
			iconSize = Style.imageSize.icon;


		var buttonStyle = [];
		if(buttonStatus === 0)
			buttonStyle = styles.green;
		if(buttonStatus === 1)
			buttonStyle = styles.yellow;
		if(buttonStatus === 2)
			buttonStyle = styles.red;

		var titleLength = buttonTitle.length;
		var titleLengthThreshold = 25;
		if (titleLength > titleLengthThreshold && language === "ta")
			textSize = 9;


		let key = button.isCustomIndicator ? `customIndicator_${button.indicator}` : button.title;

		return (
			<SquareButton
				key={key}
				title={buttonTitle}
				style={buttonStyle}
				textSize={textSize}
				icon={icon}
				iconSize={iconSize}
				onPress={action}>
				{hasNotification}
			</SquareButton>
		);
	});

	return (
		<View style={responsiveStyles.container}>
			{renderedTitle}
			<View style={styles.buttons}>
				{renderedButtons}
			</View>
		</View>
	);
}

function makeIncrements(buttons) {
	var i = buttons;
	var increment = Style.layout.squareButton + Style.layout.marginSmall;
	var definition = {};

	while(i){
		var size = increment * i;

		definition[size] = {
			container: {
				width: size,
			},
		};

		i--;
	}

	return ResponsiveStylesheet.createSized("min-width", definition);
}
