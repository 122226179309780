import React from "react";
import {
	StyleSheet
} from "react-native";

import BlockView from "ae-base/Base/BlockView";
import Style from "@aetonix/styles";

var styles = StyleSheet.create({
	group: {
		marginBottom: Style.layout.marginMedium,
		borderBottomWidth: 2,
		borderColor: Style.colors.tertiary,
	}
});

export default function Group(props){
	return (
		<BlockView style={styles.group}>
			{props.children}
		</BlockView>
	);
}
