export default {
	title: "MultiFactorAuth",
	description: "schemas_multifactor_title",
	type: "object",
	properties: {
		authcode: {
			order: 0,
			description: "login.authcode",
			type: "string",
			inputType: "text",
			format: "string",
			maxLength: 5
		},
	}
};
