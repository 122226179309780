import PropTypes from "prop-types";
import React from "react";
import {
	View,
	StyleSheet,
} from "react-native";

import Form from "ae-forms";
import Style from "ae-style";
import BraceletSettingsSchema from "../../../schemas/BraceletSettings";
import StyledButton from "ae-base/Base/StyledButton";
import StyledText from "ae-base/Base/StyledText";
import Indicator from "ae-base/Base/Indicator";
import BlockView from "ae-base/Base/BlockView";
import BraceletPressContact from "./BraceletPressContact";

import ManagedSection from "./ManagedSection";
import StyledIcon from "ae-base/Base/StyledIcon";
import ListItem from "ae-base/List/ListItem";

import { useResources } from "@aetonix/hooks";

var styles = StyleSheet.create({
	main: {
		flexDirection: "row",
		flex: 1,
	},
	icon: {
		marginRight: Style.layout.paddingSmall,
	},
	marginLeft: {
		marginLeft: 4,
	},
	mainContent: {
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
	},
	subTitle: {
		flex: 1,
	},
	macAddress: {
		backgroundColor: "#EDF2F3",
		marginVertical: 4,
	},
	padded: {
		paddingVertical: 10,
		paddingLeft: 10,
	},
});

export default function Managedbracelet(props) {
	const {
		localize,
		dispatch,
	} = useResources();

	var model = props.model;
	var toast = model.toast;
	var defaultData = model.manage.bracelet;
	var scanning = defaultData.scanning;
	var scanned_devices = defaultData.scanned_devices || [];
	var renderScanButton = null;
	var renderScanning = null;
	var contact = model.manage.account;
	var mac = defaultData.mac;
	var contactData = defaultData.contactData || {};

	var screen = model.size;
	var scan = localize("managedbracelet.scan");
	var cancel = localize("managedbracelet.cancel");
	var braceletsettings = localize("managedbracelet.braceletsettings");
	var braceletmac = localize("managedbracelet.braceletmac");
	var clear = localize("managedbracelet.clear");
	var nodeviceconnected = localize("managedbracelet.nodeviceconnected");

	var renderFoundDevice = scanned_devices.map((device) =>
		<Devicefound key={device.id} device={device} />
	);

	if(!scanning)
		renderScanButton = (
			<BlockView style={styles.marginLeft}>
				<StyledButton title={scan} onPress={dispatch("managedbracelet:trigger:scan:start")} />
			</BlockView>
		);
	else{
		renderScanButton = (
			<BlockView style={styles.marginLeft}>
				<StyledButton title={cancel} onPress={dispatch("managedbracelet:trigger:scan:stop")} />
			</BlockView>
		);
		renderScanning = (
			<View>
				<Indicator />
				{renderFoundDevice}
			</View>
		);
	}

	var loadedKey = Object.keys(defaultData || {}).length;

	return (
		<ManagedSection online={model.online} contact={contact} title={braceletsettings} toast={toast}>
			<View style={styles.mainContent}>
				<StyledText emphasized bold style={styles.subTitle}>{braceletmac}</StyledText>
				<StyledButton title={clear} onPress={dispatch("managedbracelet:trigger:unassociate")} />
				{renderScanButton}
			</View>
			<View style={styles.macAddress}>
				<StyledText style={styles.padded}>{mac || nodeviceconnected}</StyledText>
			</View>
			{renderScanning}
			<BlockView>
				<Form key={loadedKey} schema={BraceletSettingsSchema} defaultData={defaultData} onSubmit={dispatch("managedbracelet:trigger:update")} />
			</BlockView>
			<BraceletPressContact isContact={defaultData.press} contactData={contactData} screen={screen} />
		</ManagedSection>
	);
}

function Devicefound(props){
	const { localize, dispatch } = useResources();
	var device = props.device;
	var name = device.name;
	var id = device.id;
	var connect = localize("managedbracelet.connect");

	return (
		<ListItem>
			<View style={styles.main}>
				<BlockView style={styles.icon}>
					<StyledIcon name="aeto-watch" />
				</BlockView>
				<BlockView>
					<StyledText bold>{name}</StyledText>
				</BlockView>
			</View>
			<BlockView>
				<StyledButton title={connect} onPress={dispatch("managedbracelet:trigger:associate", {
					mac: id,
				})}  />
			</BlockView>
		</ListItem>
	);
}

Managedbracelet.propTypes = {
	model: PropTypes.object,
};

Devicefound.propTypes = {
	device: PropTypes.object,
};
