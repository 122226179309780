import React from "react";

import CardPage from "../../../Layout/CardPage";
import StyledButton from "ae-base/Base/StyledButton";
import StyledText from "ae-base/Base/StyledText";
import ConfirmationModal from "ae-base/Base/ConfirmationModal";
import Form from "ae-forms";

import ReminderMakerSchema from "../../../../schemas/ReminderMaker";

import { useResources } from "@aetonix/hooks";

var DEFAULT_DATA = {
	time: {
		hours: "0",
		minutes: "0",
	},
	days: {
		all: true,
	},
	months: new Date().toJSON(),
	responseType: "confirm",
	repeat: 0,
	notification: 0,
	month: false,
};

export default function Remindermaker(props) {
	const {
		localize,
		dispatch,
	} = useResources();

	var model = props.model;

	var toast = model.toast;
	var manage = model.manage;
	var reminder_maker = manage.reminder_maker || {};
	var editing = reminder_maker.editing;
	var creating = reminder_maker.creating;
	var reminders = manage.reminders;
	var removing = reminders && reminders.removing;

	var screen = model.size;

	var defaultData = DEFAULT_DATA;
	var action = null;

	var deleteButton = null;
	var deleteReminder, confirmDelete, cancelDelete;
	var deletetext = localize("remindermaker.delete");
	var title = localize("remindermaker.title");
	var confirmtext = localize("remindermaker.confirmtext");
	var canceltext = localize("remindermaker.canceltext");
	var remindersetup = localize("remindermaker.remindersetup");
	var discardchanges = localize("remindermaker.discardchanges");
	var remove = localize("remindermaker.remove");

	if (editing){
		defaultData = editing;
		defaultData.monthSwitch = !!editing.monthSwitch;
		if(!editing.months) defaultData.months = DEFAULT_DATA.months;
		action = dispatch("managedremindermaker:trigger:edit:finish");
		deleteReminder = dispatch("managedreminders:trigger:remove:start", {
			reminder: editing._id,
		});
		deleteButton = (
			<StyledButton title={deletetext} icon="delete" alert onPress={deleteReminder} />
		);
	} else if (creating)
		action = dispatch("managedremindermaker:trigger:add:finish");


	var shouldRemove = removing && editing && (removing === editing._id);

	if(shouldRemove){
		confirmDelete = dispatch("managedreminders:trigger:remove:confirm", {
			reminder: removing,
		});
		cancelDelete = dispatch("managedreminders:trigger:remove:cancel");
	}

	var goBack = dispatch("desktop:trigger:page:back");

	var confirmation = (
		<ConfirmationModal
			open={shouldRemove}
			title={title}
			titleIcon="warning"
			confirmText={confirmtext}
			cancelText={canceltext}
			onConfirm={confirmDelete}
			onCancel={cancelDelete}
		>
			<StyledText contrast>{remove}</StyledText>
		</ConfirmationModal>
	);

	var formKey = editing && (editing._id + editing.updated_at);
	var loadedKey = Object.keys(defaultData || {}).length;

	return (
		<CardPage title={remindersetup} icon="notifications" edit={deleteButton} external={confirmation} toast={toast} screen={screen} online={model.online}>
			<Form key={formKey + loadedKey} schema={ReminderMakerSchema} defaultData={defaultData} onSubmit={action} onCancel={goBack} cancelText={discardchanges} />
		</CardPage>
	);
}
