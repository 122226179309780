import { useState, useMemo, useEffect, useCallback, useRef } from 'react';
import {
	useMeetingManager,
	useLocalVideo,
	useRemoteVideoTileState,
	useAudioVideo,
    useRosterState,
	useMeetingEvent,
	useActiveSpeakersState,
	useAudioOutputs,
	useMeetingStatus
} from "amazon-chime-sdk-component-library-react";
import { fetchMeeting } from '../../libraries/useCallManager.web.library';
import * as Sentry from '@sentry/react-native';
import MeetingError from '../../MeetingError';
import useMeetingSettings from '../useMeetingSettings/useMeetingSettings';
import { MeetingSessionConfiguration } from 'amazon-chime-sdk-js';

export default (meeting, isInMeeting, user, members, pinning, onLeaveMeeting, getUserType, setError) => {
	
	const { name } = user;
    const { externalMeetingId, _id: id } = meeting || {};
	const { layout, isActiveSpeakerEnabled } = useMeetingSettings();
	
    const [selfAttendeeId, setSelfAttendeeId] = useState(null);
    const [orientation, setOrientation] = useState('horizontal');
    const [isMuted, setIsMuted] = useState(true);
    const [isCameraOn, setIsCameraOn] = useState(false);

	// Meeting management
	const meetingManager = useMeetingManager();
	const meetingEvent = useMeetingEvent();
	const previousMeetingEvent = useRef(null);
	const audioVideo = useAudioVideo();
	const activeSpeakers = useActiveSpeakersState();

	const { isVideoEnabled, toggleVideo } = useLocalVideo();
	const { devices, selectedDevice } = useAudioOutputs();
	const { roster } = useRosterState();
	const { tiles: remoteTiles, tileIdToAttendeeId: remoteTileIdToAttendeeId } = useRemoteVideoTileState();

	const shouldJoinMeeting = id && externalMeetingId && isInMeeting && name;
	const status = useMeetingStatus()

	const flattenedRoster = useMemo(() => Object.values(roster) ?? [], [roster])

	/* istanbul ignore next */
	const onJoinMeeting = useCallback(async () => {
		const joinName = `${user.name}@@${user.id}`;

		try {
			const { JoinInfo } = await fetchMeeting(externalMeetingId, joinName, "ca-central-1");
			
			const meetingSessionConfiguration = new MeetingSessionConfiguration(
				JoinInfo.Meeting,
				JoinInfo.Attendee
			);

			await meetingManager.join(meetingSessionConfiguration);
			await meetingManager.start();

			setSelfAttendeeId(JoinInfo.Attendee.Attendee.AttendeeId);
		} catch (error) {

			Sentry.withScope(scope => {
				scope.setExtras({ action: 'join request', joinName, meetingId: id, user: user.id });
				Sentry.captureException(error);
			});

			setError(new MeetingError(error.message, 'expired'));
		}
	}, [
		meetingManager.join,
		meetingManager.start,
		externalMeetingId,
		user,
	]);

	const onStopMeeting = useCallback(async () => {
		if (id) {
			setIsCameraOn(false);
			setIsMuted(true);
			setSelfAttendeeId(null);

			await onLeaveMeeting(id);
			await meetingManager.leave();	
		}
	} , [id, meetingManager.leave, onLeaveMeeting]);

	useEffect(() => {
		if (status === 1) {
			if (!isVideoEnabled) {
				toggleVideo()
				setIsCameraOn(true)
				setIsMuted(false)
			}
		}
	}, [status])

	useEffect(() => {
		const isChimeMeetingOngoing = meetingManager?.meetingStatus === 1 // Succeeded code

		if (shouldJoinMeeting && !isChimeMeetingOngoing) {
			onJoinMeeting();
		}
	}, [shouldJoinMeeting, onJoinMeeting]);

	useEffect(() => {
		const hasMeetingEnded = meetingEvent?.name === 'meetingEnded';
		const wasPreviousEventEnded = previousMeetingEvent.current?.name === 'meetingEnded';

		if (hasMeetingEnded && !wasPreviousEventEnded) {
			onStopMeeting(id);
		}

		previousMeetingEvent.current = meetingEvent;
	}, [meetingEvent, id, onStopMeeting, previousMeetingEvent]);

	useEffect(() => {
		// istanbul ignore next
		if (!audioVideo || !isInMeeting) {
			return;
		}

		if (isMuted) {
			audioVideo.realtimeMuteLocalAudio();
		} else {
			audioVideo.realtimeUnmuteLocalAudio();
		}
	}, [audioVideo, isMuted, isInMeeting])

	const onToggleMute = useCallback(() => {
		if (isMuted) {
			audioVideo.realtimeUnmuteLocalAudio();
			setIsMuted(false);
		} else {
			audioVideo.realtimeMuteLocalAudio();
			setIsMuted(true);
		}
	}, [audioVideo, isMuted]);

	const onToggleCamera = useCallback(() => {
		toggleVideo();
		setIsCameraOn(!isCameraOn);
	}, [toggleVideo, isCameraOn]);

	const onListAudioDevice = useCallback(() => {
		try {
			const audioDevices = devices.map(device => ({
				label: device.label,
				deviceId: device.deviceId,
				isSelected: selectedDevice === device.deviceId,
			}));

			return audioDevices;
		} catch (error) {
			Sentry.withScope(scope => {
				scope.setExtras({ action: 'list devices', meetingId: id });
				Sentry.captureException(error);
			});
		}
	}, [devices, selectedDevice]);

	const onSetAudioDevice = useCallback((deviceId) => {
		meetingManager.startAudioOutputDevice(deviceId);
	}, [meetingManager.startAudioOutputDevice]);


	const attendees = useMemo(() => flattenedRoster.map(attendee => {
		const info = attendee.externalUserId.split("#")[1];
		const [name, userId] = info.split('@@');

		const isSelf = !!selfAttendeeId && selfAttendeeId === attendee.chimeAttendeeId;
		const isMostActiveSpeaker = isActiveSpeakerEnabled && activeSpeakers[0] === attendee.chimeAttendeeId;
		return {
			...attendee,
			externalId: attendee.chimeAttendeeId,
			isSelf,
			tileId: null,
			name,
			userId,
			userType: getUserType(userId),
			isMostActiveSpeaker,
		}
	}), [
		roster,
		getUserType,
		selfAttendeeId,
		activeSpeakers,
		isActiveSpeakerEnabled,
	]);

	const tiles = useMemo(() => attendees.map(attendee => {
		const tileId = remoteTiles.find(tile => attendee.externalId === remoteTileIdToAttendeeId[tile]);
		const isMostActiveSpeaker = isActiveSpeakerEnabled && activeSpeakers?.[0] === attendee.externalId;
		const image = members?.find?.(member => member?._id === attendee.userId)?.image ?? null;

		const isCurrentTilePinned = !!pinning.pinnedUser && pinning.pinnedUser === attendee.userId
		const isActiveFeatured = !attendee?.isSelf && isMostActiveSpeaker && !pinning.pinnedUser;

		return {
			...attendee,
			tileId,
			image,
			isVideoEnabled: !!tileId,
			isFeatured: isCurrentTilePinned || isActiveFeatured,
		};
	}), [
		members,
		attendees,
		remoteTiles,
		pinning.pinnedUser,
		activeSpeakers,
		isActiveSpeakerEnabled,
		remoteTileIdToAttendeeId,
	]);

	// *** Removes the locally pinned user if they leave the call *** //
	useEffect(() => pinning.removePinIfUserIsMissing(attendees), [attendees.length]);

	return {
		onToggleMute,
		onToggleCamera,
		onStopMeeting,
		onListAudioDevice,
		onSetAudioDevice,
		setOrientation,
		attendees,
		tiles,
		isMuted,
		isCameraOn,
		orientation,
		layout,
	}
};