export default {
	title: "AtouchawayLegacy",
	type: "object",
	properties: {
		legacy: {
			order: 5,
			description: "atouchawaysettings.legacy",
			format: "switch",
			inputType: "switch",
			type: "boolean",
		}
	}
};
