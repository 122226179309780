import React from "react";

import StyledButton from "ae-base/Base/StyledButton";

import { useResources } from "@aetonix/hooks";

export default function WorkflowContinueButton(props) {
	const {
		localize,
		dispatch,
	} = useResources();

	var action = props.action;

	var title = localize("workflow.continue");

	var onPress = dispatch("activeworkflowaction:trigger:view", {
		action: action._id,
	});

	return <StyledButton centered fullheight stretch contrast title={title} onPress={onPress} {...props} />;
}
