import React from "react";
import {
	View,
	StyleSheet
} from "react-native";

import SimpleState from "./aTouchAwayCalling/SimpleState";
import { useResources } from "@aetonix/hooks";

var styles = StyleSheet.create({
	container: {
		position: "absolute",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
	}
});

export default function CallGroupBusy(props){
	const { localize } = useResources();
	var group = props.group;
	var message = localize("callgroups.busy", group);
	return (
		<View style={styles.container}>
			<SimpleState who={group} message={message} />
		</View>
	);
}