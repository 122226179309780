
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";
import {
	View,
	ImageBackground
} from "react-native";
import ResponsiveStylesheet from "react-native-responsive-stylesheet";

import Style from "ae-style";
import headerTexture from "../../../../Resources/img/Aetonix_wallpaper.png";

var styles = ResponsiveStylesheet.createSized("min-direction", {
	0: {
		container: {
			flexDirection: "row",
			justifyContent: "center",
			paddingHorizontal: Style.layout.paddingMedium,
			paddingVertical: Style.layout.paddingSmall
		},
		content: {
			maxWidth: Style.layout.smallContent,
			flexBasis: Style.layout.smallContent,
			flexShrink: 1,
		},
		contentMargin: {
			marginTop: Style.layout.marginContentVertical
		},
		contrast: {
			backgroundColor: Style.colors.white
		},
		backgroundImage: {
			height: null,
			width: null
		},
		containerZIndex: {
			zIndex: 2
		}
	},
	512: {
		container: {
			flexDirection: "row",
			justifyContent: "center",
			paddingHorizontal: Style.layout.paddingSmall,
			paddingVertical: Style.layout.paddingLarge
		}
	}
});

export default function LoginSection(props){
	var children = props.children;
	var contrast = props.contrast;
	var external = props.external;
	var header = props.header;

	var containerStyles = [styles.container];
	if(contrast)
		containerStyles.push(styles.contrast);
	if(header)	{
		var contentStyles = [styles.content, styles.contentMargin];
		return (
			<ImageBackground resizeMethod="resize" source={headerTexture} style={styles.backgroundImage}>
				<View style={containerStyles}>
					<View style={contentStyles}>
						{children}
					</View>
					{external}
				</View>
			</ImageBackground>
		);

	}
	containerStyles.push(styles.containerZIndex);
	return (
		<View style={containerStyles}>
			<View style={styles.content}>
				{children}
			</View>
			{external}
		</View>
	);
}
