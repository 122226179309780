import React from "react";
import {
	Platform,
	StyleSheet,
} from "react-native";

import StyledButton from "ae-base/Base/StyledButton";

import { useResources } from "@aetonix/hooks";

var styles = StyleSheet.create({
	buttonMobile: {
		padding: 0,
	},
});

export default function PowerButtonNew(props) {
	const {
		dispatch,
	} = useResources();
	const settings = props.settings || {};

	let handlePowerButton = null;
	if (!settings.power_button || settings.power_button === "standby")
		handlePowerButton = dispatch("app:trigger:power");

	if (settings.power_button === "hide")
		handlePowerButton = dispatch("screenhider:trigger:hide");

	const style = [];
	if(Platform.OS === "android" || Platform.OS === "ios")
		style.push(styles.buttonMobile);

	return (
		<StyledButton contrast onPress={handlePowerButton} stretch largeHeading icon="power-off" centered style={style} />
	);
}
