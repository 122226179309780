import React from "react";
import {
	View,
} from "react-native";
import VideoView from "../../VideoView";
import ResponsiveStylesheet from "react-native-responsive-stylesheet";

import StyledText from "ae-base/Base/StyledText";
import BlockView from "ae-base/Base/BlockView";

import Avatar from "../../../Contacts/Avatar";

import Style from "@aetonix/styles";
import Breakpoints from "../../../../Breakpoints";

var styles = ResponsiveStylesheet.createSized("min-width", {
	0: {
		container: {
			alignItems: "stretch",
			flex: 1
		},
		title: {
			position: "absolute",
			top: 0,
			left: 0,
			right: 0,
			backgroundColor: "rgba(0,0,0,0.3)",
			flexDirection: "row",
			justifyContent: "space-between",
			alignItems: "center",
			paddingVertical: Style.layout.marginSlim,
			paddingHorizontal: Style.layout.paddingMedium,
			zIndex: 1
		},
		centered: {
			flex: 1,
			alignSelf: "center",
			justifyContent: "center"
		},
		hidden: {
			width: 1,
			height: 1,
			opacity: 0,
			position: "absolute"
		},
		name: {
			justifyContent: "center",
			alignItems: "center",
			marginTop: Style.layout.marginLarge
		},
		state: {
			color: Style.colors.grey
		},
		icon: {
			marginRight: Style.layout.marginMedium
		},
		statusName: {
			flexDirection: "row",
			alignItems: "center",
		}
	},
	512: {
		title: {
			paddingVertical: Style.layout.paddingMedium,
			paddingHorizontal: Style.layout.paddingLarge,
		},
	}
});

export default function RemotePerson(props){
	var stream = props.stream;
	var voiceOnly = props.voiceOnly;
	var person = props.person;
	var message = props.message;
	var conference = props.conference;
	var id = props.id;
	var callgroup = props.callgroup;

	if (stream && voiceOnly) return (
		<VoiceView callgroup={callgroup} stream={stream} person={person} conference={conference} />
	);

	if(stream) return (
		<VideoView stream={stream} id={id} zOrder={0} />
	);

	return (
		<View style={styles.centered}>
			<StyledText contrast emphasized>{message}</StyledText>
		</View>
	);
}

function VoiceView(props) {
	var stream = props.stream;
	var person = props.person;
	var conference = props.conference;
	var callgroup = props.callgroup;

	var sizeAvatar = "large";
	if (conference) {
		sizeAvatar = "medium";
	}
	if (Breakpoints.smallerThan("mobile")) {
		if (conference)
			sizeAvatar = "extrasmall";
		else
			sizeAvatar = "verysmall";
	} else if (Breakpoints.smallerThan("smallTablet")) {
		if (conference)
			sizeAvatar = "small";
		else
			sizeAvatar = "medium";
	}

	var name = person.lname + ", " + person.fname;

	if(callgroup){
		name = callgroup.name;
		person = callgroup;
	}

	return (
		<View style={styles.centered}>
			<BlockView style={styles.hidden}>
				<VideoView voiceOnly stream={stream} />
			</BlockView>
			<Avatar person={person} size={sizeAvatar} />
			<View style={styles.name}>
				<StyledText contrast smallHeading>{name}</StyledText>
			</View>
		</View>
	);
}
