import MeetingContext from '../../../context/MeetingContext';
import VideoTile from '../VideoTile';
import React, { useRef, useEffect, useState, useContext } from 'react';
import { useLocalVideo, useAudioVideo, useApplyVideoObjectFit } from 'amazon-chime-sdk-component-library-react';

export default ({ nameplate, mode, isFeatured, image, ...rest }) => {
	const { tileId, isVideoEnabled } = useLocalVideo();
	const audioVideo = useAudioVideo();
	const videoEl = useRef(null);
	const { meetingState } = useContext(MeetingContext)

	useApplyVideoObjectFit(videoEl);
	const [simplifiedCamera, setSimplifiedCamera] = useState(1);

	useEffect(() => {
		if (!audioVideo || !tileId || !videoEl.current || !isVideoEnabled) {
			return;
		}

		tileId && audioVideo.bindVideoElement(tileId, videoEl.current);

		return () => {
			const tile = audioVideo.getVideoTile(tileId);
			if (tile) {
				audioVideo.unbindVideoElement(tileId);
			}
		};
	}, [audioVideo, tileId, isVideoEnabled]);

	const onCameraFlip = async () => {
		const videoInputDevices = await audioVideo.listVideoInputDevices();
		if (mode === "simplified" && videoInputDevices.length > 1) {
			await audioVideo.chooseVideoInputDevice(videoInputDevices[simplifiedCamera].deviceId);
			setSimplifiedCamera(prevState => prevState === 1 ? 0 : 1);
		}
	}

	if (tileId) {
		return (
			<VideoTile
				mode={mode}
				type={isFeatured ? 'featured' : 'standard'}
				isSelf={true}
				version="web"
				image={image}
				isMuted={meetingState?.isMuted ?? false}
				nameplate={nameplate}
				objectFit="contain"
				onCameraFlip={onCameraFlip}
				isVideoEnabled={isVideoEnabled}
				videoComponent={<video ref={videoEl} />}
			/>
		);
	}

	return (
		<VideoTile
			type={isFeatured ? 'featured' : 'standard'}
			isSelf={true}
			image={image}
			version="web"
			isMuted={meetingState?.isMuted ?? false}
			nameplate={nameplate}
			objectFit="contain"
			isVideoEnabled={isVideoEnabled}
		/>
	);
}
