import React from "react";
import {
	View,
	StyleSheet,
} from "react-native";

import StyledText from "ae-base/Base/StyledText";

var styles = StyleSheet.create({
	label: {
		flex: 0.4,
		flexBasis: 0.4,
	}
});

export default function Label(props){
	var styledLabel = [styles.label];

	return (
		<View style={styledLabel}>
			<StyledText>{props.children}</StyledText>
		</View>
	);
}
