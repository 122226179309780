export const sortNow = (a, b) => {
    if (a.userType === "patient" && b.userType !== "patient") return -1;
    if (a.userType !== "patient" && b.userType === "patient") return 1;

    if (a.userType === "adminstrator" && b.userType !== "adminstrator") return -1;
    if (a.userType !== "adminstrator" && b.userType === "adminstrator") return 1;

    if (a.userType === "care-team" && b.userType !== "care-team") return -1;
    if (a.userType !== "care-team" && b.userType === "care-team") return 1;

    if (a.userType === "none" && b.userType !== "none") return -1;
    if (a.userType !== "none" && b.userType === "none") return 1;
}