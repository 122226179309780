import React from "react";
import {
	View,
	Image,
	StyleSheet,
	Dimensions,
} from "react-native";

import AtaContainer from "../aTouchAway/AtaContainer";
import LogoImg from "../../Resources/img/Aetonix_logo.png";
import SquareButton from "./SquareButton";
import StyledButton from "ae-base/Base/StyledButton";
import Card from "../Layout/Card";
import MainScroll from "../Layout/MainScroll";

import Grid from "./Grid";

import Style from "ae-style";

import { useResources } from "@aetonix/hooks";

var styles = StyleSheet.create({
	flex1: {
		flex: 1,
	},
	logoImg: {
		width: 180,
		height: 120,
		resizeMode: "contain",
	},
	logoView: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		marginHorizontal: Style.layout.marginContent,
	},
	squreButtonView: {
		flex: 1,
		flexDirection: "row",
		justifyContent: "space-around",
	},
	inlineSqureButtonView: {
		flex: 1,
		flexDirection: "row",
		justifyContent: "space-around",
		alignItems: "center",
		marginVertical: Style.layout.marginMedium,
	},
});

export default function Setting(props) {
	const {
		dispatch,
	} = useResources();

	var columns = 3;
	var rows = 1;

	var width = Dimensions.get("window").width;
	var height = Dimensions.get("window").height;

	if(width < height){
		columns = 1;
		rows = 3;
	}

	var about = props.model.aboutbox;
	var options = props.model.options;
	var mediaSettingOpen = options.settings;
	var androidSetting = about.showSettings;
	var renderSettingButtons = androidSetting ? (
		<View style={styles.flex1}>
			<View style={styles.squreButtonView}>
				<Grid rows={rows} columns={columns}>
					<SettingsButton />
					<UpdateButton updateState={about.updateState} />
					<LogoutButton />
				</Grid>
			</View>
		</View>
	) : (
		<View style={styles.inlineSqureButtonView}>
			<Grid rows={1} columns={1}>
				<LogoutButton />
			</Grid>
		</View>
	);

	return mediaSettingOpen ? (
		<AtaContainer>
			<MainScroll>
				<Card>
					<Logo onBack={dispatch("options:trigger:settings")} />
				</Card>
			</MainScroll>
		</AtaContainer>
	) : (
		<AtaContainer>
			<Logo onBack={dispatch("about:trigger:exit")} />
			{renderSettingButtons}
		</AtaContainer>
	);
}

function Logo(props) {
	const {
		localize,
	} = useResources();

	var onBack = props.onBack;
	var goBack = localize("about.goback");

	return (
		<View style={styles.logoView}>
			<Image resizeMethod="resize" style={styles.logoImg} source={LogoImg} />
			<StyledButton alert title={goBack} onPress={onBack} />
		</View>
	);
}

function LogoutButton(props) {
	const {
		localize,
		dispatch,
	} = useResources();

	var logoutText = localize("mobileatouchawaysettings.logoutbutton");

	return (
		<SquareButton small highlight contrast title={logoutText} icon={"delete"} onPress={dispatch("logout:trigger:logout")} />
	);
}

function UpdateButton(props) {
	const {
		localize,
		dispatch,
	} = useResources();
	var updateState = props.updateState;

	var disabled = (!updateState) || (updateState === "none");
	var current = updateState === "current";

	if(disabled) return null;

	var action = current ? null : dispatch("contentupdate:trigger:update");

	if(updateState === "current" || updateState === "none"){
		var updateText = localize("mobileatouchawaysettings.updatebutton.noupdate");
		var downloadIcon = "check";
	}else if(updateState === "available"){
		updateText = localize("mobileatouchawaysettings.updatebutton.newupdate");
		downloadIcon = "download";
	}else if(updateState === "downloading" || updateState === "installing"){
		updateText = localize("mobileatouchawaysettings.updatebutton.updating");
		downloadIcon = "sync";
	}

	return (
		<SquareButton small secondary contrast title={updateText} icon={downloadIcon} onPress={action} />
	);
}

function SettingsButton(props) {
	const {
		localize,
		dispatch,
	} = useResources();

	var androidText = localize("mobileatouchawaysettings.androidbutton");

	return (
		<SquareButton small secondary contrast title={androidText} icon={"smartphone"} onPress={dispatch("about:trigger:settings:android")} />
	);
}
