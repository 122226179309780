import React from "react";
import {
	StyleSheet,
	View,
} from "react-native";

import MainContent from "../../Layout/MainContent";
import Divider from "../../Layout/Divider";
import ContactHeading from "../../Contacts/ContactHeading";
import ContactHide from "../../Contacts/ContactHide";
import StyledButton from "ae-base/Base/StyledButton";
import ManageGroups from "./ManageGroups";

import Style from "ae-style";
import Form from "ae-forms";
import ManagedUserSearchSchema from "../../../schemas/ManagedUserSearch";
import debounce from "debounce";

import ManagedList from "./ManagedList";
import MessageSpinner from "../Messaging/MessageSpinner";

import { useResources } from "@aetonix/hooks";

var styles = StyleSheet.create({
	badge: {
		position: "absolute",
		right: 0,
		top: 0,
	},
	moreButton: {
		alignItems: "center",
		flex: 1,
		margin: Style.layout.marginSmall,
	},
});

export default function ManagedContactList(props) {
	const {
		dispatch,
		localize,
	} = useResources();

	var list = props.list || [];
	var availability = props.availability;
	var hiding = props.hiding;
	var managed_rendered_count = props.count;
	var displaying = props.displaying;
	var missed = props.missed;
	var managedStatuses = props.managedStatuses;
	var padBottom = props.padBottom;
	var managed_users_count = props.totalCount ?? managed_rendered_count;
	var contactSettings = props.contactsettings || {};
	var isSearching = props.isSearching;
	var loading = props.loading;
	var patientGroups = props.patientGroups;
	const max_users = 32;
	var managegroups = props.managegroups || [];
	var activemanagegroup  = props.activemanagegroup;

	var screen = props.screen;

	var hideIndex = 0;

	let count;

	if( managed_rendered_count < max_users)
		count = managed_rendered_count;
	else
		count = managed_users_count;


	var renderMoreButton = props.listEnd ? null : (
		<View style={styles.moreButton}>
			<StyledButton title={localize("userlist.loadmore")} contrast onPress={dispatch("managedusers:trigger:more")} />
		</View>
	);

	var handleSearch = debounce(dispatch("managedusers:trigger:search"), 500);

	var content = displaying ? (
		<>
			<Hide key={hideIndex++} hiding={hiding} />
			<ManageGroups contrast title="managegroups.managedusers" trigger="activemanagegroup:trigger:load" groups={managegroups} active={activemanagegroup} />
			<Form schema={ManagedUserSearchSchema} contrast autoSubmit={true} onSubmit={handleSearch} />
			<Divider key={hideIndex++} contrast />
			<MessageSpinner open={loading} />
			<ManagedList screen={screen} activemanagegroup={activemanagegroup} list={list} availability={availability} missed={missed} managedStatuses={managedStatuses} contactSettings={contactSettings} />
			{renderMoreButton}
		</>) : null;

	if(!count && !managegroups.length && !patientGroups.length && !isSearching) return null;

	return (
		<MainContent contrast padBottom={padBottom}>
			<ContactHeading
				count={count}
				contrast
				displaying={displaying}
				section="managed"
				screen={screen}
				patientGroups={patientGroups}
			/>
			{content}
		</MainContent>
	);
}

function Hide(props) {
	const {
		dispatch,
	} = useResources();

	var hiding = props.hiding;

	var toggleHide = dispatch("homepage:trigger:toggle:offline:managed");

	return (
		<ContactHide id={"hideManagedContact"} contrast onPress={toggleHide} hiding={hiding} />
	);
}
