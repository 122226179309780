/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React, { useState} from "react";
import {
	StyleSheet,
	Text,
	View,
	Platform,
	Linking,
} from "react-native";

import Loader from "ae-base/Base/Loader";
import StyledText from "ae-base/Base/StyledText";
import StyledButton from "ae-base/Base/StyledButton";
import BlockView from "ae-base/Base/BlockView";
import PageContainer from "../../Layout/PageContainer";
import MainScroll from "../../Layout/MainScroll";
import Divider from "../../Layout/Divider";
import Style from "@aetonix/styles";
import VersionNumber from "../Myprofile/VersionNumber";

import Form from "ae-forms";
import LoginSchema from "../../../schemas/Login";
import MultiFactorAuthSchema  from "../../../schemas/MultiFactorAuth";

import LoginSection from "./LoginSection";
import LoginLogo from "./LoginLogo";
import LoginBack from "./LoginBack";
import LoginLanguageBar from "./LoginLanguageBar";
import LoginSpacer from "./LoginSpacer";
import { AllowShowOnTopModal } from "./AllowShowOnTopModal";

import { useResources } from "@aetonix/hooks";

var styles = StyleSheet.create({
	submitAlign: {
		justifyContent: "flex-start",
	},
	dividerSpacer: {
		marginBottom: Style.layout.marginMedium,
	},
	backButton: {
		zIndex: 1,
		position: "absolute",
		top: -10,
		left: 0,
		right: 0,
		bottom: 0,
	},
	buttonStyle: {
		paddingLeft: 0,
	},
	settingsButton: {
		justifyContent: "center",
		alignSelf: "center",
		marginBottom: "0%",
	},
});

export default function Login(props){
	const [time, setTime] = useState(60);
	const [btnDisable, setBtnDisable] = useState(false);
	const [initialSend, setInitialSend] = useState(false);
	const [btnContent, setBtnContent] = useState(null);

	const { model, dispatch, localize } = useResources();

	const saving = model.save;
	const toast = model.toast;
	const loading = model.authenticating;
	const asked = model.asked;
	const twofactorauth = model.id && model.email;

	const already = localize("login.already");
	const haveanatouchawayaccount = localize("login.haveanatouchawayaccount");
	const signintoatouchaway = localize("login.signintoatouchaway");
	const areyou = localize("login.areyou");
	const newtoatouchaway = localize("login.newtoatouchaway");
	const createyourselfanaccount = localize("login.createyourselfanaccount");
	const ifyouvebeen = localize("login.ifyouvebeen");
	const givenanaccountkey = localize("login.givenanaccountkey");
	const enteranaccountkey = localize("login.enteranaccountkey");
	const authcoderequest = localize("multifactor.authcoderequest");
	const entercode = localize("multifactor.entercode");
	const codesubmit = localize("multifactor.submit");
	const signin = localize("login.signin");
	const signtoatouchaway = localize("login.signtoatouchaway");
	const forget = localize("login.forget");

	if(loading)
		return (
			<Loader />
		);

	const defaultData = {
		id: model.id,
	};

	const maskedEmail = model.email ? emailMask(model.email) : "";
	var timeChange;
	var ti = time;
	const clock = () => {
		if (ti > 0) {
			ti = ti - 1;
			setTime(ti);
			setBtnContent(authcoderequest + " (" + ti + "s)");
		} else {
			clearInterval(timeChange);
			setBtnDisable(false);
			setTime(60);
			setBtnContent(authcoderequest);
		}
	};

	const sendCode = () => {
		setBtnDisable(true);
		setBtnContent(authcoderequest + " (60s)");
		setInitialSend(true);
		timeChange = setInterval(clock, 1000);
		dispatch("login:trigger:twofactorauth:request", {email: model.email, id: model.id})();
	};

	if(twofactorauth && !initialSend)
		sendCode();


	const content = asked && !twofactorauth ? (
		<LoginSection contrast >
			<StyledText large bold>{signin}</StyledText>
			<LoginSpacer>
				<Form id_prefix={"signinform_"} schema={LoginSchema} submitText={signtoatouchaway} onSubmit={dispatch("login:trigger:login")} submitStyle={styles.submitAlign} />
			</LoginSpacer>
			<LoginSpacer>
				<StyledButton id={"passwordReset"} title={forget} style={styles.buttonStyle} clear tertiary onPress={dispatch("app:trigger:passwordreset")} />
			</LoginSpacer>
		</LoginSection>
	) : twofactorauth ? (
		<LoginSection contrast >
			<StyledText large bold>{entercode}</StyledText>
			<LoginSpacer>
				<StyledText bold>{maskedEmail}</StyledText>
				<Form id_prefix={"multifactorauthform_"} schema={MultiFactorAuthSchema} submitText={codesubmit} defaultData={defaultData} onSubmit={dispatch("login:trigger:twofactorauth")} submitStyle={styles.submitAlign} />
				<BlockView style={styles.dividerSpacer}>
					<Divider />
				</BlockView>
				<StyledButton title={btnContent} id={"authcodeRequest"} onPress={sendCode} border white disabled={btnDisable} />
			</LoginSpacer>
		</LoginSection>
	) : (
		<LoginSection contrast>
			<Text>
				<StyledText>{already}</StyledText>
				<StyledText bold>{haveanatouchawayaccount}</StyledText>
			</Text>
			<LoginSpacer>
				<StyledButton contrast id={"signInButton"}  title={signintoatouchaway} onPress={dispatch("login:trigger:show")} />
			</LoginSpacer>
			<BlockView style={styles.dividerSpacer}>
				<Divider />
			</BlockView>
			<Text>
				<StyledText>{areyou}</StyledText>
				<StyledText bold>{newtoatouchaway}</StyledText>
			</Text>
			<LoginSpacer>
				<BlockView>
					<StyledButton title={createyourselfanaccount} id={"createNewAccount"} onPress={dispatch("app:trigger:register")} border white />
				</BlockView>
			</LoginSpacer>
		</LoginSection>
	);

	var headerContent = asked && !twofactorauth ? (
		<View style={styles.backButton}>
			<LoginBack id={"loginBack"} onPress={dispatch("login:trigger:asked")} />
		</View>
	) : twofactorauth ? (
		<View style={styles.backButton}>
			<LoginBack id={"loginBack"} onPress={dispatch("login:trigger:twofactorauth:cancel")} />
		</View>
	) : (
		<LoginLanguageBar language={model.language} />
	);

	return (
		<PageContainer dark saving={saving} toast={toast} >
			<AllowShowOnTopModal localize={localize} />
			<MainScroll>
				{headerContent}

				<LoginSection header>
					<LoginLogo />
				</LoginSection>

				{content}

				<LoginSection>
					<Text>
						<StyledText contrast>{ifyouvebeen}</StyledText>
						<StyledText bold contrast>{givenanaccountkey}</StyledText>
					</Text>
					<LoginSpacer>
						<StyledButton title={enteranaccountkey} id={"enterAccountKey"} onPress={dispatch("app:trigger:tokenlogin")} />
					</LoginSpacer>
				</LoginSection>

				<VersionNumber config={model.config} model={model} showRegionChange={!asked} />

				{ Platform.OS === "android" &&
					<StyledButton medium clear title={localize("contactsettings.settings")}
						wrapperViewStyle={styles.settingsButton}
						onPress={() => Linking.sendIntent("android.settings.WIFI_SETTINGS")}
					/>
				}

			</MainScroll>
		</PageContainer>
	);
}

function emailMask(email) {
	var maskedEmail = email.replace(/([^@.])/g, "*").split("");
	var previous = "";
	for(var i = 0; i < maskedEmail.length; i++){
		if (i <= 1 || previous === "." || previous === "@")
			maskedEmail[i] = email[i];

		previous = email[i];
	}
	return maskedEmail.join("");
}
