import React from "react";
import {
	View,
	StyleSheet,
} from "react-native";

import AtaContainer from "../AtaContainer";
import	StyledButton from "ae-base/Base/StyledButton";
import { useResources } from "@aetonix/hooks";

var styles = StyleSheet.create({
	button: {
		flex: 1,
		justifyContent: "flex-end",
		alignItems: "flex-end",
	},
});

export default function StandBy(props) {
	const { dispatch, localize } = useResources();
	var handlePowerButton = dispatch("app:trigger:power");
	var powerbutton = localize("powerbutton.powerbutton");

	return (
		<AtaContainer>
			<View style={styles.button}>
				<StyledButton border clear title={powerbutton} icon="power-off" onPress={handlePowerButton} largeHeading />
			</View>
		</AtaContainer>
	);
}
