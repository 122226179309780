export const ADMIN = 1;
export const RECIPIENT = 2;
export const PARTICIPANT = 3;

export function getUserCallRole(user) {
    const types = user?.type;

    if (!Array.isArray(types)) {
        return PARTICIPANT;
    }

    const isCareMember = types.includes('care');
    if (isCareMember) {
        return ADMIN;
    }

    const isPatient = types.some(type => type === 'user:mobile' || type === 'atouchaway' || type === 'simplified');
    if (isPatient) {
        return RECIPIENT;
    }

    return ADMIN;
}

export function getUserCallRoles(...users) {

    const roles = users.reduce((roles, user) => {
        const type = getUserCallRole(user);
        const userId = user._id;

        if (type === ADMIN) {
            roles.administrators.push(userId);
        } else if (type === RECIPIENT) {
            if (!roles.recipient) {
                roles.recipient = userId;   
            } else {
                roles.participants.push(userId);
            }
        } else {
            roles.participants.push(userId);
        }

        return roles;
    }, {
        recipient: null,
        administrators: [],
        participants: [],
    });

    return roles;
}