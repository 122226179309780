export default {
    title: "License Checkbox Schema",
    description: "A schema for the Aetonix license agreement checkbox",
    type: "object",
    properties: {
      iAcceptAetonixLicense: {
        description: "register.iaccepttheaetonixlicenseagreement",
        inputType: "checkbox",
        type: "boolean",
        required: true,
      },
    },
  };
  
