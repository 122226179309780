export default {
	type: "object",
	properties: {
		contact: {
			type: "array",
			inputType: "checkboxes",
			items: {
				type: "string",
				value_description: "managedcontact",
				enum: [
					"should"
				]
			}
		}
	}
};