import React from "react";

import BasicState from "./BasicState";
import CallButton from "../CallButton";
import { useResources } from "@aetonix/hooks";

export default function Connecting(props) {
	const { localize } = useResources();

	var calling = props.calling;
	var who = calling.who;
	var message = localize("connecting.message", who);
	var button = "endCall";

	var callgroup = props.callgroup;
	if(callgroup){
		message = localize("outgoing.callgroupCalling", callgroup);
		button = "callgroupEndCall";
	}

	return (
		<BasicState message={message}>
			<CallButton button={button} />
		</BasicState>
	);
}