import React from "react";
import {
	View,
	StyleSheet,
	Dimensions,
} from "react-native";
import AtaContainer from "../AtaContainer";
import About from "../About";
import Reminder from "../Reminder";
import ResourcesList from "./ResourcesList";
import ResourceView from "./ResourceView";
import Style from "ae-style";
import WorkflowContinueButton from "../WorkflowContinueButton";

var styles = StyleSheet.create({
	content: {
		flex: 1,
		flexDirection: "row",
	},
});

export default function ATouchAwayResources(props){
	var model = props.model;
	var options = model.options;
	var trunk = options.trunk;
	var reminder = model.reminders || {};
	var resources = model.resources;

	var renderTrunk = trunk ? <About options={options} /> : null;
	var renderReminder = reminder.active && !reminder.answered ? <Reminder reminder={reminder} /> : null;

	var renderList = (<ResourcesList resources={resources} model={model} />);

	var renderResourceView = resources.viewingResource ? (
		<ResourceView resources={resources} />
	) : null;

	var renderWorkflowContinue = null;

	if (model.workflowAction && model.workflowAction.collapsed) {
		var textSize =
		Dimensions.get("window").height > Style.breakpoints.smallTablet
			? {
				largeHeading: true,
			}
			: {
				smallHeading: true,
			};
		renderWorkflowContinue = (<WorkflowContinueButton style={{
			marginHorizontal: Style.layout.marginMedium}} stretch action={model.workflowAction} size={textSize} />);
	}

	return (
		<AtaContainer>
			<View style={styles.content}>
				{renderList}
				<View style={{flex: 1}}>
					{renderWorkflowContinue}
					{renderResourceView}
				</View>
			</View>
			{renderTrunk}
			{renderReminder}
		</AtaContainer>
	);
}
