import React from "react";

import MyHealthSection from "../MyHealthSection";
import DeviceAdd from "../../../Devices/DeviceAdd";

import StyledButton from "ae-base/Base/StyledButton";
import ConfirmationModal from "ae-base/Base/ConfirmationModal";
import StyledText from "ae-base/Base/StyledText";

import { useResources } from "@aetonix/hooks";

export default function DevicesUpdate(props) {
	const {
		localize,
		dispatch,
	} = useResources();

	var model = props.model;
	var devices = model.owndevices;
	var updating = devices.updating || {};
	var removing = devices.removing;
	var toast = model.toast;

	if(!updating) return null;

	var onSubmit = dispatch("owndevices:trigger:update:submit");
	var devicesTitle = localize("devices.edittitle");
	var defaultData = updating;

	var removeDevice = dispatch("owndevices:trigger:remove:start", {
		device: updating._id,
	});

	var deleteButton = (
		<StyledButton title={localize("devices.removebutton")} icon="delete" onPress={removeDevice} clear contrast />
	);

	var backTitle = localize("devices.backTitle");

	var renderContent = (
		<MyHealthSection online={model.online} title={devicesTitle} toast={toast} back={backTitle} edit={deleteButton} >
			<DeviceAdd enumValue={[updating.model]} defaultData={defaultData} onSubmit={onSubmit} />
		</MyHealthSection>
	);

	var confirmRemove = dispatch("owndevices:trigger:remove:submit");
	var cancelRemove = dispatch("owndevices:trigger:remove:cancel");

	if(removing)
		renderContent = (
			<ConfirmationModal
				open={removing}
				title={localize("devices.confirmremovetitle")}
				titleIcon="warning"
				confirmText={localize("devices.yesremove")}
				cancelText={localize("devices.cancelremove")}
				onConfirm={confirmRemove}
				onCancel={cancelRemove} >
				<StyledText contrast>{localize("devices.areyousure")}</StyledText>
			</ConfirmationModal>
		);

	return renderContent;
}
