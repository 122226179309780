import React, {
	Component
} from "react";
import {
	View,
	StyleSheet
} from "react-native";

var styles = StyleSheet.create({
	videoContainer: {
		flex: 1,
		alignSelf: "stretch"
	}
});

var videoStyle = {
	position: "absolute",
	top: 0,
	bottom: 0,
	left: 0,
	right: 0,
	width: "100%",
	height: "100%",
	objectPosition: "center center",
	objectFit: "contain"
};

export default class VideoView extends Component {
	constructor(props){
		super(props);
		this._handle_video_element = handle_video_element.bind(this);
	}

	render() {
		var props = this.props;
		var key = props.stream ? props.stream.id : "loading";
		return (
			<View style={styles.videoContainer}>
				<video key={key} id={props.id} style={videoStyle} autoPlay muted={!!props.muted} ref={this._handle_video_element} />
			</View>
		);
	}
}

function handle_video_element(video) {
	if(!video) return;
	var props = this.props;
	var stream = props.stream;

	if ("srcObject" in video) {
		video.srcObject = stream;
	} else {
		// Avoid using this in new browsers, as it is going away.
		video.src = window.URL.createObjectURL(stream);
	}
}
