export default {
	title: "Search",
	description: "search_title",
	type: "object",
	properties: {
		query: {
			type: "string",
			inputType: "search",
			format: "search"
		},
		filter: {
			default: {
				type: {
					exclude: ["atouchaway"]
				}
			},
			inputType: "hidden"
		}
	}
};
