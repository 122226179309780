export default {
	title: "AetonixPermissionChange",
	description: "",
	type: "object",
	properties: {
		"devices": {
			order: 14,
			description: "devices.title",
			value_description: "devices.permissions",
			type: "number",
			inputType: "dropdown",
			enum: [0, 1, 2],
			default: 0
		},
		"resources": {
			order: 13,
			description: "resources.title",
			value_description: "resources",
			type: "number",
			inputType: "dropdown",
			enum: [0, 1, 2],
			default: 0
		},
		"manage": {
			order: 12,
			description: "permissionsaetonix.manage",
			value_description: "permissionsaetonix",
			type: "number",
			inputType: "dropdown",
			enum: [0, 1, 3],
			default: 0
		},
		"personal": {
			order: 1,
			description: "permissionsaetonix.personal",
			value_description: "atouchawaysettings",
			type: "number",
			inputType: "checkbox",
			enum: [0, 1],
			format: "checkbox",
			default: 0
		},
		"urgencycontacts": {
			order: 2,
			description: "permissionsaetonix.urgencycontacts",
			value_description: "atouchawaysettings",
			type: "number",
			inputType: "checkbox",
			enum: [0, 1],
			format: "checkbox",
			default: 0
		},
		"contacts": {
			order: 3,
			description: "permissionsaetonix.contacts",
			value_description: "atouchawaysettings",
			type: "number",
			inputType: "checkbox",
			enum: [0, 1],
			format: "checkbox",
			default: 0
		},
		"device": {
			order: 4,
			description: "permissionsaetonix.device",
			value_description: "atouchawaysettings",
			type: "number",
			inputType: "checkbox",
			enum: [0, 1],
			format: "checkbox",
			default: 0
		},
		"atouchaway": {
			order: 5,
			description: "permissionsaetonix.atouchaway",
			value_description: "atouchawaysettings",
			type: "number",
			inputType: "checkbox",
			enum: [0, 1],
			format: "checkbox",
			default: 0
		},
		"reminders": {
			order: 6,
			description: "permissionsaetonix.reminders",
			value_description: "atouchawaysettings",
			type: "number",
			inputType: "checkbox",
			enum: [0, 1],
			format: "checkbox",
			default: 0
		},
		"notes": {
			order: 7,
			description: "permissionsaetonix.notes",
			value_description: "atouchawaysettings",
			type: "number",
			inputType: "checkbox",
			enum: [0, 1],
			format: "checkbox",
			default: 0
		},
		"workflow": {
			order: 10,
			description: "permissionsaetonix.workflow",
			value_description: "atouchawaysettings",
			type: "number",
			inputType: "checkbox",
			enum: [0, 1],
			format: "checkbox",
			default: 0
		},
		"observations": {
			order: 9,
			description: "permissionsaetonix.observations",
			value_description: "atouchawaysettings",
			type: "number",
			enum: [0, 1],
			format: "checkbox",
			default: 0
		},
	}
};
