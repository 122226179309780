import React from "react";

import {
	View,
	StyleSheet,
} from "react-native";

import HealthSection from "./HealthSection/HealthSection";
import FormSection from "./FormSection/FormSection";
import PageContainer from "../../Layout/PageContainer";
import MainScroll from "../../Layout/MainScroll";
import HomeTabs from "../Homepage/HomeTabs";
import HomeHeader from "../Homepage/HomeHeader";
import WorkflowStatus from "../Contactprofile/WorkflowStatus";
import StyledText from "ae-base/Base/StyledText";

import { useResources } from "@aetonix/hooks";

const MYHEALTH_BUTTONS = [{
	title: "myhealth.overview", permission: "observations", "icon": "address-card-o", trigger: "overview:trigger:view", type: "overview",
}, {
	title: "myhealth.activity", permission: "observations", "icon": "line-chart", trigger: "activity:trigger:view", indicator: "steps", type: "activity",
}, {
	title: "myhealth.bloodpressure", permission: "observations", "icon": "heartbeat", trigger: "bloodpressure:trigger:view", indicator: "bloodpressure", type: "bloodpressure",
}, {
	title: "myhealth.bloodsugar", permission: "observations", "icon": "rocket", trigger: "bloodsugar:trigger:view", indicator: "bloodsugar", type: "bloodsugar",
}, {
	title: "myhealth.bodytemp", permission: "observations", "icon": "thermometer-empty", trigger: "bodytemperature:trigger:view", indicator: "bodytemperature", type: "bodytemperature",
},  {
	title: "myhealth.oxygen", permission: "observations", "icon": "stethoscope", trigger: "bloodoxygen:trigger:view", indicator: "bloodoxygen", type: "bloodoxygen",
}, {
	title: "myhealth.weight", permission: "observations", "icon": "user", trigger: "weight:trigger:view", indicator: "weight", type: "weight",
}];

const UTILITIES_BUTTONS = [{
	title: "myhealth.reminders", permission: "reminders", "icon": "bell", trigger: "ownreminders:trigger:view", type: "reminders",
}, {
	title: "resources.title", permission: "resources", "icon": "newspaper-o", trigger: "ownresources:trigger:open", type: "resources",
}, {
	title: "devices.managedbutton", permission: "bluetooth", icon: "chargingbattery", trigger: "owndevices:trigger:view", type: "bluetooth",
}];

var styles = StyleSheet.create({
	center: {
		alignItems: "center",
	},

});

export default function MyHealth(props) {
	const {
		localize,
	} = useResources();

	var model = props.model;

	var personal = model.personal;
	var type = personal.type || [];

	var ownforms = model.ownformlist || {};
	var updates = model.newsections;
	var status = model.status || {};
	var current_status = status.current_status;
	var workflowDefinitions = model.workflowdefinitions;

	var unreadnotifications = model.usernotifications.unread;
	var screen = model.size;

	var healthsection = localize("myhealth.title");
	var formsection = localize("myhealth.forms");
	var utilitiessection = localize("myhealth.utilities");

	var atRisk = localize("workflowstatus.risk");
	var moderate = localize("workflowstatus.moderate");
	var stable = localize("workflowstatus.stable");

	const userOrggroup = model.orggroup;
	const customIndicatorsList = model.customindicatorslist || [];
	let customIndicatorsButtons = [];
	if (userOrggroup && customIndicatorsList.length > 0)
		customIndicatorsButtons = customIndicatorsList.map((indicator) => {
			return {
				"isCustomIndicator": true,
				"index": indicator.index,
				"indicator": indicator._id,
				"title": indicator.localization,
				"unit": indicator.unit,
				"permission": "observations",
				"type": "customindicators",
				"icon": "medkit",
				"trigger": "customindicator:trigger:view",
			};
		});


	var atouchawaysettings = model.atouchawaysettings || {};
	var ismyHealthOn = atouchawaysettings.myhealth || false;
	var isFormstrue = atouchawaysettings.forms || false;
	var isStatusOn = atouchawaysettings.status || false;
	var ownformsordered = model.ownformorderedlist || [];
	var allowedForms = atouchawaysettings.forms_sections || {};

	var overallWorkflowStatus = current_status === 2 ? (
		<StyledText red smallHeading bold>{atRisk}</StyledText>
	) : current_status === 1 ? (
		<StyledText yellow smallHeading bold>{moderate}</StyledText>
	) : current_status === 0 ? (
		<StyledText green smallHeading bold>{stable}</StyledText>
	) : null;

	var isEmpty = (obj) => Object.keys(obj).length === 0;

	var formsViewable = checkPermissions(ownforms, allowedForms);
	var isFormsEmpty = isEmpty(formsViewable);

	return (
		<PageContainer saving={model.saving} toast={model.toast}>
			<HomeHeader unreadnotifications={unreadnotifications} personal={model.personal} screen={screen} />
			<MainScroll>
				<View style={styles.center}>
					{ isStatusOn ? <WorkflowStatus status={status} screen={screen} workflowDefinitions={workflowDefinitions} extraText={overallWorkflowStatus} /> : null}
					{ ismyHealthOn ? <HealthSection title={healthsection} buttons={MYHEALTH_BUTTONS} customIndicatorsButtons={customIndicatorsButtons} screen={screen} person={personal} updates={updates} status={status.indicator_status || {}} health_sections={atouchawaysettings.myhealth_sections || {}} /> : null}
					{ isFormstrue && !isFormsEmpty ? <FormSection title={formsection} forms={ownforms} person={personal} updates={updates} forms_sections={atouchawaysettings.forms_sections || {}} orderedforms={ownformsordered} /> : null}
					<HealthSection title={utilitiessection} buttons={UTILITIES_BUTTONS} screen={screen} person={personal} updates={updates} atouchawaysettings={atouchawaysettings || {}} />
				</View>
			</MainScroll>
			<HomeTabs page={model.page} userType={type} groupsettings={model.groupsettings}/>
		</PageContainer>
	);
}

function checkPermissions(list, permissions){
	return Object.keys(list).reduce(function(acc, element){
		if(permissions[element])
			acc[element] = list[element];
		return acc;
	}, {});
}
