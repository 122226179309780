import React from "react";

import BannerSoundSchema from "../../../../schemas/BannerSound";
import Form from "ae-forms";
import Divider from "../../../Layout/Divider";
import BlockView from "ae-base/Base/BlockView";

import { useIdPrefix, useResources } from "@aetonix/hooks";

export default function BannerSound(props) {
	const {
		dispatch,
	} = useResources();
	const idPrefix = useIdPrefix();
	var options = props.options;

	var soundOptions = {
		enabled: options.bannernotice,
	};

	return (
		<BlockView>
			<Form id_prefix={idPrefix + "soundForm"} schema={BannerSoundSchema} defaultData={soundOptions} autoSubmit onSubmit={dispatch("bannernotice:trigger:set")} />
			<Divider />
		</BlockView>
	);
}
