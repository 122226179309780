import React from "react";
import {
	View,
	Text,
} from "react-native";

import Style from "ae-style";
import StyledText from "ae-base/Base/StyledText";
import StyledButton from "ae-base/Base/StyledButton";
import StyledIcon from "ae-base/Base/StyledIcon";
import ConfirmationModal from "ae-base/Base/ConfirmationModal";
import ManagedSection from "./ManagedSection";
import ResponsiveStylesheet from "react-native-responsive-stylesheet";
import Breakpoints from "../../../Breakpoints";

import { useResources } from "@aetonix/hooks";

var styles = ResponsiveStylesheet.createSized("min-direction", {
	0: {
		mainContent: {
			flexDirection: "row",
			alignItems: "center",
			backgroundColor: Style.colors.light,
			justifyContent: "space-between",
			margin: 1,
		},
		textContect: {
			flexDirection: "column",
			alignItems: "center",
			paddingVertical: Style.layout.marginSmall,
		},
		updateTextContent: {
			flex: 1,
			flexDirection: "column",
			alignItems: "center",
			paddingVertical: Style.layout.marginSmall,
		},
		checked: {
			backgroundColor: Style.colors.secondary,
			flexDirection: "row",
			alignItems: "center",
			justifyContent: "space-between",
			margin: 1,
			paddingVertical: Style.layout.marginSmall,
		},
		margin: {
			marginHorizontal: Style.layout.marginSlim,
		},
		marginContent: {
			maxWidth: Style.layout.maxContent / 5,
		},
		padded: {
			marginVertical: Style.layout.marginLarge,
		},
		alertText: {
			textAlign: "center",
			marginHorizontal: Style.layout.marginLarge,
		},
		updateContent: {
			flexDirection: "row",
			alignItems: "center",
			justifyContent: "space-between",
			margin: 1,
			borderBottomWidth: 2,
			borderBottomColor: Style.colors.light,
			paddingVertical: Style.layout.paddingSmall,
		},
	},
	512: {
		marginContent: {
			marginHorizontal: Style.layout.marginSmall,
			maxWidth: Style.layout.maxContent,
		},
		textContect: {
			flexDirection: "row",
			alignItems: "center",
			paddingVertical: Style.layout.marginSmall,
			paddingHorizontal: Style.layout.marginContentSmall,
		},
		margin: {
			marginHorizontal: Style.layout.marginSmall,
		},
	},
});

export default function Metrics(props) {
	const {
		localize,
		dispatch,
	} = useResources();

	var model = props.model;
	var metrics = model.manage.metrics;
	var contact = model.manage.account;
	var confirming = metrics.confirming;
	var confirmRemove, cancelRemove;
	var hasthe = localize("metrics.hasthe");
	var beendone = localize("metrics.beendone");
	var caresetup = localize("metrics.caresetup");
	var confirmtext1 = localize("metrics.confirmtext1");
	var canceltext1 = localize("metrics.canceltext1");
	var register = localize("metrics.register");
	var one = localize("metrics.one");
	var new_text = localize("metrics.new_text");
	var questionmark = localize("metrics.questionmark");
	var careupdate = localize("metrics.careupdate");
	var confirmtext2 = localize("metrics.confirmtext2");
	var canceltext2 = localize("metrics.canceltext2");
	var metric_s = localize("metrics.metric_s");
	var consentform = localize("metrics.consentform");
	var careplan = localize("metrics.careplan");
	var initialcaregoals = localize("metrics.initialcaregoals");
	var survey = localize("metrics.survey");
	var	conferences = localize("metrics.care_conferences");
	var	visit = localize("metrics.home_visits");
	var	phonecall = localize("metrics.caregiver_phone_calls");
	var	summaries = localize("metrics.summaries_left_with_client");
	var	communications = localize("metrics.direct_communications_with_physicians");
	var	resources = localize("metrics.community_resources_contacted");
	var	careteam = localize("metrics.ccps_given_to_careteam");

	if(confirming){
		confirmRemove = dispatch("managedmetrics:trigger:create:accept", {
			type: confirming,
		});
		cancelRemove = dispatch("managedmetrics:trigger:create:cancel");
	}

	var careSetupItems = {
		"consents_signed": consentform,
		"cpps_initiated": careplan,
		"care_goals_defined": initialcaregoals,
		"client_surveys_distributed": survey,
	};

	var careUpdateItems = {
		"care_conferences": conferences,
		"home_visits": visit,
		"caregiver_phone_calls": phonecall,
		"summaries_left_with_client": summaries,
		"direct_communications_with_physicians": communications,
		"community_resources_contacted": resources,
		"ccps_given_to_careteam": careteam,
	};

	var setupText = (
		<Text style={styles.alertText}>
			<StyledText contrast>{hasthe}</StyledText>
			<StyledText contrast large> {careSetupItems[confirming]} </StyledText>
			<StyledText contrast>{beendone}</StyledText>
		</Text>
	);

	var renderConfirmation = (
		<ConfirmationModal
			open={confirming}
			title={caresetup}
			titleIcon="warning"
			confirmText={confirmtext1}
			cancelText={canceltext1}
			onConfirm={confirmRemove}
			onCancel={cancelRemove}>
			{setupText}
		</ConfirmationModal>
	);

	var updateText = (
		<Text style={styles.alertText}>
			<StyledText contrast>{register}</StyledText>
			<StyledText contrast large> {one} </StyledText>
			<StyledText contrast> {new_text} </StyledText>
			<StyledText contrast large> {careUpdateItems[confirming]} </StyledText>
			<StyledText contrast>{questionmark}</StyledText>
		</Text>
	);

	if(Object.keys(careSetupItems).indexOf(confirming) === -1)
		renderConfirmation = (
			<ConfirmationModal
				open={confirming}
				title={careupdate}
				titleIcon="warning"
				confirmText={confirmtext2}
				cancelText={canceltext2}
				onConfirm={confirmRemove}
				onCancel={cancelRemove}
			>
				{updateText}
			</ConfirmationModal>
		);


	return (
		<ManagedSection online={model.online} contact={contact} title={metric_s} external={renderConfirmation}>
			<StyledText style={styles.padded} large>{caresetup}</StyledText>
			<CareSetup item="consents_signed" metrics={metrics} />
			<CareSetup item="cpps_initiated" metrics={metrics} />
			<CareSetup item="care_goals_defined" metrics={metrics} />
			<CareSetup item="client_surveys_distributed" metrics={metrics} />

			<StyledText style={styles.padded} large>{careupdate}</StyledText>
			<CareUpdate item="care_conferences" metrics={metrics} />
			<CareUpdate item="home_visits" metrics={metrics} />
			<CareUpdate item="caregiver_phone_calls" metrics={metrics} />
			<CareUpdate item="summaries_left_with_client" metrics={metrics} />
			<CareUpdate item="direct_communications_with_physicians" metrics={metrics} />
			<CareUpdate item="community_resources_contacted" metrics={metrics} />
			<CareUpdate item="ccps_given_to_careteam" metrics={metrics} />
		</ManagedSection>
	);
}

function CareSetup(props) {
	const {
		localize,
		dispatch,
	} = useResources();

	var item = props.item;
	var value = props.metrics.cumulative[item];
	var consentform = localize("metrics.consentform");
	var signed = localize("metrics.signed");
	var careplan = localize("metrics.careplan");
	var initiated = localize("metrics.initiated");
	var initialcaregoals = localize("metrics.initialcaregoals");
	var defined = localize("metrics.defined");
	var survey = localize("metrics.survey");
	var distributed = localize("metrics.distributed");
	var not = localize("metrics.not");
	var makeasdone = localize("metrics.makeasdone");
	if (Breakpoints.smallerThan("mobileSmall"))
		makeasdone = localize("metrics.done");


	var careSetup = {
		consents_signed: {
			name: consentform,
			checked: signed,
		},
		cpps_initiated: {
			name: careplan,
			checked: initiated,
		},
		care_goals_defined: {
			name: initialcaregoals,
			checked: defined,
		},
		client_surveys_distributed: {
			name: survey,
			checked: distributed,
		},
	};

	var onEdit = dispatch("managedmetrics:trigger:create:start", {
		type: item,
	});

	if(value)
		var renderBody = (
			<View style={styles.checked}>
				<StyledIcon contrast name="check" style={styles.margin} />
				<StyledText contrast bold style={styles.margin}>{careSetup[item].name}</StyledText>
				<StyledText contrast style={styles.margin}>{careSetup[item].checked}</StyledText>
			</View>
		);
	else
		renderBody =  (
			<View style={styles.mainContent}>
				<StyledIcon name={"delete"} style={styles.margin} />
				<View style={styles.textContect}>
					<StyledText style={styles.margin} bold>{careSetup[item].name}</StyledText>
					<StyledText style={styles.margin}>{not + careSetup[item].checked}</StyledText>
				</View>
				<StyledButton title={makeasdone}  contrast onPress={onEdit} />
			</View>
		);


	return (
		<View>
			{renderBody}
		</View>
	);
}

function CareUpdate(props) {
	const {
		localize,
		dispatch,
	} = useResources();

	var item = props.item;
	var value = props.metrics.cumulative[item];
	var onEdit = dispatch("managedmetrics:trigger:create:start", {
		type: item,
	});

	var	conferences = localize("metrics.care_conferences");
	var	visit = localize("metrics.home_visits");
	var	phonecall = localize("metrics.caregiver_phone_calls");
	var	summaries = localize("metrics.summaries_left_with_client");
	var	communications = localize("metrics.direct_communications_with_physicians");
	var	resources = localize("metrics.community_resources_contacted");
	var	careteam = localize("metrics.ccps_given_to_careteam");

	var careUpdate = {
		care_conferences: {
			name: conferences,
		},
		home_visits: {
			name: visit,
		},
		caregiver_phone_calls: {
			name: phonecall,
		},
		summaries_left_with_client: {
			name: summaries,
		},
		direct_communications_with_physicians: {
			name: communications,
		},
		community_resources_contacted: {
			name: resources,
		},
		ccps_given_to_careteam: {
			name: careteam,
		},
	};

	var update = localize("metrics.update");

	return (
		<View style={styles.updateContent}>
			<View style={styles.updateTextContent}>
				<StyledText bold style={styles.margin}>{value}</StyledText>
				<StyledText style={styles.marginContent}>{careUpdate[item].name}</StyledText>
			</View>
			<StyledButton title={update} onPress={onEdit} />
		</View>
	);
}
