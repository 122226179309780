import React from "react";
import {
	View,
	StyleSheet,
} from "react-native";

import StyledText from "ae-base/Base/StyledText";
import Style from "@aetonix/styles";

var styles = StyleSheet.create({
	item: {
		marginBottom: Style.layout.marginMedium
	},
	value: {
		marginTop: Style.layout.marginContentSmall
	}
});

export default function Item(props){
	var values = (props.value ? [props.value] : props.values) || [];

	return (
		<View style={styles.item}>
			<StyledText tertiary emphasized>{props.title}</StyledText>
			<StyledText contrast inline style={styles.value}>{"\u2022"} {values}</StyledText>
		</View>
	);
}
