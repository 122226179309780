import React from "react";
import {
	View,
	StyleSheet,
	TouchableOpacity,
} from "react-native";

import StyledText from "ae-base/Base/StyledText";
import Style from "ae-style";
import NoticePageBase from "./NoticePageBase";

import { useResources } from "@aetonix/hooks";

var styles = StyleSheet.create({
	cancelButtons: {
		flex: 1,
		flexDirection: "row",
	},
	denyButton: {
		marginHorizontal: Style.layout.marginBordered,
		paddingHorizontal: Style.layout.paddingContent,
		paddingVertical: Style.layout.paddingButton,
		backgroundColor: "red",
	},
	acceptButton: {
		marginHorizontal: Style.layout.marginBordered,
		paddingHorizontal: Style.layout.paddingContent,
		paddingVertical: Style.layout.paddingButton,
		backgroundColor: "green",
	},
});

export default function ConfirmPage(props) {
	const {
		localize,
		dispatch,
	} = useResources();

	var cancelText = localize("atouchawaycalling.cancelText");
	var cancelAccept = dispatch("urgencycalls:trigger:cancel:accept");
	var cancelDeny = dispatch("urgencycalls:trigger:cancel:deny");
	var acceptButtonText = localize("atouchawaycalling.acceptCancelText");
	var denyButtonText = localize("atouchawaycalling.denyCancelText");
	return (
		<NoticePageBase>
			<StyledText style={styles.noticeTitle} bold black large>{cancelText}</StyledText>
			<View style={styles.cancelButtons}>
				<TouchableOpacity style={styles.denyButton} onPress={cancelDeny}>
					<StyledText emphasized bold contrast>{denyButtonText}</StyledText>
				</TouchableOpacity>
				<TouchableOpacity style={styles.acceptButton} onPress={cancelAccept}>
					<StyledText emphasized bold contrast>{acceptButtonText}</StyledText>
				</TouchableOpacity>
			</View>
		</NoticePageBase>
	);
}
