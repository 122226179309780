import React from "react";
import {
	View,
} from "react-native";
import ResponsiveStylesheet from "react-native-responsive-stylesheet";

import Breakpoints from "../../../Breakpoints";
import LinearGradient from "react-native-linear-gradient";
import StyledIconButton from "ae-base/Base/StyledIconButton";
import StyledText from "ae-base/Base/StyledText";
import BackButton from "ae-base/Base/BackButton";
import VerticalButton from "ae-base/Base/VerticalButton";

import Style from "@aetonix/styles";

import { useResources } from "@aetonix/hooks";

var gradientColors = [Style.colors.gradientStart, Style.colors.gradientEnd];
var gradientStart = {
	x: 0.0, y: 0.75,
};
var gradientEnd = {
	x: 1.0, y: 0.25,
};

var styles = ResponsiveStylesheet.createSized("min-direction", {
	0: {
		mainContent: {
			backgroundColor: Style.colors.primary,
			flexDirection: "row",
			justifyContent: "center",
			alignItems: "center",
			padding: Style.layout.paddingSmall,
		},

		text: {	},

		iconStyles: {
			padding: Style.layout.paddingSmall,
		},
		container: {
			paddingLeft: Style.layout.paddingMedium,
			paddingRight: Style.layout.paddingMedium,
		},
		controls: {
			justifyContent: "flex-end",
			flexDirection: "row",
			flex: 1,
		},
	},
	512: {
		mainContent: {
			marginTop: Style.layout.marginSlim,
			flexDirection: "row",
		},
		container: {
			backgroundColor: Style.colors.primary,
		},
		text: {
			flex: 1,
		},
	},
});

export default function TimelineHeader(props) {
	const {
		localize,
		dispatch,
	} = useResources();
	var exitMsgTitle = localize("timeline.exitMsgTitle");
	var pageTitle = localize("timeline.pageTitle");
	var isMobile = Breakpoints.smallerThan("mobile");
	var headingSize = isMobile ? "emphasized" : "largeHeading";
	var clearAllTitle = localize("timeline.clearAll");

	var markAllRead = dispatch("usernotifications:trigger:clear:all");

	if(isMobile) return (
		(<LinearGradient colors={gradientColors} start={gradientStart} end={gradientEnd}>
			<View style={styles.mainContent}>
				<StyledIconButton clear contrast icon="left" smallHeading style={styles.iconStyles} onPress={dispatch("desktop:trigger:page:back")} />
				<StyledText size={headingSize} bold inline contrast>{pageTitle}</StyledText>
				<View style={styles.controls}>
					<VerticalButton  title={clearAllTitle} icon="check-square" contrast onPress={markAllRead} />
				</View>
			</View>
		</LinearGradient>)
	);

	else return (
		<LinearGradient colors={gradientColors} start={gradientStart} end={gradientEnd}>
			<View style={styles.container}>
				<BackButton title={exitMsgTitle} />
				<View style={styles.mainContent}>
					<View style={styles.text}>
						<StyledText size={headingSize} bold inline contrast>{pageTitle}</StyledText>
					</View>
					<VerticalButton  title={clearAllTitle} icon="check-square" contrast onPress={markAllRead} />
				</View>
			</View>
		</LinearGradient>
	);
}
