import React, { useEffect, useRef, useState } from 'react';
import { createStyled } from '@emotion/primitives-core'
import { StyleSheet, View, ScrollView, Dimensions, Platform } from 'react-native';
import { asInitials } from '../../libraries/tile.library';
import Avatar from './Avatar';
import { faMedal, faStar } from '@fortawesome/free-solid-svg-icons';
import { default as TypeBadge } from './Badge';
import { variant } from 'styled-system';

const styled = createStyled(StyleSheet);

const marginOffset = 15;

const styles = StyleSheet.create({
    contentContainer: {
        width: 'auto',
        paddingRight: 50,
        alignSelf: 'center',
        height: '100%',
    },
});
  
const Container = styled(ScrollView)({
    width: 'auto',
    maxWidth: '100%',
    height: 'auto',
    flexGrow: 0,
    paddingLeft: marginOffset,
    paddingBottom: 5,
    paddingTop: 20,
    paddingRight: 20,
    /* alignItems: 'flex-start', */
}/* , variant({
    prop: 'align',
    variants: {
        center: { alignItems: 'center' },
        left: { alignItems: 'flex-start' },
    }
}) */) ;

const iconsProps = { 
    'patient': { icon: faStar, color: 'gold' },
	'administrator': { icon : faMedal, color:'silver', style: { 'borderRadius': 250 } } 
};

const badges = { large: 60, small: 30, list: 10 } 

const Badge = ({ type, size }) => {
    const icon = iconsProps?.[type] ?? null;
    return icon && <TypeBadge {...icon} size={size}/>;
};

export default ({ items = [], size = 'large', colour, align = 'left' }) => {

    const [count, setCount] = useState(4);
    const avatarRef = useRef(null);
    const containerRef = useRef(null);

    useEffect(() => {

        if (!containerRef?.current) {
            return;
        }

        /**
         * 
         *  MAJOR @wip
         *  Works for shrinking, not for growing 
         *  @reest I will return shortly
         */

        const onResizeAvatars = () => {
            const { scrollWidth, clientWidth } = containerRef?.current || {};
            const isOverflown = scrollWidth > clientWidth;

            if (isOverflown) {
                setCount(prevCount => --prevCount);
            }
        }

        onResizeAvatars();

        const subscription = Dimensions.addEventListener('change', onResizeAvatars);

        return () => subscription?.remove?.();
    }, [containerRef, count]);

    const boundCount = count <= 1 ? 1 : count;
    const membersToShow = items?.slice?.(0, boundCount) ?? [];
    const isOverFourMembers = items && items.length > boundCount;

    return (
        <>
            {membersToShow && membersToShow.length ? (
                <Container ref={containerRef}
                    horizontal
                    contentContainerStyle={styles.contentContainer}
                    align={align}
                >
                    { membersToShow.map((item, index) => (
                        <Avatar
                            key={`incoming-avatar-${index}`}
                            {...(index === 0 && { ref: avatarRef })}
                            size={size}
                            isStacked={true}
                            image={item.image}
                            text={asInitials(item.name)}
                            colour={colour}
                            badge={<Badge type={item.userType} size={badges?.[size] ?? badges['large']} />}
                        />
                    ))}
                    {isOverFourMembers && (
                        <Avatar size={size} isStacked={true} text={`+${items.length - boundCount}`} />
                    )}
                </Container>
            ) : null}
        </>
    );
}