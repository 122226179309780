
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 16, 2018
 * For information or permission request, email info@aetonixsystems.com
 */

export default {
	title: "Blood oxygen",
	description: "glucose.manual_entry",
	type: "object",
	displayDependencies: {
		oxygen: {
			required: ["followup"],
			properties: {
				followup: {
					enum: [true]
				}
			}
		},
		exertion: {
			required: ["followup"],
			properties: {
				followup: {
					enum: [true]
				}
			}
		}
	},
	properties: {
		followup: {
			type: "boolean",
			inputType: "hidden"
		},
		oxygensaturation: {
			type: "number",
			description: "bloodoxygen.saturationdescription",
			placeholder: "bloodoxygen.saturationmessage",
			minimum: 1,
			maximum: 100
		},
		pulserate: {
			type: "number",
			description: "bloodoxygen.pulseratedescription",
			placeholder: "bloodoxygen.pulseratemessage",
			minimum: 30,
			maximum: 200
		},
		comment: {
			description: "bloodoxygen.commenttitle",
			type: "string",
			multiline: true,
			placeholder: "bloodoxygen.comment"
		},
		oxygen: {
			description: "bloodoxygen.oxygentitle",
			value_localization: "bloodoxygen.oxygenvalues",
			type: "string",
			inputType: "radio",
			enum: [
				"oxygen",
				"room"
			]
		},
		exertion: {
			description: "bloodoxygen.exertiontitle",
			value_localization: "bloodoxygen.exertionvalues",
			type: "string",
			inputType: "radio",
			enum: [
				"rest",
				"exertion"
			]
		}
	},
	required: ["oxygensaturation", "pulserate"]
};
