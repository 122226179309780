import React from "react";
import TimelineItem from "./TimelineItem";

import { useResources } from "@aetonix/hooks";

export default function Wandering(props) {
	const {
		localize,
	} = useResources();

	var notification = props.notification;
	var screen = props.screen;
	var wandering = notification.content.wandering;
	var wanderleftzone = localize("banner.wanderLeftzone", notification.from);
	var wanderreturnzone = localize("banner.wanderInzone", notification.from);

	var acknowledgedby = localize("banner.wander_acknowledge", notification.content.acknowledge);

	var falsealarm = localize("banner.wanderInzone", notification.from);
	var confirm = localize("banner.wanderFalseAlarmQuestion");
	var notificationcontentdisplay = "";

	if (wandering && wandering !== 2)
		notificationcontentdisplay = wanderleftzone;

	if (!wandering)
		notificationcontentdisplay = wanderreturnzone;


	if (wandering && wandering === 2)
		if (!notification.invalid) {
			notificationcontentdisplay = wanderleftzone + acknowledgedby;

			if (notification.wanderinvalidator)  notificationcontentdisplay = confirm;
		}

	if (notification.invalid)  notificationcontentdisplay = falsealarm;

	return (
		<TimelineItem content={notificationcontentdisplay} iconName="info"  notification={notification} screen={screen} />
	);
}
