export default {
	title: "AtouchawaySettings",
	description: "schemas_atouchaway_settings_title",
	type: "object",
	properties: {
		status: {
			order: 1,
			description: "atouchawaysettings.status",
			format: "switch",
			inputType: "switch",
			type: "boolean"
		},
		resources: {
			order: 3,
			description: "resources.title",
			format: "switch",
			type: "boolean"
		}
	}
};
