import React from "react";
import {
	View,
	StyleSheet,
	TouchableOpacity,
} from "react-native";

import StyledText from "ae-base/Base/StyledText";
import StyledIcon from "ae-base/Base/StyledIcon";

import MainScroll from "../../Layout/MainScroll";

var CAREPLAN_BUTTONS = [{
	title: "client.patientidentifiers", section: "Client", "icon": "address-card-o",
}, {
	title: "careteam.careteam", section: "CareTeam", "icon": "users",
}, {
	title: "healthconditions.healthissues", section: "HealthConditions", "icon": "heartbeat",
}, {
	title: "treatments.allergiesmedications", section: "Treatments", "icon": "medkit",
}, {
	title: "goals.caregoals", section: "Goals", "icon": "list",
}, {
	title: "situation.situationlifestyle", section: "Situation", "icon": "bed",
}, {
	title: "healthassessments.healthassessment_s", section: "HealthAssessments", "icon": "stethoscope",
}, {
	title: "hospitalvisits.hospitalvisit_s", section: "HospitalVisits", "icon": "hospital-o",
}, {
	title: "othertreatments.othertreatments", section: "OtherTreatments", "icon": "bath",
}, {
	title: "supports.supportsservices", section: "Supports", "icon": "user-md",
}, {
	title: "appointments.appointmentsreferrals", section: "Appointments", "icon": "calendar",
}];

import Style from "@aetonix/styles";

import { useResources } from "@aetonix/hooks";

var styles = StyleSheet.create({
	container: {
		backgroundColor: "#131618",
		flexGrow: 0,
		flexShrink: 0,
		flexBasis: 235,
		borderRadius: Style.layout.atouchaway.border,
		padding: Style.layout.paddingButton,
		marginRight: Style.layout.marginMedium,
	},
	button: {
		marginBottom: Style.layout.marginLarge,
		paddingLeft: Style.layout.paddingSmall,
		alignItems: "center",
		flexDirection: "row",
	},
	icon: {
		marginRight: Style.layout.marginMedium,
	},
	scroll: {
		flexDirection: "column",
	},
	textStyle: {
		flex: 1,
		flexWrap: "wrap",
	},
});

export default function SectionList(props) {
	var section = props.section;
	var settings = props.settings || {};
	var sections = settings.careplan_sections || [];

	var available_buttons = CAREPLAN_BUTTONS.filter(function(button){
		return sections[button.section];
	});

	var buttons = available_buttons.map(function(button){
		var currentSection = button.section;
		var isActive = button.section === section;
		return (
			<Section section={button} key={currentSection} active={isActive} />
		);
	});

	return (
		<View style={styles.container}>
			<MainScroll style={styles.scroll}>
				{buttons}
			</MainScroll>
		</View>
	);
}

function Section(props) {
	const {
		localize,
		dispatch,
	} = useResources();

	var section = props.section;
	var active = props.active;

	var icon = section.icon;
	var title = localize(section.title);

	var openSection = dispatch("careplan:trigger:view", section);

	return (
		<TouchableOpacity onPress={openSection} style={styles.button}>
			<StyledIcon tertiary={!active} large name={icon} contrast={active} style={styles.icon} />
			<StyledText style={styles.textStyle} tertiary={!active} contrast={active}>{title}</StyledText>
		</TouchableOpacity>
	);
}
