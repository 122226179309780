import React from "react";
import PropTypes from "prop-types";

import AtaContainer from "./../AtaContainer";
import NewReadingModal from "../../Pages/MyHealth/HealthSection/NewReadingModal";

export default function AtouchAwaNewReadingModal(props) {
	const model = props.model;
	const newreading = model.newreading;
	const onWorkflowReadFromDevice = model.workflowaction && model.myhealthdevice;

	if (!(newreading && newreading.open)) return null;

	if (onWorkflowReadFromDevice) {
		return (
			<NewReadingModal model={model} atouchaway newreading={newreading} />
		);
	}

	return (
		<AtaContainer>
			<NewReadingModal model={model} atouchaway newreading={newreading} />
		</AtaContainer>
	);
}

AtouchAwaNewReadingModal.propTypes = {
	model: PropTypes.object,
};
