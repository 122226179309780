
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

export default {
	title: "AutoReply",
	description: "autoreply.title",
	type: "object",
	properties: {
		autoreply: {
			description: "autoreply.message",
			type: "string",
			multiline: true,
			inputType: "text",
			placeholder: "autoreply.title"
		}
	}
};
