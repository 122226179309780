import React from "react";
import TimelineItem from "./TimelineItem";

import { useResources } from "@aetonix/hooks";

export default function BraceletConnect(props) {
	const {
		localize,
	} = useResources();

	var notification = props.notification;
	var deviceconnected = localize("banner.braceletreconnect", notification.from);
	var screen = props.screen;

	return (
		<TimelineItem content={deviceconnected} iconName="info"  notification={notification} screen={screen} />
	);
}
