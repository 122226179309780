import React from "react";
import StyledButton from "ae-base/Base/StyledButton";

import { View } from "react-native";

import { useIdPrefix, useResources } from "@aetonix/hooks";

const styles = {
	sectionContent: {
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "flex-start",
	},
};

export default function GroupHeading(props) {
	const {
		localize,
		dispatch,
	} = useResources();
	const idPrefix = useIdPrefix();
	var contrast = props.contrast;
	var count = props.count;
	var section = props.section;
	var displaying = props.displaying;
	var screen = props.screen;

	var headerYou = localize("groupchat.headerYou", {
		count: count,
	});
	var nogroups = localize("groupchat.nogroups");
	var minimize = localize("groupchat.minimize");
	var	maximize = localize("groupchat.maximize");

	var headerMessage = headerYou;
	var icon = displaying ? "directup" : "dropdown";
	var title = displaying ? minimize : maximize;
	var toggleDisplaying = dispatch("homepage:trigger:toggle:" + section);

	if(section === "nogroups") {
		headerMessage = nogroups;
		icon = null;
		title = null;
		toggleDisplaying = null;
	}

	return (
		<View style={styles.sectionContent}>
			<StyledButton
				id={idPrefix + section + "_visibility"}
				icon={count ? icon : undefined}
				clear
				title={headerMessage}
				smallHeading
				contrast={contrast}
				onPress={count ? toggleDisplaying : undefined}
			/>
		</View>
	);
}
