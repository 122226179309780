import en from "./en.json";
import fr from "./fr.json";
import cn_s from "./cn_s.json";
import cn_t from "./cn_t.json";
import es from "./es.json";
import pt from "./pt.json";
import ta from "./ta.json";

export default {
	en: en,
	fr: fr,
	cn_s: cn_s,
	cn_t: cn_t,
	es: es,
	pt: pt,
	ta: ta
};
