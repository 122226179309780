import PropTypes from "prop-types";

/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React, {
	PureComponent
} from "react";

import PageContainer from "../../../Layout/PageContainer";
import MainScroll from "../../../Layout/MainScroll";
import MainContent from "../../../Layout/MainContent";
import ManagedHeader from "./ManagedHeader";
import OfflineBanner from "../../Homepage/OfflineBanner";

export default class ManagedSection extends PureComponent {
	render(){
		var props = this.props;
		var children = props.children;
		var title = props.title;
		var contact = props.contact;
		var external = props.external;
		var editButton = props.edit;
		var finishButton = props.finish;
		var saving = props.saving;
		var toast = props.toast;

		var offline = props.online ? null : (
			<OfflineBanner />
		);

		return (
			<PageContainer saving={saving} toast={toast}>
				<MainScroll>
					<ManagedHeader title={title} contact={contact} finish={finishButton} subTitle={props.subTitle} additionalTitles={props.additionalTitles} edit={editButton} back={props.back} actionId={props.actionId}/>
					{offline}
					<MainContent>
						{children}
					</MainContent>
				</MainScroll>
				{external}
			</PageContainer>
		);
	}
}

ManagedSection.propTypes = {
	name: PropTypes.string,
};