/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";
import {
	StyleSheet,
} from "react-native";

import GroupFormHeader from "./GroupFormHeader";
import OutputForm from "ae-forms/src/Outputs/OutputForm";
import PageContainer from "../../Layout/PageContainer";
import MainScroll from "../../Layout/MainScroll";
import MainContent from "../../Layout/MainContent";
import StyledButton from "ae-base/Base/StyledButton";
import { DATE_TIME_FORMAT } from "../../../utils/date-utils";

import { useResources } from "@aetonix/hooks";

const styles = StyleSheet.create({
	editButton: {
		alignSelf: "flex-end",
		marginTop: 4,
	},
});

export default function ActiveGroupFormSubmission(props) {
	const {
		localize,
		dispatch,
		formatDate,
		localizeFrom,
	} = useResources();

	const model = props.model;
	const submission = props.submission;
	const singleForm = props.singleForm;
	const activegroupformsubmission = model.activegroupformsubmission || {};
	const groupform = model.groupformsubmissions.form;
	const groupformlist = model.groupformlist;

	const currentform = groupformlist[groupform];
	const localization = currentform.localization;
	const title = localizeFrom(localization, ".") || currentform.name || currentform._id;
	const currentSubmission = submission || activegroupformsubmission;

	const locked = currentSubmission.locked;
	const defaultData = currentSubmission.data;
	const schema = groupformlist[groupform].schema;

	const editedHistory = currentSubmission.editHistory || [];
	const lastEdit = editedHistory.length ? editedHistory[editedHistory.length - 1] : null;
	const editedBy = singleForm ? (lastEdit ? localize("groupforms.edit_by", lastEdit) : null) : null;

	const date = singleForm && lastEdit ? formatDate(lastEdit.updated_at, DATE_TIME_FORMAT) : null;

	const edittitle = localize("groupforms.edit");

	const edit = dispatch("activegroupformsubmission:trigger:update:start", {
		id: currentSubmission._id,
	});

	const renderEditButton = (singleForm && !locked) ? (
		<StyledButton title={edittitle} icon="compose" onPress={edit} clear contrast style={styles.editButton} />
	) : null;

	return (
		<PageContainer>
			<MainScroll>
				<GroupFormHeader title={title} edit={renderEditButton} subTitle={editedBy} additionalTitles={[date]} />
				<MainContent>
					<OutputForm schema={schema} data={defaultData} />
				</MainContent>
			</MainScroll>
		</PageContainer>
	);
}
