import React from "react";
import {
	StyleSheet,
	View,
} from "react-native";
import ResponsiveStylesheet from "react-native-responsive-stylesheet";
import Style from "ae-style";

import PageContainer from "../../Layout/PageContainer";
import MainScroll from "../../Layout/MainScroll";
import HomeTabs from "../Homepage/HomeTabs";
import SquareButton from "ae-base/Base/SquareButton";
import HomeHeader from "../Homepage/HomeHeader";
import Heading from "ae-base/Base/Heading";

import { useResources } from "@aetonix/hooks";

var styles = StyleSheet.create({
	buttons: {
		flexDirection: "row",
		flexWrap: "wrap",
		justifyContent: "flex-start",
		alignSelf: "center",
	},
});

var MAX_BUTTONS = 6;

var responsiveStyles = makeIncrements(MAX_BUTTONS);

export default function GroupForms(props) {
	const {
		localize,
		dispatch,
	} = useResources();

	var model = props.model;
	var groupforms = model.groupformlist || {};
	var ownforms = model.ownformlist || {};
	var groupsettings = model.groupsettings || {};

	var personal = model.personal;
	var type = personal.type || [];

	var staffuser = type.indexOf("staff") !== -1;
	var unreadnotifications = model.usernotifications.unread;
	var screen = model.size;

	var renderedGroupButtons = Object.keys(groupforms).map(function(formId){
		var form = groupforms[formId];
		return (
			<FormButton key={formId} form={form} type={"group"} />
		);
	});

	var renderedStaffButtons = Object.keys(ownforms).map(function(formId){
		var form = ownforms[formId];
		if(!form || form.targetType !== "staff") return null;
		return (
			<FormButton key={formId} form={form} type={"staff"} />
		);
	});

	var renderedUtilities = [
		<SquareButton key={"creation"} title={localize("userlist.title")} icon={"user"} onPress={dispatch("orgusers:trigger:view")} />,
	];

	var orgformtitle = localize("groupforms.orgform");
	var ownformtitle = localize("groupforms.staffform");
	var utilitiesTitle = localize("groupforms.utilities");

	var renderOrgForm = renderedGroupButtons.length ? (
		<FormSection title={orgformtitle} screen={screen} buttons={renderedGroupButtons} />
	) : null;

	var renderStaffForm = renderedStaffButtons.length ? (
		<FormSection title={ownformtitle} screen={screen} buttons={renderedStaffButtons} />
	) : null;

	var renderUtilities = groupsettings?.hideCreationToken ? null : (
		<FormSection title={utilitiesTitle} screen={screen} buttons={renderedUtilities} />
	);

	return (
		<PageContainer saving={model.saving} toast={model.toast}>
			<HomeHeader unreadnotifications={unreadnotifications} personal={model.personal} screen={screen} />
			<MainScroll>
				{renderOrgForm}
				{renderStaffForm}
				{renderUtilities}
			</MainScroll>
			<HomeTabs page={model.page} userType={type} ownforms={model.ownformlist} groupforms={model.groupformlist} groupsettings={groupsettings}/>
		</PageContainer>
	);
}

function FormButton(props) {
	const {
		localizeFrom,
		dispatch,
	} = useResources();

	var form = props.form;
	var type = form.targetType;
	var localization = form.localization;
	var icon = form.icon;

	var onPress = type === "group" ? dispatch("groupformsubmissions:trigger:search", {
		form: form._id,
	}) : dispatch("ownformsubmissions:trigger:search", {
		form: form._id,
	});

	var title = localizeFrom(localization, ".") || form.name || form._id;

	return (
		<SquareButton
			title={title}
			icon={icon}
			onPress={onPress} />
	);
}

function makeIncrements(buttons) {
	var i = buttons;
	var increment = Style.layout.squareButton + Style.layout.marginSmall;
	var definition = {};

	while(i){
		var size = increment * i;

		definition[size] = {
			container: {
				width: size,
			},
		};
		i--;
	}

	return ResponsiveStylesheet.createSized("min-width", definition);
}

function FormSection(props) {
	var title = props.title;
	var screen = props.screen;
	var buttons = props.buttons;
	return (
		<View style={styles.buttons}>
			<View style={responsiveStyles.container}>
				<Heading title={title} screen={screen} />
				<View style={styles.buttons}>
					{buttons}
				</View>
			</View>
		</View>
	);
}
