
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
	* Unauthorized copying of this file, via any medium is strictly prohibited
	* Proprietary and confidential
	* Written by Aetonix, January 01, 2017
	* For information or permission request, email info@aetonixsystems.com
	*/

import React from "react";

import ListIcon from "ae-base/List/ListIcon";

var EMOJI = {
	"😄": "smile-o",
	"😦": "frown-o",
};

export default {
	check: check
};

function check(response) {
	if ( EMOJI[response]) {
		return (
			<ListIcon name={EMOJI[response]} />
		);
	}
	return response;
}
