import PropTypes from "prop-types";

/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";
import {
	View,
} from "react-native";
import Breakpoints from "../../../Breakpoints";
import ResponsiveStylesheet from "react-native-responsive-stylesheet";

import CircleButton from "ae-base/Base/CircleButton";
import NotificationIcon from "ae-base/Base/NotificationIcon";
import Style from "ae-style";

import { useResources } from "@aetonix/hooks";

var styles = ResponsiveStylesheet.createSized("min-direction", {
	0: {
		buttons: {
			flexDirection: "row",
			justifyContent: "center",
			flexWrap: "wrap",
		},
		notification: {
			position: "absolute",
			right: Style.layout.badge * 2,
		},
	},
	512: {
		notification: {
			right: 28,
			top: 22,
		},
	},
});

CircleButtons.propTypes = {
	buttons: PropTypes.array,
	permissions: PropTypes.object,
	contact: PropTypes.object,
	newsections: PropTypes.object,
};

export default function CircleButtons(props) {
	const {
		localize,
		dispatch,
	} = useResources();

	var buttons = props.buttons || [];
	var permissions = props.permissions || {};
	var contact = props.contact || {};
	var newsections = props.newsections || [];
	const group = props.group;

	var who = contact._id;
	var userType = contact.type || [];

	if(!who) return (
		null
	);

	var validButtons = buttons.filter(function (button) {
		var permissionName = button.permission;
		var permission = permissions[permissionName];
		if(!permission) return false;
		var trigger = button.trigger;
		if(!trigger) return false;
		var matchType = button.type;
		if(matchType && userType.indexOf(matchType) === -1) return false;

		return true;
	});

	if(!validButtons.length)
		return (
			null
		);

	var renderedButtons = validButtons.map(function(button){
		var permissionName = button.permission;
		var trigger = button.trigger;

		var triggerPayload = {
			who,
			group,
		};

		var buttonTitle = localize(button.title) || permissionName;

		var icon = button.icon;

		var action = dispatch(trigger, triggerPayload);

		var showNotification = newsections[permissionName]  ? (
			<NotificationIcon style={styles.notification} title="!" />
		) : null;

		// To scale circles for small format screens
		var circleDiameter;
		var circleIconSize;
		var circleTextSize;

		if (Breakpoints.smallerThan("small")) {
			circleDiameter = 80;
			circleIconSize = "emphasized";
			circleTextSize = 10;
		}

		return (
			<CircleButton
				id={`${props.testID}${buttonTitle}`}
				key={permissionName}
				diameter={circleDiameter}
				iconSize={circleIconSize}
				textSize={circleTextSize}
				title={buttonTitle}
				icon={icon}
				onPress={action}
				contrast
				clear>
				{showNotification}
			</CircleButton>
		);
	});

	return (
		<View style={styles.buttons}>
			{renderedButtons}
		</View>
	);
}
