
export function getIndexOfFeaturedTile(tiles) {
    const indexOfFeaturedTile = tiles.findIndex(tile => tile.isFeatured);
    const indexOfAdminTile = tiles.findIndex(tile => tile.userType === "administrator");
    const indexOfSelfTile = tiles.findIndex(tile => tile.isSelf);
    const isSelfAloneInCall = tiles.length === 1;

    if (isSelfAloneInCall) {
        return indexOfSelfTile
    }

    if (indexOfSelfTile === indexOfAdminTile && !isSelfAloneInCall) {      //for adminstrator of meeting
        return (indexOfFeaturedTile >= 0) ? indexOfFeaturedTile : 1
    }

    if (indexOfAdminTile !== indexOfSelfTile) {                    // for patients (attendees who are not admin) 
        return (indexOfFeaturedTile >= 0) ? indexOfFeaturedTile : indexOfAdminTile
    }

    return 0;
}

export function getListTiles(tiles, featuredIndex) {
    const sortSelf = (tileA, tileB) => (tileA.isSelf === tileB.isSelf) ? 0 : tileA.isSelf ? -1 : 1;
    return tiles.filter((_tile, index) => index !== featuredIndex).sort(sortSelf);
}
