import React, { useRef, useEffect } from 'react';
import { createStyled } from '@emotion/primitives-core'
import { StyleSheet, View, Animated, TouchableOpacity } from 'react-native';
import ResponsiveText from './Text/ResponsiveText';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faPhoneSlash } from '@fortawesome/free-solid-svg-icons';
import { useLocalize } from '../../hooks/useLocalize/LocalizeContext'

const styled = createStyled(StyleSheet);

const Overlay = styled(View)({
    width: '100%',
    height: '100%',
    position: 'absolute',
    opacity: 0.9,
    backgroundColor: '#023020',
    zIndex: 10000,
    flexDirection: 'row',
    justifyContent: 'center',
});

const Container = styled(View)({
    width: '100%',
    height: '100%',
    maxWidth: 800,
    padding:  15,
    
    // Flex
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
});

const ErrorText = styled(ResponsiveText)({
    color: 'white',
    marginTop: 20,
});

const CallActionButton = styled(TouchableOpacity)({
	width: 'auto',
	alignItems: "center",
	justifyContent: 'center',
	backgroundColor: 'white',
	height: 'auto',
	maxHeight: 40,
	minWidth: 130,
	padding: 10,
	borderRadius: 10,

	// Layout
	marginTop: 20,
})

export default ({ type = 'default', seconds = null, onClick }) => {

    const localize = useLocalize();

    const iconAnimationRef = useRef(new Animated.Value(1));
    const redirectMessage = `${localize('meeting.redirectMessage')} ${seconds ? seconds : 0 }s...`;

    const pulse = () => {
        const composite = Animated.sequence([
            Animated.timing(iconAnimationRef?.current, { toValue: 0.1, duration: 1000 }),
            Animated.timing(iconAnimationRef?.current, { toValue: 1, duration: 1000 })
        ]);

        Animated.loop(composite).start();
    };

    useEffect(() => pulse(), []);

    const types = {
        'adminEndedCall': localize('meeting.errors.adminEndedCall'),
        'default': localize('meeting.errors.default'),
        'expired': localize('meeting.errors.meetingExpired'),
    };

    const message = types?.[type] ?? types['default'];

    return (
        <Overlay>
            <Container>
                <Animated.View style={{ opacity: iconAnimationRef?.current ?? 1 }}>
                    <FontAwesomeIcon icon={faPhoneSlash} color="white" size={60}/>
                </Animated.View>
                
                <ErrorText>{ message }</ErrorText>

                { seconds >= 1 && <ErrorText>{redirectMessage}</ErrorText> }

                { onClick && <CallActionButton onPress={onClick}>
                    <ResponsiveText>Back</ResponsiveText>
                </CallActionButton> }
            </Container>
        </Overlay> 
    );
}