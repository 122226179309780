import React from "react";
import {
	View,
} from "react-native";
import prop from "prop";

import Items from "../Items";
import Item from "../Item";
import Label from "../Label";
import Value from "../Value";
import Summarized from "../Summarized";
import SectionTitle from "../SectionTitle";
import GroupItems from "../GroupItems";

import { useResources } from "@aetonix/hooks";

var getAssessmentName = prop("AssessmentName");
var getCompleted = prop("Completed");
var getCompletedDate = prop("CompletedDate");
var getAssessmentScore = prop("AssessmentScore");
var getActionsTaken = prop("ActionsTaken");
var getAssessmentType = prop("AssessmentType.Name");

export default function Healthassessments(props) {
	const {
		localize,
	} = useResources();

	var careplan = props.careplan;
	var currentSection = props.currentSection;
	var permissions = props.permissions;
	var changes = props.changes;
	var showCareplanDetail = props.showCareplanDetail;
	var ownCareplan = props.ownCareplan;

	var assessments = localize("healthassessments.assessments");

	var healthassessments = careplan.HealthAssessment || [];

	var renderHealthAssessment = healthassessments.map(function(healthassessment, index){
		var isChanged = changes["HealthAssessment." + index] || index === -1;
		var hasChanged = permissions >= 2 && isChanged;
		return (
			<HealthAssessment key={index} healthassessment={healthassessment} index={index} currentSection={currentSection} hasChanged={hasChanged} ownCareplan={ownCareplan} showCareplanDetail={showCareplanDetail} />
		);
	}

	);

	return (
		<View>
			<SectionTitle name={assessments} section="HealthAssessment" permissions={permissions} changes={changes} />
			{renderHealthAssessment}
		</View>
	);
}

function HealthAssessment(props){
	const { localize } = useResources();
	var healthassessment = props.healthassessment;
	var index = props.index;
	var currentSection = props.currentSection;
	var hasChanged = props.hasChanged;
	var showCareplanDetail = props.showCareplanDetail;
	var ownCareplan = props.ownCareplan;

	var assessmenttype = localize("healthassessments.assessmenttype");
	var assessmentname = localize("healthassessments.assessmentname");
	var completed = localize("healthassessments.completed");
	var completeddate = localize("healthassessments.completeddate");
	var score = localize("healthassessments.score");
	var actionstaken = localize("healthassessments.actionstaken");

	return (
		<GroupItems>
			<Items hasChanged={hasChanged} data={"HealthAssessment." + index}>
				<Item>
					<Label>{assessmenttype}</Label>
					<Value>{getAssessmentType(healthassessment)}</Value>
				</Item>

				<Item alternate>
					<Label>{assessmentname}</Label>
					<Value>{getAssessmentName(healthassessment)}</Value>
				</Item>

				<Summarized currentSection={currentSection} section={"HealthAssessment." + index} ownCareplan={ownCareplan} showCareplanDetail={showCareplanDetail}>
					<Item>
						<Label>{completed}</Label>
						<Value>{getCompleted(healthassessment)}</Value>
					</Item>

					<Item alternate>
						<Label>{completeddate}</Label>
						<Value>{getCompletedDate(healthassessment)}</Value>
					</Item>

					<Item>
						<Label>{score}</Label>
						<Value>{getAssessmentScore(healthassessment)}</Value>
					</Item>

					<Item alternate>
						<Label>{actionstaken}</Label>
						<Value>{getActionsTaken(healthassessment)}</Value>
					</Item>
				</Summarized>
			</Items>
		</GroupItems>
	);
}
