import React from "react";
import {
	View,
	StyleSheet,
} from "react-native";

import CallPage from "./CallPage";
import CallButton from "./CallButton";

import { useResources } from "@aetonix/hooks";

var styles = StyleSheet.create({
	threecallbutton: {
		flex: 1.5,
		justifyContent: "space-between",
	},
});

export default function Incoming(props) {
	const {
		localize,
		dispatch,
	} = useResources();
	var who = props.who;
	var cancelCall = dispatch("calling:trigger:deny", {
		reason: "denied",
	});
	var acceptCall = dispatch("calling:trigger:accept");
	var acceptText = localize("atouchawaycalling.accept");
	var denyText = localize("atouchawaycalling.ignore");

	return (
		<CallPage who={who} state={"incoming"}>
			<View style={styles.threecallbutton}>
				<CallButton
					accept
					icon={"aeto-phone"}
					buttonText={acceptText}
					buttonAction={acceptCall}
				/>
				<CallButton
					hangup
					icon={"aeto-hang_up-2"}
					buttonAction={cancelCall}
					buttonText={denyText}
				/>
			</View>
		</CallPage>
	);
}
