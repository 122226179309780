import React from "react";
import DeviceSchema from "../../schemas/Devices";
import Form from "ae-forms";
import xtend from "xtend";
import memoize from "memoizee";

import { useResources } from "@aetonix/hooks";

var getSchema = memoize(getAdjustedSchema);

export default function DeviceAdd(props) {
	const {
		localize,
		dispatch,
	} = useResources();

	var defaultData = props.defaultData;
	var onSubmit = props.onSubmit;
	var managed = props.managed;
	var enumValue = props.enumValue;

	var cancelText = localize("common.cancel");
	var onCancel = dispatch("desktop:trigger:page:back");

	return (
		<Form schema={getSchema(DeviceSchema, managed, enumValue)} defaultData={defaultData} onSubmit={onSubmit} onCancel={onCancel} cancelText={cancelText} />
	);
}

function getAdjustedSchema(schema, managed, enumValue){
	var properties = {...schema.properties};

	if(enumValue){
		var model = {...properties.model};
		model.enum = enumValue;
		properties.model = model;
	}

	if(managed){
		var mac = {...properties.mac};
		mac.managed = true;
		properties.mac = mac;
	}

	return xtend(schema, {
		properties: properties,
	});
}
