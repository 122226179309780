import React from "react";
import MyHealthComponent from "./MyHealthComponent";

import { useResources } from "@aetonix/hooks";

export default function BloodOxygen(props) {
	const {
		localize,
	} = useResources();

	var model = props.model;
	var bloodoxygen = model.bloodoxygen;

	var cumulative = bloodoxygen.cumulative;
	var reverse_cumulative = bloodoxygen.reverse; // needed for chart

	var textformat = "{oxygensaturation}%-{pulserate}";
	var readFromDevice = props.readFromDevice;

	var saturationlabel = localize("bloodoxygen.saturationlabel");
	var today = null;
	var section = model.page;

	// Sets up the format to the 'today' button
	if (cumulative.length) {
		var graphdata = adjust_time(reverse_cumulative, "oxygensaturation").slice(-10); // for chart
		var pulseratedata = adjust_time(reverse_cumulative, "pulserate").slice(-10);
		today = cumulative[0].history.oxygensaturation + saturationlabel + " - "
		+ cumulative[0].history.pulserate;
	}

	return (
		<MyHealthComponent model={model} readFromDevice={readFromDevice} section={section}
			textformat={textformat} latestReading={today} reverseEntries={graphdata}
			secondaryGraph={pulseratedata} activeModel={bloodoxygen} xaxis={"x"} yaxis={"y"} />
	);
}

function adjust_time(data, key) {
	var adjusted = data.map(function (item) {
		var time = time_label(item.updated_at, false);
		var value = (typeof item.history[key] === "string")
			? parseFloat(item.history[key])
			: item.history[key];
		return {
			x: time,
			y: value,
		};
	});
	return adjusted;
}

function time_label(data, forBoxGraphic) {
	var parsed = time_parse(data, forBoxGraphic);

	return (parsed.month + "-" + parsed.day + "  " + parsed.time);
}

function time_parse(data, forBoxGraphic) {
	var date = new Date(data);
	var month = date.getMonth() + 1;
	var day = date.getDate();
	day = two_digit(day);
	month = two_digit(month);
	var hours = two_digit(date.getHours());
	var minutes = two_digit(date.getMinutes());
	var seconds = forBoxGraphic ? "" : ":" + two_digit(date.getSeconds());
	var time = hours + ":" + minutes + seconds;

	return {
		month: month,
		day: day,
		time: time,
	};
}

function two_digit(data) {
	var item = (data < 10)
		? "0" + data
		: data;
	return item;
}
