import React from "react";
import {
	View,
} from "react-native";
import ResponsiveStylesheet from "react-native-responsive-stylesheet";

import StyledButton from "ae-base/Base/StyledButton";
import Card from "../../Layout/Card";
import ButtonSpacer from "ae-base/Base/ButtonSpacer";
import Style from "ae-style";
import StyledText from "ae-base/Base/StyledText";

import Change from "./Change";
import Path from "./Path";

import { useResources } from "@aetonix/hooks";

var styles = ResponsiveStylesheet.createSized("min-width", {
	0: {
		changeViewer: {
			marginVertical: Style.layout.marginSmall,
		},
		controls: {
			flexDirection: "column",
			justifyContent: "space-around",
			marginTop: Style.layout.marginLarge,
		},
		controlButton: {
			justifyContent: "space-between",
		},
		card: {
			marginVertical: Style.layout.marginSmall / 2,
			padding: Style.layout.paddingMedium,
		},
		cardHeader: {
			marginBottom: Style.layout.marginSmall,
		},
		cardTitle: {
			flexDirection: "row",
			justifyContent: "space-between",
		},
		timestamp: {
			textAlign: "right",
		},
		change: {
			marginBottom: Style.layout.marginSlim,
		},
	},
	512: {
		controls: {
			flexDirection: "row",
		},
		card: {
			marginVertical: Style.layout.marginSmall / 2,
		},
	},
});

export default function ChangeViewer(props){
	const { localize, dispatch } = useResources();
	var careplan = props.careplan;
	var permissions = props.permissions;
	var changeViewer = props.changeViewer;
	var createTime = changeViewer.created_at;
	var id = changeViewer._id;
	var screen = props.screen;
	var changes = changeViewer.changes || [];

	var renderChange = changes.map(function(change, index){
		var value = change.value;
		var path = change.path;
		var values;
		if(change.op === "add" && (typeof value === "object") && !Array.isArray(value) && path.indexOf("-") === -1)
			values = getValues(value, path);

		if(values)
			return values.map(function(val, i){
				return (
					<RenderChange key={index + "." + i} id={index + "." + i} careplan={careplan} change={val} screen={screen} />
				);
			});
		else
			return (
				<RenderChange key={index} id={index} careplan={careplan} change={change} screen={screen} />
			);

	});
	var acceptandapplythischange = localize("proposal.acceptandapplythischange");
	var denythischange = localize("proposal.denythischange");
	var proposedupdate = localize("proposal.proposedupdate");
	var by = localize("proposal.by", changeViewer.created_by);
	var renderControlsEnabled = props.renderControls;

	var renderControls = null;
	if(permissions["careplan_" + careplan.section.section] >= 3 && renderControlsEnabled)
		renderControls = (
			<View style={styles.controls}>
				<StyledButton contrast style={styles.controlButton} title={acceptandapplythischange} icon="check" onPress={dispatch("managedcareplanproposedchanges:trigger:confirm", {
					change: id,
				})} />
				<ButtonSpacer />
				<StyledButton alert style={styles.controlButton} title={denythischange} icon="times" onPress={dispatch("managedcareplanproposedchanges:trigger:deny", {
					change: id,
				})} />
			</View>
		);

	return (
		<View style={styles.changeViewer}>
			<Card style={styles.card}>
				<StyledText style={styles.cardHeader} bold smallHeading>{proposedupdate}</StyledText>
				<View style={styles.cardTitle}>
					<StyledText bold>{by}</StyledText>
					<StyledText style={styles.timestamp}bold>{createTime}</StyledText>
				</View>
				{renderChange}
				{renderControls}
			</Card>
		</View>
	);
}

function RenderChange(props){
	var change = props.change;
	var screen = props.screen;
	var careplan = props.careplan;
	var key = props.id;
	return (
		<View key={key} style={styles.change}>
			<Path careplan={careplan} change={change} />
			<Change careplan={careplan} change={change} screen={screen} />
		</View>
	);
}

function getValues(value, path){
	return Object.keys(value).reduce(function(changes, key){
		var newPath = path + "/" + key;
		var simplePath = newPath.replace(/\d\/+/g, "");
		if(typeof (value[key]) === "object" && !Array.isArray(value[key]))
			return changes.concat(getValues(value[key], newPath));
		else {
			var newChange = {
				op: "add",
				path: newPath,
				simplePath: simplePath,
				value: value[key],
			};
			return changes.concat([newChange]);
		}
	}, []);
}
