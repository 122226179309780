export default {
	title: "AtouchawaySettings",
	description: "schemas_atouchaway_settings_title",
	type: "object",
	properties: {
		temperature: {
			order: 0,
			description: "atouchawaysettings.temperature",
			value_description: "atouchawaysettings",
			type: "string",
			inputType: "dropdown",
			enum: ["c", "f"],
		},
		pages: {
			order: 1,
			description: "atouchawaysettings.pages",
			value_description: "atouchawaysettings.page_values",
			type: "number",
			inputType: "dropdown",
			enum: [0, 2, 3, 4, 6, 8],
		},
		power_button: {
			order: 2,
			description: "atouchawaysettings.power",
			value_description: "atouchawaysettings",
			type: "string",
			inputType: "dropdown",
			enum: ["standby", "none", "hide"],
		},
		screensaver: {
			order: 3,
			description: "atouchawaysettings.screensaver",
			value_description: "atouchawaysettings.screensaver_values",
			type: "string",
			inputType: "dropdown",
			enum: [
				"time",
				"hide"
			]
		},
		messaging: {
			order: 4,
			description: "atouchawaysettings.messaging",
			value_description: "atouchawaysettings.messaging_values",
			type: "string",
			enum: [
				"basic",
				"intermediate",
				"advanced"
			]
		},
		textsize: {
			order: 5,
			description: "atouchawaysettings.textsize",
			value_description: "atouchawaysettings.textsize_values",
			type: "number",
			enum: [
				0,
				1,
				2
			]
		},
		switch_camera: {
			order: 6,
			description: "atouchawaysettings.switch_camera",
			format: "switch",
			type: "boolean"
		},
		locked: {
			order: 7,
			description: "atouchawaysettings.locked",
			format: "switch",
			inputType: "switch",
			type: "boolean",
		},
		status: {
			order: 7,
			description: "atouchawaysettings.status",
			format: "switch",
			inputType: "switch",
			type: "boolean"
		},
		resources: {
			order: 8,
			description: "resources.title",
			format: "switch",
			inputType: "switch",
			type: "boolean"
		}
	}
};
