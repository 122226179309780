
export default async (content, filename = "tmpfile") => {

	let blob = convertBase64ToBlob(content);
	let file = new File([blob], `${filename}.png`, { type: "image/png" });

	return file;
};

function convertBase64ToBlob(base64Image) {
	// Split into two parts
	const parts = base64Image.split(";base64,");

	// Hold the content type
	const imageType = parts[0].split(":")[1];

	// Decode Base64 string
	const decodedData = window.atob(parts[1]);

	// Create UNIT8ARRAY of size same as row data length
	const uInt8Array = new Uint8Array(decodedData.length);

	// Insert all character code into uInt8Array
	for (let i = 0; i < decodedData.length; ++i) {
		uInt8Array[i] = decodedData.charCodeAt(i);
	}

	// Return BLOB image after conversion
	return new Blob([uInt8Array], { type: imageType });
}