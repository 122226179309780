import React from "react";
import {
	StyleSheet,
	TouchableOpacity,
} from "react-native";

import StyledText from "ae-base/Base/StyledText";
import StyledIcon from "ae-base/Base/StyledIcon";

import Style from "ae-style";

var styles = StyleSheet.create({
	invitebutton: {
		alignItems: "center",
		padding: Style.layout.paddingSmall,
		backgroundColor: "#F5A80A",
		margin: 2,
	},
	hangupbutton: {
		alignItems: "center",
		padding: Style.layout.paddingSmall,
		backgroundColor: "#AE1010",
		margin: 2,
	},
	acceptbutton: {
		alignItems: "center",
		padding: Style.layout.paddingSmall,
		backgroundColor: "#21a117",
		margin: 2,
	},
});

export default function SideButton(props) {
	var buttonText = props.buttonText;
	var buttonAction = props.buttonAction;
	var icon = props.icon;

	if(props.hangup)
		var buttonStyle = styles.hangupbutton;
	if(props.accept)
		buttonStyle = styles.acceptbutton;
	if(props.invite)
		buttonStyle = styles.invitebutton;

	return (
		<TouchableOpacity style={buttonStyle} onPress={buttonAction}>
			<StyledIcon name={icon} contrast hugeIcon />
			<StyledText bold contrast emphasized>{buttonText}</StyledText>
		</TouchableOpacity>
	);
}
