import React, { useMemo, useCallback } from 'react';
import { createStyled } from '@emotion/primitives-core'
import { variant } from 'styled-system';
import { StyleSheet, View, FlatList } from 'react-native';
import { getIndexOfFeaturedTile, getListTiles } from './teams.library';

const styled = createStyled(StyleSheet);

const GridContainer = styled(View)({
	width: '100%',
	height: '100%',
	alignItems: 'flex-end',
	flexDirection: 'column',
	overflowY: 'scroll',
	backgroundColor: 'white'
}, variant({
	prop: 'orientation',
	variants: {
		horizontal: { flexDirection: 'row' },
		vertical: { flexDirection: 'column' },
	}
}));

const FeaturedContainer = styled(View)({
	flex: 1,
	alignSelf: 'stretch',
}, variant({
	prop: 'orientation',
	variants: {
		horizontal: {
			borderRightWidth: 2,
			borderRightColor: 'white',
			borderRightStyle: 'solid',
			borderBottomRightRadius: 4,
			borderTopLeftRadius: 4,
		},
		vertical: {
			borderBottomWidth: 2,
			borderBottomColor: 'white',
			borderBottomStyle: 'solid',
			borderBottomRightRadius: 4,
			borderBottomLeftRadius: 4,
		}
	}
}));

const ItemContainerHorizontal = styled(View)({
	flexGrow: 1,
	
	// Add outline for the grid
	borderWidth: 2,
	borderColor: 'white',
	borderStyle: 'solid',
	borderRadius: 4,
}, variant({
	prop: 'type',
	variants: {
		filler: { flexGrow: 0, display: 'none' },
	}
}), variant({
	prop: 'orientation',
	variants: {
		horizontal: {
			height: 200,
			width: '100%',
			padding: 0,
		},
		vertical: {
			width: 300,
			flexGrow: 1,
			marginRight: 1,
		}
	}
}));

const TileList = styled(FlatList)({
	backgroundColor: '#023020B3'
}, variant({
	prop: 'orientation',
	variants: {
		vertical: {
			height: 200,
			width: '100%',
			flexGrow: 0,
			maxHeight: '40%',
		},
		horizontal: {
			padding: 1,
			width: 260,
			height: '100%',
			maxWidth: 250,
			flex: 0,
		}
	}
}));

export default ({ tiles, orientation = 'vertical', renderTile }) => {

	const containerStyle = useMemo(() => ({
		justifyContent: 'flex-end',
		...(orientation === 'vertical') && { padding: 2 }, 
	}), [orientation]);
	
	const featuredIndex = useMemo(() => getIndexOfFeaturedTile(tiles), [getIndexOfFeaturedTile, tiles]);
	const featuredTile = useMemo(() => tiles[featuredIndex], [tiles, featuredIndex]);

	const isHorizontal = useMemo(() => orientation === 'vertical', [orientation]);

	const remainingTiles = useMemo(() => getListTiles(tiles, featuredIndex), [tiles, featuredIndex, getListTiles]);

	const renderItem = useCallback(({ item }) => (
		<ItemContainerHorizontal orientation={orientation} testID="teams-video-tile-container" key={`teams-tile-container-${item.externalId}`}>
			{ renderTile(item) }
		</ItemContainerHorizontal>
	), [renderTile, orientation]);

	return (
		<GridContainer orientation={orientation} testID="teams-grid-container">
			<FeaturedContainer testID="teams-featured-container" orientation={orientation}>
				{ renderTile(featuredTile) }
			</FeaturedContainer>

			{ remainingTiles.length >= 1 && tiles.length > 1 && <TileList
				testID="teams-other-list"
				data={remainingTiles}
				renderItem={renderItem}
				contentContainerStyle={containerStyle}
				horizontal={isHorizontal}
				inverted
				keyExtractor={item => item?.externalId}
				orientation={orientation}
				initialScrollIndex={0}
			/> }
		</GridContainer>
	);
}