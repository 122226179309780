import React from "react";
import {
	View,
	StyleSheet,
	ScrollView,
	TouchableWithoutFeedback,
	Dimensions,
	Image,
} from "react-native";

import StyledText from "ae-base/Base/StyledText";
import SquareButton from "../SquareButton";
import Modal from "../../Layout/Modal";
import Card from "../../Layout/Card";
import ImageUrl from "ae-base/Base/ImageUrl";
import Divider from "../../Layout/Divider";

import Style from "ae-style";

import { useResources } from "@aetonix/hooks";
import { useLocalize } from "../../../hooks/useLocalize/LocalizeContext";

var styles = StyleSheet.create({
	modalContainer: {
		backgroundColor: "#FFFFFF",
		padding: 24,
		borderRadius: 24,
	},
	messageBox: {},
	controls: {
		flexDirection: "row",
		justifyContent: "space-around",
		margin: Style.layout.marginSmall,
	},
	messageBoxContainer: {
		flexDirection: "row",
		alignItems: "center",
		borderRadius: 24,
	},
	infoContainer: {
		flexDirection: "row",
		margin: Style.layout.marginSmall,
	},
	imageStyle: {
		height: 150,
		width: 150,
		borderRadius: 20,
	},
	textContainer: {
		marginHorizontal: Style.layout.marginMedium,
		justifyContent: "flex-end",
	},
	viewingContainer: {
		alignItems: "center",
	},
	messageInfoContainer: {
		flexDirection: "row",
		justifyContent: "space-between",
		marginVertical: Style.layout.marginSmall,
	},
});

export default function MessageViewer(props) {
	const {
		localize,
		dispatch,
		config,
	} = useResources();
	var viewing = props.viewing;
	var missed = props.missed;
	var from = viewing.who;
	var unreadList = viewing.unreadList;
	var missedPerson = missed[from._id];

	var messageWidth = {
		width: Dimensions.get("window").width / 1.6,
		maxHeight: Dimensions.get("window").height / 3,
	};

	var open = !!viewing;
	var messageStyle = [styles.messageBox, messageWidth];
	var cancelView = dispatch("atouchawaymessaging:trigger:view:cancel");
	var readMessage = dispatch("atouchawaymessaging:trigger:view:read", {
		who: from._id,
	});

	var acknowledge = localize("atouchawaymessaging.acknowledge");
	var close = localize("atouchawaymessaging.close");
	var viewingMessages = localize("atouchawaymessaging.viewingmessages", {
		total: unreadList?.length ?? 0,
	});

	var renderMessages = unreadList.map(function(message, index){
		return(
			<MessageView message={message} index={index} />
		);
	});

	var buttonSize = Dimensions.get("window").width < 650 || Dimensions.get("window").height < 700 ? {small: true} : null;

	return (
		<Modal open={open}>
			<Card scroll atouchaway>
				<View style={styles.viewingContainer}>
					<StyledText bold black>{viewingMessages}</StyledText>
				</View>
				<View style={styles.infoContainer}>
					<Image source={ImageUrl(from.image, config)} style={styles.imageStyle} />
					<View style={styles.textContainer}>
						<StyledText bold smallHeading>{from.lname + ", " + from.fname}</StyledText>
					</View>
				</View>
				<View style={styles.messageBoxContainer} >
					<ScrollView style={messageStyle}>
						<TouchableWithoutFeedback>
							<View>
								<Divider />
								{renderMessages}
							</View>
						</TouchableWithoutFeedback>
					</ScrollView>
				</View>
				<View style={styles.controls}>
					<SquareButton title={acknowledge} secondary contrast icon={"check"} onPress={readMessage} {...buttonSize} />
					<SquareButton title={close} highlight contrast icon={"delete"} onPress={cancelView} {...buttonSize} />
				</View>
			</Card>
		</Modal>
	);
}

function MessageView(props) {
	var localize = useLocalize();
	var message = props.message;
	var index = props.index + 1;
	var messageNum = localize("atouchawaymessaging.messagenum", {
		index: index,
	});

	return (
		<View style={styles.messageContainer}>
			<View style={styles.messageInfoContainer}>
				<StyledText black>{messageNum}</StyledText>
				<StyledText black>{message.simple_date + " " + message.timestamp}</StyledText>
			</View>
			<StyledText black emphasized>{message.content}</StyledText>
			<Divider />
		</View>
	);
}
