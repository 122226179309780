import React from "react";
import {
	StyleSheet,
	View,
} from "react-native";

import Style from "ae-style";
import OverviewItem from "./OverviewItem";
import ManagedHeader from "./ManagedSection/ManagedHeader";
import MainScroll from "../../Layout/MainScroll";
import { useMeasureUnits } from "@aetonix/hooks";

import { useResources } from "@aetonix/hooks";

var styles = StyleSheet.create({
	overviewContainer: {
		alignItems: "flex-start",
		justifyContent: "space-around",
		flexDirection: "row",
		flexWrap: "wrap",
		padding: Style.layout.paddingMedium,
	},
});

export default function OverviewMetrics(props) {
	const {
		localize,
		dispatch,
	} = useResources();

	var model = props.model;
	var activity = model.manage.managedactivity || {};
	var bloodsugar = model.manage.managedbloodsugar || {};
	var weight = model.manage.managedweight || {};
	var bloodpressure = model.manage.managedbloodpressure || {};
	var bloodoxygen = model.manage.managedbloodoxygen || {};
	var bodytemperature = model.manage.managedbodytemperature || {};
	var contact = model.manage.account;
	var group = model.activemanagegroup;
	var who = model.manage.account._id;
	var language = model.personal.language;


	const measureUnits = useMeasureUnits();
	const weightUnits = measureUnits.weightUnits;
	const tempUnitKeys = measureUnits.temperatureUnits;
	const glucoseUnitKeys = measureUnits.glucometerUnits;
	var oldGlucoseUnitKeys = glucoseUnitKeys === "mg" ? "meanblood" : "glucose";

	var tempUnits = localize("bodytemperature." + tempUnitKeys);
	var glucoseUnits = localize("bloodsugar." + glucoseUnitKeys);

	var title = localize("myhealthoverview.overview");
	var activity_title = localize("myhealth.activity");
	var bloodsugar_title = localize("myhealth.bloodsugar");
	var weight_title = localize("myhealth.weight");
	var bloodpressure_title = localize("myhealth.bloodpressure");
	var bloodoxygen_title = localize("myhealth.oxygen");
	var bodytemp_title = localize("myhealth.bodytemp");
	var unit = localize("activity.steps_text");

	var latestActivity = activity.latest ? activity.latest.cumulative + " " + unit : " ";
	var latestBloodSugar = bloodsugar.latest ? (bloodsugar.latest.history[glucoseUnitKeys] || bloodsugar.latest.history[oldGlucoseUnitKeys]) + " " + glucoseUnits : " ";
	var latestWeight = weight.latest ? weight.latest.history[weightUnits] + " " + weightUnits : " ";
	var latestBloodPressure = bloodpressure.latest ? bloodpressure.latest.history.systolic + "/" + bloodpressure.latest.history.diastolic + " " + "mmhg\n" + (bloodpressure.latest.history.bpPulserate || "--") + " bpm" : " ";
	var latestBloodOxygen = bloodoxygen.latest ? bloodoxygen.latest.history.oxygensaturation + "%" + " - " + bloodoxygen.latest.history.pulserate  + "bpm" : " ";
	var latestBodyTemp = bodytemperature.latest ? bodytemperature.latest.history[tempUnitKeys] + " " + tempUnits : " ";

	var jumpToActivity = dispatch("managedactivity:trigger:view", {
		who: who,
		group,
	});

	var jumpToBloodSugar = dispatch("managedbloodsugar:trigger:view", {
		who: who,
		group,
	});

	var jumpToWeight = dispatch("managedweight:trigger:view", {
		who: who,
		group,
	});

	var jumpToBloodPressure = dispatch("managedbloodpressure:trigger:view", {
		who: who,
		group,
	});

	var jumpToBloodOxygen = dispatch("managedbloodoxygen:trigger:view", {
		who: who,
		group,
	});

	var jumpToBodyTemp = dispatch("managedbodytemperature:trigger:view", {
		who: who,
		group,
	});

	var longString = language === "ta";

	var overviewItems = [
		{
			title: latestActivity, text: activity_title, onPress: jumpToActivity,
		},
		{
			title: latestBloodSugar, text: bloodsugar_title, onPress: jumpToBloodSugar,
		},
		{
			title: latestWeight, text: weight_title, onPress: jumpToWeight,
		},
		{
			title: latestBloodPressure, text: bloodpressure_title, onPress: jumpToBloodPressure, longString: longString,
		},
		{
			title: latestBloodOxygen, text: bloodoxygen_title, onPress: jumpToBloodOxygen, longString: longString,
		},
		{
			title: latestBodyTemp, text: bodytemp_title, onPress: jumpToBodyTemp,
		},
	];

	var renderedItems = overviewItems.map(function(itemData) {
		return <OverviewItem title={itemData.title} text={itemData.text} onpress={itemData.onPress} managed longString={itemData.longString}/>;
	});

	return (
		<MainScroll style={styles.center}>
			<ManagedHeader title={title} contact={contact} />
			<View style={styles.overviewContainer}>
				{renderedItems}
			</View>
		</MainScroll>
	);
}
