import React from "react";
import {
	View,
	StyleSheet,
} from "react-native";
import AtaContainer from "../AtaContainer";
import MyHealthList from "./MyHealthList";
import StyledButton from "ae-base/Base/StyledButton";
import Style from "ae-style";
import About from "../About";
import Reminder from "../Reminder";
import Comment from "./Comment";
import AtouchAwayActivityMetrics from "./AtouchAwayActivityMetrics";
import BloodPressure from "./BloodPressure";
import MyHealthOverview from "./MyHealthOverview";
import BloodSugarLevel from "./BloodSugarLevel";
import BodyTemperature from "./BodyTemperature";
import BloodOxygen from "./BloodOxygen";
import Weight from "./Weight";
import MainScroll from "../../Layout/MainScroll";
import CustomIndicator from "./CustomIndicator";

import { useResources } from "@aetonix/hooks";

var styles = StyleSheet.create({
	content: {
		flex: 1,
		flexDirection: "row",
	},
	enternew: {
		borderRadius: Style.layout.paddingSlim,
		backgroundColor: Style.colors.secondary,
		borderColor: Style.colors.black,
		marginVertical: Style.layout.marginSmall,
		alignSelf: "stretch",
		alignItems: "center",
		textAlign: "center",
	},
});

var DEVICE_SECTIONS = [
	"activity",
	"bloodpressure",
	"bloodsugar",
	"bloodoxygen",
	"weight",
	"bodytemperature",
];

var SECTION_MAP = {
	"activity": AtouchAwayActivityMetrics,
	"bloodpressure": BloodPressure,
	"bloodsugar": BloodSugarLevel,
	"bodytemperature": BodyTemperature,
	"bloodoxygen": BloodOxygen,
	"weight": Weight,
	"customindicator": CustomIndicator,
};

export default function ATouchAwayMyHealth(props) {
	const {
		localize,
		dispatch,
	} = useResources();

	var model = props.model;
	var settings = model.settings;
	var options = model.options;

	const customIndicatorsList = model.customindicatorslist;

	var page = model.page;
	var content;
	var trunk = options.trunk;
	var openComment = model.comment ? model.comment.open : false;
	var reminder = model.reminders || {};
	var section = page;

	var sectionPermissions = settings.myhealth_sections || {};

	var readDevice = sectionPermissions[section + "_readdevice"];

	if(page === "overview")
		content = <MyHealthOverview model={model} />;
	else {
		var canRead = DEVICE_SECTIONS.indexOf(section) !== -1;
		var readFromDevice = null;

		if(canRead && !readDevice) {
			var onPress = dispatch("myhealthdevice:trigger:showinfo", {
				deviceType: section,
			});
			var title = localize("myhealthdevice.read");
			readFromDevice = (
				<StyledButton stretch contrast emphasized style={styles.enternew} title={title} onPress={onPress} />
			);
		}

		var SectionComponent = SECTION_MAP[section] || View;
		content = (
			<SectionComponent model={model} readFromDevice={readFromDevice} />
		);
	}

	if((page in sectionPermissions) && !sectionPermissions[page])
		content = null;

	var renderComment = openComment ? <Comment model={model} /> : null;
	var renderTrunk = trunk ? <About options={options} /> : null;
	var renderReminder = reminder.active && !reminder.answered ? <Reminder reminder={reminder} /> : null;
	var renderContent = settings.myhealth ? content : null;
	var renderMyHealthBar = settings.myhealth ? (<MyHealthList
		customIndicatorsList={customIndicatorsList}
		customIndicator={model.customindicator}
		section={section}
		settings={settings}
	/>) : null;

	return (
		<AtaContainer>
			<View style={styles.content}>
				{renderMyHealthBar}
				<MainScroll atouchaway>
					{renderContent}
				</MainScroll>
			</View>
			{renderComment}
			{renderTrunk}
			{renderReminder}
		</AtaContainer>
	);
}
