import PropTypes from "prop-types";

/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React, {
	PureComponent,
} from "react";
import {
	View,
} from "react-native";
import prop from "prop";

import Items from "../Items";
import Item from "../Item";
import Label from "../Label";
import Value from "../Value";
import Summarized from "../Summarized";
import GroupItems from "../GroupItems";

import { useResources } from "@aetonix/hooks";

var getSocialInteractionInLast7DaysOther = prop("SocialInteractionInLast7DaysOther");
var getSupplementaryBenefit = prop("SupplementaryBenefit");
var getUsedSubstance = prop("UsedSubstance");
var getOtherConsiderations = prop("OtherConsiderations");
var getEmploymentName = prop("Employment.Name");
var getFollowRecommendedDietName = prop("FollowRecommendedDiet.Name");
var getFoodAdequateName = prop("FoodAdequate.Name");
var getHousingAdequateName = prop("HousingAdequate.Name");
var getHowDifficultUnderstandHealthInfoName = prop("HowDifficultUnderstandHealthInfo.Name");
var getIncomeAdequacyName = prop("IncomeAdequacy.Name");
var getPhysicalActivity5TimesPerWeekName = prop("PhysicalActivity5TimesPerWeek.Name");
var getSocialInteractionInLast7DaysName = prop("SocialInteractionInLast7Days.Name");
var getTravelDifficultyName = prop("TravelDifficulty.Name");
var getTravelMethodName = prop("TravelMethod.Name");
var getUsedOtherSubstancesName = prop("UsedOtherSubstances.Name");
var getSubstanceName = prop("Substance.Name");
var getSubstanceFrequently = prop("HowFrequently.Name");
var getSubstanceRecently = prop("HowRecently.Name");
var getSmokesTobaccoName = prop("SmokesTobacco.Name");
var getDrinksAlcoholName = prop("DrinksAlcohol.Name");
var getGambleResponsiblyName = prop("GambleResponsibly.Name");

export default function Situations(props) {
	const {
		localize,
	} = useResources();

	var careplan = props.careplan;
	var currentSection = props.currentSection;
	var permissions = props.permissions;
	var changes = props.changes;
	var showCareplanDetail = props.showCareplanDetail;
	var ownCareplan = props.ownCareplan;

	var supplementarybenefitsirecieve = localize("situation.supplementarybenefitsirecieve");
	var howiwork = localize("situation.howiwork");
	var howadequateismyincomeisformyhealth = localize("situation.howadequateismyincomeisformyhealth");
	var ifollowmyrecommendeddiet = localize("situation.ifollowmyrecommendeddiet");
	var howadequatemyfoodisformyhealth = localize("situation.howadequatemyfoodisformyhealth");
	var howitravel = localize("situation.howitravel");
	var howdifficultitistotravel = localize("situation.howdifficultitistotravel");
	var howdifficultitistoreadandunderstandinformationaboutmyhealth = localize("situation.howdifficultitistoreadandunderstandinformationaboutmyhealth");
	var howadequatemyhousingisformyhealth = localize("situation.howadequatemyhousingisformyhealth");
	var ihaveusedothersubstances = localize("situation.ihaveusedothersubstances");
	var igetminutesofphysicalactivity = localize("situation.igetminutesofphysicalactivity");
	var ihavehadsocialinteractioninthelastsevendays = localize("situation.ihavehadsocialinteractioninthelastsevendays");
	var otherconsiderations = localize("situation.otherconsiderations");

	var hasChanged = permissions >= 2;
	var SocialInteractionInLast7Days = getSocialInteractionInLast7DaysOther(careplan) || getSocialInteractionInLast7DaysName(careplan);

	var supplementaryBenefits = getSupplementaryBenefit(careplan) || [];
	var renderSupplementaryBenefits = supplementaryBenefits.map((supplementarybenefit, index) =>
		<SupplementaryBenefits key={index} supplementarybenefit={supplementarybenefit} />
	);

	var isChanged = changes.SmokesTobacco || changes.NumberOfCigarettesPerDay || changes.NumberOfPackYears ||  changes.QuitSmokingDate;
	var tobaccoPermission = hasChanged && isChanged;
	var renderTobacco = (
		<Tobacco careplan={careplan}
			currentSection={currentSection}
			isChanged={tobaccoPermission}
			ownCareplan={ownCareplan}
			showCareplanDetail={showCareplanDetail}
			data={"SmokesTobacco,NumberOfCigarettesPerDay,NumberOfPackYears,QuitSmokingDate"} />);

	var alcoholChanged = changes.DrinksAlcohol || changes.NumberOfDrinksOneSitting || changes.NumberOfDrinksPerWeek;
	var alcoholPermission = hasChanged && alcoholChanged;
	var renderAlcohol = (
		<Alcohol careplan={careplan}
			currentSection={currentSection}
			isChanged={alcoholPermission}
			ownCareplan={ownCareplan}
			showCareplanDetail={showCareplanDetail}
			data={"DrinksAlcohol,NumberOfDrinksOneSitting,NumberOfDrinksPerWeek"} />);

	var gambleChanged = changes.GambleResponsibly || changes.MostRecentDateGambled || changes.NumberOfDaysGambledIn90Days;
	var gamblePermission = hasChanged && gambleChanged;
	var renderGambleResponsibly = (
		<Gamble careplan={careplan}
			currentSection={currentSection}
			isChanged={gamblePermission}
			ownCareplan={ownCareplan}
			showCareplanDetail={showCareplanDetail}
			data={"GambleResponsibly,MostRecentDateGambled,NumberOfDaysGambledIn90Days"} />);

	var subStances = getUsedSubstance(careplan) || [];
	var renderSubstances = subStances.map((substance, index) =>
		<Substances key={index} substance={substance} />
	);

	var SocialInteractionInLast7DaysData = getSocialInteractionInLast7DaysOther(careplan) ? "SocialInteractionInLast7DaysOther" : "SocialInteractionInLast7Days";

	return (
		<View>
			<GroupItems>
				<Items hasChanged={hasChanged && changes.SupplementaryBenefit} data={"SupplementaryBenefit"}>
					<Item>
						<Label>{supplementarybenefitsirecieve}</Label>
					</Item>
					<Summarized currentSection={currentSection} section={"supplementarybenefit"} ownCareplan={ownCareplan} showCareplanDetail={showCareplanDetail}>
						{renderSupplementaryBenefits}
					</Summarized>
				</Items>
			</GroupItems>

			<Items hasChanged={hasChanged && changes.Employment} data={"Employment"}>
				<Item alternate>
					<Label>{howiwork}</Label>
					<Value>{getEmploymentName(careplan)}</Value>
				</Item>
			</Items>

			<Items hasChanged={hasChanged && changes.IncomeAdequacy} data={"IncomeAdequacy"}>
				<Item>
					<Label>{howadequateismyincomeisformyhealth}</Label>
					<Value>{getIncomeAdequacyName(careplan)}</Value>
				</Item>
			</Items>

			<Items hasChanged={hasChanged && changes.FollowRecommendedDiet} data={"FollowRecommendedDiet"}>
				<Item alternate>
					<Label>{ifollowmyrecommendeddiet}</Label>
					<Value>{getFollowRecommendedDietName(careplan)}</Value>
				</Item>
			</Items>

			<Items hasChanged={hasChanged && changes.FoodAdequate} data={"FoodAdequate"}>
				<Item>
					<Label>{howadequatemyfoodisformyhealth}</Label>
					<Value>{getFoodAdequateName(careplan)}</Value>
				</Item>
			</Items>

			<Items hasChanged={hasChanged && changes.TravelMethod} data={"TravelMethod"}>
				<Item alternate>
					<Label>{howitravel}</Label>
					<Value>{getTravelMethodName(careplan)}</Value>
				</Item>
			</Items>

			<Items hasChanged={hasChanged && changes.TravelDifficulty} data={"TravelDifficulty"}>
				<Item>
					<Label>{howdifficultitistotravel}</Label>
					<Value>{getTravelDifficultyName(careplan)}</Value>
				</Item>
			</Items>

			<Items hasChanged={hasChanged && changes.HowDifficultUnderstandHealthInfo} data={"HowDifficultUnderstandHealthInfo"}>
				<Item alternate>
					<Label>{howdifficultitistoreadandunderstandinformationaboutmyhealth}</Label>
					<Value>{getHowDifficultUnderstandHealthInfoName(careplan)}</Value>
				</Item>
			</Items>

			<Items hasChanged={hasChanged && changes.HousingAdequate} data={"HousingAdequate"}>
				<Item>
					<Label>{howadequatemyhousingisformyhealth}</Label>
					<Value>{getHousingAdequateName(careplan)}</Value>
				</Item>
			</Items>

			{renderTobacco}

			{renderAlcohol}

			<GroupItems>
				<Items hasChanged={hasChanged && (changes.UsedOtherSubstances || changes.UsedSubstance)} data={"UsedSubstance,UsedOtherSubstances"}>
					<Item alternate>
						<Label>{ihaveusedothersubstances}</Label>
						<Value>{getUsedOtherSubstancesName(careplan)}</Value>
					</Item>
					{
						getUsedOtherSubstancesName(careplan) === "Yes" ?
							(<Summarized currentSection={currentSection} section={"usedsubstance"} ownCareplan={ownCareplan} showCareplanDetail={showCareplanDetail}>
								{renderSubstances}
							</Summarized>) : null
					}
				</Items>
			</GroupItems>

			{renderGambleResponsibly}

			<Items hasChanged={hasChanged && changes.PhysicalActivity5TimesPerWeek} data={"PhysicalActivity5TimesPerWeek"}>
				<Item alternate>
					<Label>{igetminutesofphysicalactivity}</Label>
					<Value>{getPhysicalActivity5TimesPerWeekName(careplan)}</Value>
				</Item>
			</Items>

			<Items hasChanged={hasChanged && (changes.SocialInteractionInLast7Days || changes.SocialInteractionInLast7DaysOther)} data={SocialInteractionInLast7DaysData}>
				<Item>
					<Label>{ihavehadsocialinteractioninthelastsevendays}</Label>
					<Value>{SocialInteractionInLast7Days}</Value>
				</Item>
			</Items>

			<Items hasChanged={hasChanged && changes.OtherConsiderations} data={"OtherConsiderations"}>
				<Item alternate>
					<Label>{otherconsiderations}</Label>
					<Value>{getOtherConsiderations(careplan)}</Value>
				</Item>
			</Items>
		</View>
	);
}

class SupplementaryBenefits extends PureComponent {
	render(){
		var props = this.props;
		var supplementarybenefit = props.supplementarybenefit;
		return (
			<Item>
				<Label />
				<Value>{"• "}{supplementarybenefit.Name}</Value>
			</Item>
		);
	}
}

function Tobacco(props) {
	const {
		localize,
	} = useResources();

	var careplan = props.careplan;
	var smoke = getSmokesTobaccoName(careplan);
	var perDay = careplan.NumberOfCigarettesPerDay;
	var perYear = careplan.NumberOfPackYears;
	var quit = careplan.QuitSmokingDate;
	var currentSection = props.currentSection;
	var showCareplanDetail = props.showCareplanDetail;
	var ownCareplan = props.ownCareplan;

	var ismoketobacco = localize("situation.ismoketobacco");
	var cigarettesday = localize("situation.cigarettesday");
	var packsyear = localize("situation.packsyear");
	var quitsmoking = localize("situation.quitsmoking");
	if(!smoke)
		return null;


	return (
		<GroupItems>
			<Items hasChanged={props.isChanged} data={props.data}>
				<Item alternate>
					<Label>{ismoketobacco}</Label>
					<Value>{smoke}</Value>
				</Item>
				{
					smoke === "Yes" ? (
						<Summarized currentSection={currentSection} section={"smokestobacco"} ownCareplan={ownCareplan} showCareplanDetail={showCareplanDetail}>
							<Item>
								<Label>{cigarettesday}</Label>
								<Value>{perDay}</Value>
							</Item>
							<Item alternate>
								<Label>{packsyear}</Label>
								<Value>{perYear}</Value>
							</Item>
							{
								quit ? (
									<Item>
										<Label>{quitsmoking}</Label>
										<Value>{quit}</Value>
									</Item>
								) : null
							}
						</Summarized>
					) : null
				}
			</Items>
		</GroupItems>
	);
}

function Alcohol(props) {
	const {
		localize,
	} = useResources();

	var careplan = props.careplan;
	var drink = getDrinksAlcoholName(careplan);
	var perSitting = careplan.NumberOfDrinksOneSitting;
	var perWeek = careplan.NumberOfDrinksPerWeek;
	var currentSection = props.currentSection;
	var showCareplanDetail = props.showCareplanDetail;
	var ownCareplan = props.ownCareplan;
	var idrinkalcohol = localize("situation.idrinkalcohol");
	var drinksinonesitting = localize("situation.drinksinonesitting");
	var drinksweek = localize("situation.drinksweek");
	if(!drink)
		return null;


	return (
		<GroupItems>
			<Items hasChanged={props.isChanged} data={props.data}>
				<Item>
					<Label>{idrinkalcohol}</Label>
					<Value>{drink}</Value>
				</Item>
				{
					drink === "Yes" ? (
						<Summarized currentSection={currentSection} section={"drinksalcohol"} ownCareplan={ownCareplan} showCareplanDetail={showCareplanDetail}>
							<Item alternate>
								<Label>{drinksinonesitting}</Label>
								<Value>{perSitting}</Value>
							</Item>
							<Item>
								<Label>{drinksweek}</Label>
								<Value>{perWeek}</Value>
							</Item>
						</Summarized>
					) : null
				}
			</Items>
		</GroupItems>
	);
}

function Substances(props){
	const { localize } = useResources();
	var substance = props.substance;

	var howrecently = localize("situation.howrecently");
	var howfrequently = localize("situation.howfrequently");

	if(!substance)
		return null;


	return (
		<Items>
			<Item>
				<Label>{getSubstanceName(substance)}</Label>
			</Item>
			<Item alternate>
				<Label>{howrecently}</Label>
				<Value>{getSubstanceRecently(substance)}</Value>
			</Item>
			<Item>
				<Label>{howfrequently}</Label>
				<Value>{getSubstanceFrequently(substance)}</Value>
			</Item>
		</Items>
	);
}

function Gamble(props) {
	const {
		localize,
	} = useResources();

	var careplan = props.careplan;
	var gamble = getGambleResponsiblyName(careplan);
	var recent = careplan.MostRecentDateGambled;
	var number = careplan.NumberOfDaysGambledIn90Days;
	var currentSection = props.currentSection;
	var showCareplanDetail = props.showCareplanDetail;
	var ownCareplan = props.ownCareplan;
	var igambleresponsibly = localize("situation.igambleresponsibly");
	var mostrecentdateigambled = localize("situation.mostrecentdateigambled");
	var numberofdaysinlast90days = localize("situation.numberofdaysinlast90days");

	if(!gamble)
		return null;


	return (
		<GroupItems>
			<Items hasChanged={props.isChanged} data={props.data}>
				<Item>
					<Label>{igambleresponsibly}</Label>
					<Value>{gamble}</Value>
				</Item>
				<Summarized currentSection={currentSection} section={"gambleresponsibly"} ownCareplan={ownCareplan} showCareplanDetail={showCareplanDetail}>
					<Item alternate>
						<Label>{mostrecentdateigambled}</Label>
						<Value>{recent}</Value>
					</Item>
					<Item>
						<Label>{numberofdaysinlast90days}</Label>
						<Value>{number}</Value>
					</Item>
				</Summarized>
			</Items>
		</GroupItems>
	);
}

Situations.propTypes = {
	model: PropTypes.object,
};

SupplementaryBenefits.propTypes = {
	supplementarybenefit: PropTypes.object,
};

Substances.propTypes = {
	substance: PropTypes.object,
};
