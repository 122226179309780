import React from "react";
import {
	StyleSheet,
	View,
} from "react-native";

import AtaContainer from "../AtaContainer";
import Person from "./Person";

var styles = StyleSheet.create({
	mainContainer: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center"
	},
	contentContainer: {
		flexDirection: "row",
	},
	controls: {
		justifyContent: "flex-end"
	},
});

export default function SimpleState(props){
	var controls = props.children;

	return (
		<AtaContainer>
			<View style={styles.mainContainer}>
				<View style={styles.contentContainer}>
					<Person who={props.who} message={props.message} />
					<View style={styles.controls}>
						{controls}
					</View>
				</View>
			</View>
		</AtaContainer>
	);
}
