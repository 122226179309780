import React from "react";
import {
	TouchableOpacity,
	StyleSheet,
	View,
} from "react-native";
import noop from "noop3";

import Style from "@aetonix/styles";

import StyledText from "ae-base/Base/StyledText";
import StyledIcon from "ae-base/Base/StyledIcon";

var styles = StyleSheet.create({
	button: {
		alignSelf: "baseline",
		borderRadius: 24,
		marginHorizontal: Style.layout.marginSmall * 2,
		width: 248,
		height: 248,
		overflow: "hidden",
	},
	smallPadding: {
		paddingVertical: Style.layout.marginSmall,
		paddingHorizontal: Style.layout.marginContentSmall,
	},
	content: {
		flex: 1,
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center"
	},
	icon: {
		flex: 35,
		justifyContent: "flex-end",
		alignItems: "center",
	},
	centeredIcon: {
		flex: 35,
		justifyContent: "center",
		alignItems: "center",
		padding: 0,
		margin: 0,
	},
	text: {
		textAlign: "center",
		flex: 26,
	},
	disabled: {
		opacity: Style.look.disabled,
	},

	containerWhite: {
		backgroundColor: Style.look.background,
	},
	containerHighlight: {
		backgroundColor: Style.colors.highlight,
	},
	containerSecondary: {
		backgroundColor: Style.colors.secondary,
	},
	small: {
		borderRadius: 16,
		width: 128,
		height: 128
	},
	tiny: {
		borderRadius: 16,
		width: 96,
		height: 96
	},
	microscopic: {
		borderRadius: 12,
		width: 48,
		height: 48
	}
});

export default function SquareButton(props){
	var onPress = props.onPress;
	var title = props.title || "";

	var disabled = props.disabled;
	var contrast = props.contrast;
	var clear = props.clear;
	var highlight = props.highlight;
	var secondary = props.secondary;
	var small = props.small;
	var tiny = props.tiny;
	var microscopic = props.microscopic;

	var iconName = props.icon || "dot-circle-o";

	var styleList = [styles.button];

	if (!small && !tiny) {
		styleList.push(styles.smallPadding);
	}

	if(disabled){
		onPress = noop;
		styleList.push(styles.disabled);
	}

	if(small){
		styleList.push(styles.small);
	}

	if(tiny){
		styleList.push(styles.tiny);
	}

	if (microscopic){
		styleList.push(styles.microscopic);
	}

	var textColor = {
		black: true
	};
	var iconColor = {};
	var containerColor = null;

	styleList.push(containerColor);

	if(contrast){
		textColor = {
			contrast: true
		};
		iconColor = {
			contrast: true
		};
	}

	if(!clear){
		containerColor = styles.containerWhite;
	}

	if(highlight){
		containerColor = styles.containerHighlight;
	}

	if(secondary){
		containerColor = styles.containerSecondary;
	}

	styleList.push(containerColor);

	var iconSize = (tiny || microscopic) ? {emphasized: true} : {hugeHeading: true};

	return (
		<View>
			<TouchableOpacity accessibilityComponentType="button" style={styleList} onPress={onPress}>
				<View style={styles.content}>
					<View style={!microscopic ? styles.icon : styles.centeredIcon}>
						<StyledIcon name={iconName} {...iconSize} {...iconColor} />
					</View>
					{!microscopic ?
						(<StyledText style={styles.text} size={tiny ? "tiny" : small ? "verySmall" : null} compact bold {...textColor}>
							{title}
						</StyledText>) : null
					}
				</View>
			</TouchableOpacity>
		</View>
	);
}
