/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";

import Emoji from "./Emoji";
import Reminder from "./Reminder";

import StyledButton from "ae-base/Base/StyledButton";
import StyledText from "ae-base/Base/StyledText";

import { useResources } from "@aetonix/hooks";

export default function StandardReminder(props) {
	const {
		localize,
		dispatch,
	} = useResources();
	var edit = localize("reminder.edit");

	var reminder = props.reminder;
	var content = reminder.content;
	var readable = reminder.readable;
	var latestHistory = reminder.latestHistory;

	var contentElement = (
		<StyledText>{content}</StyledText>
	);

	if(latestHistory && latestHistory.shown){
		var responseValue = localize("reminder.response");

		var responseData = Emoji.check(latestHistory.response);

		if (responseData === "Accepted")
			responseData = localize("reminder.Accepted");
		else if (responseData === "Denied")
			responseData = localize("reminder.Denied");
		else if (responseData === "Cleared")
			responseData = localize("reminder.Cleared");
		else if (responseData === "Yes")
			responseData = localize("common.yes");
		else if (responseData === "No")
			responseData = localize("common.no");
		else if(responseData === "N/A")
			responseData = localize("reminder.Pending");

		var formatedDate = latestHistory.readable;

		contentElement = [
			contentElement,
			(<StyledText>{responseValue} {responseData}</StyledText>),
			(<StyledText>{formatedDate}</StyledText>),
		];
	}

	var editReminder = dispatch("managedremindermaker:trigger:edit:start", {
		reminder: reminder._id,
	});

	var editButton = (
		<StyledButton title={edit} icon="compose" onPress={editReminder} />
	);

	return (
		<Reminder title={readable} content={contentElement} action={editButton} />
	);
}
