import PropTypes from "prop-types";
import React from "react";
import {
	StyleSheet,
	View,
} from "react-native";
import SimpleState from "../SimpleState";
import SquareButton from "../../SquareButton";

import { useResources } from "@aetonix/hooks";

var styles = StyleSheet.create({
	callActionContainer: {
		display: "flex",
		flexDirection: "column",
		flexWrap: "nowrap",
		justifyContent: "center",
		alignItems: "center",
		alignContent: "stretch",
	},
});

export default function Connecting(props) {
	const {
		localize,
		dispatch,
	} = useResources();

	var who = props.who;
	var callgroup = props.callgroup;

	var message = localize("atouchawaycalling.connectingto", who);

	var cancelCall = dispatch("calling:trigger:hangup", {
		reason: "hangup",
	});

	var cancelTitle = localize("atouchawaycalling.cancel");

	if(callgroup){
		cancelCall = dispatch("callgroupscalling:trigger:cancel");
		message = localize("callgroups.connectingto", callgroup);
		who = callgroup;
	}

	return (
		<SimpleState who={who} message={message}>
			<View style={styles.callActionContainer}>
				<SquareButton title={cancelTitle} icon="aeto-hang_up-2" onPress={cancelCall} small contrast highlight />
			</View>
		</SimpleState>
	);
}
