import React, { useMemo, useState, useCallback , forwardRef} from 'react';
import NavigationItem from './NavigationItem';
import { variant } from 'styled-system';
import { createStyled } from '@emotion/primitives-core'
import { StyleSheet, View } from 'react-native'
import { DesktopNavigationWrapper } from '../Containers';

const styled = createStyled(StyleSheet);

const Container = styled(View)({
    backgroundColor: "white",
    flexDirection: 'row',
    alignSelf: 'stretch',
    justifyContent: "space-around",
    minHeight: 70,
    height: 'auto',
}, variant({
    prop: 'mode',
    variants: {
        desktop: {
            borderWidth: 2,
            borderColor: '#dbdbdb',
            borderRadius: 20,
            shadowColor: '#171717',
            shadowOffset: { width: -1, height: 2 },
            shadowOpacity: 0.2,
            shadowRadius: 3,
            overflow: 'hidden',
            alignSelf: "center",
            opacity: 0.1,
            transition: 'opacity 0.3s',
        }, 
    } 
}), props => variant({
    prop: 'orientation',
    variants: {
        vertical: {
            flexDirection: "column",
            width: 100,
            height: '100%',
            ...(props.mode === 'simplified' && { width: 130 }),
        },
        horizontal: {
            flexDirection: "row",
        },
    }
}));

const Navigation = forwardRef((props, ref) => {
    const { items, onClick, selected, mode, menu = null, orientation } = props;

    const [isHovered, setIsHovered] = useState(false);

    const onHoverEnter = useCallback(() => setIsHovered(true), [setIsHovered]);
    const onHoverLeave = useCallback(() => setIsHovered(false), [setIsHovered]);

    const hoverStyle = useMemo(() => ({ ...((isHovered || !!menu) && { opacity: 1 } )} ), [isHovered, menu]);

    const navItems = useMemo(() => items.map((item, index) => {
        const variant = index === selected ? "selected" : mode;
        const label = mode === "simplified" && item.label;
        const icon = item.isActive ? item?.icons?.['on'] : item?.icons?.['off'] ?? item.icon;

        return (
            <NavigationItem
                testID={`navigation-item-${index}`}
                key={`navigation-item-${index}`}
                variant={variant}
                label={label}
                icon={icon}
                mode={mode}
                onClick={() => onClick(index, item)}
            />
        );
    }), [items, selected, mode, onClick]);

    return (
        <Container
            testID="navigation"
            orientation={orientation}
            mode={mode}
            onClick={onClick}
            style={hoverStyle}
            ref={ref}
            onMouseEnter={onHoverEnter}
            onMouseLeave={onHoverLeave}
        >
            { navItems }
        </Container>
    );
});

function withWebWrapper(navigation) {
    return <DesktopNavigationWrapper>{navigation}</DesktopNavigationWrapper>
}

const DesktopNavigation = withWebWrapper(Navigation);

export { DesktopNavigation };
export default Navigation;
