import React from "react";
import memoize from "memoizee";

import MyHealthSection from "../MyHealthSection";
import DeviceAdd from "../../../Devices/DeviceAdd";

import { useResources } from "@aetonix/hooks";

var ENUM_VALUES = {
	"weight": [
		"a&dweight",
		"ihweight",
	],
	"activity": [
		"metawear",
	],
	"bloodsugar": [
		"foratng",
	],
	"bodytemperature": [
		"forair20",
	],
	"bloodpressure": [
		"a&dbloodpressure",
		"ihbloodpressure",
	],
	"spo2": [
		"nonin",
		"foraspo2",
		"ihspo2",
	],
};

var TYPES = [
	"weight",
	"activity",
	"bloodsugar",
	"bodytemperature",
	"bloodpressure",
	"spo2",
];

var getEnum = memoize(getDeviceEnum);

export default function DevicesAdd(props) {
	const {
		localize,
		dispatch,
	} = useResources();

	var model = props.model;
	var toast = model.toast;

	var owndevices = model.owndevices || {};
	var deviceList = owndevices.list || [];

	var devicesTitle = localize("devices.addtitle");
	var onSubmit = dispatch("owndevices:trigger:add:submit");

	var backTitle = localize("devices.backTitle");
	return (
		<MyHealthSection online={model.online} title={devicesTitle} toast={toast} back={backTitle} >
			<DeviceAdd enumValue={getEnum(deviceList)} onSubmit={onSubmit} />
		</MyHealthSection>
	);
}

function getDeviceEnum(devices){
	var defaultEnum = Object.assign({}, ENUM_VALUES);

	devices.forEach((device) => {
		var device_type = device.device_type || "";
		if(TYPES.indexOf(device_type) !== -1)
			delete defaultEnum[device_type];
	});

	return flattenMap(defaultEnum);
}

function flattenMap(obj){
	return Object.keys(obj).reduce((acc, e) => {
		return acc.concat(obj[e]);
	}, []);
}
