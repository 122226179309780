import React from "react";
import {
	View,
	StyleSheet,
} from "react-native";
import Style from "ae-style";

var styles = StyleSheet.create({
	callcontrols: {
		position: "absolute",
		bottom: 0,
		right: 0,
		justifyContent: "flex-end",
		alignItems: "flex-end",
		flexDirection: "row",
		padding: Style.layout.marginContentSmall
	},
});

export default function CallControls(props){
	return (
		<View style={styles.callcontrols}>
			{props.children}
		</View>
	);
}
