export default {
	title: "Passwordreset",
	description: "schemas_passwordreset_title",
	type: "object",
	properties: {
		email: {
			placeholder: "passwordreset.description",
			type: "string",
			inputType: "text",
			format: "email"
		}
	}
};
