import React from "react";
import {
	View,
} from "react-native";
import Breakpoints from "../../Breakpoints";
import ResponsiveStylesheet from "react-native-responsive-stylesheet";

import StyledButton from "ae-base/Base/StyledButton";
import BlockView from "ae-base/Base/BlockView";

import Style from "ae-style";

import { useResources } from "@aetonix/hooks";

var styles = ResponsiveStylesheet.createSized("min-direction", {
	0: {
		hideContainer: {
			marginVertical: Style.layout.marginSmall,
			alignItems: "flex-end",
		},
	},
	512: {
		hideContainer: {
			marginVertical: Style.layout.marginLarge,
		},
	},
});

export default function ContactHide(props) {
	const {
		localize,
	} = useResources();

	var contrast = props.contrast;
	var hideTextKey = props.hiding ? "homepage.show_contacts_" : "homepage.hide_contacts_";
	hideTextKey += Breakpoints.smallerThan("mobile") ? "small" : "large";

	var hideText = localize(hideTextKey);

	return (
		<View style={styles.hideContainer}>
			<BlockView>
				<StyledButton id={props.id} title={hideText} contrast={contrast} onPress={props.onPress} />
			</BlockView>
		</View>
	);
}
