export default {
	title: "ChangeEmail",
	description: "changeemail.title",
	type: "object",
	properties: {
		email: {
			order: 0,
			description: "profile.email.current",
			type: "string",
			format: "email",
			inputType: "label",
			label: true
		},
		new_email: {
			order: 1,
			description: "profile.email.new_email",
			type: "string",
			inputType: "text",
			format: "email"
		},
		confirm_email: {
			order: 2,
			description: "profile.email.confirm_email",
			type: "string",
			inputType: "text",
			format: "email"
		}
	}
};
