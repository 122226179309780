import React from "react";
import {
	View,
	StyleSheet,
} from "react-native";

import StyledText from "ae-base/Base/StyledText";
import StyledIconButton from "ae-base/Base/StyledIconButton";
import Style from "ae-style";
import Breakpoints from "../../../Breakpoints";
import BackButton from "ae-base/Base/BackButton";

import { useResources } from "@aetonix/hooks";

var styles = StyleSheet.create({
	mainContent: {
		backgroundColor: Style.colors.primary,
		flexDirection: "row",
		alignItems: "center",
		padding: Style.layout.paddingSmall,
	},
	backButton: {
		padding: Style.layout.paddingSmall,
	},
	container: {
		backgroundColor: Style.colors.primary,
		paddingTop: Style.layout.paddingSlim,
		flex: 2,
	},
	leftAligned: {
		backgroundColor: Style.colors.primary,
		paddingTop: Style.layout.paddingSlim,
		flex: 2,
	},
	mainContainer: {
		backgroundColor: Style.colors.primary,
		paddingTop: Style.layout.paddingSlim,
		paddingHorizontal: Style.layout.paddingMedium,
		flex: 2,
	},
	editContainer: {
		flexDirection: "row",
		padding: Style.layout.paddingSmall,
		paddingLeft: Style.layout.paddingMedium,
	},
	iconStyle: {
		justifyContent: "flex-end",
		flexDirection: "row",
		flex: 1,
	},
});

export default function HealthHeader(props) {
	const {
		localize,
		dispatch,
	} = useResources();

	var defaultTitle = localize("myhealth.title");
	var backText = props.back || localize("common.back");
	var title = props.title || defaultTitle;
	var edit = props.edit;

	var isMobile = Breakpoints.smallerThan("mobile");

	var contents = null;
	var backPress = null;
	var mobileBack = dispatch("desktop:trigger:page:back");

	const model = props.model;
	if (model && model.workflowaction && model.workflowaction.collapsed) {
		backText = localize("workflow.continue");
		backPress = () => {
			dispatch("activeworkflowaction:trigger:view", {
				action: model.workflowaction._id,
			})();
			dispatch("desktop:trigger:page:home", {
				action: model.workflowaction._id,
			})();
		};
		mobileBack = backPress;
	}

	if (isMobile) return (
		<View style={styles.mainContent}>
			<StyledIconButton clear contrast smallHeading icon="left" style={styles.backButton} onPress={mobileBack} />
			<View style={styles.leftAligned}>
				<StyledText smallHeading bold contrast>{title}</StyledText>
			</View>
			<View style={styles.iconStyle}>
				{edit}
			</View>
			{contents}
		</View>
	);
	else return (
		<View style={styles.mainContainer}>
			<BackButton title={backText} onPress={backPress}>
				<View style={styles.editContainer}>
					{edit}
				</View>
				{contents}
			</BackButton>
			<View style={styles.mainContent}>
				<View style={styles.container}>
					<StyledText centered smallHeading bold contrast>{title}</StyledText>
				</View>
			</View>
		</View>
	);
}
