import React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { createStyled } from '@emotion/primitives-core'
import { variant } from 'styled-system';
import Icon from '../Icon';
import ResponsiveText from '../Text/ResponsiveText';
import Style from '@aetonix/styles';

const styled = createStyled(StyleSheet)

const ItemText = styled(ResponsiveText)({
    fontWeight: '500',
    color: "#141823",
    textAlign: 'center',
    marginTop: 5, // @reest Move to child selector when emotion is fixed
});

const Container = styled(TouchableOpacity)({
    backgroundColor: 'white',
    flex: 1,
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 5,
    paddingLeft: 12,
    paddingRight: 12,
    position: 'relative',
}, variant({
    variants: {
        selected: {
            backgroundColor: '#e6e6e6'
        },
        simplified: {
            borderBottomWidth: 3,
            borderBottomColor: '#e8e8e8',
        }
    }
}));

export default ({ label, onClick, variant, icon, mode, ...rest }) => (
    <Container
        testID="nav-item"
        title={label}
        variant={variant}
        onPress={onClick}
        { ...rest }
    >
        <Icon testID="nav-item-icon" title={label} icon={icon}/>
        { !!label && <ItemText size={Style.text.small} max={Style.text.emphasized} testID="nav-item-text" numberOfLines={2} adjustsFontSizeToFit>{label}</ItemText> }
    </Container>
);