import React from "react";
import {
	View,
} from "react-native";

import ViewButton from "./ViewButton";

export default function Items(props){
	var hasChanged = props.hasChanged;
	var data = props.data;

	var viewButton = hasChanged ? (
		<ViewButton keys={data} />
	) : null;

	return (
		<View>
			{viewButton}
			{props.children}
		</View>
	);
}
