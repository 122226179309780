import React from "react";

import Item from "../Item";
import Group from "../Group";
import Section from "../Section";
import Title from "../Title";
import prop from "prop";

import { useResources } from "@aetonix/hooks";

var getProviderName = prop("ProviderName");
var getAppointmentDate = prop("AppointmentDate");
var getAppointmentTime = prop("AppointmentTime");
var getPurpose = prop("Purpose");
var getNotes = prop("Notes");

export default function Appointments(props) {
	const {
		localize,
	} = useResources();
	var appointmentsTitle = localize("appointments.appointmentsreferrals");
	var appointment_s = localize("appointments.appointment_s");
	var providername = localize("appointments.providername");
	var datetime = localize("appointments.datetime");
	var purpose = localize("appointments.purpose");
	var notes = localize("appointments.notes");
	var careplan = props.careplan || {};

	var appointments = careplan.Appointment || [];

	var rendredAppointments = appointments.map(function(appointment, index){
		var appointmentDate = getAppointmentDate(appointment) || "";
		var appointmentTime = getAppointmentTime(appointment) || "";
		var appointmentDateTime = appointmentDate + " " + appointmentTime;
		return (
			<Group key={index}>
				<Item title={providername} value={getProviderName(appointment)} />
				<Item title={datetime} value={appointmentDateTime} />
				<Item title={purpose} value={getPurpose(appointment)} />
				<Item title={notes} value={getNotes(appointment)} />
			</Group>
		);
	});

	return (
		<Section title={appointmentsTitle}>
			<Title title={appointment_s} />
			{rendredAppointments}
		</Section>
	);
}
