import React, {
	Component,
} from "react";

import MessageBox from "ae-forms/src/Inputs/MessageBox";
import { useResources } from "@aetonix/hooks";

export default (props) => {
	const { dispatch, localize, config } = useResources();
	return <MessagingInput dispatch={dispatch} localize={localize} config={config} {...props} />;
};

class MessagingInput extends Component{

	constructor(props){
		super(props);
		this.state = {
			new_content: props.messages.new_content || props.messages.texting,
			keyID: Math.random(),
		};

		this.handleChange = handleChange.bind(this);
		this.sendMessage = sendMessage.bind(this);
	}

	componentDidUpdate(prevProps){
		if(prevProps.messages.texting !== this.props.messages.texting)
			this.setState({
				new_content: this.props.messages.texting || "",
			});

	}

	render() {
		var props = this.props;
		var localize = props.localize;
		var name = props.name;
		var startUpload = props.startUpload;
		var placeholder = name ? localize("messaginginput.placeholder") + name : localize("messaginginput.sendmessage");

		return(
			<MessageBox
				id={"messageBox"}
				value={this.state.new_content}
				key={this.state.keyID}
				startUpload={startUpload}
				onChange={this.handleChange}
				onSubmit={this.sendMessage}
				placeholder={placeholder}
				defaultValue={this.state.defaultData}
				aTouchAway={props.aTouchAway}
				noSendButton={props.noSendButton}
				noFileUploadButton={props.noFileUploadButton}
				invertColors={props.invertColors}
				noMargin={props.noMargin}
			/>
		);
	}
}

function sendMessage() {
	var content = this.state.new_content;
	if(!content) return;

	var sendMessageDispatch = this.props.sendMessage;
	var dispatch = this.props.dispatch;
	var who = this.props.config.id;
	var from = this.props.messages.from?._id || this.props.messages.group?._id;
	if(this.props.type === "bulk")
		from = who;


	dispatch("persiststorage:delete")({ dataId: who, targetId: from });

	sendMessageDispatch(content);

	this.setState({
		new_content: "",
		keyID: Math.random(),
		defaultData : "",
	});
}

function handleChange(event){
	var dispatch = this.props.dispatch;
	var who = this.props.config.id;
	var from = this.props.messages.from?._id || this.props.messages.group?._id;

	if(this.props.type === "bulk")
		from = who;

	var value = event;
	var onChange = this.props.onChange;
	if(onChange) onChange(value);
	dispatch("persiststorage:save")({ dataId: who, targetId: from, data: {
		message: value,
	}});

	this.setState({
		new_content: value,
	});
}
