import React from "react";
import HealthIndicator from "./HealthIndicator";

import { useResources } from "@aetonix/hooks";

export default function ActivityMetrics(props) {
	const {
		localize,
	} = useResources();

	var model = props.model;
	var myhealth = model.myhealth;
	var activity = myhealth.activity;
	var steps = activity.cumulative;
	var reverse_steps = activity.reverse; // needed for chart
	var today = null;
	var section = model.page;
	var pagetitle = localize("activity.status");
	var  units = localize("activity.rightLabel");
	var textFormat = "{cumulative}";

	// Sets up the format to the 'today' button
	if (steps.length) {
		var graphdata = reverse_steps.slice(-24); // for chart
		today = steps[steps.length - 1].cumulative;
	}

	return (
		<HealthIndicator model={model} section={section} textformat={textFormat} pagetitle={pagetitle} latestReading={today} units={units} reverseEntries={graphdata} activeModel={activity} xaxis={"updated_at"} yaxis={"cumulative"} bar managed />
	);
}
