import React from "react";
import {
	View
} from "react-native";

var TO_DEGREE = (180 / Math.PI);

export default function GradientWeb(props){
	var colors = props.colors || [];
	var locations = props.locations || [];
	var start = props.start;
	var end = props.end;

	var angle = 0;

	if(start && end){
		var dx = end.x - start.x;
		var dy = end.y - start.y;
		angle = Math.atan2(dx, dy) * TO_DEGREE;
	}

	var colorList = colors.map(function(color, index){
		var location = locations[index];
		if(!location) return color;
		return color + " " + (location * 100) + "%";
	}).join(",");

	var gradient = "linear-gradient(" + angle + "deg," + colorList + ")";

	var backgroundStyle = {
		backgroundImage: gradient
	};

	var styles = [backgroundStyle];
	var style = props.style;
	if(style)
		styles.unshift(style);

	return (
		<View style={styles}>
			{props.children}
		</View>
	);
}
