
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";
import {
	View,
	Dimensions
} from "react-native";

import PageContainer from "./PageContainer";
import MainScroll from "./MainScroll";
import Card from "./Card";
import Heading from "ae-base/Base/Heading";
import BackButton from "ae-base/Base/BackButton";

import Style from "ae-style";
import ResponsiveStylesheet from "react-native-responsive-stylesheet";

var styles = ResponsiveStylesheet.createSized("min-direction", {
	0: {
		back: {
			marginLeft: Style.layout.marginBordered,
			marginTop: Style.layout.marginBordered
		}
	},
	512: {
		back: {
			marginLeft: Style.layout.marginContent,
			marginTop: Style.layout.marginContentVertical
		}
	}
});

export default function CardPage(props) {
	const {
		edit,
		title,
		icon,
		external,
		children,
		backTitle,
		saving,
		toast,
		screen,
		backAction
	} = props;

	var backButton = backTitle ? (
		<View style={styles.back}>
			<BackButton title={backTitle} onPress={backAction} />
		</View>
	) : null;

	var heading = title ? (
		<Heading title={title} icon={icon} screen={screen}>
			{edit}
		</Heading>
	) : null;

	return (
		<PageContainer contrast saving={saving} toast={toast}>
			<MainScroll online={props.online} extraScrollHeight={-Dimensions.get("window").height}>
				{backButton}
				<Card>
					{heading}
					{children}
				</Card>
			</MainScroll>
			{external}
		</PageContainer>
	);
}
