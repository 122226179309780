
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";

import Emoji from "../../Managed/Reminders/Emoji";
import Reminder from "../../Managed/Reminders/Reminder";
import StyledButton from "ae-base/Base/StyledButton";
import StyledText from "ae-base/Base/StyledText";
import { WEEKDAY_DATE_TIME_FORMAT } from "../../../../utils/date-utils";

import { useResources } from "@aetonix/hooks";

export default function StandardReminder(props) {
	const {
		localize,
		dispatch,
		formatDate,
	} = useResources();

	const edit = localize("reminder.edit");

	const reminder = props.reminder;
	const content = reminder.content;
	const readable = reminder.readable;
	const latestHistory = reminder.latestHistory;

	const ownReminder = reminder.from === reminder.to;

	let contentElement = (
		<StyledText>{content}</StyledText>
	);

	if(latestHistory && latestHistory.shown){
		const responseValue = localize("reminder.response");
		const responseTime = latestHistory.responseTime;

		let responseData = Emoji.check(latestHistory.response);
		if (responseData === "Accepted")
			responseData = localize("reminder.Accepted");
		else if (responseData === "Denied")
			responseData = localize("reminder.Denied");
		else if (responseData === "Cleared")
			responseData = localize("reminder.Cleared");
		else if(responseData === "Yes")
			responseData = localize("common.yes");
		else if(responseData === "No")
			responseData = localize("common.no");
		else if(responseData === "N/A")
			responseData = localize("reminder.Pending");

		const formatedDate = formatDate(responseTime, WEEKDAY_DATE_TIME_FORMAT);

		contentElement = [
			contentElement,
			(<StyledText>{responseValue} {responseData}</StyledText>),
			(<StyledText>{formatedDate}</StyledText>),
		];
	}

	const editReminder = dispatch("ownremindermaker:trigger:edit:start", {
		reminder: reminder._id,
	});

	const editButton = ownReminder ? (
		<StyledButton title={edit} icon="compose" onPress={editReminder} />
	) : null;

	return (
		<Reminder title={readable} content={contentElement} action={editButton} own={ownReminder} />
	);
}
