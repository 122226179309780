import React from "react";

import AtaContainer from "./AtaContainer";
import StyledButton from "ae-base/Base/StyledButton";
import ScreenSaver from "./ScreenSaver";
import { useResources } from "@aetonix/hooks";

export default function PowerButton(props) {
	const { localize, dispatch } = useResources();
	const model = props.model;
	const handlePowerButton = dispatch("app:trigger:power");

	const screensaver = model.screensaver;
	const powerbutton = localize("powerbutton.powerbutton");

	if(screensaver)
		return <ScreenSaver type={screensaver} />;

	return (
		<AtaContainer>
			<StyledButton secondary contrast title={powerbutton} icon="power-off" onPress={handlePowerButton} largeHeading />
		</AtaContainer>
	);
}
