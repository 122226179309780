export default {
	title: "CareplanPermissionChange",
	description: "",
	type: "object",
	properties: {
		"careplan_Appointments": {
			order: 14,
			description: "permissionscareplan.careplan_Appointments",
			value_description: "permissionscareplan",
			type: "number",
			inputType: "dropdown",
			enum: [0, 1, 2, 3]
		},
		"careplan_CareTeam": {
			order: 15,
			description: "permissionscareplan.careplan_CareTeam",
			value_description: "permissionscareplan",
			type: "number",
			inputType: "dropdown",
			enum: [0, 1, 2, 3]
		},
		"careplan_Client": {
			order: 16,
			description: "permissionscareplan.careplan_Client",
			value_description: "permissionscareplan",
			type: "number",
			inputType: "dropdown",
			enum: [0, 1, 2, 3]
		},
		"careplan_Goals": {
			order: 17,
			description: "permissionscareplan.careplan_Goals",
			value_description: "permissionscareplan",
			type: "number",
			inputType: "dropdown",
			enum: [0, 1, 2, 3]
		},
		"careplan_HealthAssessments": {
			order: 18,
			description: "permissionscareplan.careplan_HealthAssessments",
			value_description: "permissionscareplan",
			type: "number",
			inputType: "dropdown",
			enum: [0, 1, 2, 3]
		},
		"careplan_HealthConditions": {
			order: 19,
			description: "permissionscareplan.careplan_HealthConditions",
			value_description: "permissionscareplan",
			type: "number",
			inputType: "dropdown",
			enum: [0, 1, 2, 3]
		},
		"careplan_HospitalVisits": {
			order: 20,
			description: "permissionscareplan.careplan_HospitalVisits",
			value_description: "permissionscareplan",
			type: "number",
			inputType: "dropdown",
			enum: [0, 1, 2, 3]
		},
		"careplan_OtherTreatments": {
			order: 21,
			description: "permissionscareplan.careplan_OtherTreatments",
			value_description: "permissionscareplan",
			type: "number",
			inputType: "dropdown",
			enum: [0, 1, 2, 3]
		},
		"careplan_Situation": {
			order: 22,
			description: "permissionscareplan.careplan_Situation",
			value_description: "permissionscareplan",
			type: "number",
			inputType: "dropdown",
			enum: [0, 1, 2, 3]
		},
		"careplan_Supports": {
			order: 23,
			description: "permissionscareplan.careplan_Supports",
			value_description: "permissionscareplan",
			type: "number",
			inputType: "dropdown",
			enum: [0, 1, 2, 3]
		},
		"careplan_Treatments": {
			order: 24,
			description: "permissionscareplan.careplan_Treatments",
			value_description: "permissionscareplan",
			type: "number",
			inputType: "dropdown",
			enum: [0, 1, 2, 3]
		}
	}
};
