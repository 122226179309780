import React, {
	PureComponent,
} from "react";
import {
	View,
} from "react-native";
import prop from "prop";

import Link from "ae-base/Base/Link";

import Items from "../Items";
import Item from "../Item";
import Label from "../Label";
import Value from "../Value";
import SectionTitle from "../SectionTitle";
import Summarized from "../Summarized";
import GroupItems from "../GroupItems";

import { useResources } from "@aetonix/hooks";

var getActionsResponsibleParty = prop("ResponsibleParty");
var getSuggestedBy = prop("SuggestedBy.Name");
var getDescription = prop("Description");
var getReviewDate = prop("ReviewDate");
var getExpectedOutcome = prop("ExpectedOutcome");
var getChallengesDescription = prop("ChallengesDescription");
var getAchievedResults = prop("AchievedResults");
var getConcerns = prop("Concerns");
var getContributingCareTeamMember = prop("ContributingCareTeamMembers");
var getMostImportant = prop("MostImportant");
var getAcpAware = prop("Plan.AcpAware.Name");
var getAcpCompleted = prop("Plan.AcpCompleted.Name");
var getAcpLocation = prop("Plan.AcpLocation");
var getPoaDescription = prop("Plan.PoaDescription");
var getPoaCompleted = prop("Plan.PoaCompleted.Name");
var getPoaLocation = prop("Plan.PoaLocation");
var getPoaContactSurname = prop("Plan.PoaContact.Surname");
var getPoaContactFirstName = prop("Plan.PoaContact.FirstName");
var getPoaContactRelationship = prop("Plan.PoaContact.Relationship");
var getPoaContactRelationshipOther = prop("Plan.PoaContact.RelationshipCodedValue.Name");
var getPoaContactPhone = prop("Plan.PoaContact.Phone.Number");
var getSituationDescription = prop("Description");
var getSituationReviewDate = prop("ReviewDate");
var getSituationWhatWillDo = prop("WhatWillDo");
var getSituationWhoWillHelp = prop("WhoWillHelp");
var getSituationWhatWillNotDo = prop("WhatWillNotDo");
var getSituationTelephone = prop("Telephone");
var getSpecificGoal = prop("SpecificGoal");
var getFutureSituation = prop("FutureSituation");
var getActions = prop("Actions");

export default function Goals(props) {
	const {
		localize,
	} = useResources();

	var careplan = props.careplan;
	var currentSection = props.currentSection;
	var permissions = props.permissions;
	var changes = props.changes;
	var showCareplanDetail = props.showCareplanDetail;
	var ownCareplan = props.ownCareplan;

	var caregoals = localize("goals.caregoals");
	var careteammemberwhocontributedtothisplan = localize("goals.careteammemberwhocontributedtothisplan");
	var whatismostimportantformerightnow = localize("goals.whatismostimportantformerightnow");
	var whatconcernsmeaboutmyhealthcarerightnow = localize("goals.whatconcernsmeaboutmyhealthcarerightnow");
	var plansforfuturesituations = localize("goals.plansforfuturesituations");
	var ihaverecievedinformationaboutadvancecareplanning = localize("goals.ihaverecievedinformationaboutadvancecareplanning");
	var ihavecompletedanadvancedcareplan = localize("goals.ihavecompletedanadvancedcareplan");
	var myacpislocatedhere = localize("goals.myacpislocatedhere");
	var asiunderstandmyacpsays = localize("goals.asiunderstandmyacpsays");
	var ihaveapowerofattorneyforpersonalcare = localize("goals.ihaveapowerofattorneyforpersonalcare");
	var mypoadocumentedislocatedhere = localize("goals.mypoadocumentedislocatedhere");
	var nameofpoaattorney = localize("goals.nameofpoaattorney");
	var relationshiptome = localize("goals.relationshiptome");
	var telephone = localize("goals.telephone");

	var hasGoalsPermission = permissions >= 2;
	var careGoals = getSpecificGoal(careplan) || [];
	var renderCareGoals = careGoals.map(function(caregoal, index) {
		var isChanged = changes["SpecificGoal." + index] || index === -1;
		var hasChanged = permissions >= 2 && isChanged;
		return (
			<CareGoal key={index} defaultData={caregoal} index={index} currentSection={currentSection} hasChanged={hasChanged} ownCareplan={ownCareplan} showCareplanDetail={showCareplanDetail} />
		);
	}
	);

	var futureSituations = getFutureSituation(careplan) || [];
	var renderFutureSituations = futureSituations.map(function(situation, index){
		var isChanged = changes["FutureSituation." + index] || index === -1;
		var hasChanged = permissions >= 2 && isChanged;
		return (
			<FutureSituation key={index} situation={situation} index={index} currentSection={currentSection} hasChanged={hasChanged} ownCareplan={ownCareplan} showCareplanDetail={showCareplanDetail} />
		);
	}
	);

	return (
		<View>
			<Items hasChanged={hasGoalsPermission && changes.ContributingCareTeamMembers} data={"ContributingCareTeamMembers"} >
				<Item>
					<Label>{careteammemberwhocontributedtothisplan}</Label>
					<Value>{getContributingCareTeamMember(careplan)}</Value>
				</Item>
			</Items>

			<Items hasChanged={hasGoalsPermission && changes.MostImportant} data={"MostImportant"} >
				<Item alternate>
					<Label>{whatismostimportantformerightnow}</Label>
					<Value>{getMostImportant(careplan)}</Value>
				</Item>
			</Items>

			<Items hasChanged={hasGoalsPermission && changes.Concerns} data={"Concerns"} >
				<Item>
					<Label>{whatconcernsmeaboutmyhealthcarerightnow}</Label>
					<Value>{getConcerns(careplan)}</Value>
				</Item>
			</Items>

			<SectionTitle name={caregoals} section="SpecificGoal" permissions={permissions} changes={changes} />
			{renderCareGoals}
			<SectionTitle name={plansforfuturesituations} section="FutureSituation" permissions={permissions} changes={changes} />
			{renderFutureSituations}
			<SectionTitle />

			<Items hasChanged={hasGoalsPermission && changes["Plan.AcpAware.Name"]} data={"Plan.AcpAware.Name"}>
				<Item alternate>
					<Label>{ihaverecievedinformationaboutadvancecareplanning}</Label>
					<Value>{getAcpAware(careplan)}</Value>
				</Item>
			</Items>

			<Items hasChanged={hasGoalsPermission && changes["Plan.AcpCompleted.Name"]} data={"Plan.AcpCompleted.Name"}>
				<Item>
					<Label>{ihavecompletedanadvancedcareplan}</Label>
					<Value>{getAcpCompleted(careplan)}</Value>
				</Item>
			</Items>

			<Items hasChanged={hasGoalsPermission && changes["Plan.AcpLocation"]} data={"Plan.AcpLocation"}>
				<Item alternate>
					<Label>{myacpislocatedhere}</Label>
					<Value>{getAcpLocation(careplan)}</Value>
				</Item>
			</Items>

			<Items hasChanged={hasGoalsPermission && changes["Plan.PoaDescription"]} data={"Plan.PoaDescription"}>
				<Item>
					<Label>{asiunderstandmyacpsays}</Label>
					<Value>{getPoaDescription(careplan)}</Value>
				</Item>
			</Items>

			<Items hasChanged={hasGoalsPermission && changes["Plan.PoaCompleted.Name"]} data={"Plan.PoaCompleted.Name"}>
				<Item alternate>
					<Label>{ihaveapowerofattorneyforpersonalcare}</Label>
					<Value>{getPoaCompleted(careplan)}</Value>
				</Item>
			</Items>

			<Items hasChanged={hasGoalsPermission && changes["Plan.PoaLocation"]} data={"Plan.PoaLocation"}>
				<Item>
					<Label>{mypoadocumentedislocatedhere}</Label>
					<Value>{getPoaLocation(careplan)}</Value>
				</Item>
			</Items>

			<Items hasChanged={hasGoalsPermission && changes["Plan.PoaContact.FirstName"] || changes["Plan.PoaContact.Surname"]} data={"Plan.PoaContact.FirstName,Plan.PoaContact.Surname"}>
				<Item alternate>
					<Label>{nameofpoaattorney}</Label>
					<Value>{(getPoaContactFirstName(careplan) || "") + " " + (getPoaContactSurname(careplan) || "")}</Value>
				</Item>
			</Items>

			<Items hasChanged={hasGoalsPermission && changes["Plan.PoaContact.Relationship"] || changes["Plan.PoaContact.RelationshipCodedValue.Name"]} data={"Plan.PoaContact.Relationship,Plan.PoaContact.RelationshipCodedValue.Name"}>
				<Item>
					<Label>{relationshiptome}</Label>
					<Value>{getPoaContactRelationshipOther(careplan) || getPoaContactRelationship(careplan)}</Value>
				</Item>
			</Items>

			<Items hasChanged={hasGoalsPermission && changes["Plan.PoaContact.Phone.Number"]} data={"Plan.PoaContact.Phone.Number"}>
				<Item alternate>
					<Label>{telephone}</Label>
					<Value>
						<Link title={getPoaContactPhone(careplan)} uri={"tel:" + getPoaContactPhone(careplan)} />
					</Value>
				</Item>
			</Items>
		</View>
	);
}

function CareGoal(props){
	const { localize } = useResources();
	var defaultData = props.defaultData;
	var currentSection = props.currentSection;
	var hasChanged = props.hasChanged;
	var index = props.index;
	var showCareplanDetail = props.showCareplanDetail;
	var ownCareplan = props.ownCareplan;

	var actions = getActions(defaultData) || [];

	var renderActions = actions.map((action, itemIndex) =>
		<Actions key={itemIndex} actions={action} />
	);

	var whatihopetoachieve = localize("goals.whatihopetoachieve");
	var suggestedby = localize("goals.suggestedby");
	var whaticandotoachieveit = localize("goals.whaticandotoachieveit");
	var expectedoutcome = localize("goals.expectedoutcome");
	var barriersandchallenges = localize("goals.barriersandchallenges");
	var resultsachievedsofar = localize("goals.resultsachievedsofar");
	var reviewdate = localize("goals.reviewdate");

	return (
		<GroupItems>
			<Items hasChanged={hasChanged} data={"SpecificGoal." + index}>
				<Item>
					<Label>{whatihopetoachieve}</Label>
					<Value>{getDescription(defaultData)}</Value>
				</Item>

				<Item alternate>
					<Label>{reviewdate}</Label>
					<Value>{getReviewDate(defaultData)}</Value>
				</Item>

				<Summarized currentSection={currentSection} section={"SpecificGoal." + index} ownCareplan={ownCareplan} showCareplanDetail={showCareplanDetail}>
					<Item>
						<Label>{suggestedby}</Label>
						<Value>{getSuggestedBy(defaultData)}</Value>
					</Item>

					<Item alternate>
						<Label>{whaticandotoachieveit}</Label>
						<Value />
					</Item>
					{renderActions}

					<Item alternate>
						<Label>{expectedoutcome}</Label>
						<Value>{getExpectedOutcome(defaultData)}</Value>
					</Item>

					<Item>
						<Label>{barriersandchallenges}</Label>
						<Value>{getChallengesDescription(defaultData)}</Value>
					</Item>

					<Item alternate>
						<Label>{resultsachievedsofar}</Label>
						<Value>{getAchievedResults(defaultData)}</Value>
					</Item>
				</Summarized>
			</Items>
		</GroupItems>
	);
}

class Actions extends PureComponent {
	render(){
		var props = this.props;
		var actions = props.actions;
		return (
			<Item>
				<Label>{" • "}{getDescription(actions)}</Label>
				<Value>{getActionsResponsibleParty(actions)}</Value>
			</Item>
		);
	}
}

function FutureSituation(props){
	const { localize } = useResources();
	var situation = props.situation;
	var currentSection = props.currentSection;
	var hasChanged = props.hasChanged;
	var showCareplanDetail = props.showCareplanDetail;
	var ownCareplan = props.ownCareplan;

	var index = props.index;

	var futuresituations = localize("goals.futuresituations");
	var whatiwilldo = localize("goals.whatiwilldo");
	var whatiwillnotdo = localize("goals.whatiwillnotdo");
	var whowillhelpme = localize("goals.whowillhelpme");
	var telephonenumber = localize("goals.telephonenumber");
	var reviewdate = localize("goals.reviewdate");

	return (
		<GroupItems>
			<Items hasChanged={hasChanged} data={"FutureSituation." + index}>
				<Item>
					<Label>{futuresituations}</Label>
					<Value>{getSituationDescription(situation)}</Value>
				</Item>

				<Item alternate>
					<Label>{reviewdate}</Label>
					<Value>{getSituationReviewDate(situation)}</Value>
				</Item>

				<Summarized currentSection={currentSection} section={"FutureSituation." + index} ownCareplan={ownCareplan} showCareplanDetail={showCareplanDetail}>
					<Item>
						<Label>{whatiwilldo}</Label>
						<Value>{getSituationWhatWillDo(situation)}</Value>
					</Item>
					<Item alternate>
						<Label>{whatiwillnotdo}</Label>
						<Value>{getSituationWhatWillNotDo(situation)}</Value>
					</Item>

					<Item>
						<Label>{whowillhelpme}</Label>
						<Value>{getSituationWhoWillHelp(situation)}</Value>
					</Item>

					<Item alternate>
						<Label>{telephonenumber}</Label>
						<Value>
							<Link title={getSituationTelephone(situation)} uri={"tel:" + getSituationTelephone(situation)} />
						</Value>
					</Item>
				</Summarized>

			</Items>
		</GroupItems>
	);
}
