import React from "react";

import BasicState from "./BasicState";
import { useResources } from "@aetonix/hooks";

export default function Busy(props){
	const { localize } = useResources();
	var toast = props.toast;
	var calling = props.calling;
	var who = calling.who;
	var message = localize("outgoing.messageBusy", who);

	return (
		<BasicState message={message} toast={toast} />
	);
}
