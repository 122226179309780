import React from "react";

import CallPage from "./CallPage";

export default function Busy(props) {
	var who = props.who;

	return (
		<CallPage who={who} state={"busy"} />
	);
}
