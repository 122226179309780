import React from "react";
import {
	View,
	StyleSheet,
} from "react-native";

import MainScroll from "../../Layout/MainScroll";
import OutputForm from "ae-forms/src/Outputs/OutputForm";
import StyledText from "ae-base/Base/StyledText";

import Style from "@aetonix/styles";

var styles = StyleSheet.create({
	container: {
		flex: 1,
		marginHorizontal: Style.layout.marginMedium,
		flexDirection: "column"
	},
	content: {
		flex: 1,
		padding: Style.layout.paddingButton,
		borderWidth: 2,
		borderColor: Style.colors.tertiary,
	},
	scroll: {
		flex: 1,
		paddingHorizontal: Style.layout.paddingButton,
	},
	title: {
		alignItems: "center"
	}
});

export default function FormSubmission(props){
	var schema = props.schema;
	var data = props.data;
	var title = props.title;

	return (
		<View style={styles.container}>
			<View style={styles.content}>
				<MainScroll atouchaway style={styles.scroll}>
					<View style={styles.title}>
						<StyledText tertiary bold smallHeading>{title}</StyledText>
					</View>
					<OutputForm schema={schema} data={data} contrast />
				</MainScroll>
			</View>
		</View>
	);
}
