import React, { useCallback, useMemo } from 'react';
import { createStyled } from '@emotion/primitives-core';
import {  SectionList,  StyleSheet, TouchableOpacity, View } from 'react-native';
import MembersItem from './MembersItem';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faMicrophone, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { sortNow } from './trayMembers.library';
import { useLocalize } from '../../../hooks/useLocalize/LocalizeContext';
import ResponsiveText from '../Text/ResponsiveText';
import Style from '@aetonix/styles';

const TrayMembers = ({ onClickAddPerson, members = [], isAddDisabled = false }) => {
    const sortedMembers = useMemo(() => members?.map(d => {
        const sortedResult = d.data?.sort(sortNow);
        return { ...d, data: sortedResult };
    }) ?? [], [members]);

    const localize = useLocalize();

    const renderItem = useCallback(({ item }) => <MembersItem
        title={item?.name}
        userType={item?.userType}
        isActiveSpeaker={item?.isMostActiveSpeaker ?? false}
        actionIcon={item?.isMostActiveSpeaker && <FontAwesomeIcon style={{ color: 'green' }} size={23} icon={faMicrophone}/> }
        onClick={() => onClickAddPerson(item)}
    />, []);

    const addPersonHeader = (
        <AddPersonContainer testID={"add-person-button"} onPress={onClickAddPerson}>
            <FontAwesomeIcon icon={faUserPlus} size={23}/>
            <AddPersonLabel size={Style.text.emphasized} max={Style.text.smallHeading}>{ localize("meeting.memberstray.three") }</AddPersonLabel>
        </AddPersonContainer>
    );
    
    return <Container>
        <MembersList
            ListHeaderComponent={!isAddDisabled && addPersonHeader}
            showsVerticalScrollIndicator={false}
            sections={sortedMembers}
            keyExtractor={(item, index) => item + index}
            renderItem={renderItem}
            renderSectionHeader={({ section: { title } }) => (
                <SectionHeader size={Style.text.emphasized} max={Style.text.smallHeading}>{title}</SectionHeader>
            )}
            style={{ overflowY: 'auto' }}
            SectionSeparatorComponent={() => <View style={{ marginBottom: 10 }} />}
        />
    </Container>
}

const styled = createStyled(StyleSheet);

const Container = styled(View)({
    alignItems: "flex-start",
    padding: 20,
    paddingBottom: 0,
    width: "100%",
    paddingLeft: 30,
    paddingRight: 30,
})

const AddPersonContainer = styled(TouchableOpacity)({
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 15,
})

const AddPersonLabel = styled(ResponsiveText)({
    fontWeight: '500',
    marginLeft: 8,
    color: "#141823",
});

const MembersList = styled(SectionList)({
    width: "100%",
    alignContent: "center",
    backgroundColor: 'white',
});

const SectionHeader = styled(ResponsiveText)({
    fontWeight: 'bold',
    backgroundColor: "#fff",
    paddingBottom: 10,
})


export default TrayMembers;
