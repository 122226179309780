import React from 'react';
import { createStyled } from '@emotion/primitives-core'
import { TouchableOpacity, View, StyleSheet } from 'react-native';
import Icon from '../Icon';

const styled = createStyled(StyleSheet);

const IconContainer = styled(View)({
    justifyContent: "center",
    alignContent: "center",
    flexDirection: "row",
    margin: 16,
});

export default ({ onClick }) => (
    <TouchableOpacity>
        <IconContainer onClick={onClick}>
            <Icon fill="white" style={{ color: "#e8e8e8" }} icon={"messageWhite"} />
        </IconContainer> 
    </TouchableOpacity>
);