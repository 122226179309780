import React from "react";
import { View, ScrollView, Linking, Platform } from "react-native";

import { isOverlayPermissionGranted, requestOverlayPermission } from "react-native-can-draw-overlays";


import Style from "ae-style";
import ResponsiveStylesheet from "react-native-responsive-stylesheet";

import DialogContainer from "ae-base/Base/DialogContainer";
import StyledButton from "ae-base/Base/StyledButton";
import StyledText from "ae-base/Base/StyledText";

var styles = ResponsiveStylesheet.createSized("min-direction", {
	0: {
		dialogItem: {
			backgroundColor: Style.colors.white,
			padding: Style.layout.paddingButton - 4,
			borderWidth: 1,
			borderColor: "rgba(0,0,0,0.1)",
			width: 250,
			justifyContent: "center",
			zIndex: 2,
		},
		closeButton: {
			flexDirection: "row",
			justifyContent: "center",
			paddingTop: Style.layout.paddingSmall,
		},
	},
	512: {
		dialogItem: {
			padding: Style.layout.paddingLarge,
			width: 350,
		},
	},
});

export const AllowShowOnTopModal = props => {
	const { localize } = props; // Pass in localize because we may be outside of useLocalize()
	const [showing, setShowing] = React.useState(false);

	React.useEffect(() => {
		if(Platform.OS === "android") {
			const checkOverlayPermission = async () => {
				const canDrawOverlays = await isOverlayPermissionGranted();
				setShowing(!canDrawOverlays);
			};
			checkOverlayPermission();
		}
	}, []);

	if (!showing || Platform.OS !== "android") return null;

	const openShowOnTopSettings = () => {
		requestOverlayPermission();
		setShowing(false);
	};

	const text = localize("regionselect.showOnTopDescription");

	return (
		<DialogContainer>
			<View style={styles.dialogItem}>
				<ScrollView>
					<View>
						<StyledText>{text}</StyledText>
						<View style={styles.closeButton}>
							<StyledButton
								title={localize("regionselect.showOnTopOpenSettings")}
								onPress={openShowOnTopSettings}
							/>
						</View>
					</View>
				</ScrollView>
			</View>
		</DialogContainer>
	);
};
