export default {
	title: "Search",
	description: "search_title",
	type: "object",
	properties: {
		query: {
			description: "request.searchcontent",
			type: "string",
			inputType: "search",
			format: "search"
		}
	}
};
