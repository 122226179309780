import React from "react";
import {
	StyleSheet,
} from "react-native";
import Style from "ae-style";
import LinearGradient from "react-native-linear-gradient";
// import CallPageTitle from "./CallPageTitle";

var styles = StyleSheet.create({
	callpage: {
		flex: 1,
		alignItems: "stretch",
	}
});

export default function CallPage(props){

	var gradientColors = [Style.colors.medium, Style.colors.black];
	var gradientStart = {
		x: 1, y: 1
	};
	var gradientEnd = {
		x: 0, y: 0
	};
	var gradientLocations = [0, 1];

	return (
		<LinearGradient style={styles.callpage} colors={gradientColors} start={gradientStart} end={gradientEnd} locations={gradientLocations}>
			{props.children}
		</LinearGradient>
	);
}
