import React from "react";
import {
	View,
} from "react-native";

import Form from "ae-forms";

import GeneralSchema from "../../../../../schemas/aTouchAwayGeneral";

import SettingSection from "../SettingSection";

import UserMobileGeneral from "../../../../../schemas/UserMobileGeneral";

import { useResources } from "@aetonix/hooks";

export default function General(props) {
	const {
		localize,
		dispatch,
	} = useResources();
	var display = props.display;
	var model = props.model;
	var toggle = props.onToggle;

	var atouchaway = model.manage.atouchaway || {};
	var account = model.manage.account || {};

	var title = localize("atasettings.general") || "General";

	var UseSchema = (account.type.indexOf("user:mobile") === -1) ? GeneralSchema : UserMobileGeneral;
	var renderBody = display ? (
		<Form key={"general"} schema={UseSchema} defaultData={atouchaway} onSubmit={dispatch("managedatouchaway:trigger:update")} />
	) : null;

	return (
		<View>
			<SettingSection section="general" title={title} display={display} onToggle={toggle} />
			{renderBody}
		</View>
	);
}
