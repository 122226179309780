import PropTypes from "prop-types";
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";
import {
	View,
} from "react-native";

import LiveChatHeader from "../LiveChat/LiveChatHeader";
import MessagingInput from "./MessagingInput";
import Messages from "./Messages";
import Message from "./Message";
import MessageScroll from "../../Layout/MessageScroll";
import Divider from "../../Layout/Divider";
import PageContainer from "../../Layout/PageContainer";
import StyledButton from "ae-base/Base/StyledButton";
import StyledText from "ae-base/Base/StyledText";
import ResponsiveStylesheet from "react-native-responsive-stylesheet";
import BlockView from "ae-base/Base/BlockView";
import FileUploadDialog from "./FileUploadDialog";
import OfflineBanner from "../Homepage/OfflineBanner";
import UploadConfirm from "./UploadConfirm";

import Style from "@aetonix/styles";

import { useResources } from "@aetonix/hooks";

var styles = ResponsiveStylesheet.createSized("min-direction", {
	0: {
		messages: {
			marginHorizontal: Style.layout.marginContent / 2,
		},
		messagesPending: {
			justifyContent: "center",
		},
		divider: {
			marginHorizontal: Style.layout.marginLarge,
		},
		more: {
			marginVertical: Style.layout.marginContentVertical / 2,
			alignItems: "center",
			justifyContent: "center",
		},
		messageRead: {
			alignItems: "flex-end",
			marginHorizontal: Style.layout.marginContent / 2,
			padding: Style.layout.paddingSlim,
		},
		messageReadAlternate: {
			alignItems: "flex-start",
		},
	},
	512: {
		messages: {
			marginHorizontal: Style.layout.marginContent,
		},
		messageRead: {
			marginHorizontal: Style.layout.marginContent,
		},
		more: {
			marginVertical: Style.layout.marginContentVertical,
			alignItems: "center",
			justifyContent: "center",
		},
	},
});

export default function GroupMessaging(props) {
	const {
		localize,
		dispatch,
	} = useResources();

	var model = props.model;
	var saving = model.saving;
	var livechat = model.livechatmessages;
	var group = model.activelivechatgroup || {};
	var session = model.activelivechatsession || {};
	var uploading = livechat.uploading;
	var file = livechat.file;
	var image = livechat.fileImage;
	var personal = model.personal;
	var toast = model.toast;
	var groupedMsgList = model.livechatgroupedmessages || [];
	var loadmore = localize("timeline.loadmore");
	var getMore = dispatch("livechatmessages:trigger:more");
	var loadMoreVal = !livechat.isEnd;
	var pendingMessages = livechat.pendingList;
	var name = group.name;
	var lastMessage;
	var isAlternate;

	var ENDBUTTON = [{
		id: "livechat_end",
		title: localize("livechat.end"),
		onPress: dispatch("livechatsessions:trigger:end", {
			session: session._id,
		}),
		icon: "aeto-i",
	}];

	var ACCEPTBUTTON = [{
		id: "livechat_accept",
		title: localize("livechat.accept"),
		onPress: dispatch("livechatsessions:trigger:accept", {
			session: session._id,
		}),
		icon: "aeto-i",
	}];

	var screen = model.size;

	var renderedGroupedMessages = groupedMsgList
		.map(function(list){
			var from = list[0].from;
			isAlternate = from._id !== personal._id;
			lastMessage = isAlternate ? null : list[list.length - 1];

			return (
				<Messages person={from} alternate={isAlternate} messages={list} screen={screen} fullName />
			);
		});

	var renderedPendingMessages = null;
	if(!saving)
		renderedPendingMessages = pendingMessages.map(function(pendingItem){
			return (
				<Message file={pendingItem.file} content={pendingItem.content} pending={true} />
			);
		});

	var renderMore = loadMoreVal ? (
		<View style={styles.more}>
			<StyledButton title={loadmore} icon="chevron-circle-up" onPress={getMore} />
		</View>
	) : null;

	var sendMessage = function(content){
		dispatch("livechatmessages:trigger:send", {
			content: content,
		})();
	};

	var onUpload = function(uploadfile){
		dispatch("livechatmessages:trigger:upload:confirm", {
			file: uploadfile,
		})();
	};

	var cancelUpload = dispatch("livechatmessages:trigger:upload:cancel");
	var startUpload = dispatch("livechatmessages:trigger:upload:start");

	var renderDialog = null;
	if(uploading)
		renderDialog = (
			<FileUploadDialog onUpload={onUpload} cancelUpload={cancelUpload} />
		);

	var renderMessageRead = null;
	if(lastMessage && lastMessage.read){
		var readMessage = localize("messaging.readmessage", lastMessage);
		renderMessageRead = (<StyledText grey small>{readMessage}</StyledText>);
	}

	var offline = model.online ? null : (
		<OfflineBanner />
	);

	var messageReadStyle = [styles.messageRead];
	var defaultMessage = livechat.defaultMessage || "";
	if(isAlternate)
		messageReadStyle.push(styles.messageReadAlternate);
	var onConfirm = dispatch("livechatmessages:trigger:upload:finish");
	var onCancel = dispatch("livechatmessages:trigger:upload:cancel");

	var isMember = group.members.indexOf(personal._id) !== -1;

	var status = session.status;
	var showInput = (isMember && status === "active") || (!isMember && status !== "closed");
	var renderInput = showInput ? (
		<MessagingInput startUpload={startUpload} messages={livechat} name={name} defaultMessage={defaultMessage} sendMessage={sendMessage} />
	) : null;

	var renderButtons = null;
	if(isMember && status === "pending")
		renderButtons = ACCEPTBUTTON;
	else if (showInput)
		renderButtons = ENDBUTTON;

	return (
		<PageContainer toast={toast} saving={saving}>
			<LiveChatHeader person={personal} group={group} buttons={renderButtons} backText={localize("livechat.backmessaging")} />
			{offline}
			<MessageScroll>
				{renderMore}
				<View style={styles.messages} >
					{renderedGroupedMessages}
				</View>
				<View style={styles.messagesPending} >
					{renderedPendingMessages}
				</View>
				<View style={messageReadStyle} >
					{renderMessageRead}
				</View>
			</MessageScroll>
			<BlockView style={styles.divider}>
				<Divider />
			</BlockView>
			{renderInput}
			{renderDialog}
			<UploadConfirm open={!!file} file={file} image={image} toast={toast} onConfirm={onConfirm} onCancel={onCancel} screen={screen} />
		</PageContainer>
	);
}
