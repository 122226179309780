import React from "react";
import {
	View,
	StyleSheet,
} from "react-native";

import CallPage from "./CallPage";
import CallButton from "./CallButton";

import { useResources } from "@aetonix/hooks";

var styles = StyleSheet.create({
	onecallbutton: {
		flex: 1.5,
		justifyContent: "flex-end",
	},
});

export default function Connecting(props) {
	const {
		localize,
		dispatch,
	} = useResources();
	var who = props.who;
	var hangUpText = localize("atouchawaycalling.endcall");
	var cancelCall = (
		dispatch("calling:trigger:hangup", {
			reason: "hangup",
		})
	);

	return (
		<CallPage who={who} state={"connecting"}>
			<View style={styles.onecallbutton}>
				<CallButton
					hangup
					icon={"aeto-hang_up-2"}
					buttonAction={cancelCall}
					buttonText={hangUpText}
				/>
			</View>
		</CallPage>
	);
}
