/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";

import Loader from "ae-base/Base/Loader";
import CardPage from "../../../Layout/CardPage";

import Form from "ae-forms";

import { useResources } from "@aetonix/hooks";

var DEFAULT_DATA = {
	files: [],
};

export default function Managedformscreation(props) {
	const {
		localize,
		dispatch,
	} = useResources();

	var model = props.model;
	var screen = model.size;

	var saving = model.saving;

	var manage = model.manage;
	var forms = manage.forms;
	var schema = forms.schema;
	var hasSchema = (forms.schema && forms.schema.properties);

	var createnewnote = localize("managedformscreation.createnewnote");
	var discardchanges = localize("managedformscreation.discardchanges");

	if(!hasSchema) return <Loader />;

	var createNote = dispatch("managedforms:trigger:submit");
	var goBack = dispatch("managedforms:trigger:cancel");

	return (
		<CardPage title={createnewnote} icon="notebook" saving={saving} screen={screen} online={model.online}>
			<Form schema={schema} defaultData={DEFAULT_DATA} onSubmit={createNote} onCancel={goBack} cancelText={discardchanges} />
		</CardPage>
	);
}
