/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";

import ProfileSection from "../ProfileSection";
import BlockView from "ae-base/Base/BlockView";

import Form from "ae-forms";
import PrivacySchema from "../../../../schemas/Privacy";

import ChangePassword from "./ChangePassword";
import ChangeEmail from "./ChangeEmail";
import { useResources, useIdPrefix } from "@aetonix/hooks";

export default function PrivacySettings(props){
	const { dispatch, localize } = useResources();
	const idPrefix = useIdPrefix();
	var autologout = props.autologout;
	var multifactor = props.multifactor;
	var preferences = props.preferences;
	var display = props.profilesection.displaying.privacy;

	var title = localize("privacysettings.title");

	var loadedKey = Object.keys(preferences || {}).length;

	var schema = {...PrivacySchema};

	if(autologout){
		const properties = {...schema.properties};
		delete properties.autologout;
		schema.properties = properties;
	}

	if (multifactor) {
		const properties = {...schema.properties};
		delete properties.multifactor;
		schema.properties = properties;
	}

	var renderBody = null;
	if(display)
		renderBody = (
			<BlockView>
				<ChangeEmail preferences={preferences} />
				<ChangePassword preferences={preferences} />
				<Form id_prefix={idPrefix + "privacyForm"} key={loadedKey} schema={schema} defaultData={preferences} autoSubmit onSubmit={dispatch("preferences:trigger:preferences:update")} />
			</BlockView>
		);

	return (
		<BlockView>
			<ProfileSection title={title} section="privacy" display={display} />
			{renderBody}
		</BlockView>
	);
}
