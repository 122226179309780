/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";
import {
	View,
	StyleSheet,
} from "react-native";

import ButtonGrid from "./ButtonGrid";
import ButtonGridHealth from "./ButtonGridHealth";
import ButtonGridForms from "./ButtonGridForms";
import WorkflowStatus from "./WorkflowStatus";
import { useResources } from "@aetonix/hooks";

var MYHEALTH_BUTTONS = [{
	title: "myhealth.overview", permission: "observations", "icon": "address-card-o", trigger: "managedoverview:trigger:view",
}, {
	title: "myhealth.activity", permission: "observations", "icon": "line-chart", trigger: "managedactivity:trigger:view", indicator: "steps",
}, {
	title: "myhealth.bloodpressure", permission: "observations", "icon": "heartbeat", trigger: "managedbloodpressure:trigger:view", indicator: "bloodpressure",
}, {
	title: "myhealth.bloodsugar", permission: "observations", "icon": "rocket", trigger: "managedbloodsugar:trigger:view", indicator: "bloodsugar",
}, {
	title: "myhealth.bodytemp", permission: "observations", "icon": "thermometer-empty", trigger: "managedbodytemperature:trigger:view", indicator: "bodytemperature",
},  {
	title: "myhealth.oxygen", permission: "observations", "icon": "stethoscope", trigger: "managedbloodoxygen:trigger:view", indicator: "bloodoxygen",
}, {
	title: "myhealth.weight", permission: "observations", "icon": "user", trigger: "managedweight:trigger:view", indicator: "weight",
}];

var CAREPLAN_BUTTONS = [{
	title: "client.patientidentifiers", permission: "careplan_Client", "icon": "address-card-o", trigger: "managedcareplansection:trigger:view",
}, {
	title: "careteam.careteam", permission: "careplan_CareTeam", "icon": "usergroup", trigger: "managedcareplansection:trigger:view",
}, {
	title: "healthconditions.healthissues", permission: "careplan_HealthConditions", "icon": "heartbeat", trigger: "managedcareplansection:trigger:view",
}, {
	title: "treatments.allergiesmedications", permission: "careplan_Treatments", "icon": "medkit", trigger: "managedcareplansection:trigger:view",
}, {
	title: "goals.caregoals", permission: "careplan_Goals", "icon": "list", trigger: "managedcareplansection:trigger:view",
}, {
	title: "situation.situationlifestyle", permission: "careplan_Situation", "icon": "bed", trigger: "managedcareplansection:trigger:view",
}, {
	title: "healthassessments.healthassessment_s", permission: "careplan_HealthAssessments", "icon": "stethoscope", trigger: "managedcareplansection:trigger:view",
}, {
	title: "hospitalvisits.hospitalvisit_s", permission: "careplan_HospitalVisits", "icon": "hospital-o", trigger: "managedcareplansection:trigger:view",
}, {
	title: "othertreatments.othertreatments", permission: "careplan_OtherTreatments", "icon": "bath", trigger: "managedcareplansection:trigger:view",
}, {
	title: "supports.supportsservices", permission: "careplan_Supports", "icon": "user-md", trigger: "managedcareplansection:trigger:view",
}, {
	title: "appointments.appointmentsreferrals", permission: "careplan_Appointments", "icon": "calendar", trigger: "managedcareplansection:trigger:view",
}];

var SETTINGS_BUTTONS = [{
	title: "managedprofile.personalinformation", permission: "personal", icon: "edit", trigger: "managedpreferences:trigger:view",
}, {
	title: "managedcontacts.contact_s", permission: "contacts", icon: "contacts", trigger: "managedcontacts:trigger:view",
}, {
	title: "managedcontacts.managedcareteam", permission: "manage", icon: "usergroup", trigger: "managedmanagers:trigger:view",
}, {
	title: "devices.managedbutton", permission: "devices", icon: "chargingbattery", trigger: "manageddevices:trigger:view",
}, {
	title: "atouchawaysettings.atouchawaysettings", permission: "atouchaway", icon: "television", trigger: "managedatouchaway:trigger:view",
}];

var NON_MOBILE_PERMISSIONS = [{
	title: "managedbracelet.braceletsettings", permission: "device", icon: "watch", trigger: "managedbracelet:trigger:view",
}];

var SIMPLIFIED_PERMISSIONS = [
	{
		title: "managedprofile.personalinformation", permission: "personal", icon: "edit", trigger: "managedpreferences:trigger:view",
	}, {
		title: "managedcontacts.managedcareteam", permission: "manage", icon: "usergroup", trigger: "managedmanagers:trigger:view",
	}, {
		title: "devices.managedbutton", permission: "devices", icon: "chargingbattery", trigger: "manageddevices:trigger:view",
	}, {
		title: "atouchawaysettings.atouchawaysettings", permission: "atouchaway", icon: "television", trigger: "managedsimplified:trigger:view",
	}];

var styles = StyleSheet.create({
	sections: {
		alignItems: "center",
	},
});

export default function ManagedSections(props){
	const { localize } = useResources();
	var manage = props.manage;
	var contact = manage.account;
	const group = props.group;
	var permissions = manage.permissions;
	var newsections = manage.newsections;
	var allSections = manage.allSections;
	var status = manage.managedstatuses[contact._id] || {};

	const userOrggroup = manage.managedusergroup;
	const managedCustomIndicatorsList = manage.managedcustomindicatorslist || [];
	let customIndicatorsButtons = [];
	if (userOrggroup && managedCustomIndicatorsList.length > 0)
		customIndicatorsButtons = managedCustomIndicatorsList.map((indicator) => {
			return {
				"isCustomIndicator": true,
				"index": indicator.index,
				"indicator": indicator._id,
				"title": indicator.localization,
				"unit": indicator.unit,
				"permission": "observations",
				"icon": "medkit",
				"trigger": "managedcustomindicator:trigger:view",
			};
		});


	var groupformlist = manage.managedgroupformlist || [];
	var groupformorder = manage.managedgroupformorderedlist || [];
	var contactType = contact.type || [];
	var workflowDefinitions = manage.workflow.definition || [];
	var groupformsPermissions = permissions.groupforms || {};

	var groupforms = {};
	if(typeof groupformsPermissions === "object")
		groupforms = groupformorder.reduce(function(acc, groupform){
			if(groupformsPermissions[groupform])
				acc[groupform] = groupformlist[groupform];
			return acc;
		}, {});

	var screen = props.screen;
	var configButtons = SETTINGS_BUTTONS;
	if(contactType.indexOf("simplified") !== -1) configButtons = SIMPLIFIED_PERMISSIONS;
	else if(contactType.indexOf("atouchaway") !== -1) configButtons = SETTINGS_BUTTONS.concat(NON_MOBILE_PERMISSIONS);

	var careplan = localize("managedsections.careplan");
	var configuration = localize("managedsections.utilities");
	var healthstatus = localize("managedsections.health");
	var groupform = localize("groupforms.form");
	return (
		<View style={styles.sections}>
			<WorkflowStatus status={status} screen={screen} workflowDefinitions={workflowDefinitions} />
			<ButtonGrid group={group} title={careplan} buttons={CAREPLAN_BUTTONS} permissions={permissions} contact={contact} newsections={newsections} allSections={allSections} screen={screen} />
			<ButtonGridHealth group={group} title={healthstatus} status={status.indicator_status || {}} buttons={MYHEALTH_BUTTONS} customIndicatorsButtons={customIndicatorsButtons} permissions={permissions} contact={contact} screen={screen} />
			<ButtonGridForms group={group} title={groupform} forms={groupforms} contact={contact} screen={screen} newsections={newsections} />
			<ButtonGrid group={group} title={configuration} buttons={configButtons} permissions={permissions} contact={contact} newsections={newsections} screen={screen} />
		</View>
	);
}
