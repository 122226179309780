/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";
import{
	View,
	StyleSheet
} from "react-native";
import StyledText from "ae-base/Base/StyledText";
import Notification from "../Layout/Notification";
import Style from "ae-style";
import Breakpoints from "../../Breakpoints";

/**  This component can take the following props:
			 color = the background color
			 onPress = a function to fire on click
			 children = children components
			 time = DTG
			 message_line1 = first line of a message
			 message_line2 = optionally the second line of a message
			 icon = an icon
			 iconColor = a colour for the icon (from Style)
 **/

var styles = StyleSheet.create({
	break: {
		flex: 1,
		justifyContent: "flex-end",
	}
});

export default function Banner(props){
	var children = props.children;
	var color = props.color;
	var iconColor = props.iconColor;
	var time = props.time;
	var date = props.date;
	var message_line1 = props.message_line1;
	var message_line2 = props.message_line2;
	var onPress = props.onPress;
	var textSize = props.textSize;
	var border = props.border;
	var icon = props.icon;
	var textColor = props.textColor || Style.colors.white;

	if (!textSize) textSize = "standard";

	if (color === Style.colors.yellow)
		textColor = Style.colors.black;
	if (color === Style.colors.white)
		textColor = Style.colors.primary;

	var text_style = {
		color: textColor,
	};

	if (Breakpoints.smallerThan("mobileSmall")) {
		text_style["fontSize"] = 14;
	}

	if (Breakpoints.smallerThan("small")) {
		text_style["fontSize"] = 12;
	}

	return (
		<Notification id={icon} icon={icon} color={color} iconColor={iconColor} onPress={onPress} border={border}>
			<View style={styles.break}>
				{!!props.topMessages && !!props.topMessages.length && props.topMessages.map(message => (
					<StyledText style={text_style} size={textSize}>{message}</StyledText>
				))}
				<StyledText style={text_style} size={textSize}>{time} {date}</StyledText>
				<StyledText style={text_style} size={textSize}>{message_line1}</StyledText>
				<StyledText style={text_style} size={textSize}>{message_line2}</StyledText>
				{!!props.bottomMessages && !!props.bottomMessages.length && props.bottomMessages.map(message => (
					<StyledText style={text_style} size={textSize}>{message}</StyledText>
				))}
			</View>
			{children}
		</Notification>
	);
}
