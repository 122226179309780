import React from "react";
import {
	View,
} from "react-native";
import ResponsiveStylesheet from "react-native-responsive-stylesheet";
import prop from "prop";

import Divider from "../../Layout/Divider";
import StyledText from "ae-base/Base/StyledText";
import Style from "ae-style";
import Breakpoints from "../../../Breakpoints";

import ChangeValue from "./ChangeValue";

import { useResources } from "@aetonix/hooks";

var styles = ResponsiveStylesheet.createSized("min-direction", {
	0: {
		cardItemBackgroundAlternate: {
			paddingHorizontal: Style.layout.paddingSmall,
			backgroundColor: Style.colors.itemGray,
		},
		styledTextFlex: {
			paddingVertical: Style.layout.paddingSmall / 2,
			paddingHorizontal: Style.layout.paddingSmall / 3,
		},
		changeSubItemsViewRow: {
			flexDirection: "row",
		},
		changeSubItemsViewColumn: {
			flexDirection: "column",
		},
		changeSubItems: {},
	},
	512: {
		styledTextFlex: {
			paddingVertical: Style.layout.paddingSmall * 2,
			paddingHorizontal: Style.layout.paddingSmall * 3,
		},
	},
});

var reNumverPath = /\/\d+|\/-/g;
var rePath = /\//g;

export default function Change(props){
	const { localize } = useResources();
	var change = props.change;
	var careplan = props.careplan;
	var changeValue = change.value;
	var path = change.path;
	var sectionSchema = careplan.section.schema;
	var transNumPath = path.replace(reNumverPath, ".items");
	var transPath = transNumPath.replace(rePath, ".properties.");
	var getValue = prop(transPath.slice(1));
	var schemaValue = getValue(sectionSchema);
	var hasValueDescription = schemaValue.value_description;
	var value = hasValueDescription ? localize(hasValueDescription + "." + changeValue) : changeValue;

	var op = change.op;

	var from = localize("proposal.from");
	var to = localize("proposal.to");

	var changeSubItemsViewStyle = [styles.changeSubItemsView];

	var renderContent = null;
	if(!careplan.flattened[path] && (typeof value === "object"))
		renderContent = null;
	else {
		if(op === "replace" || op === "add"){
			if(Breakpoints.smallerThan("mobileSmall")){
				var renderDivider = null;
				changeSubItemsViewStyle.push(styles.changeSubItemsViewColumn);
			}else{
				changeSubItemsViewStyle.push(styles.changeSubItemsViewRow);
				renderDivider = ( <Divider vertical valueDivider /> );
			}

			var fromValue = careplan.flattened[path];
			fromValue = hasValueDescription ? localize(hasValueDescription + "." + fromValue) : fromValue;

			renderContent = (
				<View style={styles.cardItemBackgroundAlternate}>
					<View style={changeSubItemsViewStyle}>
						<View style={styles.changeSubItems}>
							<View style={styles.styledTextFlex}>
								<StyledText bold>{from}</StyledText>
							</View>
							<ChangeValue>{fromValue}</ChangeValue>
						</View>
						{renderDivider}
						<View style={styles.changeSubItems}>
							<View style={styles.styledTextFlex}>
								<StyledText bold>{to}</StyledText>
							</View>
							<ChangeValue>{value}</ChangeValue>
						</View>
					</View>
				</View>);
		}
		if(op === "remove")
			renderContent = null;

	}
	return (
		<View>
			{renderContent}
		</View>
	);
}
