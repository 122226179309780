
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 16, 2018
 * For information or permission request, email info@aetonixsystems.com
 */

export default {
	title: "Blood sugar",
	description: "glucose.manual_entry",
	type: "object",
	properties: {
		mmol: {
			type: "number",
			placeholder: "bloodsugar.messageMmol",
			description: "bloodsugar.glucosedescriptionMmol",
			minimum: 1,
			maximum: 40
		},
		comment: {
			description: "bloodsugar.commenttitle",
			type: "string",
			multiline: true,
			placeholder: "bloodsugar.comment"
		}
	},
	required: ["mmol"]
};
