import PropTypes from "prop-types";
import React from "react";

import Proposal from "./Proposal";
import ManagedSection from "../Pages/Managed/ManagedSection";
import StyledButton from "ae-base/Base/StyledButton";

import { useResources } from "@aetonix/hooks";

export default function Section(props) {
	const {
		localize,
		dispatch,
	} = useResources();

	var model = props.model;
	var manage = model.manage;
	var toast = model.toast;
	var permissions = manage.permissions;
	var title = props.title;
	var content = props.children;
	var section = props.section;
	var changeViewer = manage.careplan.changeViewer;

	var screen = model.size;

	var edit = localize("section.edit");

	var canEdit = permissions && (permissions["careplan_" + section] >= 2);
	var action = dispatch("managedcareplansection:trigger:edit");
	var editButton = canEdit ? (
		<StyledButton title={edit} icon={"compose"} onPress={action} clear contrast />
	) : null;
	var contact = model.manage.account;

	var isChangeViewer =
		changeViewer ? (<Proposal careplan={manage.careplan} permissions={permissions} screen={screen} />) : (
			<ManagedSection online={model.online} contact={contact} title={title} toast={toast} edit={editButton}>
				{content}
			</ManagedSection>
		);
	return isChangeViewer;
}

Section.propTypes = {
	name: PropTypes.string,
};
