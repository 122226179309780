import React from "react";
import PropTypes from "prop-types";
import HealthIndicator from "./HealthIndicator";
import { useMeasureUnits } from "@aetonix/hooks";

import { useResources } from "@aetonix/hooks";

export default function WeightMetrics(props) {
	const {
		localize,
	} = useResources();

	var model = props.model;
	var myhealth = model.myhealth;
	var weight = myhealth.weight;

	var steps = weight.cumulative;
	var reverse_steps = weight.reverse; // needed for chart
	var today = null;
	var section = model.page;


	const { weightUnits } = useMeasureUnits();

	var pagetitle = localize("weight.status");
	var textformat = "{" + weightUnits + "}";

	// Sets up the format to the 'today' button
	if (steps.length) {
		var graphdata = adjust_time(reverse_steps, null, weightUnits).slice(-10); // for chart
		today = steps[0].history[weightUnits];
	}

	return (
		<HealthIndicator model={model} section={section} textformat={textformat} units={weightUnits} pagetitle={pagetitle}  latestReading={today} reverseEntries={graphdata} activeModel={weight} xaxis={"x"} yaxis={"y"} managed />
	);
}

function adjust_time(data, forBoxGraphic, weightUnits) {
	var adjusted = data.map(function (item) {
		var time = time_label(item.updated_at, forBoxGraphic);
		var value = (typeof item.history[weightUnits] === "string")
			? parseFloat(item.history[weightUnits])
			: item.history[weightUnits];
		return {
			x: time,
			y: value,
		};
	});
	return adjusted;
}

function time_label(data, forBoxGraphic) {
	var parsed = time_parse(data, forBoxGraphic);

	return (parsed.month + "-" + parsed.day + "  " + parsed.time);
}

function time_parse(data, forBoxGraphic) {
	var date = new Date(data);
	var month = date.getMonth() + 1;
	var day = date.getDate();
	day = two_digit(day);
	month = two_digit(month);
	var hours = two_digit(date.getHours());
	var minutes = two_digit(date.getMinutes());
	var seconds = forBoxGraphic ? "" : ":" + two_digit(date.getSeconds());
	var time = hours + ":" + minutes + seconds;

	return {
		month: month,
		day: day,
		time: time,
	};
}

function two_digit(data) {
	var item = (data < 10)
		? "0" + data
		: data;
	return item;
}
