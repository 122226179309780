import React from "react";
import {
	View,
	StyleSheet,
	TouchableOpacity,
} from "react-native";

import StyledText from "ae-base/Base/StyledText";
import StyledIcon from "ae-base/Base/StyledIcon";

import PowerButton from "./PowerButton";

import Style from "ae-style";

import { useResources } from "@aetonix/hooks";

var styles = StyleSheet.create({
	urgencyButton: {
		width: Style.imageSize.verysmall,
		height: Style.imageSize.verysmall,
		borderWidth: 2,
		margin: Style.layout.marginSmall,
		borderColor: "#AE1010",
		backgroundColor: "#FFFFFF",
		alignItems: "center",
		justifyContent: "center",
	},
	urgencyText: {
		color: "#AE1010",
		position: "absolute",
		bottom: 0,
		left: 0,
		right: 0,
		textAlign: "center",
	},
	urgencyIcon: {
		color: "#AE1010",
	},
	sideBar: {
		justifyContent: "space-between",
	},
});

export default function Sidebar(props) {
	const {
		localize,
		dispatch,
	} = useResources();
	var urgency = props.urgency;
	var settings = props.settings;
	var buttonFunction = settings.power_button;
	var poweroffButtonText = localize("atouchawayheader.poweroff");
	var poweroffButtonAction = dispatch("app:trigger:power:legacy");
	var hideButtonText = localize("atouchawayheader.hidescreen");
	var hideButtonAction = dispatch("screenhider:trigger:hide");

	if(buttonFunction === "none")
		var renderPowerButton = null;
	else if(buttonFunction === "hide")
		renderPowerButton = (
			<PowerButton buttonText={hideButtonText} buttonAction={hideButtonAction} />
		);
	else if(buttonFunction === "standby")
		renderPowerButton = (
			<PowerButton buttonText={poweroffButtonText} buttonAction={poweroffButtonAction} />
		);


	return (
		<View style={styles.sideBar}>
			<UrgencyButton urgency={urgency} />
			{renderPowerButton}
		</View>
	);
}

function UrgencyButton(props) {
	const {
		localize,
		dispatch,
	} = useResources();

	var urgency = props.urgency;
	var emergency = localize("atouchawaycontact.emergency");
	var emergencyPress = urgency.active ? (
		dispatch("urgencycalls:trigger:cancel:start")
	) : (
		dispatch("urgencycalls:trigger:call", {
			reason: "screen",
		}));

	return (
		<TouchableOpacity style={styles.urgencyButton} onPress={emergencyPress}>
			<StyledIcon style={styles.urgencyIcon} name="plus" hugeIcon />
			<StyledText bold emphasized style={styles.urgencyText}>{emergency}</StyledText>
		</TouchableOpacity>
	);
}
