import React from "react";
import {
	StyleSheet,
	View,
} from "react-native";

import StyledText from "ae-base/Base/StyledText";
import StyledIcon from "ae-base/Base/StyledIcon";
import BlockView from "ae-base/Base/BlockView";

import Style from "ae-style";
import { useResources } from "@aetonix/hooks";

var styles = StyleSheet.create({
	page: {
		flexDirection: "row",
		padding: Style.layout.paddingSmall * 2,
		justifyContent: "center",
		alignItems: "center",
	},
});

export default function SimplifiedFooter(props){
	const { localize } = useResources();
	var model = props.model;
	var personal = model.personal;

	var testModeEnabled = !!personal.testModeEnabled;

	var contents = [];
	if (testModeEnabled)
		contents.push((
			<BlockView key={"testmode"}>
				<StyledText bold smallHeading color={Style.colors.orange}>
					<StyledIcon color={Style.colors.orange} name={"fa-bug"} />
					{" "}
					{localize("testmode.title")}
				</StyledText>
			</BlockView>
		));

	return (
		<View style={styles.page}>
			{contents}
		</View>
	);
}