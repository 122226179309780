/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React, { Component } from "react";
import { StyleSheet, Dimensions, View } from "react-native";
import SquareGrid from "react-native-square-grid";

import StyledIconButton from "ae-base/Base/StyledIconButton";
import NotificationIcon from "ae-base/Base/NotificationIcon";

import Contact from "./Contact";
import CallGroup from "./CallGroup";
import ATouchAwaySidebar from "./ATouchAwaySidebar";
import AtaContainer from "./AtaContainer";
import Modal from "../Layout/Modal";
import Form from "ae-forms";

import MessageViewer from "./Messaging/MessageViewer";

import MainScroll from "../Layout/MainScroll";
import ATouchAwayInfoBar from "./ATouchAwayInfoBar";

import BraceletIcon from "./BraceletIconNew";
import StyledText from "ae-base/Base/StyledText";
import StyledIcon from "ae-base/Base/StyledIcon";
import Style from "ae-style";
import ConfirmationModal from "ae-base/Base/ConfirmationModal";
import MeetingAlerts from "../../Calling/components/Alert/MeetingAlerts";
import { useResources } from "@aetonix/hooks";

var styles = StyleSheet.create({
	main: {
		height: "100%",
		flexDirection: "row",
		overflow: "hidden",
	},
	contactList: {
		flex: 1,
	},
	item: {
		flex: 1,
		alignSelf: "stretch",
		padding: 16,
	},
	content: {
		flex: 1,
		alignItems: "center",
		justifyContent: "center",
	},
	paddingLeft: {
		alignSelf: "center",
		paddingLeft: 16,
	},
	paddingRight: {
		alignSelf: "center",
		paddingRight: 16,
	},
	survey_outer: {
		padding: 24,
		backgroundColor: "white",
		borderRadius: 24,
	},
	badge: {
		right: -8,
		top: -8,
		width: 30,
		height: 30,
		borderRadius: 20,
	},
	flex1: {
		flex: 1,
	},
});

const MIN_CONTACT_SIZE = 200;
const MIN_DATE_WIDTH = 1000;
const MAX_CONTACT_COLUMNS = 4;
const MIN_CONTACT_COLUMNS = 2;

const OngoingMeetingBanners = ({ banners, onClear }) => {

	return (
		<MeetingAlerts banners={banners} onClear={onClear}/>
	);
};

export default (props) => {
	const { localize, dispatch } = useResources();
	return (
		<ATouchAwayHome localize={localize} dispatch={dispatch} {...props} />
	);
};

class ATouchAwayHome extends Component {
	constructor(props) {
		super(props);

		this._renderContact = renderContact.bind(this);
		this._renderContactComplex = renderContactComplex.bind(this);
		this._getCoreForms = get_coreform.bind(this);
	}

	state = {
		sidebarWidth: 0,
		showConfirmationModal: false,
	};

	onSidebarLayoutChange = (event) => {
		let width = event.nativeEvent.layout.width;
		this.setState({
			sidebarWidth: width,
		});
	};

	confirmCall(name, dispatchCall, isUrgencyCall) {
		this.setState({
			showConfirmationModal: true,
			isUrgencyCall,
			callConfirmArgs: {
				name,
				dispatchCall,
			},
		});
	}

	onConfirmCall() {
		this.setState({
			showConfirmationModal: false,
			isUrgencyCall: false,
		});
		this.state.callConfirmArgs?.dispatchCall();
	}

	onCancelCall() {
		this.setState({
			showConfirmationModal: false,
			isUrgencyCall: false,
			callConfirmArgs: null,
		});
	}

	render() {
		var props = this.props;
		var dispatch = props.dispatch;
		var localize = props.localize;
		var model = props.model;
		var missed = model.missed;
		var contacts = model.contacts || {};
		var personal = model.personal;
		var column = contacts.columns || 1;
		var row = contacts.rows || 1;
		var list = contacts.list || [];
		var hasLess = contacts.hasLess;
		var hasMore = contacts.hasMore;
		var disabledLeft = true;
		var disabledRight = true;
		var rows = contacts.rows;
		var settings = model.settings;
		var messaging = model.messaging;
		var paused = model.pausescreen;
		var resources = model.resources;
		var status = model.status || {};
		var forms = model.forms;
		var allowedForms = settings.forms_sections || {};
		var screenWidth = Dimensions.get("window").width;
		var screenHeight = Dimensions.get("window").height;

		var testModeEnabled = personal.testModeEnabled;

		var surveys = model.surveys;
		var pending_survey = surveys.pending;
		var active_survey = pending_survey ? surveys.surveys[0] : null;
		var coreforms = model.coreforms;

		if (hasMore || hasLess) {
			if (hasLess) disabledLeft = false;
			if (!hasLess) disabledLeft = true;
			if (hasMore) disabledRight = false;
			if (!hasMore) disabledRight = true;
		}

		var onLeft = dispatch("contactspage:trigger:previous");
		var onRight = dispatch("contactspage:trigger:next");

		const onClearMeetingBanners = (from, type) => {
			dispatch("banner:trigger:clear", { from, type })();
		};

		var renderContactFunction = this._renderContact;
		if (settings.messaging !== "basic") renderContactFunction = this._renderContactComplex;

		var renderInfoBar = <ATouchAwayInfoBar model={model} sidebarWidth={this.state.sidebarWidth} />;

		var contactIconSize;
		if (column === "scroll" || row === "scroll") {
			column = Math.min(Math.floor((screenWidth - this.state.sidebarWidth) / MIN_CONTACT_SIZE), MAX_CONTACT_COLUMNS);
			column = Math.max(column, MIN_CONTACT_COLUMNS);
			contactIconSize = (screenWidth - this.state.sidebarWidth) / column;
		} else
			contactIconSize = Math.min(screenHeight / row, (screenWidth - this.state.sidebarWidth) / column);


		var footer = model.footer;
		var temperatureAndDate = [
			<View style={{ justifyContent: "center" }}>
				<StyledText bold contrast emphasized maxSize="smallHeading">
					{screenWidth < MIN_DATE_WIDTH ? null : footer.temperature}
				</StyledText>
			</View>,
			<View style={{ justifyContent: "center" }}>
				<StyledText bold contrast emphasized maxSize="smallHeading">
					{screenWidth < MIN_DATE_WIDTH ? footer.shortDate : footer.date}
				</StyledText>
			</View>];
		var testModeBanner = testModeEnabled ? (<View style={{ justifyContent: "center", flexGrow: 1, alignItems: "center"  }}>
			<StyledText bold emphasized maxSize="smallHeading" color={Style.colors.orange}>
				<StyledIcon color={Style.colors.orange} name={"fa-bug"} />
				{" "}
				{localize("testmode.title")}
			</StyledText>
		</View>) : null;
		var footerRight = testModeBanner || temperatureAndDate;
		var renderBraceletBar = (
			<View style={{ justifyContent: "space-between", flexDirection: "row", paddingRight: 12 }}>
				<BraceletIcon bracelet={model.bracelet} icon={"aeto-watch"} />
				{footerRight}
			</View>
		);

		var renderContactList = paused ? null : rows === "scroll" ? (
			<View style={{ flex: 1 }}>
				<MainScroll atouchaway style={styles.contactList}>
					<SquareGrid
						key={settings.messaging}
						columns={column}
						rows={null}
						items={list}
						renderItem={(item) => renderContactFunction(contactIconSize, item)}
					/>
				</MainScroll>
			</View>
		) : (
			<View style={{ flex: 1 }}>
				<View style={{ flexDirection: "row", flex: 1 }}>
					<View key={settings.messaging + "container"} style={styles.paddingLeft}>
						{disabledLeft ? null : (<StyledIconButton
							key={settings.messaging + "navleft"}
							icon={"navigateleft"}
							onPress={onLeft}
							contrast
							hugeHeading
							disabled={disabledLeft}
						/>)}

					</View>
					<View key={settings.messaging + "contact"} style={styles.contactList}>
						<SquareGrid
							key={settings.messaging}
							columns={column}
							rows={row}
							items={list}
							renderItem={(item) => renderContactFunction(contactIconSize, item)}
						/>
					</View>
					<View key={settings.messaging + "navright"} style={styles.paddingRight}>
						{disabledRight ? null : (<StyledIconButton icon={"navigateright"} onPress={onRight} contrast hugeHeading disabled={disabledRight} />)}
					</View>
				</View>
			</View>
		);

		var renderMessageViewer =
			settings.messaging === "intermediate" && messaging.unreadList && messaging.unreadList.length ? (
				<MessageViewer viewing={messaging} settings={settings} missed={model.missed} scroll />
			) : null;

		var survey_schema = active_survey ? this._getCoreForms(coreforms, active_survey) : null;

		var outerStyles = [
			styles.survey_outer,
			{
				maxWidth: (Dimensions.get("window").width / 10) * 8,
			},
		];

		var survey_respond = dispatch("surveyprocessing:trigger:respond");
		var submit_text = localize("callsurvey.send");

		var defaultSurveyData = {
			Q1: "NA",
			Q2: "NA",
		};
		var textStyle = {
			fontSize: 30,
		};
		var surveyForm = survey_schema ? (
			<View style={outerStyles}>
				<Form
					key={active_survey._id}
					schema={survey_schema}
					autoSubmit={false}
					submitText={submit_text}
					textStyle={textStyle}
					onSubmit={survey_respond}
					defaultData={defaultSurveyData}
					isATouchAway
				/>
			</View>
		) : null;

		var formsViewable = checkPermissions(forms, allowedForms);

		return (
			<AtaContainer>
				<OngoingMeetingBanners
					banners={model?.banners}
					onClear={onClearMeetingBanners}
				/>
				<View style={{ height: "100%" }}>
					{renderInfoBar}
					<View style={{ flex: 1, flexDirection: "row" }}>
						<View onLayout={this.onSidebarLayoutChange}>
							<ATouchAwaySidebar
								formsViewable={formsViewable}
								workflowActions={model.pendingactions}
								missed={missed}
								settings={settings}
								page={"contacts"}
								resources={resources}
								status={status}
							/>
						</View>
						<View style={styles.flex1}>{renderContactList}</View>
						<ConfirmationModal
							open={this.state.showConfirmationModal}
							title={
								this.state.isUrgencyCall
									? localize("calling.urgencyCall")
									: localize("calling.confirmTitle", {
										name: this.state.callConfirmArgs?.name,
									})
							}
							titleIcon="warning"
							confirmText={localize("calling.confirmCall")}
							cancelText={localize("calling.cancelText")}
							onConfirm={this.onConfirmCall.bind(this)}
							onCancel={this.onCancelCall.bind(this)}
						/>
					</View>
					{renderBraceletBar}
				</View>
				<Modal open={pending_survey}>{surveyForm}</Modal>
				{renderMessageViewer}
			</AtaContainer>
		);
	}
}

function renderContact(allocatedSpace, item) {
	// this function is used by squareGrid to render the contacts.
	// Allocated space represents the amount of space allocated by squareGrid.
	var members = item.callgroupsMembers;
	var isCallGroup = item.name;
	var missed = isCallGroup ? false : item.missed[item._id];
	var renderMissedMessage =
		allocatedSpace > MIN_CONTACT_SIZE && missed && missed.atouchaway ? <NotificationIcon style={styles.badge} title={missed.atouchaway} /> : null;

	var textSize =
		allocatedSpace < 200 ? "tiny" :
			allocatedSpace < 300 ? "small" :
				allocatedSpace < 400 ? "emphasized" :
					"large";
	const isConfirming = this.props.model?.groupsettings?.callConfirmation ?? false;

	var renderContactItem = (
		<Contact
			key={item}
			contact={item}
			status={item.isAvailable}
			message={item.hasMessage}
			messaging={item.messaging}
			disableMessagePreview={allocatedSpace < MIN_CONTACT_SIZE}
			disableName={allocatedSpace < MIN_CONTACT_SIZE / 2}
			textSize={textSize}
			isConfirming={isConfirming}
			confirmCall={this.confirmCall.bind(this)}
		/>
	);

	if (isCallGroup)
		renderContactItem = (
			<CallGroup
				key={item.name}
				group={item}
				members={members}
				textSize={textSize}
				disableName={allocatedSpace < MIN_CONTACT_SIZE / 2}
				isConfirming={isConfirming}
				confirmCall={this.confirmCall.bind(this)}
			/>
		);


	return (
		<View style={styles.item}>
			<View style={styles.content}>
				{renderContactItem}
				{renderMissedMessage}
			</View>
		</View>
	);
}

function renderContactComplex(allocatedSpace, item) {
	var members = item.callgroupsMembers;
	var isCallGroup = item.name;
	var missed = isCallGroup ? false : item.missed[item._id];
	var renderMissedMessage =
		allocatedSpace > MIN_CONTACT_SIZE && missed && missed.atouchaway ?
			<NotificationIcon style={styles.badge} title={missed.atouchaway} />
			: null;

	var textSize =
		allocatedSpace < 200 ? "tiny" :
			allocatedSpace < 300 ? "small" :
				allocatedSpace < 400 ? "emphasized" :
					"large";
	const isConfirming = this.props.model?.groupsettings?.callConfirmation ?? false;

	var renderContactItem = (
		<Contact
			key={item}
			complex
			contact={item}
			status={item.isAvailable}
			message={item.hasMessage}
			messaging={item.messaging}
			textSize={textSize}
			disableMessagePreview={allocatedSpace < MIN_CONTACT_SIZE}
			disableName={allocatedSpace < MIN_CONTACT_SIZE / 2}
			isConfirming={isConfirming}
			confirmCall={this.confirmCall.bind(this)}
		/>
	);

	if (isCallGroup)
		renderContactItem = (
			<CallGroup
				key={item.name}
				group={item}
				members={members}
				textSize={textSize}
				disableName={allocatedSpace < MIN_CONTACT_SIZE / 2}
				isConfirming={isConfirming}
				confirmCall={this.confirmCall.bind(this)}
			/>
		);


	return (
		<View style={styles.item}>
			<View style={styles.content}>
				{renderContactItem}
				{renderMissedMessage}
			</View>
		</View>
	);
}

function get_coreform(coreforms, surveys) {
	var schema_id = surveys.schema;
	var coreform = Object.keys(coreforms).filter(function (item) {
		// schema_id may be a name or number
		return coreforms[item]._id === schema_id || item === schema_id;
	});
	if (coreform.length) return coreforms[coreform[0]].schema;
	return;
}

function checkPermissions(list, permissions) {
	return Object.keys(list).reduce(function (acc, element) {
		if (permissions[element]) acc[element] = list[element];
		return acc;
	}, {});
}
