import React, { forwardRef, useCallback } from 'react';
import { StyleSheet, View, Pressable } from 'react-native';
import { createStyled } from '@emotion/primitives-core';
import { variant } from 'styled-system';

const styled = createStyled(StyleSheet);

const Overlay = styled(View)({
    position: 'absolute',
    left: 0,
    bottom: 0,
    top: 0,
    right: 0,
    zIndex: 4,
    width: '100%',
	height: '100%',
    overflow: 'hidden',
	flexDirection: 'column',
	justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor: 'rgba(10, 10, 10, 0.6)',
});

const Container = styled(View)({
    position: 'relative',
    maxWidth: 500,
    borderTopWidth: 2,
    borderTopColor: '#e8e8e8',
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    borderBottomWidth: 0,
    height: "auto",
    backgroundColor: 'white',
    
    // Shadow
    shadowColor: "#000",
	shadowOffset: { width: 0, height: 12 },
	shadowOpacity: 1,
	shadowRadius: 16.00,
}, variant({
    prop: 'orientation',
    variants: {
        vertical: {
            width: "100%",
            maxHeight: "80%",
        },
        horizontal: {
            maxHeight: "90%",
            maxWidth: 900, /* @reest Temporary for demo */
            minWidth: 400,
            width: '70%',
        },
    }
}));

const Content = styled(View)({
    flex: 0,
	flexShrink: 1,
	flexGrow: 1,
});

export default forwardRef((props, ref) => {
    const { orientation, children, hasClickedOutside, onClose } = props;

    /* const onClickOutside = useCallback(event => {
        if (hasClickedOutside(event)) {
            onClose && onClose();
        }
    }, [hasClickedOutside, onClose]) */

    return (
        <Overlay /* onPress={onClickOutside} */>
            <Container orientation={orientation} ref={ref}>
                <Content>
                    {children}
                </Content>
            </Container>
        </Overlay>
    );
});