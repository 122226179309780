import React from "react";
import {
	View,
} from "react-native";

import Form from "ae-forms";

import HealthSchema from "../../../../../schemas/aTouchAwayHealth";
import HealthSchemaWithCustomIndicators from "../../../../../schemas/aTouchAwayHealthWithCustomIndicators";

import SettingSection from "../SettingSection";

import { useResources } from "@aetonix/hooks";

export default function Health(props) {
	const {
		localize,
		dispatch,
	} = useResources();
	var display = props.display;
	var model = props.model;
	var toggle = props.onToggle;

	var atouchaway = model.manage.atouchaway || {};
	const managedCustomIndicatorsList = model.manage.managedcustomindicatorslist || [];

	var title = localize("atasettings.health") || "Health";

	var renderBody = display ? (
		<Form
			schema={managedCustomIndicatorsList.length < 1 ? HealthSchema : HealthSchemaWithCustomIndicators}
			defaultData={atouchaway}
			onSubmit={dispatch("managedatouchaway:trigger:update")}
		/>
	) : null;

	return (
		<View>
			<SettingSection section="health" title={title} display={display} onToggle={toggle} />
			{renderBody}
		</View>
	);
}
