
/* © 2021 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, September 17, 2021
 * For information or permission request, email info@aetonixsystems.com
 */

export default {
	title: "AtouchawaySettings",
	description: "schemas_atouchaway_settings_title",
	type: "object",
	displayDependencies: {
		myhealth_sections: {
			required: ["myhealth"],
			properties: {
				myhealth: {
					enum: [true]
				}
			}
		}
	},
	properties: {
		postbloodoxygen: {
			order: 8,
			description: "atouchawaysettings.postbloodoxygen",
			format: "switch",
			inputType: "switch",
			type: "boolean"
		},
		myhealth: {
			order: 9,
			description: "atouchawaysettings.myhealth",
			format: "switch",
			type: "boolean"
		},
		myhealth_sections: {
			order: 10,
			type: "object",
			displayDependencies: {
				activity_readdevice: {
					required: ["activity"],
					properties: {
						activity: {
							enum: [true]
						}
					}
				},
				bloodsugar_readdevice: {
					required: ["bloodsugar"],
					properties: {
						bloodsugar: {
							enum: [true]
						}
					}
				},
				bloodsugar_manualentry: {
					required: ["bloodsugar"],
					properties: {
						bloodsugar: {
							enum: [true]
						}
					}
				},
				weight_readdevice: {
					required: ["weight"],
					properties: {
						weight: {
							enum: [true]
						}
					}
				},
				weight_manualentry: {
					required: ["weight"],
					properties: {
						weight: {
							enum: [true]
						}
					}
				},
				bloodpressure_readdevice: {
					required: ["bloodpressure"],
					properties: {
						bloodpressure: {
							enum: [true]
						}
					}
				},
				bloodpressure_manualentry: {
					required: ["bloodpressure"],
					properties: {
						bloodpressure: {
							enum: [true]
						}
					}
				},
				bloodoxygen_readdevice: {
					required: ["bloodoxygen"],
					properties: {
						bloodoxygen: {
							enum: [true]
						}
					}
				},
				bloodoxygen_manualentry: {
					required: ["bloodoxygen"],
					properties: {
						bloodoxygen: {
							enum: [true]
						}
					}
				},
				bodytemperature_readdevice: {
					required: ["bodytemperature"],
					properties: {
						bodytemperature: {
							enum: [true]
						}
					}
				},
				bodytemperature_manualentry: {
					required: ["bodytemperature"],
					properties: {
						bodytemperature: {
							enum: [true]
						}
					}
				},
				customindicators_manualentry: {
					required: ["customindicators"],
					properties: {
						customindicators: {
							enum: [true]
						}
					}
				}
			},
			properties: {
				overviewTitle: {
					order: 0,
					type: "string",
					inputType: "output",
					displayType: "title",
					description: "myhealth.overview"
				},
				overview: {
					order: 10,
					type: "boolean",
					inputType: "switch",
					description: "atasetting.overview",
					default: true
				},
				activityTitle: {
					order: 20,
					type: "string",
					inputType: "output",
					displayType: "title",
					description: "myhealth.activity"
				},
				activity: {
					order: 30,
					type: "boolean",
					inputType: "switch",
					description: "atasetting.activity",
					default: true
				},
				activity_readdevice: {
					order: 40,
					type: "boolean",
					inputType: "checkbox",
					description: "indicator_setting.deviceread",
					default: false
				},
				activity_manualentry: {
					order: 41,
					type: "boolean",
					inputType: "checkbox",
					description: "indicator_setting.manualentry",
					default: false
				},
				bloodsugarTitle: {
					order: 50,
					type: "string",
					inputType: "output",
					displayType: "title",
					description: "myhealth.bloodsugar"
				},
				bloodsugar: {
					order: 60,
					type: "boolean",
					inputType: "switch",
					description: "atasetting.bloodsugar",
					default: true
				},
				bloodsugar_readdevice: {
					order: 70,
					type: "boolean",
					inputType: "checkbox",
					description: "indicator_setting.deviceread",
					default: false
				},
				bloodsugar_manualentry: {
					order: 80,
					type: "boolean",
					inputType: "checkbox",
					description: "indicator_setting.manualentry",
					default: false
				},
				weightTitle: {
					order: 90,
					type: "string",
					inputType: "output",
					displayType: "title",
					description: "myhealth.weight"
				},
				weight: {
					order: 100,
					type: "boolean",
					inputType: "switch",
					description: "atasetting.weight",
					default: true
				},
				weight_readdevice: {
					order: 110,
					type: "boolean",
					inputType: "checkbox",
					description: "indicator_setting.deviceread",
					default: false
				},
				weight_manualentry: {
					order: 120,
					type: "boolean",
					inputType: "checkbox",
					description: "indicator_setting.manualentry",
					default: false
				},
				bloodpressureTitle: {
					order: 130,
					type: "string",
					inputType: "output",
					displayType: "title",
					description: "myhealth.bloodpressure"
				},
				bloodpressure: {
					order: 140,
					type: "boolean",
					inputType: "switch",
					description: "atasetting.bloodpressure",
					default: true
				},
				bloodpressure_readdevice: {
					order: 150,
					type: "boolean",
					inputType: "checkbox",
					description: "indicator_setting.deviceread",
					default: false
				},
				bloodpressure_manualentry: {
					order: 160,
					type: "boolean",
					inputType: "checkbox",
					description: "indicator_setting.manualentry",
					default: false
				},
				bloodoxygenTitle: {
					order: 170,
					type: "string",
					inputType: "output",
					displayType: "title",
					description: "myhealth.oxygen"
				},
				bloodoxygen: {
					order: 180,
					type: "boolean",
					inputType: "switch",
					description: "atasetting.bloodoxygen",
					default: true
				},
				bloodoxygen_readdevice: {
					order: 190,
					type: "boolean",
					inputType: "checkbox",
					description: "indicator_setting.deviceread",
					default: false
				},
				bloodoxygen_manualentry: {
					order: 200,
					type: "boolean",
					inputType: "checkbox",
					description: "indicator_setting.manualentry",
					default: false
				},
				bodytemperatureTitle: {
					order: 210,
					type: "string",
					inputType: "output",
					displayType: "title",
					description: "myhealth.bodytemp"
				},
				bodytemperature: {
					order: 220,
					type: "boolean",
					inputType: "switch",
					description: "atasetting.bodytemperature",
					default: true
				},
				bodytemperature_readdevice: {
					order: 230,
					type: "boolean",
					inputType: "checkbox",
					description: "indicator_setting.deviceread",
					default: false
				},
				bodytemperature_manualentry: {
					order: 240,
					type: "boolean",
					inputType: "checkbox",
					description: "indicator_setting.manualentry",
					default: false
				},
				customindicatorsTitle: {
					order: 250,
					type: "string",
					inputType: "output",
					displayType: "title",
					description: "myhealth.customIndicators"
				},
				customindicators: {
					order: 260,
					type: "boolean",
					inputType: "switch",
					description: "atasetting.customIndicators",
					default: true
				},
				customindicators_manualentry: {
					order: 280,
					type: "boolean",
					inputType: "checkbox",
					description: "indicator_setting.manualentry",
					default: false
				}
			}
		}
	}
};