import PropTypes from "prop-types";

/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React, {Fragment, useContext, useMemo} from "react";
import {
	View,
	Dimensions,
	Platform,
} from "react-native";
import ResponsiveStylesheet from "react-native-responsive-stylesheet";
import Style from "ae-style";
import Breakpoints from "../../../Breakpoints";

import CircleButton from "ae-base/Base/CircleButton";
import NotificationIcon from "ae-base/Base/NotificationIcon";

import getVersion, {NewCallingVersion} from "../../../utils/GetVersion";
import MeetingContext from "../../../Calling/context/MeetingContext";
import useEnabledFeatures from "../../../hooks/useEnabledFeatures/useEnabledFeatures";

import { useResources } from "@aetonix/hooks";

var styles = ResponsiveStylesheet.createSized("min-direction", {
	0: {
		backbutton: {
			marginTop: 10,
			marginLeft: 20,
		},
		badge: {
			position: "absolute",
			right: 4,
			top: 4,
		},
		avatar: {
			marginTop: 50,
		},
		center: {
			alignItems: "center",
			justifyContent: "center",
			marginTop: 10,
			marginBottom: 10,
		},
		text: {
			marginTop: 20,
			marginBottom: 70,
		},
		offset: {
			position: "relative",
			bottom: 40,
			marginHorizontal: 30,
		},
		spacing: {
			marginHorizontal: 20,
		},
		horizontal: {
			flexDirection: "row",
		},
		controls: {
			marginVertical: 18,
		},
	},
	512: {
		backbutton: {
			marginTop: 0,
			marginLeft: 0,
		},
		badge: {
			position: "absolute",
			right: Style.layout.badge,
			top: Style.layout.badge - 4,
		},
		controls: {
			marginVertical: 18,
		},
		center: {
			alignItems: "center",
			justifyContent: "center",
		},
		offset: {
			position: "relative",
			bottom: 40,
			marginHorizontal: 16,
		},
	},
});

ControlButtons.propTypes = {
	contact: PropTypes.object,
	missed: PropTypes.object,
};

export default function ControlButtons(props) {
	const {
		localize,
		dispatch,
		config
	} = useResources();

	var controlText;
	var controlSize;
	var sizeIcon;

	var missed = props.missed;
	var contact = props.contact;
	var textBoxPadding = props.textBoxPadding || false;
	var id = contact._id;
	var ownId = config.id;
	var patientId = contact.patientId;

	var overrideMessage = props.allowMessage;
	var settings = props.settings;

	var offset = styles.offset;
	var badge = styles.badge;
	var buttonDisabled = false;
	var isAvailable = contact.isAvailable;
	var renderMissedCall;
	var renderMissedVoiceCall;
	var renderMissedMessage;

	var isSelf = id === ownId;

	const { isCallingEnabled } = useEnabledFeatures();

	var callData = {
		who: id,
		reasion: "screen",
	};

	if(patientId)
		callData.metadata = {
			patientId: patientId,
		};


	var voiceOnlyCallData = {
		...callData,
		voiceOnly: true,
	};

	var callContact = isAvailable ? dispatch("calling:trigger:call", callData) : null;

	var voiceCallContact = isAvailable ? dispatch("calling:trigger:call", voiceOnlyCallData) : null;

	var openContactMessages = dispatch("messages:trigger:open", {
		from: id,
	});

	if (missed) {
		renderMissedCall = missed[id] && missed[id].calls
			? <NotificationIcon style={badge} title={missed[id].calls} large />
			: null;

		renderMissedVoiceCall = missed[id] && missed[id].voiceCalls
			? <NotificationIcon style={badge} title={missed[id].voiceCalls} large />
			: null;

		renderMissedMessage = missed[id] && missed[id].messages
			? <NotificationIcon style={badge} title={missed[id].messages} large />
			: null;
	}

	if (Breakpoints.smallerThan("mobileSmall")) {
		sizeIcon = "smallHeading";
		controlSize = Style.layout.controlButtons;
		controlText = 12;
	}

	if (Breakpoints.smallerThan("smallTablet")) {
		sizeIcon = "large";
		controlSize = Style.layout.controlButtons;
		controlText = 12;
	}

	var videoTitle = localize("contactprofileheader.video");
	var voiceTitle = localize("contactprofileheader.voice");
	var messageTitle = localize("contactprofileheader.messaging");

	var messageVisibility;
	// if _not_ a contact, do not show 'message' button
	var isContact = contact.isContact;
	if (!isContact && !overrideMessage) {
		messageVisibility = {
			opacity: 0,
		};
		buttonDisabled = true;
	}

	const textBoxStyling = useMemo(() => {
		const windowWidth = Dimensions.get("window").width;
		let style = {
			maxWidth: Platform.OS === "web" ? "90px" : "90%",
		};

		if (windowWidth < 500)
			style = {
				...style,
				fontSize: 10,
			};


		if (textBoxPadding)
			style = { ...style, ...textBoxPadding };


		return style;
	}, [textBoxPadding]);

	var disableVoice = settings.voice || !isAvailable || isSelf;
	var disableVideo = settings.video || !isAvailable || isSelf;
	var disableMessage = settings.message || buttonDisabled || isSelf;

	const version = getVersion(contact.versionInfo || {});
	const meetingContext = useContext(MeetingContext);

	let buttons = (
		<Fragment>
			<View style={offset}>
				{renderMissedCall}
				<CircleButton title={videoTitle}
					id={"videoCall"}
					key={isAvailable}
					disabled={disableVideo}
					icon="aeto-video_call"
					iconSize={sizeIcon}
					textSize={controlText}
					onPress={callContact}
					diameter={controlSize}
					textBoxStyling={textBoxStyling} />
			</View>
			<View style={messageVisibility}>
				{renderMissedMessage}
				<CircleButton disabled={disableMessage}
					id={"messaging"}
					title={messageTitle}
					icon="aeto-messaging"
					iconSize={sizeIcon}
					textSize={controlText}
					onPress={openContactMessages}
					textBoxStyling={textBoxStyling}
					diameter={controlSize} />
			</View>
			<View style={offset}>
				{renderMissedVoiceCall}
				<CircleButton title={voiceTitle}
					id={"voiceCall"}
					key={isAvailable}
					disabled={disableVoice}
					icon="aeto-phone"
					iconSize={sizeIcon}
					textSize={controlText}
					onPress={voiceCallContact}
					diameter={controlSize}
					textBoxStyling={textBoxStyling} />
			</View>
		</Fragment>
	);

	const isNewCallingSupported = version && version.majorVersion >= NewCallingVersion;

	if (isNewCallingSupported && isCallingEnabled) {
		videoTitle = localize("workflow.call");
		callContact = () => meetingContext.onCreateMeeting(contact._id, `${config.lname}, ${config.fname}`);
		buttons = (
			<Fragment>
				<View style={styles.spacing} >
					<CircleButton title={videoTitle}
						id={"videoCall"}
						key={isAvailable}
						disabled={disableVideo}
						icon="aeto-video_call"
						iconSize={sizeIcon}
						textSize={controlText}
						onPress={callContact}
						diameter={controlSize}
						textBoxStyling={textBoxStyling} />
				</View>
				{!buttonDisabled && <View style={styles.spacing}>
					{renderMissedMessage}
					<CircleButton disabled={disableMessage}
						id={"messaging"}
						title={messageTitle}
						icon="aeto-messaging"
						iconSize={sizeIcon}
						textSize={controlText}
						onPress={openContactMessages}
						textBoxStyling={textBoxStyling}
						diameter={controlSize} />
				</View>}
			</Fragment>
		);
	}

	return (
		<View style={[styles.center, styles.horizontal, styles.controls]}>
			{buttons}
		</View>
	);
}
