import PropTypes from "prop-types";
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React, {
	PureComponent,
} from "react";

import Contact from "./Contact";
import StyledButton from "ae-base/Base/StyledButton";
import { Platform } from "react-native";

export default class ToRequest extends PureComponent {
	render() {
		var props = this.props;
		var screen = props.screen;

		var who = props.who;
		var id = who._id;  // _id of the person
		var action = props.action; // action to be taken
		var icon = props.icon;  // icon to render for action button
		var message = props.message; // message to show user

		const { width: screenWidth } = screen;
		const maxButtonWidthPercentage = 0.35;
		const wrapperViewStyle = {
			maxWidth: Platform.OS !== "web" ? screenWidth * maxButtonWidthPercentage : null,
		};

		return (
			<Contact key={id} contact={who} onPress={action} screen={screen} >
				<StyledButton clear title={message} wrapperViewStyle={wrapperViewStyle} icon={icon} onPress={action} />
			</Contact>
		);
	}
}
