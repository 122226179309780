import React from "react";
import {
	StyleSheet,
} from "react-native";

import TimeoutLogin from "../../schemas/TimeoutLogin";
import Modal from "../Layout/Modal";
import Card from "../Layout/Card";

import Form from "ae-forms";
import Heading from "ae-base/Base/Heading";

import StyledText from "ae-base/Base/StyledText";
import StyledButton from "ae-base/Base/StyledButton";

import { useResources } from "@aetonix/hooks";

const styles = StyleSheet.create({
	text: {
		marginVertical: 5,
	},
	button: {
		paddingHorizontal: 0,
		paddingVertical: 4,
	},
});

export default function Relogin(props){
	const { localize, dispatch } = useResources();
	const accountSwitch = props.accountSwitch || {};

	const confirmText = localize("common.save");
	const cancelText = localize("common.cancel");

	const title = localize("logoutreturn.relogin");

	const errors = accountSwitch.errors ? (
		<StyledText red style={styles.text}>{localize(accountSwitch.errors)}</StyledText>
	) : null;

	const emailText = `${localize("logoutreturn.email")}${formatEmail(accountSwitch.email)}`;

	const emailSent = accountSwitch.sent ? (
		<StyledText style={styles.text} bold>{localize("logoutreturn.sent")}</StyledText>
	) : null;

	return (
		<Modal open>
			<Card scroll>
				<Heading title={title} />
				{errors}
				<StyledText style={styles.text}>{emailText}</StyledText>
				<Form
					schema={TimeoutLogin}
					onSubmit={dispatch("accountswitcher:trigger:login")}
					submitText={confirmText}
					cancelText={cancelText}
					style={{flex: 1}}
					onCancel={dispatch("accountswitcher:trigger:reset")}>
					<StyledButton style={styles.button} title={localize("login.forget")} onPress={dispatch("accountswitcher:trigger:forgot")} white />
					{emailSent}
				</Form>
			</Card>
		</Modal>
	);
}

function formatEmail(email){
	const split = email.split("@");
	const name = split[0];
	const numStars = Math.max(0, name.length - 2);
	const newEmail = `${name.slice(0, 2)}${"*".repeat(numStars)}`;
	return [newEmail, split[1]].join("@");
}
