import React from "react";
import xtend from "xtend";

import ManagedSection from "../ManagedSection";
import Form from "ae-forms";

import RESOURCES_EDIT_SCHEMA from "../../../../schemas/ResourcesEdit";

import { useResources } from "@aetonix/hooks";

export default function ResourcesEdit(props) {
	const {
		localize,
		dispatch,
		localizeFrom,
	} = useResources();

	var model = props.model;
	var account = model.manage.account;
	var resourcesList = model.manage.resourceslist || [];
	var ownedResources = model.manage.resources.resources || [];
	var toast = model.toast;

	var resourcesTitle = localize("resources.edit");

	function by_name(x, y) {
		x = resourcesList[x].name;
		y = resourcesList[y].name;
		if (typeof x === "object")
			x = localizeFrom(x, ".");
		if (typeof y === "object")
			y = localizeFrom(y, ".");
		let locale = model.personal.language;
		if (locale === "cn_s" || locale === "cn_t")
			locale = (locale === "cn_s") ? "zh-CN" : "zh-HK";
		return x.localeCompare(y, locale);
	}

	var properties = Object.keys(resourcesList).sort(by_name).reduce(function(acc, resourceKey, index){
		var resource = resourcesList[resourceKey];
		var name = resource.name;
		if(typeof resource.name === "object")
			name = localizeFrom(resource.name, ".");
		acc[resourceKey] = {
			order: index + 1,
			inputType: "checkbox",
			type: "boolean",
			description: name,
		};
		return acc;
	}, {});

	var defaults = ownedResources.reduce(function(acc, resource){
		acc[resource._id] = true;
		return acc;
	}, {});

	var schema = xtend(RESOURCES_EDIT_SCHEMA, {
		properties: properties,
	});

	return (
		<ManagedSection online={model.online} title={resourcesTitle} contact={account} toast={toast} back={localize("resources.goback")}>
			<Form schema={schema} defaultData={defaults} onSubmit={dispatch("managedresources:trigger:edit:finish")} />
		</ManagedSection>
	);
}
