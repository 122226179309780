import React from "react";
import {
	View,
	StyleSheet,
} from "react-native";
import CircleButton from "ae-base/Base/CircleButton";
import Style from "ae-style";
import Breakpoints from "../../Breakpoints";

import { useResources } from "@aetonix/hooks";

var styles = StyleSheet.create({
	endButton: {
		backgroundColor: Style.colors.alert,
	},
	audioButton: {
		backgroundColor: Style.colors.black,
	},
	acceptButton: {
		backgroundColor: Style.colors.green,
	},
	functionButton: {
		backgroundColor: Style.colors.primary,
	},
	endButtonMargin: {
		marginHorizontal: Style.layout.marginSlim,
	},
});

export default function CallButton(props) {
	const {
		localize,
		dispatch,
	} = useResources();
	var button = props.button;

	var buttonEndCall = localize("callbutton.endcall");
	var buttonAudio = localize("callbutton.audio");
	var buttonSwitch = localize("callbutton.switch");
	var buttonZoomIn = localize("callbutton.zoomin");
	var buttonZoomOut = localize("callbutton.zoomout");
	var buttonWay3 = localize("callbutton.way3");
	var buttonForward = localize("callbutton.forward");
	var buttonDeny = localize("callbutton.deny");
	var buttonAccept = localize("callbutton.accept");

	var callButton = null;
	var controllButton = null;
	var iconControll = null;
	var iconCall = null;
	var smallButton = true;
	if(Breakpoints.smallerThan("mobileSmall")) {
		callButton = Style.imageSize.extrasmall;
		controllButton = Style.imageSize.extrasmall;
		iconControll = "small";
		iconCall = "emphasized";
		smallButton = false;
	}

	if(button === "endCall"){
		var hangup = dispatch("calling:trigger:hangup", {
			reason: "cancel",
		});
		var title = buttonEndCall;
		return (
			<View style={styles.endButtonMargin}>
				<CircleButton
					id={"hangUp"}
					icon={"aeto-hang_up-2"}
					title={title}
					containerColor={styles.endButton}
					onPress={hangup}
					contrast
					diameter={callButton}
					iconSize={iconCall} />
			</View>
		);
	} else if(button === "audio"){
		var audio = dispatch("audiooutputselector:trigger:toggle");
		title = buttonAudio;
		return (
			<CircleButton
				id={"audio"}
				icon={"volume-up"}
				title={title}
				containerColor={styles.audioButton}
				onPress={audio}
				contrast
				small={smallButton}
				diameter={controllButton}
				iconSize={iconControll} />
		);
	}else if(button === "switchCamera"){
		var switchCamera = dispatch("callinputswitcher:trigger:toggle");
		title = buttonSwitch;
		return (
			<CircleButton
				id={"switchCamera"}
				icon={"aeto-camera_flip"}
				title={title}
				containerColor={styles.audioButton}
				onPress={switchCamera}
				contrast
				small={smallButton}
				diameter={controllButton}
				iconSize={iconControll} />
		);
	}else if(button === "zoomIn"){
		var zoomIn = dispatch("callmagnifier:trigger:toggle");
		title = buttonZoomIn;
		return (
			<CircleButton
				id={"zoomIn"}
				icon={"search"}
				title={title}
				containerColor={styles.audioButton}
				onPress={zoomIn}
				contrast
				small={smallButton}
				diameter={controllButton}
				iconSize={iconControll} />
		);
	}else if(button === "zoomOut"){
		var zoomOut = dispatch("callmagnifier:trigger:toggle");
		title = buttonZoomOut;
		return (
			<CircleButton
				id={"zoomOut"}
				icon={"aeto-expand"}
				title={title}
				containerColor={styles.audioButton}
				onPress={zoomOut}
				contrast
				small={smallButton}
				diameter={controllButton}
				iconSize={iconControll} />
		);
	}else if(button === "way3"){
		var way3 = dispatch("conferenceinviter:trigger:open");
		title = buttonWay3;
		return (
			<CircleButton
				id={"3way"}
				icon={"users"}
				title={title}
				containerColor={styles.functionButton}
				onPress={way3}
				contrast
				small={smallButton}
				diameter={controllButton}
				iconSize={iconControll} />
		);
	}else if(button === "forward"){
		var forward = dispatch("callredirector:trigger:open");
		title = buttonForward;
		return (
			<CircleButton
				id={"forward"}
				icon={"reply"}
				title={title}
				containerColor={styles.functionButton}
				onPress={forward}
				contrast
				small={smallButton}
				diameter={controllButton}
				iconSize={iconControll} />
		);
	}else if(button === "deny"){
		var deny = dispatch("calling:trigger:deny", {
			reason: "deny",
		});
		title = buttonDeny;
		return (
			<CircleButton
				id={"hangUp"}
				icon={"aeto-hang_up-2"}
				title={title}
				containerColor={styles.endButton}
				onPress={deny}
				contrast
				diameter={callButton}
				iconSize={iconCall} />
		);
	}else if(button === "accept"){
		var accept = dispatch("calling:trigger:accept");
		title = buttonAccept;
		return (
			<CircleButton
				id={"accept"}
				icon={"phone"}
				title={title}
				containerColor={styles.acceptButton}
				onPress={accept}
				contrast
				diameter={callButton}
				iconSize={iconCall} />
		);
	}else if (button === "callgroupEndCall"){
		var end = dispatch("callgroupscalling:trigger:cancel");
		title = buttonEndCall;
		return (
			<View style={styles.endButtonMargin}>
				<CircleButton
					icon={"aeto-hang_up-2"}
					title={title}
					containerColor={styles.endButton}
					onPress={end}
					contrast
					diameter={callButton}
					iconSize={iconCall} />
			</View>
		);
	}
}
