import React from "react";
import TimelineItem from "./TimelineItem";

import { useResources } from "@aetonix/hooks";

export default function BatteryChanged(props) {
	const {
		localize,
	} = useResources();

	var notification = props.notification;
	var devicebatterychanged = localize("banner.batterychanged", notification.from);
	var screen = props.screen;

	return (
		<TimelineItem content={devicebatterychanged} iconName="info"  notification={notification} screen={screen} />
	);
}
