
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";

import Style from "ae-style";
import ResponsiveStylesheet from "react-native-responsive-stylesheet";
import BlockView from "ae-base/Base/BlockView";

var styles = ResponsiveStylesheet.createSized("min-direction", {
	0: {
		spaced: {
			marginVertical: Style.layout.marginBordered / 2,
		},
	},
	512: {
		spaced: {
			marginVertical: Style.layout.marginBordered,
		},
	}
});

export default function LoginSpacer(props) {
	var children = props.children;

	return (
		<BlockView style={styles.spaced}>
			{children}
		</BlockView>
	);
}
