/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";
import {
	StyleSheet,
} from "react-native";
import Loader from "ae-base/Base/Loader";

import OutputForm from "ae-forms/src/Outputs/OutputForm";
import ManagedSection from "../ManagedSection";
import StyledButton from "ae-base/Base/StyledButton";
import { DATE_TIME_FORMAT } from "../../../../utils/date-utils";

import { useResources } from "@aetonix/hooks";

const styles = StyleSheet.create({
	editButton: {
		alignSelf: "flex-end",
		marginTop: 4,
	},
});

export default function ManagedActiveGroupFormSubmission(props) {
	const {
		localize,
		dispatch,
		localizeFrom,
		formatDate,
	} = useResources();

	const model = props.model;
	const manage = model.manage;
	const submission = props.submission;
	const singleForm = props.singleForm;
	const managedactivegroupformsubmission = manage.managedactivegroupformsubmission || {};
	const groupform = manage.managedgroupformsubmissions.form;
	const managedgroupformlist = manage.managedgroupformlist;
	const currentSubmission = submission || managedactivegroupformsubmission;
	const defaultData = currentSubmission.data;
	const currentform = managedgroupformlist[groupform];
	if (!currentform || !defaultData) return <Loader />;
	const schema = managedgroupformlist[groupform].schema;
	const contact = manage.account;
	const editedHistory = currentSubmission.editHistory || [];
	const lastEdit = editedHistory.length ? editedHistory[editedHistory.length - 1] : null;
	const editedBy = singleForm ? (lastEdit ? localize("groupforms.edit_by", lastEdit) : null) : null;
	const date = singleForm && lastEdit ? formatDate(lastEdit.updated_at, DATE_TIME_FORMAT) : null;

	const localization = currentform.localization;
	const title = localizeFrom(localization, ".") || currentform.name || currentform._id;

	const edittitle = localize("groupforms.edit");

	const edit = dispatch("managedactivegroupformsubmission:trigger:update:start", {
		id: currentSubmission._id,
		owner: currentSubmission.owner,
	});

	const permissions = manage.permissions || {};
	const groupFormPermissions = permissions.groupforms || {};
	const currentPermission = groupFormPermissions[currentform._id];
	const locked = currentSubmission.locked;
	const renderEditButton = (singleForm && !locked && currentPermission && currentPermission > 1) ? (
		<StyledButton title={edittitle} icon="compose" onPress={edit} clear contrast style={styles.editButton} />
	) : null;

	return (
		<ManagedSection model={model} online={model.online} title={title} subTitle={editedBy} additionalTitles={[date]} contact={contact} edit={renderEditButton}>
			<OutputForm schema={schema} data={defaultData} />
		</ManagedSection>
	);
}
