/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";

import Notification from "../../Layout/Notification";
import StyledText from "ae-base/Base/StyledText";

import Style from "ae-style";
import { useResources } from "@aetonix/hooks";

export default function AppearOfflineBanner(props){
	const { localize } = useResources();
	const color = Style.colors.white;
	const content = localize("banner.appearoffline");
	const text_style = {
		color: Style.colors.red,
	};

	return (
		<Notification
			color={color}
			icon={"user-times"}
			iconColor={"red"}>
			<StyledText style={text_style} standard>
				{content}
			</StyledText>
		</Notification>
	);
}