import React from "react";
import prop from "prop";

import Item from "../Item";
import Group from "../Group";
import Section from "../Section";
import Title from "../Title";

import { useResources } from "@aetonix/hooks";

var getProvidedServices = prop("ProvidedServices");
var getContactName = prop("ContactName");
var getPhone = prop("Phone");
var getEmail = prop("Email");
var getStartDate = prop("StartDate");
var getOrganization = prop("Organization.Name");

export default function Supports(props) {
	const {
		localize,
	} = useResources();

	var careplan = props.careplan;

	var supportTitle = localize("supports.supportsservices");
	var supportsandservicesinformation = localize("supports.supportsandservicesinformation");

	var communitySupport = careplan.CommunitySupport || [];

	var renderCommunitySupport = communitySupport.map(function(support, index) {
		return (
			<Communitysupport key={index} support={support} index={index} />
		);
	});

	return (
		<Section title={supportTitle}>
			<Title title={supportsandservicesinformation} />
			{renderCommunitySupport}
		</Section>
	);
}

function Communitysupport(props) {
	const { localize } = useResources();
	var support = props.support;

	var organizationname = localize("supports.organizationname");
	var servicesprovided = localize("supports.servicesprovided");
	var contactname = localize("supports.contactname");
	var telephonenumber = localize("supports.telephonenumber");
	var emailaddress = localize("supports.emailaddress");
	var startdate = localize("supports.startdate");

	return (
		<Group>
			<Item title={organizationname} value={getOrganization(support)} />

			<Item title={servicesprovided} value={getProvidedServices(support)} />

			<Item title={contactname} value={getContactName(support)} />

			<Item title={telephonenumber} value={getPhone(support)} />

			<Item title={emailaddress} value={getEmail(support)} />

			<Item title={startdate} value={getStartDate(support)} />
		</Group>
	);

}
