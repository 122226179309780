export default {
	title: "GroupChat",
	description: "schemas_groupchat_title",
	type: "object",
	properties: {
		name: {
			order: 0,
			type: "string",
			description: "groupchat.name",
			inputType: "text",
			minLength: 1,
			maxLength: 30
		},
		description: {
			order: 1,
			type: "string",
			description: "groupchat.description",
			inputType: "text",
			multiline: true,
			maxLength: 60
		}
	}
};
