import React from "react";
import {
	View,
	StyleSheet,
	Platform,
} from "react-native";

import AsyncStorage from "@react-native-async-storage/async-storage";
import Homepage from "./Pages/Homepage";
import NotFound from "./Pages/NotFound";
import Myprofile from "./Pages/Myprofile";
import ProfilePictureChoose from "./Pages/Myprofile/ProfilePicture/ProfilePictureChoose";
import ProfilePictureCrop from "./Pages/Myprofile/ProfilePicture/ProfilePictureCrop";
import Contactprofile from "./Pages/Contactprofile";

import Request from "./Pages/Request";
import Schedule from "./Pages/Myprofile/Schedule/Schedule";
import Calling from "./Calling/Calling";
import Messaging from "./Pages/Messaging";
import GroupMessaging from "./Pages/Messaging/GroupMessaging";
import GroupAdd from "./Pages/Groups/GroupAdd";
import GroupInviteContact from "./Pages/Groups/GroupInviteContact";
import GroupMembersList from "./Pages/Groups/GroupMembersList";

import ATouchAwaySettings from "./Pages/Managed/ATouchAwaySettings";
import Reminders from "./Pages/Managed/Reminders";
import Remindermaker from "./Pages/Managed/Reminders/Remindermaker";
import Managedprofile from "./Pages/Managed/Managedprofile";
import ManagedProfilePictureCrop from "./Pages/Managed/ProfilePicture/ProfilePictureCrop";
import Managedurgency from "./Pages/Managed/Managedurgency";
import Managedurgencypotentials from "./Pages/Managed/Managedurgencypotentials";
import ManagedBraceletContactPotentials from "./Pages/Managed/BraceletContactPotentials";
import Managedcontacts from "./Pages/Managed/Contacts";
import ManagedContactsRequest from "./Pages/Managed/Contacts/ManagedcontactsRequest";
import Managedmanagers from "./Pages/Managed/Managers";
import ManagedManagerRequests from "./Pages/Managed/Managers/ManagedManagerRequests";
import ManagedManagerPermissions from "./Pages/Managed/Permissions";
import Managedbracelet from "./Pages/Managed/Managedbracelet";
import Managedforms from "./Pages/Managed/Forms";
import Managedformscreation from "./Pages/Managed/Forms/Managedformscreation";
import ManagedformsEdit from "./Pages/Managed/Forms/ManagedformsEdit";
import Managedformsview from "./Pages/Managed/Forms/Managedformsview";
import ManagedGroupFormSubmissions from "./Pages/Managed/GroupForms/ManagedGroupFormSubmissions";
import ManagedGroupFormSubmissionCreation from "./Pages/Managed/GroupForms/ManagedGroupFormSubmissionCreation";
import ManagedGroupFormSubmissionUpdate from "./Pages/Managed/GroupForms/ManagedGroupFormSubmissionUpdate";
import ManagedActiveGroupFormSubmission from "./Pages/Managed/GroupForms/ManagedActiveGroupFormSubmission";
import Metrics from "./Pages/Managed/Metrics";
import ActivityMetrics from "./Pages/Managed/ActivityMetrics";
import GroupFormSubmissions from "./Pages/GroupForms/GroupFormSubmissions";
import ActiveGroupFormSubmission from "./Pages/GroupForms/ActiveGroupFormSubmission";
import SubmissionCreation from "./Pages/GroupForms/SubmissionCreation";
import SubmissionUpdate from "./Pages/GroupForms/SubmissionUpdate";
import StaffFormSubmissions from "./Pages/GroupForms/StaffFormSubmissions";
import ActiveStaffFormSubmission from "./Pages/GroupForms/ActiveStaffFormSubmission";
import StaffSubmissionCreation from "./Pages/GroupForms/StaffSubmissionCreation";
import StaffSubmissionUpdate from "./Pages/GroupForms/StaffSubmissionUpdate";
import BloodSugarMetrics from "./Pages/Managed/BloodSugarMetrics";
import WeightMetrics from "./Pages/Managed/WeightMetrics";
import BloodPressureMetrics from "./Pages/Managed/BloodPressureMetrics";
import OverviewMetrics from "./Pages/Managed/OverviewMetrics";
import BodyTempMetrics from "./Pages/Managed/BodyTempMetrics";
import OxygenMetrics from "./Pages/Managed/OxygenMetrics";
import ManagedCustomIndicatorMetrics from "./Pages/Managed/ManagedCustomIndicatorMetrics";
import SectionEdit from "./Careplan/SectionEdit.js";
import CareplanView from "./Careplan/CareplanView";
import Timeline from "./Pages/Timeline";
import TimeoutModal from "./Pages/TimeoutModal";
import OwnCareplanView from "./Careplan/OwnCareplanView";
import ConnectedFooter from "./Calling/ConnectedFooter";

import Workflow from "./Pages/Managed/Workflow/Workflow";
import WorflowActions from "./Pages/Managed/Workflow/WorkflowActions";
import WorkflowEdit from "./Pages/Managed/Workflow/WorkflowEdit";
import WorkflowCreate from "./Pages/Managed/Workflow/WorkflowCreate";

import GroupForms from "./Pages/GroupForms";
import MyHealth from "./Pages/MyHealth";

import MyBloodSugarMetrics from "./Pages/MyHealth/HealthSection/BloodSugarMetrics";
import MyBloodPressureMetrics from "./Pages/MyHealth/HealthSection/BloodPressureMetrics";
import MyBodyTempMetrics from "./Pages/MyHealth/HealthSection/BodyTempMetrics";
import MyOxygenMetrics from "./Pages/MyHealth/HealthSection/OxygenMetrics";
import MyWeightMetrics from "./Pages/MyHealth/HealthSection/WeightMetrics";
import MyActivityMetrics from "./Pages/MyHealth/HealthSection/ActivityMetrics";
import MyCustomIndicatorMetrics from "./Pages/MyHealth/HealthSection/MyCustomIndicatorMetrics";
import MyOverviewMetrics from "./Pages/MyHealth/HealthSection/OverviewMetrics";
import MyReminders from "./Pages/MyHealth/Reminders/MyReminders";
import MyRemindermaker from "./Pages/MyHealth/Reminders/Remindermaker";
import MyReminderView from "./Pages/MyHealth/Reminders/ReminderView";
import FeedbackModal from "./aTouchAway/FeedbackModal";
import CallGroupBusy from "./Groups/CallGroupBusy";
import FileViewer from "./FileViewers/FileViewer";
import Resources from "./Pages/Managed/Resources";
import ResourceView from "./Pages/Managed/Resources/ResourceView";
import ResourcesEdit from "./Pages/Managed/Resources/ResourcesEdit";
import OwnResources from "./Pages/MyHealth/Resources/Resources";
import OwnResourceView from "./Pages/MyHealth/Resources/ResourceView";
import UsersCreationToken from "./Pages/UserCreationToken";
import UserCreation from "./Pages/UserCreation";
import MassMessage from "./Pages/MassMessage";

import ManagedDevices from "./Pages/Managed/Devices";
import ManagedDevicesAdd from "./Pages/Managed/Devices/DevicesAdd";
import ManagedDevicesUpdate from "./Pages/Managed/Devices/DevicesUpdate";
import NewReadingModal from "./Pages/MyHealth/HealthSection/NewReadingModal";

import Devices from "./Pages/MyHealth/Devices";
import DevicesAdd from "./Pages/MyHealth/Devices/DevicesAdd";
import DevicesUpdate from "./Pages/MyHealth/Devices/DevicesUpdate";

import OwnLiveChatGroup from "./Pages/LiveChat/OwnLiveChatGroup";
import LiveChatMessaging from "./Pages/Messaging/LiveChatMessaging";

import WorkflowActionModal from "./Workflow/WorkflowActionModal";
import License from "./Pages/Login/License";

import SimplifiedSettings from "./Pages/Managed/SimplifiedSettings";

import ContactSettings from "./Pages/ContactSettings";
import Relogin from "./AccountSwitch/Login";

import DeviceFollowUp from "./aTouchAway/ATouchAwayMyHealth/DeviceFollowUp";

import FinishAccount from "./FinishAccount";
import IncomingCallModal from "../Calling/components/Modals/IncomingCall";
import Call from "../Calling/Call";

import useActiveMeetingManager from "../Calling/hooks/useActiveMeetingManager/useActiveMeetingManager";
import MeetingContext from "../Calling/context/MeetingContext";
import MeetingMinimize from "../Calling/components/MeetingMinimize";
import MeetingAlerts from "../Calling/components/Alert/MeetingAlerts";
import useEnabledFeatures from "../hooks/useEnabledFeatures/useEnabledFeatures";
import useMeetingSettings from "../Calling/hooks/useMeetingSettings/useMeetingSettings";
import PrivacyPolicy from "./Pages/Login/PrivacyPolicy";
import { useResources } from "@aetonix/hooks";

var styles = StyleSheet.create({
	container: {
		flex: 1,
	},
	hidden: {
		right: -420,
		position: "absolute",
		height: 0,
		width: 0,
		overflow: "hidden",
		opacity: 0,
	},
});

export default function Desktop(){
	const { dispatch, api, timeoutReset, listen, unlisten, config, model } = useResources();
	const events = { api, dispatch, listen, unlisten };

	var currentPage = model.page;
	var fileViewer = model.fileviewer;
	var isHome = currentPage === "home";
	var passwordTimeout = model.passwordtimeout;
	var content = null;

	const meetingManager = useActiveMeetingManager(events, config, model);
	const { meeting, isIncomingCallOpen } = meetingManager;

	if(!model) return null;

	var screen = model.size;

	var workflowaction = model.workflowaction;
	var isCancelable = !model.workflowautoview;

	var externalForms = model.workflowexternals;

	var renderFinishAccount = model?.finishaccount?.open && !model.license && !model.privacypolicy   ? (<FinishAccount model={model} />) : null;

	var renderLicense = model.license ? (
		<License close={dispatch("license:trigger:hide")} />
	) : null;

	var renderPrivacyPolicy = model.privacypolicy ? (
		<PrivacyPolicy close={dispatch("privacypolicy:trigger:hide")} />
	) : null;

	var renderActiveWorkflowAction = workflowaction && !workflowaction.collapsed ? (
		<WorkflowActionModal online={model.online} cancelable={isCancelable} action={workflowaction} externalForms={externalForms} />
	) : null;

	var accountSwitch = model.accountswitch && model.accountswitch.email ? (
		<Relogin accountSwitch={model.accountswitch} />
	) : null;

	var feedbackModal = model.feedbackmodal ? (
		<FeedbackModal model={model} />
	) : null;

	const healthIndicatorNewReadingModal = model.page ? (<NewReadingModal model={model} />) : null;

	var callgroupBusy = model.callgroupscalling.busy ? (
		<CallGroupBusy group={model.callgroupscalling.group} toast={model.toast} />
	) : null;

	var renderReminder = model.reminders ? (
		<MyReminderView model={model} />
	) :  null;

	var timeoutModal = passwordTimeout && currentPage !== "calling" ? (
		<TimeoutModal model={model} />
	) : null;

	var deviceFollowUp = model.devicefollowup && model.devicefollowup.open ? (
		<DeviceFollowUp followup={model.devicefollowup} />
	) : null;

	const onClearMeetingBanners = (from, type) => {
		dispatch("banner:trigger:clear", { from, type })();
	};

	if (currentPage === "meeting") {
		const mode = Platform.OS === "web" ? "desktop" : "regular";
		content = <Call mode={mode}/>;
	} else if (currentPage === "preferences")
		content = <Myprofile model={model} />;

	else if(currentPage === "org_user_list")
		content = (
			<UsersCreationToken model={model} />
		);
	else if(currentPage === "profile_picture")
		content = (
			<ProfilePictureChoose model={model} screen={screen} />
		);
	else if(currentPage === "profile_picture_crop")
		content = (
			<ProfilePictureCrop model={model} />
		);
	else if(currentPage === "schedule")
		content = (
			<Schedule model={model} />
		);
	else if(currentPage === "contactprofile")
		content = (
			<Contactprofile model={model} />
		);
	else if(currentPage === "request")
		content = (
			<Request model={model} />
		);
	else if(currentPage === "calling")
		return (
			<Calling model={model} />
		);
	else if(currentPage === "messaging")
		content = (
			<Messaging model={model} />
		);
	else if(currentPage === "groupchat_add")
		content = (
			<GroupInviteContact model={model} />
		);
	else if(currentPage === "groupmessaging")
		content = (
			<GroupMessaging model={model} />
		);
	else if(currentPage === "groupadd")
		content = (
			<GroupAdd model={model} />
		);
	else if(currentPage === "groupchat_members")
		content = (
			<GroupMembersList model={model} />
		);
	else if(currentPage === "managed_atouchaway")
		content = (
			<ATouchAwaySettings model={model} />
		);
	else if(currentPage === "managed_simplified")
		content = (
			<SimplifiedSettings model={model} />
		);
	else if(currentPage === "reminders")
		content = (
			<Reminders model={model} />
		);
	else if(currentPage === "managed_reminder_maker")
		content = (
			<Remindermaker model={model} />
		);
	else if(currentPage === "managed_preferences")
		content = (
			<Managedprofile model={model} />
		);
	else if(currentPage === "managed_picture_crop")
		content = (
			<ManagedProfilePictureCrop model={model} />
		);
	else if(currentPage === "managed_urgency")
		content = (
			<Managedurgency model={model} />
		);
	else if(currentPage === "managed_urgency_potentials")
		content = (
			<Managedurgencypotentials model={model} />
		);
	else if(currentPage === "managed_bracelet_contact_potentials")
		content = (
			<ManagedBraceletContactPotentials model={model} />
		);
	else if(currentPage === "managed_contacts")
		content = (
			<Managedcontacts model={model} />
		);
	else if(currentPage === "managed_managers")
		content = (
			<Managedmanagers model={model} />
		);
	else if(currentPage === "managed_manager_requests")
		content = (
			<ManagedManagerRequests model={model} />
		);
	else if(currentPage === "managed_managers_permissions")
		content = (
			<ManagedManagerPermissions model={model} />
		);
	else if(currentPage === "managed_contact_requests")
		content = (
			<ManagedContactsRequest model={model} />
		);
	else if(currentPage === "managed_bracelet")
		content = (
			<Managedbracelet model={model} />
		);
	else if(currentPage === "managed_forms")
		content = (
			<Managedforms model={model} />
		);
	else if(currentPage === "managed_forms_view")
		content = (
			<Managedformsview model={model} screen={screen} />
		);
	else if(currentPage === "managed_forms_creation")
		content = (
			<Managedformscreation model={model} />
		);
	else if(currentPage === "managed_forms_update")
		content = (
			<ManagedformsEdit model={model} />
		);
	else if(currentPage.indexOf("my_careplan_") !== -1)
		content = (
			<OwnCareplanView model={model} />
		);

	else if(currentPage.indexOf("careplan_") !== -1)
		content = (currentPage.indexOf("_edit") !== -1) ? (
			<SectionEdit model={model} />
		) : (
			<CareplanView model={model} />
		);

	else if(currentPage === "managed_metrics_list")
		content = (
			<Metrics model={model} />
		);
	else if(currentPage === "timeline")
		content = (
			<Timeline model={model} />
		);
	else if(currentPage === "managed_workflow")
		content = (
			<Workflow model={model} />
		);
	else if(currentPage === "managed_workflow_actions")
		content = (
			<WorflowActions model={model} />
		);
	else if(currentPage === "managed_workflow_create")
		content = (
			<WorkflowCreate model={model} />
		);
	else if(currentPage === "managed_workflow_edit")
		content = (
			<WorkflowEdit model={model} />
		);
	else if(currentPage === "group_forms")
		content = (
			<GroupForms model={model} />
		);
	else if(currentPage === "my_health")
		content = (
			<MyHealth model={model} />
		);

	else if(currentPage === "managedactivity")
		content = (
			<ActivityMetrics model={model} />
		);
	else if(currentPage === "group_form_submissions")
		content = (
			<GroupFormSubmissions model={model} />
		);

	else if(currentPage === "active_group_form_submission")
		content = (
			<ActiveGroupFormSubmission model={model} />
		);

	else if(currentPage === "update_group_form_submission")
		content = (
			<SubmissionUpdate model={model} />
		);
	else if(currentPage === "create_group_form_submission")
		content = (
			<SubmissionCreation model={model} />
		);
	else if(currentPage === "own_form_submissions")
		content = (
			<StaffFormSubmissions model={model} />
		);

	else if(currentPage === "active_own_form_submission")
		content = (
			<ActiveStaffFormSubmission model={model} />
		);
	else if(currentPage === "update_own_form_submission")
		content = (
			<StaffSubmissionUpdate model={model} />
		);
	else if(currentPage === "create_own_form_submission")
		content = (
			<StaffSubmissionCreation model={model} />
		);

	else if(currentPage === "managed_group_form_submissions")
		content = (
			<ManagedGroupFormSubmissions model={model} />
		);
	else if(currentPage === "create_user_group_form_submission")
		content = (
			<ManagedGroupFormSubmissionCreation model={model} />
		);
	else if(currentPage === "update_user_group_form_submission")
		content = (
			<ManagedGroupFormSubmissionUpdate model={model} />
		);
	else if(currentPage === "view_user_group_form_submission")
		content = (
			<ManagedActiveGroupFormSubmission model={model} />
		);
	else if(currentPage === "managedbloodsugar")
		content = (
			<BloodSugarMetrics model={model} />
		);
	else if(currentPage === "managedweight")
		content = (
			<WeightMetrics model={model} />
		);
	else if(currentPage === "managedbloodpressure")
		content = (
			<BloodPressureMetrics model={model} />
		);
	else if(currentPage === "managedoverview")
		content = (
			<OverviewMetrics model={model} />
		);

	else if(currentPage === "managedbodytemperature")
		content = (
			<BodyTempMetrics model={model} />
		);
	else if(currentPage === "managedbloodoxygen")
		content = (
			<OxygenMetrics model={model} />
		);
	else if (currentPage === "managedcustomindicator")
		content = (
			<ManagedCustomIndicatorMetrics model={model} />
		);
	else if(currentPage === "mybloodsugar")
		content = (
			<MyBloodSugarMetrics model={model} />
		);
	else if(currentPage === "mybloodpressure")
		content = (
			<MyBloodPressureMetrics model={model} />
		);
	else if(currentPage === "mybodytemperature")
		content = (
			<MyBodyTempMetrics model={model} />
		);
	else if(currentPage === "mybloodoxygen")
		content = (
			<MyOxygenMetrics model={model} />
		);
	else if(currentPage === "myweight")
		content = (
			<MyWeightMetrics model={model} />
		);
	else if(currentPage === "myactivity")
		content = (
			<MyActivityMetrics model={model} />
		);
	else if(currentPage === "mycustomindicator")
		content = (
			<MyCustomIndicatorMetrics model={model} />
		);
	else if(currentPage === "myoverview")
		content = (
			<MyOverviewMetrics model={model} />
		);
	else if(currentPage === "myreminders")
		content = (
			<MyReminders model={model} />
		);
	else if(currentPage === "own_reminder_maker")
		content = (
			<MyRemindermaker model={model} />
		);
	else if(currentPage === "managed_resources")
		content = (
			<Resources model={model} />
		);
	else if(currentPage === "managed_resource_view")
		content = (
			<ResourceView model={model} />
		);
	else if(currentPage === "managed_resources_edit")
		content = (
			<ResourcesEdit model={model} />
		);
	else if(currentPage === "ownresources")
		content = (
			<OwnResources model={model} />
		);
	else if(currentPage === "ownresourceview")
		content = (
			<OwnResourceView model={model} />
		);
	else if(currentPage === "org_user_creation")
		content = (
			<UserCreation model={model} />
		);
	else if(currentPage === "managed_devices")
		content = (
			<ManagedDevices model={model} />
		);
	else if(currentPage === "managed_devices_add")
		content = (
			<ManagedDevicesAdd model={model} />
		);
	else if(currentPage === "managed_devices_update")
		content = (
			<ManagedDevicesUpdate model={model} />
		);
	else if(currentPage === "devices_update")
		content = (
			<DevicesUpdate model={model} />
		);
	else if(currentPage === "devices")
		content = (
			<Devices model={model} />
		);
	else if(currentPage === "devices_add")
		content = (
			<DevicesAdd model={model} />
		);
	else if(currentPage === "livechat")
		content = (
			<OwnLiveChatGroup model={model} />
		);
	else if(currentPage === "livechat_message")
		content = (
			<LiveChatMessaging model={model} />
		);
	else if(currentPage === "contact_settings")
		content = (
			<ContactSettings model={model} />
		);
	else if(currentPage === "massmessage")
		content = (
			<MassMessage model={model} />
		);

	var home =  (
		<Homepage model={model} hidden={!isHome} />
	);

	if (!content && !isHome)
		content = (
			<NotFound model={model} />
		);


	var responderProps = {};

	if (Platform.OS === "web")
		responderProps = {
			onStartShouldSetResponder: function(){return true;},
			onResponderRelease: timeoutReset,
			onResponderMove: timeoutReset,
			tabIndex: -1,
			onKeyDown: timeoutReset,
			onWheel: timeoutReset,
			onMouseUp: (e) => {
				if (e && (e.which == 2 || e.button == 1))
					timeoutReset();

			},
		};
	else
		responderProps = {
			onStartShouldSetResponderCapture: timeoutReset,
			onMoveShouldSetResponderCapture: timeoutReset,
		};


	var styleList = [styles.container];

	if (passwordTimeout)
		styleList.push(styles.hidden);


	const shouldRenderMeetingAlerts = currentPage === "meeting";

	return (
		<View
			style={styles.container}
			{...responderProps}
		>
			<MeetingContext.Provider value={meetingManager}>
				{ shouldRenderMeetingAlerts && <MeetingAlerts banners={model.banners} onClear={onClearMeetingBanners}/> }
				<View style={styleList}>
					{home}
					{content}
					<ConnectedFooter calling={model.calling} page={currentPage} />
					{callgroupBusy}
					{renderLicense}
					{renderPrivacyPolicy}
					{renderReminder}
					{currentPage !== "meeting" && renderActiveWorkflowAction}
					<FileViewer open={!!fileViewer.file} file={fileViewer.file} screen={screen} />
					{renderFinishAccount}
					{feedbackModal}
					{deviceFollowUp}
					{healthIndicatorNewReadingModal}
					{accountSwitch}
					{ isIncomingCallOpen && <IncomingCallModal/> }
					{ meetingManager?.isMinimized && <MeetingMinimize title={meeting?.title} onClick={() => meetingManager?.goToMeeting?.()}/> }
				</View>
			</MeetingContext.Provider>
			{timeoutModal}
		</View>
	);
}
