import "ie-array-find-polyfill";
import ResizeObserver from "resize-observer-polyfill";
import global from "global";
import {
	Buffer
} from "buffer";
import process from "process";
import React from "react";
import {
	Platform
} from "react-native";

// import React from "react";
// if (process.env.NODE_ENV !== "production") {
// 	var {
// 		whyDidYouUpdate
// 	} = require("why-did-you-update");
// 	whyDidYouUpdate(React);
// }

// React.___createElement = React.createElement;
// React.createElement = newCreate;

if (!Object.entries) {
	Object.entries = function( obj ){
		var ownProps = Object.keys( obj ),
			i = ownProps.length,
			resArray = new Array(i); // preallocate the Array
		while (i--)
			resArray[i] = [ownProps[i], obj[ownProps[i]]];
		return resArray;
	};
}

if(!global.ResizeObserver)
	global.ResizeObserver = ResizeObserver;
global.Buffer = Buffer;
global.process = process;
if(!global.self)
	global.self = global;

// Taken from underscore
function isFunction(obj) {
	var type = typeof obj;
	// Not a function
	if(type !== "function") return false;

	// Is a class componentf
	if((obj.prototype && obj.prototype.constructor))
		return false;

	// Respect elements with contexts
	if(obj.contextTypes || obj.childContextTypes)
		return false;

	return true;
}

function isSimpleView(element, props) {
	if(Platform.OS !== "web") return false;
	if(element.displayName !== "View")
		return false;
	if(!props)
		return true;
	if(props.onLayout)
		return false;
	return true;
}

function newCreate(element, props, ...children) { // eslint-disable-line
	var hasKey = props.key;
	if(!hasKey){
		if(isFunction(element)){
			var newProps = makeProps(props, children);
			return element(newProps);
		} else if(isSimpleView(element, props)){
			var viewProps =  makeProps(props, children);
			return element.prototype.render.call({
				props: viewProps,
				context: {}
			});
		}
	}

	return React.___createElement.apply(React, arguments);
}

function makeProps(props, children) {
	var newProps = props || {};
	if(children && children.length)
		if(children.length === 1)
			newProps.children = children[0];
		else
			newProps.children = children;
	return newProps;
}
