import PropTypes from "prop-types";

/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";
import {
	View,
} from "react-native";
import ResponsiveStylesheet from "react-native-responsive-stylesheet";
import Breakpoints from "../../../Breakpoints";

import StyledText from "ae-base/Base/StyledText";
import BackButton from "ae-base/Base/BackButton";
import HeaderImage from "../../Effects/HeaderImage";
import Avatar from "../../Contacts/Avatar";
import ControlButtons from "./ControlButtons";
import ImageUrl from "ae-base/Base/ImageUrl";
import Style from "ae-style";

import { useResources } from "@aetonix/hooks";

var styles = ResponsiveStylesheet.createSized("min-direction", {
	0: {
		center: {
			alignItems: "center",
			justifyContent: "center",
			marginTop: 10,
			marginBottom: 10,
		},
		text: {
			margin: Style.layout.paddingMedium,
		},
		backButtonPadding: {
			padding: Style.layout.paddingSmall,
			paddingLeft: Style.layout.paddingMedium,
		},
	},
	512: {
		center: {
			alignItems: "center",
			justifyContent: "center",
		},
		controls: {
			marginVertical: 18,
		},
		text: {
			marginTop: Style.layout.marginBordered,
			marginBottom: Style.layout.marginMedium,
		},
		backButtonPadding: {
			paddingLeft: Style.layout.paddingMedium,
			paddingRight: Style.layout.paddingMedium,
		},
	},
});

export default function ContactProfileHeader(props){
	const { localize, config } = useResources();

	var text = styles.text;
	var center = styles.center;

	var missed = props.missed;
	var contact = props.contact;

	var sizeAvatar = "medium";
	var nameSize = "largeHeading";
	var backbuttonSize;
	var goHome = contact.backButton;

	var name = contact.lname + ", " + contact.fname;

	if (Breakpoints.smallerThan("mobileSmall"))
		sizeAvatar = "medium";

	if (Breakpoints.smallerThan("small")) {
		sizeAvatar = "medium";
		backbuttonSize = "small";
	}

	var backbuttonTitle = <StyledText contrast size={backbuttonSize}>{localize("contactprofileheader.goback")}</StyledText>;

	var controlButtons = (
		<ControlButtons missed={missed} contact={contact} settings={props.settings || {}} allowMessage={contact.allowMessage} />
	);

	return (
		<HeaderImage source={ImageUrl(contact.image_medium, config)}>
			<View style={styles.backButtonPadding}>
				<BackButton id={"backButton"} title={backbuttonTitle} goHome={goHome} />
			</View>
			<Avatar person={contact} size={sizeAvatar} />
			{controlButtons}
			<View style={[center, text]}>
				<StyledText size={nameSize} bold contrast>{name}</StyledText>
			</View>
		</HeaderImage>
	);
}
ContactProfileHeader.propTypes = {
	contact: PropTypes.object,
	missed: PropTypes.object,
};
