import React from "react";
import {
	View,
	StyleSheet,
} from "react-native";
import AtaContainer from "../AtaContainer";
import ContactList from "./ContactList";
import Messaging from "./Messaging";

import Styles from "ae-style";
import About from "../About";
import Reminder from "../Reminder";

var styles = StyleSheet.create({
	content: {
		flex: 1,
		flexDirection: "row",
		paddingLeft: Styles.layout.paddingSmall,
		paddingVertical: Styles.layout.paddingSmall,
	}
});

export default function ATouchAwayMessaging (props){
	var model = props.model;
	var options = model.options;
	var trunk = options.trunk;
	var reminder = model.reminders || {};
	var messaging = model.messaging;
	var messagingContacts = model.messagingcontacts;
	var groups = messagingContacts.groups;
	var contacts = messagingContacts.contacts;
	var renderTrunk = trunk ? <About options={options} /> : null;
	var renderReminder = reminder.active && !reminder.answered ? <Reminder reminder={reminder} /> : null;
	var renderMessaging = messaging.viewing ? (<Messaging model={model} />) : null;

	return (
		<AtaContainer>
			<View style={styles.content}>
				<ContactList groups={groups} aTouchAway={true} contacts={contacts} viewing={messaging.viewing} />
				{renderMessaging}
			</View>
			{renderTrunk}
			{renderReminder}
		</AtaContainer>
	);
}
