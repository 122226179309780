import React from "react";
import {
	StyleSheet,
	View,
} from "react-native";

import Style from "ae-style";
import OverviewItem from "../../Managed/OverviewItem";
import ManagedHeader from "../../Managed/ManagedSection/ManagedHeader";
import MainScroll from "../../../Layout/MainScroll";

import { useMeasureUnits, useResources } from "@aetonix/hooks";

var styles = StyleSheet.create({
	overviewContainer: {
		alignItems: "flex-start",
		justifyContent: "space-around",
		flexDirection: "row",
		flexWrap: "wrap",
		padding: Style.layout.paddingMedium,
	},
});

export default function OverviewMetrics(props) {
	const {
		localize,
		dispatch,
	} = useResources();
	const measureUnits = useMeasureUnits();

	var model = props.model;
	var myhealth = model.myhealth;
	var activity = myhealth.activity || {};
	var bloodsugar = myhealth.bloodsugar || {};
	var weight = myhealth.weight || {};
	var bloodpressure = myhealth.bloodpressure || {};
	var bloodoxygen = myhealth.bloodoxygen || {};
	var bodytemperature = myhealth.bodytemperature || {};
	var contact = model.personal;
	var who = model.personal._id;
	var tempUnitKeys = measureUnits.temperatureUnits || "celsius";
	var glucoseUnitKeys = measureUnits.glucometerUnits || "mmol";
	var oldGlucoseUnitKeys = glucoseUnitKeys === "mg" ? "meanblood" : "glucose";
	var weightUnits = measureUnits.weightUnits || "kg";

	var tempUnits = localize("bodytemperature." + tempUnitKeys);
	var glucoseUnits = localize("bloodsugar." + glucoseUnitKeys);
	var title = localize("myhealthoverview.overview");
	var activity_title = localize("myhealth.activity");
	var bloodsugar_title = localize("myhealth.bloodsugar");
	var weight_title = localize("myhealth.weight");
	var bloodpressure_title = localize("myhealth.bloodpressure");
	var bloodoxygen_title = localize("myhealth.oxygen");
	var bodytemp_title = localize("myhealth.bodytemp");
	var unit = localize("activity.steps_text");

	var latestActivity = activity.latest ? activity.latest.cumulative + " " + unit : " ";
	var latestBloodSugar = bloodsugar.latest ? (bloodsugar.latest.history[glucoseUnitKeys] || bloodsugar.latest.history[oldGlucoseUnitKeys]) + " " + glucoseUnits : " ";
	var latestWeight = weight.latest ? weight.latest.history[weightUnits] + " " + weightUnits : " ";
	var latestBloodPressure = bloodpressure.latest ? bloodpressure.latest.history.systolic + "/" + bloodpressure.latest.history.diastolic + " " + "mmhg"  + "\n"  + bloodpressure.latest.history.bpPulserate + "bpm" : " ";
	var latestBloodOxygen = bloodoxygen.latest ? bloodoxygen.latest.history.oxygensaturation + "%" + " - " + bloodoxygen.latest.history.pulserate  + "bpm" : " ";
	var latestBodyTemp = bodytemperature.latest ? bodytemperature.latest.history[tempUnitKeys] + " " + tempUnits : " ";

	var jumpToActivity = dispatch("activity:trigger:view", {
		who: who,
	});

	var jumpToBloodSugar = dispatch("bloodsugar:trigger:view", {
		who: who,
	});

	var jumpToWeight = dispatch("weight:trigger:view", {
		who: who,
	});

	var jumpToBloodPressure = dispatch("bloodpressure:trigger:view", {
		who: who,
	});

	var jumpToBloodOxygen = dispatch("bloodoxygen:trigger:view", {
		who: who,
	});

	var jumpToBodyTemp = dispatch("bodytemperature:trigger:view", {
		who: who,
	});

	var overviewItems = [
		{
			title: latestActivity, text: activity_title, onPress: jumpToActivity,
		},
		{
			title: latestBloodSugar, text: bloodsugar_title, onPress: jumpToBloodSugar,
		},
		{
			title: latestWeight, text: weight_title, onPress: jumpToWeight,
		},
		{
			title: latestBloodPressure, text: bloodpressure_title, onPress: jumpToBloodPressure,
		},
		{
			title: latestBloodOxygen, text: bloodoxygen_title, onPress: jumpToBloodOxygen,
		},
		{
			title: latestBodyTemp, text: bodytemp_title, onPress: jumpToBodyTemp,
		},
	];

	var renderedItems = overviewItems.map(function(itemData) {
		return <OverviewItem title={itemData.title} text={itemData.text} onpress={itemData.onPress} managed  />;
	});

	return (
		<MainScroll style={styles.center}>
			<ManagedHeader title={title} contact={contact} />
			<View style={styles.overviewContainer}>
				{renderedItems}
			</View>
		</MainScroll>
	);
}
