export default {
	title: "accountkey",
	description: "schemas_accountkey_title",
	type: "object",
	required: ["token"],
	properties: {
		token: {
			description: "mobiletokenlogin.keyhere",
			placeholder: "mobiletokenlogin.placeholder",
			inputType: "text",
			type: "string"
		}
	}
};
