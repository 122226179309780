export default {
	order: 10,
	description: "profile.language.title",
	value_description: "languages",
	type: "string",
	inputType: "dropdown",
	enum: [
		"en",
		"fr",
		"cn_s",
		"cn_t",
		"es",
		"pt",
		"ta"
	],
};
