import React from "react";
import {
	StyleSheet,
	View,
	ScrollView,
	TouchableWithoutFeedback,
	Platform,
} from "react-native";

import StyledButton from "ae-base/Base/StyledButton";
import Style from "ae-style";
import Modal from "../../Layout/Modal";
import Markdown from "ae-base/Base/Markdown";

import { useResources } from "@aetonix/hooks";

const styles = StyleSheet.create({
	title: {
		textAlign: "center",
	},
	container: {
		backgroundColor: Style.colors.white,
		margin: Style.layout.marginSmall,
		marginBottom: 0,
		padding: Style.layout.paddingSmall,
	},
	box: {
		borderWidth: 2,
		borderStyle: "solid",
		borderColor: Style.colors.primary,
		padding: Style.layout.paddingSmall,
		marginBottom: Style.layout.marginSmall,
	},
});

export default function PrivacyPolicy(props) {
	const { localize, timeoutReset } = useResources();

	const close = localize("license.close");
	const title = localize("privacy_policy.title");
	const section1 = localize("privacy_policy.section1");
	const section2 = localize("privacy_policy.section2");
	const section3 = localize("privacy_policy.section3");
	const section4 = localize("privacy_policy.section4");
	const section5 = localize("privacy_policy.section5");
	const section6 = localize("privacy_policy.section6");
	const section7 = localize("privacy_policy.section7");
	const section8 = localize("privacy_policy.section8");

	const scrollViewProps = {
		style: styles.container,
	};

	if (Platform.OS === "web") {
		scrollViewProps.onScroll = timeoutReset;
	}

	return (
		<Modal open>
			<ScrollView {...scrollViewProps}>
				<TouchableWithoutFeedback>
					<View>
						<Markdown>{title}</Markdown>
						<Markdown>{section1}</Markdown>
						<Markdown>{section2}</Markdown>
						<Markdown>{section3}</Markdown>
						<Markdown>{section4}</Markdown>
						<Markdown>{section5}</Markdown>
						<Markdown>{section6}</Markdown>
						<Markdown>{section7}</Markdown>
						<Markdown>{section8}</Markdown>
					</View>
				</TouchableWithoutFeedback>
			</ScrollView>
			<View style={{ alignItems: "stretch", width: "100%" }}>
				<StyledButton fullwidth stretch id={"privacy_policy_close"} centered contrast title={close} onPress={props.close} />
			</View>
		</Modal>
	);
}

const BoxedSection = (props) => {
	return <View style={styles.box} {...props} />;
};
