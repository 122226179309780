import React from "react";
import SimpleState from "../SimpleState";

import { useResources } from "@aetonix/hooks";

export default function Busy(props) {
	const {
		localize,
	} = useResources();
	var who = props.who;

	var message = localize("atouchawaycalling.isbusy", who);

	return (
		<SimpleState who={who} message={message} />
	);
}
