import React from "react";
import prop from "prop";

import Item from "../Item";
import Group from "../Group";
import Section from "../Section";
import Title from "../Title";

import { useResources } from "@aetonix/hooks";

var getAssessmentName = prop("AssessmentName");
var getCompleted = prop("Completed");
var getCompletedDate = prop("CompletedDate");
var getAssessmentScore = prop("AssessmentScore");
var getActionsTaken = prop("ActionsTaken");
var getAssessmentType = prop("AssessmentType.Name");

export default function Healthassessments(props) {
	const {
		localize,
	} = useResources();

	var careplan = props.careplan;

	var healthassessmentsTitle = localize("healthassessments.healthassessment_s");
	var assssments = localize("healthassessments.assessments");

	var healthassessments = careplan.HealthAssessment || [];

	var renderHealthAssessment = healthassessments.map(function(healthassessment, index){
		return (
			<HealthAssessment key={index} healthassessment={healthassessment} index={index} />
		);
	});

	return (
		<Section title={healthassessmentsTitle}>
			<Title title={assssments} />
			{renderHealthAssessment}
		</Section>
	);
}

function HealthAssessment(props) {
	const { localize } = useResources();
	var healthassessment = props.healthassessment;

	var assessmenttype = localize("healthassessments.assessmenttype");
	var assessmentname = localize("healthassessments.assessmentname");
	var completed = localize("healthassessments.completed");
	var completeddate = localize("healthassessments.completeddate");
	var score = localize("healthassessments.score");
	var actionstaken = localize("healthassessments.actionstaken");

	return (
		<Group>
			<Item title={assessmenttype} value={getAssessmentType(healthassessment)} />

			<Item title={assessmentname} value={getAssessmentName(healthassessment)} />

			<Item title={completed} value={getCompleted(healthassessment)} />

			<Item title={completeddate} value={getCompletedDate(healthassessment)} />

			<Item title={score} value={getAssessmentScore(healthassessment)} />

			<Item title={actionstaken} value={getActionsTaken(healthassessment)} />
		</Group>
	);
}
