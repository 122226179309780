import React from "react";
import {View, StyleSheet} from "react-native";

import ButtonGridGeneric from "./ButtonGridGeneric";

import { useResources } from "@aetonix/hooks";

const styles = StyleSheet.create({
	sections: {
		alignItems: "center",
	},
});

const BUTTONS = [
	{title: "contactsettings.settings", trigger: "owncontactsettings:trigger:view", icon: "edit"},
];

export default function ContactSettings(props) {
	const {
		localize,
	} = useResources();

	const contactSettingsTitle = localize("contactsettings.title");
	return (
		<View style={styles.sections}>
			<ButtonGridGeneric buttons={BUTTONS} title={contactSettingsTitle} contact={props.contact} />
		</View>
	);
}
