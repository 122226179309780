/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";
import {
	View,
} from "react-native";
import ResponsiveStylesheet from "react-native-responsive-stylesheet";
import StyledButton from "ae-base/Base/StyledButton";

import Style from "ae-style";

import { useResources } from "@aetonix/hooks";

var styles = ResponsiveStylesheet.createSized("min-direction", {
	0: {
		languageBar: {
			marginTop: Style.layout.marginSmall,
			flexWrap: "wrap",
			flexDirection: "row",
			justifyContent: "space-around"
		},
	},
	512: {
		languageBar: {
			marginHorizontal: Style.layout.marginSmall,
		},
	}
});

var LANGUAGES = [
	"en",
	"fr",
	"cn_s",
	"cn_t",
	"es",
	"pt",
	"ta"
];

export default function LoginLanguageBar(props) {
	const { dispatch, localize } = useResources();
	const activeLanguage = props.language;

	const languageButtons = LANGUAGES.map((language) => {
		const contrast = "contrast";
		const title = localize("languages." + language);
		const setLanguage = dispatch("localization:trigger:set", {
			language: language
		});

		let styleList = {};
		if (language === activeLanguage) {
			styleList = {
				borderWidth: 1,
				borderColor: Style.colors.white,
				paddingVertical: 3,
				paddingHorizontal: 3
			};
		} else {
			styleList = {
				paddingVertical: 3
			};
		}

		return (
			<StyledButton id={"languageBar_" + language} key={language} style={styleList} clear contrast={contrast} title={title}  onPress={setLanguage} />
		);
	});

	return (
		<View style={styles.languageBar}>
			{languageButtons}
		</View>
	);
}