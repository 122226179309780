import React from "react";
import {
	StyleSheet,
	View,
	Dimensions,
} from "react-native";

import Style from "ae-style";

import StyledText from "ae-base/Base/StyledText";
import StyledIcon from "ae-base/Base/StyledIcon";

import PersonImage from "../../Calling/PersonImage";
import VideoView from "../../Calling/VideoView";

var styles = StyleSheet.create({
	personRadius: {
		borderRadius: Style.layout.atouchaway.border,
		overflow: "hidden",
	},
	personLabel: {
		flex: 1,
		justifyContent: "center",
		flexDirection: "row",
		position: "absolute",
		left: 0,
		right: 0,
		bottom: 0,
		backgroundColor: "rgba(255, 255, 255, 0.8)",
		paddingVertical: 16
	},
	standardPadding: {
		paddingHorizontal: 24,
	},
	tinyPadding: {
		paddingHorizontal: 6,
	},
	labelMessage: {
		flex: 1,
		paddingLeft: Style.layout.paddingMedium
	},
	hidden: {
		width: 1,
		height: 1,
		opacity: 0,
		position: "absolute",
	},
	tiny: {
		padding: 0,
	}
});

const MIN_WIDTH = 280;

export default function Person(props) {
	var who = props.who;
	var message = props.message;
	var stream = props.stream;
	var dimensions = Dimensions.get("window");

	var defaultSize = Math.max(
		Math.min(dimensions.width / 2, dimensions.height / 2),
		MIN_WIDTH
	);
	var size = props.size || defaultSize;
	var tinyMode = size < MIN_WIDTH;

	var containerStyle = [styles.personRadius, {
		width: size,
		height: size,
	}];

	var video = stream ? (
		<View style={styles.hidden}>
			<VideoView stream={stream} />
		</View>
	) : null;

	return (
		<View style={containerStyle}>
			{video}
			<PersonImage who={who} />
			<View style={[styles.personLabel, !tinyMode ? styles.standardPadding : styles.tinyPadding]}>
				{!tinyMode && <StyledIcon name="rotaryphone" black hugeHeading />}
				<View style={styles.labelMessage}>
					<StyledText bold black>{message}</StyledText>
				</View>
			</View>
		</View>
	);
}
