import React from "react";
import {
	View,
	StyleSheet,
} from "react-native";

import StyledText from "ae-base/Base/StyledText";

var styles = StyleSheet.create({
	value: {
		flex: 0.6,
		flexBasis: 0.6,
	},
	parallelValue: {
		flex: 0.2,
		flexBasis: 0.2,
	},
});

export default function Value(props){
	var styledValue = [styles.value];
	var isParallel = props.parallel;
	if(isParallel){
		styledValue = [styles.parallelValue];
	}

	return (
		<View style={styledValue}>
			<StyledText black style={styles.textStyle}>{props.children}</StyledText>
		</View>
	);
}
