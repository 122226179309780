import React, { forwardRef, useEffect } from "react";
import { createStyled } from '@emotion/primitives-core';
import { Pressable, ScrollView, StyleSheet, View, } from 'react-native';

const styled = createStyled(StyleSheet);

const Container = styled(ScrollView)({
    height: 'auto',
    maxHeight: "80%",
    borderColor: "green",
    backgroundColor: "white",
    flexDirection: "column",
    alignSelf: "center",
    borderRadius: 20,
    position: 'absolute',
    bottom: 130, /* @reest Fkn gross, this is NOT ok. No time to fix rn */
    maxWidth: 400,
    minWidth: 400,
    zIndex: 1,
    borderBottomWidth: 0,
    padding: 5,
});

const Overlay = styled(Pressable)({
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 1,
    backgroundColor: 'black',
    opacity: 0.3,
});

export default forwardRef((props, ref) => {
    const { children, onClose, hasClickedOutside } = props;

    useEffect(() => {
        const onClickOutside = event => {
            if (hasClickedOutside(event)) {
                onClose && onClose();
            }
        }

        document.addEventListener("mousedown", onClickOutside);
        return () => {
            document.removeEventListener("mousedown", onClickOutside);
        };
      }, [hasClickedOutside, onClose]);

    return (
        <>
            <Overlay/>
            <Container ref={ref}>
                {children}
            </Container>
        </> 
    );
});