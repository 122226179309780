import React from "react";

import Item from "../Item";
import Section from "../Section";
import prop from "prop";

import { useResources } from "@aetonix/hooks";

var getNextPlannedDateValue = prop("NextPlannedDate");
var getSurgicalDevice = prop("SurgicalDevice");
var getAssistiveDevice = prop("AssistiveDevice");
var getHealthEducationCounselling = prop("HealthEducationCounselling");
var getSelfMonitoringRoutines = prop("SelfMonitoringRoutines");
var getOtherTreatments = prop("OtherTreatments");

export default function Othertreatments(props) {
	const {
		localize,
	} = useResources();

	var careplan = props.careplan;
	var othertreatments = localize("othertreatments.othertreatments");
	var significantsurgeriesandorimplateddevices = localize("othertreatments.significantsurgeriesandorimplateddevices");
	var healtheducationorcounselling = localize("othertreatments.healtheducationorcounselling");
	var assistivedevices = localize("othertreatments.assistivedevices");
	var selfmonitoringroutines = localize("othertreatments.selfmonitoringroutines");
	var nextplandate = localize("othertreatments.nextplandate");

	var getNextPlannedDate = careplan.NextPlannedDate ? nextplandate + getNextPlannedDateValue(careplan) : null;

	var councellingValues = [];
	if(getNextPlannedDate)
		councellingValues.push(getNextPlannedDate);
	if(getHealthEducationCounselling(careplan))
		councellingValues.push(getHealthEducationCounselling(careplan));

	return (
		<Section title={othertreatments}>
			<Item title={significantsurgeriesandorimplateddevices} values={getSurgicalDevice(careplan)} />

			<Item title={healtheducationorcounselling} values={councellingValues} />

			<Item title={assistivedevices} values={getAssistiveDevice(careplan)} />

			<Item title={selfmonitoringroutines} value={getSelfMonitoringRoutines(careplan)} />

			<Item title={othertreatments} value={getOtherTreatments(careplan)} />
		</Section>
	);
}
