import React from "react";

import ManagedSection from "../ManagedSection";

import Form from "ae-forms";

import SimplifiedSettingsSchema from "../../../../schemas/SimplifiedSettings";

import { useResources } from "@aetonix/hooks";

export default function SimplifiedSettings(props) {
	const {
		localize,
		dispatch,
	} = useResources();

	var model = props.model;
	var atouchaway = model.manage.simplified || {};
	var contact = model.manage.account;
	var toast = model.toast;
	var atouchawaysettings = localize("atouchawaysettings.atouchawaysettings");

	var loadedKey = Object.keys(atouchaway || {}).length;

	return (
		<ManagedSection online={model.online} key={loadedKey} contact={contact} title={atouchawaysettings} toast={toast}>
			<Form schema={SimplifiedSettingsSchema} defaultData={atouchaway}  onSubmit={dispatch("managedsimplified:trigger:update")} />
		</ManagedSection>
	);
}
