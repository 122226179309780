import React from "react";
import {
	View,
	StyleSheet,
} from "react-native";

import Style from "ae-style";
import StyledButton from "ae-base/Base/StyledButton";
import ManagedSection from "./ManagedSection";
import Heading from "ae-base/Base/Heading";
import Contact from "../../Contacts/Contact";

import { useResources } from "@aetonix/hooks";

var styles = StyleSheet.create({
	contacts: {
		marginTop: Style.layout.marginLarge,
		marginBottom: Style.layout.marginContentVertical,
	},
});

export default function Managedurgencypotentials(props) {
	const {
		localize,
		dispatch,
	} = useResources();
	var model = props.model;
	var manage = model.manage || {};
	var toast = model.toast;
	var account = manage.account;
	var urgencypotentials = model.manage.urgencypotentials || [];

	var screen = model.size;
	var availableurgencycontacts = localize("managedurgencypotentials.availableurgencycontacts");
	var add = localize("managedurgencypotentials.add");
	var nopotentialurgencycontactsleft = localize("managedurgencypotentials.nopotentialurgencycontactsleft");
	var addurgencycontact = localize("managedurgencypotentials.addurgencycontact");

	var renderUrgencyPotentials = urgencypotentials.length ? (
		<View>
			<Heading title={availableurgencycontacts} screen={screen} />
			<View style={styles.contacts}>
				{urgencypotentials.map(function(contact){
					var id = contact._id;
					var addUrgencyContact = dispatch("managedurgencycontacts:trigger:add", {
						who: id,
					});
					return (
						<Contact contact={contact} key={id} screen={screen} >
							<StyledButton icon="plus" title={add} primary onPress={addUrgencyContact} />
						</Contact>
					);
				})}
			</View>
		</View>
	) : (
		<Heading title={nopotentialurgencycontactsleft} screen={screen} />
	);

	return (
		<ManagedSection online={model.online} contact={account} title={addurgencycontact} toast={toast}>
			{renderUrgencyPotentials}
		</ManagedSection>
	);
}
