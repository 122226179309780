import React from "react";
import {
	View,
	StyleSheet,
} from "react-native";

import AtaContainer from "../AtaContainer";

var styles = StyleSheet.create({
	outer: {
		alignItems: "center",
		padding: 24,
		backgroundColor: "white",
		borderRadius: 24,
		width: 600
	},
});

export default function NoticePageBase(props) {
	return (
		<AtaContainer center>
			<View style={styles.outer}>
				{props.children}
			</View>
		</AtaContainer>
	);
}
