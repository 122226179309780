import React from "react";
import {
	StyleSheet,
	View,
	Platform,
} from "react-native";

import StyledIconButton from "ae-base/Base/StyledIconButton";

import { useResources } from "@aetonix/hooks";

var styles = StyleSheet.create({
	editButton: {
		position: "absolute",
		top: 0,
		right: 0,
		zIndex: 1,
	},
	view: {
		flexDirection: "row",
		justifyContent: "center",
	},
	indexed: {
		zIndex: 1,
	},
});

export default function ViewButton(props) {
	const {
		dispatch,
	} = useResources();

	var keys = props.keys;

	var viewChanges = dispatch("managedcareplanchangeviewer:trigger:view", {
		keys: keys,
	});
	var notificationView = [styles.view];
	if(Platform.OS !== "android")
		notificationView.push(styles.indexed);

	return (
		<View style={notificationView}>
			<StyledIconButton icon={"info"} style={styles.editButton} onPress={viewChanges} color={"alert"} smallHeading />
		</View>
	);
}
