import React from "react";

import Connected from "./States/Connected/Connected";
import Connecting from "./States/Connecting";
import Incoming from "./States/Incoming";
import Outgoing from "./States/Outgoing";
import Busy from "./States/Busy";
import Loader from "ae-base/Base/Loader";

export default function Calling(props) {
	var model = props.model;

	var calling = model.calling;
	var hangupreply = model.hangupreply;
	var state = calling.state;
	var conference_state = calling.conference_state;

	var screen = model.size;

	var callInputSwitcher = model.callinputswitcher;
	var callRedirector = model.callredirector;
	var conferenceInviter = model.conferenceinviter;
	var managedAccount = model.manage.account;
	var magnifying = model.magnifying;
	var auidoOutput = model.audiooutput;
	var availability = model.availability;
	var toast = model.toast;
	var banner = model.banner;
	var orientation = model.orientation;
	var available_for_call = model.personal.available;

	var callgroup = model.callgroupscalling.group;
	var managedEnd = (model.manage.managedEnd || {}).end;

	if(state === "idle") return (
		<Loader />
	);
	if(state === "calling") return (
		<Outgoing calling={calling} callgroup={callgroup} />
	);
	if(state === "receiving") {
		// Suppresses display of 'incoming call' page is user is in the
		// 'appear offline' mode
		if (available_for_call) {
			return (<Incoming calling={calling} banner={banner} screen={screen} hangupreply={hangupreply} />);
		}else{
			return null;
		}
	}
	if(state === "busy" && !callgroup) return (
		<Busy calling={calling} toast={toast} />
	);
	if(state === "busy" && callgroup) return (
		<Outgoing calling={calling} callgroup={callgroup} />
	);
	if((state === "connecting") && !calling.streams.remote) return (
		<Connecting calling={calling} key={state + conference_state} callgroup={callgroup} />
	);

	return (
		<Connected calling={calling}
			callInputSwitcher={callInputSwitcher}
			callRedirector={callRedirector}
			conferenceInviter={conferenceInviter}
			managedAccount={managedAccount}
			managedEnd={managedEnd}
			magnifying={magnifying}
			auidoOutput={auidoOutput}
			availability={availability}
			orientation={orientation}
			toast={toast}
			screen={screen}
			state={state}
			callgroup={callgroup}
		/>
	);
}
