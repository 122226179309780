import React from "react";
import {
	StyleSheet,
	View,
} from "react-native";

import Style from "@aetonix/styles";

var styles = StyleSheet.create({
	item: {
		flexDirection: "row",
		alignItems: "stretch",
		justifyContent: "flex-start",
		padding: Style.layout.paddingSmall,
		paddingRight: 20,
		backgroundColor: Style.colors.itemLightGray,
	},
	alternateItem: {
		backgroundColor: Style.colors.itemGray,
	},
});

export default function Item(props){
	var styleListSubItem = [styles.item];
	var isAlternate = props.alternate;

	if(isAlternate){
		styleListSubItem.push(styles.alternateItem);
	}

	return (
		<View style={styleListSubItem}>
			{props.children}
		</View>
	);
}
