import React from "react";
import {
	View,
} from "react-native";
import prop from "prop";

import Link from "ae-base/Base/Link";

import Items from "../Items";
import Item from "../Item";
import Label from "../Label";
import Value from "../Value";
import Summarized from "../Summarized";
import SectionTitle from "../SectionTitle";
import GroupItems from "../GroupItems";

import { useResources } from "@aetonix/hooks";

var getVisitDate = prop("VisitDate");
var getDischargeDate = prop("DischargeDate");
var getVisitReason = prop("VisitReason");
var getComplications = prop("Complications");
var getHospitalPhysicianName = prop("HospitalPhysicianName");
var getHospitalPhysicianPhone = prop("HospitalPhysicianPhone");
var getHospitalAdviceNote = prop("HospitalAdviceNote");
var getFollowupAppointmentMadeWith = prop("FollowupAppointmentMadeWith");
var getFollowupAppointmentDate = prop("FollowupAppointmentDate");
var getHospitalName = prop("Hospital.Name");
var getVisitTypeName = prop("VisitType.Name");

export default function Hospitalvisits(props) {
	const {
		localize,
	} = useResources();

	var careplan = props.careplan;
	var currentSection = props.currentSection;
	var permissions = props.permissions;
	var changes = props.changes;
	var showCareplanDetail = props.showCareplanDetail;
	var ownCareplan = props.ownCareplan;
	var hospitalvisitsinformation = localize("hospitalvisits.hospitalvisitsinformation");

	var hospitalvisits = careplan.HospitalVisit || [];
	var renderHospitalvisit = hospitalvisits.map(function(hospitalvisit, index){
		var isChanged = changes["HospitalVisit." + index] || index === -1;
		var hasChanged = permissions >= 2 && isChanged;
		return (
			<Hospitalvisit key={index} hospitalvisit={hospitalvisit} index={index} currentSection={currentSection} hasChanged={hasChanged} ownCareplan={ownCareplan} showCareplanDetail={showCareplanDetail} />
		);
	}
	);

	return (
		<View>
			<SectionTitle name={hospitalvisitsinformation} section="HospitalVisit" permissions={permissions} changes={changes} />
			{renderHospitalvisit}
		</View>
	);
}

function Hospitalvisit(props){
	const { localize } = useResources();
	var hospitalvisit = props.hospitalvisit;
	var index = props.index;
	var currentSection = props.currentSection;
	var hasChanged = props.hasChanged;
	var showCareplanDetail = props.showCareplanDetail;
	var ownCareplan = props.ownCareplan;

	var hospitalname = localize("hospitalvisits.hospitalname");
	var visitdate = localize("hospitalvisits.visitdate");
	var typeofvisit = localize("hospitalvisits.typeofvisit");
	var dateofdischarge = localize("hospitalvisits.dateofdischarge");
	var reasonofvisit = localize("hospitalvisits.reasonofvisit");
	var complications = localize("hospitalvisits.complications");
	var hospitalphysicianname = localize("hospitalvisits.hospitalphysicianname");
	var hospitalphysiciantelephonenumber = localize("hospitalvisits.hospitalphysiciantelephonenumber");
	var keyadvicefromhospitalphysician = localize("hospitalvisits.keyadvicefromhospitalphysician");
	var followupappointmentmadewith = localize("hospitalvisits.followupappointmentmadewith");
	var dateoffollowupappointment = localize("hospitalvisits.dateoffollowupappointment");

	return (
		<GroupItems>
			<Items hasChanged={hasChanged} data={"HospitalVisit." + index}>
				<Item>
					<Label>{hospitalname}</Label>
					<Value>{getHospitalName(hospitalvisit)}</Value>
				</Item>

				<Item alternate>
					<Label>{visitdate}</Label>
					<Value>{getVisitDate(hospitalvisit)}</Value>
				</Item>

				<Item>
					<Label>{typeofvisit}</Label>
					<Value>{getVisitTypeName(hospitalvisit)}</Value>
				</Item>

				<Summarized currentSection={currentSection} section={"HospitalVisit." + index} ownCareplan={ownCareplan} showCareplanDetail={showCareplanDetail}>
					<Item alternate>
						<Label>{dateofdischarge}</Label>
						<Value>{getDischargeDate(hospitalvisit)}</Value>
					</Item>

					<Item>
						<Label>{reasonofvisit}</Label>
						<Value>{getVisitReason(hospitalvisit)}</Value>
					</Item>

					<Item alternate>
						<Label>{complications}</Label>
						<Value>{getComplications(hospitalvisit)}</Value>
					</Item>

					<Item>
						<Label>{hospitalphysicianname}</Label>
						<Value>{getHospitalPhysicianName(hospitalvisit)}</Value>
					</Item>

					<Item alternate>
						<Label>{hospitalphysiciantelephonenumber}</Label>
						<Value>
							<Link title={getHospitalPhysicianPhone(hospitalvisit)} uri={"tel:" + getHospitalPhysicianPhone(hospitalvisit)} />
						</Value>
					</Item>

					<Item>
						<Label>{keyadvicefromhospitalphysician}</Label>
						<Value>{getHospitalAdviceNote(hospitalvisit)}</Value>
					</Item>

					<Item alternate>
						<Label>{followupappointmentmadewith}</Label>
						<Value>{getFollowupAppointmentMadeWith(hospitalvisit)}</Value>
					</Item>

					<Item>
						<Label>{dateoffollowupappointment}</Label>
						<Value>{getFollowupAppointmentDate(hospitalvisit)}</Value>
					</Item>
				</Summarized>
			</Items>
		</GroupItems>
	);
}
