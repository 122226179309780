import React, { useContext, useMemo, useCallback } from 'react';
import MeetingContext from "../../context/MeetingContext";
import MeetingAlert from "../../components/Alert/MeetingAlert";
import Alerts from "../../components/Alert/Alerts";

export default ({ banners, onClear }) => {
	const meetingManager = useContext(MeetingContext);

	const meetingBanners = useMemo(() => banners?.filter?.(banner => banner.banner_type === 'info:meeting:new' || !!banner?.read) ?? [], [banners]);

	const onJoinMeeting = useCallback(meetingId => {
		meetingManager.onJoinMeeting(meetingId);
	}, [meetingManager]);

    const alerts = useMemo(() => meetingBanners.map((banner, index) => {
        const { from: { _id: fromId }, banner_type, content = {} } = banner;
        const { meeting: { _id: id, title } } = content || {};

        return (
            <MeetingAlert title={title} onAccept={() => onJoinMeeting(id)} onClose={() => onClear(fromId, banner_type)} key={`meeting-banner-${index}`}/>
        );
    }), [meetingBanners, onJoinMeeting, onClear]);

	return !meetingManager?.isMinimized && <Alerts>{ alerts }</Alerts>;
}