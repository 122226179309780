import React from "react";
import {
	View,
	StyleSheet,
	Image,
} from "react-native";

import StyledText from "ae-base/Base/StyledText";
import ImageUrl from "ae-base/Base/ImageUrl";
import Style from "ae-style";

import { useResources } from "@aetonix/hooks";

var styles = StyleSheet.create({
	callingPage: {
		flex: 1,
		flexDirection: "row",
	},
	callimage: {
		flex: 8.5,
	},
	contactImage: {
		position: "absolute",
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
	},
	contactName: {
		alignItems: "center",
		backgroundColor: "rgba(0,0,0,0.77)",
		position: "absolute",
		bottom: 0,
		left: 0,
		right: 0,
		padding: Style.layout.paddingSlim,
	},
	connectingText: {
		alignItems: "center",
		backgroundColor: "rgba(0,0,0,0.77)",
		position: "absolute",
		left: 0,
		right: 0,
		padding: Style.layout.paddingSlim,
	},
});

export default function CallPage(props) {
	var who = props.who;
	var state = props.state;

	return (
		<View style={styles.callingPage}>
			{props.children}
			<CallView state={state} who={who} />
		</View>
	);
}

function CallView(props) {
	const {
		localize,
		config,
	} = useResources();

	var state = props.state;
	var who = props.who;

	var profileImage = (who.image_medium || who.image);

	var bottomText = (state === "busy") ? (
		localize("atouchawaycalling.isbusy", who)
	) : (
		who.lname + ", " + who.fname
	);

	var contectingText = localize("atouchawaycalling.connectingto", who);
	var renderConnectingText = (state === "connecting") ? (
		<View style={styles.connectingText}>
			<StyledText emphasized contrast>{contectingText}</StyledText>
		</View>
	) : null;

	return (
		<View style={styles.callimage}>
			<Image source={ImageUrl(profileImage, config)} style={styles.contactImage} />
			{renderConnectingText}
			<View style={styles.contactName}>
				<StyledText emphasized contrast>{bottomText}</StyledText>
			</View>
		</View>
	);
}
