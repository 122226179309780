
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 16, 2018
 * For information or permission request, email info@aetonixsystems.com
 */

export default {
	title: "Blood pressure",
	description: "glucose.manual_entry",
	type: "object",
	properties: {
		systolic: {
			type: "number",
			description: "bloodpressure.systolicdescription",
			placeholder: "bloodpressure.systolicmessage",
			minimum: 1,
			maximum: 300,
		},
		diastolic: {
			type: "number",
			description: "bloodpressure.diastolicdescription",
			placeholder: "bloodpressure.diastolicmessage",
			minimum: 1,
			maximum: 200
		},
		bpPulserate: {
			type: "number",
			description: "bloodpressure.pulseratedescription",
			placeholder: "bloodpressure.pulseratemessage",
			minimum: 30,
			maximum: 200
		},
		comment: {
			description: "bloodpressure.commenttitle",
			type: "string",
			multiline: true,
			placeholder: "bloodpressure.comment"
		},
	},
	required: ["systolic", "diastolic", "bpPulserate"]
};
