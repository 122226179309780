import React from "react";
import {
	View,
	StyleSheet,
} from "react-native";

import StyledText from "ae-base/Base/StyledText";

import Style from "@aetonix/styles";

import MainScroll from "../../Layout/MainScroll";

var styles = StyleSheet.create({
	container: {
		flex: 1,
		marginHorizontal: Style.layout.marginMedium,
		flexDirection: "column"
	},
	content: {
		flex: 1,
		padding: Style.layout.paddingButton,
		borderRadius: Style.layout.atouchaway.border,
		borderWidth: 2,
		borderColor: Style.colors.tertiary,
	},
	title: {
		alignItems: "center",
		justifyContent: "center",
		marginBottom: Style.layout.marginHuge,
		flex: 0.2
	},
	scroll: {
		flex: 1,
		paddingHorizontal: Style.layout.paddingButton,
	}
});

export default function Section(props){
	return (
		<View style={styles.container}>
			<View style={styles.title}>
				<StyledText large tertiary>{props.title}</StyledText>
			</View>
			<View style={styles.content}>
				<MainScroll style={styles.scroll}>
					{props.children}
				</MainScroll>
			</View>
		</View>
	);
}
