
import VideoTile from '../VideoTile';
import React, { useRef, useEffect } from 'react';
import { useAudioVideo, useApplyVideoObjectFit, useAttendeeStatus } from 'amazon-chime-sdk-component-library-react';

export default ({
	name,
	className,
	nameplate,
	isFeatured,
	chimeAttendeeID,
	tileId,
	image,
	userId,
	...rest
}) => {
	const audioVideo = useAudioVideo();
	const videoEl = useRef(null);
	const { muted: isMuted } = useAttendeeStatus(chimeAttendeeID);

	useApplyVideoObjectFit(videoEl);

	useEffect(() => {
		if (!audioVideo || !videoEl.current) {
			return;
		}

		tileId && audioVideo.bindVideoElement(tileId, videoEl.current);

		return () => {
			const tile = audioVideo.getVideoTile(tileId);
			if (tile) {
				audioVideo.unbindVideoElement(tileId);
			}
		};
	}, [audioVideo, tileId]);

	return (
		<VideoTile
			{...rest}
			userId={userId}
			image={image}
			isFeatured={isFeatured}
			externalId={chimeAttendeeID}
			objectFit="contain"
			version="web"
			type={isFeatured ? 'featured' : 'standard'}
			isMuted={isMuted}
			isVideoEnabled={!!tileId}
			nameplate={nameplate}
			{ ...(tileId ? { videoComponent: <video ref={videoEl} /> } : {})}
		/>
	);
};