import PropTypes from "prop-types";
import React from "react";
import {
	View,
	StyleSheet,
	TouchableOpacity,
	Image,
	Dimensions,
} from "react-native";

import StyledText from "ae-base/Base/StyledText";
import StyledIcon from "ae-base/Base/StyledIcon";
import StyledButton from "ae-base/Base/StyledButton";
import ImageUrl from "ae-base/Base/ImageUrl";
import NotificationIcon from "ae-base/Base/NotificationIcon";
import Divider from "../../Layout/Divider";

import MainScroll from "../../Layout/MainScroll";

import Style from "@aetonix/styles";
import sortNames from "../../../utils/SortNames";

import { useResources } from "@aetonix/hooks";

var styles = StyleSheet.create({
	container: {
		backgroundColor: Style.colors.darkGreyBlack,
		padding: Style.layout.paddingButton,
		marginRight: Style.layout.marginMedium,
		flexBasis: 235,
	},
	button: {
		marginBottom: Style.layout.marginMedium,
		paddingHorizontal: Style.layout.paddingSlim,
		paddingBottom: Style.layout.paddingButton,
		alignItems: "center",
		flexDirection: "row",
	},
	icon: {
		marginRight: Style.layout.marginMedium,
	},
	scroll: {
		flexDirection: "column",
	},
	textStyle: {
		flex: 1,
		flexWrap: "wrap",
	},
	imageStyle: {
		width: 40,
		height: 40,
		alignItems: "center",
		justifyContent: "center",
		overflow: "hidden",
		borderRadius: 60,
		marginRight: Style.layout.marginSmall,
	},
	badge: {
		right: 1,
	},
	subHeading: {
		flex: 1,
		margin: Style.layout.marginSmall,
	},
	back: {
		marginTop: Style.layout.marginSmall,
		padding: Style.layout.marginContentSmall,
		backgroundColor: Style.colors.secondary,
		borderRadius: 10,
		alignSelf: "stretch",
	},
});

const MIN_WIDTH = 675;
export default function ContactList(props) {
	const {
		localize,
		dispatch,
	} = useResources();

	var groups = props.groups;
	var contacts = props.contacts;
	var narrowMode = Dimensions.get("window").width < MIN_WIDTH;
	var isAtouchAway = props.aTouchAway;
	var viewingId = props.viewing;
	var back_title = localize("myhealth.back");
	var go_back = () => {
		dispatch("atouchawaymobile:trigger:page", {
			page: "contacts",
		})();
		dispatch("persiststorage:delete")({ dataId: viewingId });
	};

	function renderContactsAndGroups(persons){
		var data = {
			who: persons,
		};
		if(persons.members)
			data.group = true;
		return (
			<Contact
				key={persons._id}
				contact={persons}
				onPress={dispatch("atouchawaymessaging:trigger:view", data)}
				viewing={props.viewing}
				isAtouchAway={isAtouchAway}
				missed={persons.missed}
				noIcon={narrowMode}
			/>
		);
	}

	var renderGroups = groups.map(renderContactsAndGroups);
	var renderContacts = sortNames(contacts).map(renderContactsAndGroups);

	var renderGroupHeading = groups.length > 0 ? (
		<View style={styles.subHeading}>
			{isAtouchAway ? (<StyledText light>{localize("atouchawaymessaging.groups")}</StyledText>) :
				(<StyledText tertiary>{localize("atouchawaymessaging.groups")}</StyledText>)}
			<Divider tertiary />
		</View>
	) : null;

	return (
		<View style={styles.container}>
			<MainScroll atouchaway style={styles.scroll}>
				{renderGroupHeading}
				{renderGroups}
				<View style={styles.subHeading}>
					{isAtouchAway ? (<StyledText light>{localize("atouchawaymessaging.contacts")}</StyledText>) :
						(<StyledText tertiary>{localize("atouchawaymessaging.contacts")}</StyledText>)}
					<Divider tertiary />
				</View>
				{renderContacts}
			</MainScroll>
			<StyledButton icon={"chevron-left"} style={styles.back} centered contrast tertiary title={back_title} onPress={go_back} />
		</View>
	);
}

function Contact(props) {
	const {
		config,
	} = useResources();

	var contact = props.contact;
	var name = contact.lname + ", " + contact.fname;
	var onPress = props.onPress;
	var viewing = props.viewing;
	var active = viewing === contact._id;
	var missed = props.missed;
	var noIcon = props.noIcon;
	var isAtouchAway = props.isAtouchAway;
	var renderIcon = null;

	if (contact.members && (isAtouchAway === true)) {
		name = contact.name;
		renderIcon = (<StyledIcon light name={"usergroup"} style={styles.icon} />);
	} else if (contact.members && (isAtouchAway === (false || undefined))) {
		name = contact.name;
		renderIcon = (<StyledIcon tertiary name={"usergroup"} style={styles.icon} />);
	} else
		renderIcon = (<Image resizeMethod="resize" source={ImageUrl(contact.image, config)} style={styles.imageStyle} />);


	var renderMissed = missed ? (
		<NotificationIcon style={styles.badge} title={missed} />
	) : null;

	return (
		<TouchableOpacity style={styles.button} onPress={onPress}>
			{!noIcon ? renderIcon : null}
			{isAtouchAway ? (<StyledText style={styles.textStyle} light={!active} contrast={active}>{name}</StyledText>) :
				(<StyledText style={styles.textStyle} tertiary={!active} contrast={active}>{name}</StyledText>)}
			{renderMissed}
		</TouchableOpacity>
	);
}
