import React from "react";

import ProfileSection from "../ProfileSection";
import PersonalInfo from "./PersonalInfo";
import BlockView from "ae-base/Base/BlockView";
import { useResources } from "@aetonix/hooks";

export default function AccountSettings(props){
	const { localize } = useResources();
	var preferences = props.preferences;
	var display = props.profilesection.displaying.account;

	var title = localize("accountsettings.title");
	var renderBody = null;

	if(display)
		renderBody = (
			<BlockView>
				<PersonalInfo preferences={preferences} />
			</BlockView>
		);

	return (
		<BlockView>
			<ProfileSection title={title} section="account" display={display} />
			{renderBody}
		</BlockView>);
}
