export default {
	title: "SimplifiedSettings",
	description: "schemas_atouchaway_settings_title",
	type: "object",
	properties: {
		myhealth_sections: {
			order: 10,
			type: "object",
			properties: {
				activity: {
					order: 1,
					type: "boolean",
					inputType: "switch",
					description: "myhealth.activity",
					default: true
				},
				bloodsugar: {
					order: 3,
					type: "boolean",
					inputType: "switch",
					description: "myhealth.bloodsugar",
					default: true
				},
				weight: {
					order: 6,
					type: "boolean",
					inputType: "switch",
					description: "myhealth.weight",
					default: true
				},
				bloodpressure: {
					order: 9,
					type: "boolean",
					inputType: "switch",
					description: "myhealth.bloodpressure",
					default: true
				},
				bloodoxygen: {
					order: 12,
					type: "boolean",
					inputType: "switch",
					description: "myhealth.oxygen",
					default: true
				},
				bodytemperature: {
					order: 15,
					type: "boolean",
					inputType: "switch",
					description: "myhealth.bodytemp",
					default: true
				}
			}
		}
	}
};
