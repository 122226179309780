export default {
	title: "BannerSound",
	description: "schemas_banner_sound_title",
	type: "object",
	properties: {
		enabled: {
			description: "profile.bannersound.title",
			value_description: "profile.bannersound",
			type: "boolean",
			inputType: "switch",
			format: "switch",
		}
	}
};
