/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";
import {
	View,
	StyleSheet,
} from "react-native";

import Notification from "../../Layout/Notification";
import StyledText from "ae-base/Base/StyledText";
import StyledButton from "ae-base/Base/StyledButton";
import { useResources } from "@aetonix/hooks";

import Style from "ae-style";

var styles = StyleSheet.create({
	text_style: {
		color: Style.colors.white,
		paddingRight: Style.layout.paddingSmall,
		justifyContent: "center",
	},
	button_container: {
		marginLeft: Style.layout.marginSmall,
		justifyContent: "center",
	},
	text_container: {
		flex: 1,
	},
});

export default function Unverified(props){
	const { localize, dispatch } = useResources();
	const personal = props.personal;
	const reverify = props.reverify;
	const email = personal.email;
	const reverifySent = reverify.sent;
	const color = Style.colors.primary;
	const resend = localize("unverified.resend");
	const content = localize("unverified.content") + email;

	const onReverify = dispatch("desktop:trigger:reverify");

	const sendButton = !reverifySent ? <StyledButton id={"resend"} white title={resend} onPress={onReverify} /> : null;

	return (
		<Notification
			color={color}
			iconColor={"white"}>
			<View style={styles.text_container}>
				<StyledText style={styles.text_style} emphasized>
					{content}
				</StyledText>
			</View>
			<View style={styles.button_container}>
				{sendButton}
			</View>
		</Notification>
	);
}
