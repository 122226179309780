import React from 'react';
import { View, Text, Image, StyleSheet } from 'react-native';
import { createStyled } from '@emotion/primitives-core'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faMicrophone, faMicrophoneSlash, faVideo, faVideoSlash, faUsers, faPhoneSlash, faEllipsisH, faAngleLeft, faDesktop, faComment} from '@fortawesome/free-solid-svg-icons';

const styled = createStyled(StyleSheet);

export const icons = {
    microphoneOff: faMicrophoneSlash ,
    microphoneOn: faMicrophone,
    videoOn: faVideo,
    users: faUsers,
    phoneOff: faPhoneSlash,
    ellipsis: faEllipsisH,
    default: faAngleLeft,
    desktopOn: faDesktop,
    message: faComment,
    messageWhite: faComment,
    videoOff: faVideoSlash,
}

export default ({ icon, ...rest }) => icon === "phoneOff" ? <FontAwesomeIcon icon={ icons.phoneOff || icons.default} color='red' size={40} {...rest}/> : <FontAwesomeIcon icon={ icons?.[icon] || icons.default} size={40} {...rest}/>

