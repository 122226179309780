import React from "react";
import {
	StyleSheet,
} from "react-native";

import StyledButton from "ae-base/Base/StyledButton";
import BlockView from "ae-base/Base/BlockView";
import Contact from "../../Contacts/Contact";
import CardPage from "../../Layout/CardPage";

import StyledText from "ae-base/Base/StyledText";

import Style from "ae-style";
import sortNames from "../../../utils/SortNames";

import { useResources } from "@aetonix/hooks";

var styles = StyleSheet.create({
	topHeading: {
		marginVertical: Style.layout.marginSmall,
	},
});

export default function GroupInviteContact(props) {
	const {
		localize,
	} = useResources();
	var model = props.model;
	var contactList = model.contacts.contacts;
	var managedContacts = model.contacts.managed;
	var members = model.groupchat.group.members;

	var screen = model.size;

	var addContactTitle = localize("groupchat.addContact");
	var goback = localize("groupchat.backMembers");

	var addableContacts = contactList.filter(function(contact){
		return !members.some(function(member){
			return member._id === contact._id;
		});
	});

	var contacts = sortNames(addableContacts).map(function(contact){
		return (
			<ContactItem contact={contact} screen={screen} />
		);
	});

	var addableManaged = managedContacts.filter(contact => {
		return !members.some(member => {
			return member._id === contact._id;
		});
	});

	var managed = sortNames(addableManaged).map(contact => (<ContactItem contact={contact} screen={screen} />));

	var contactTitle = contacts.length ? (<StyledText style={styles.topHeading} smallHeading>{localize("managedcontacts.contact_s")}</StyledText>) : null;
	var managedTitle = managed.length ? (<StyledText style={styles.topHeading} smallHeading>{localize("managedcontacts.manageduserslist")}</StyledText>) : null;

	return (
		<CardPage title={addContactTitle} titleIcon={"plus-circle"} backTitle={goback} screen={screen}>
			{contactTitle}
			{contacts}
			{managedTitle}
			{managed}
		</CardPage>
	);
}

function ContactItem(props) {
	const {
		localize,
		dispatch,
	} = useResources();
	var contact = props.contact;
	var screen = props.screen;

	var addContactTitle = localize("groupchat.addContact");

	var addContact = dispatch("groupchat:trigger:add", {
		contact: contact._id,
	});

	return (
		<Contact contact={contact} key={contact._id} onPress={addContact} screen={screen} >
			<BlockView>
				<StyledButton id={"groupChat_addMember_" + contact._id} clear onPress={addContact} title={addContactTitle} />
			</BlockView>
		</Contact>
	);
}
