import { Text as NativeText, StyleSheet, } from 'react-native';
import React, { forwardRef } from 'react';
import { createStyled } from '@emotion/primitives-core';
import useTextSize from '../../../hooks/useTextSize/useTextSize';

const styled = createStyled(StyleSheet);

const StyledText = styled(NativeText)(props => ({ fontFamily: "Avenir", fontSize: props.size }))

export default forwardRef(({ size = 20, max = null, ...rest }, ref) => {
    const scaledSize = useTextSize(size, max);
    return <StyledText {...rest} size={scaledSize} ref={ref}/>
});