import React from "react";
import {
	View,
	StyleSheet,
	Dimensions,
} from "react-native";

import Card from "../../Layout/Card";
import Modal from "../../Layout/Modal";

import StyledButton from "ae-base/Base/StyledButton";
import OutputForm from "ae-forms/src/Outputs/OutputForm";

import BloodOxygenFollowUp from "../../../schemas/BloodOxygenFollowUp";
import CommentSchema from "../../../schemas/Comment";

import Style from "ae-style";

import { useResources } from "@aetonix/hooks";

var styles = StyleSheet.create({
	okbutton: {
		alignSelf: "flex-end",
		marginTop: Style.layout.marginBordered,
	},
	buttonContainer: {
		alignItems: "flex-end",
	},
});

var SCHEMA_MAP = {
	"bloodoxygen": BloodOxygenFollowUp,
};

const MAX_WIDTH = 800;
const MIN_WIDTH = 200;
export default function Comment(props) {
	const {
		localize,
		dispatch,
	} = useResources();
	var model = props.model;
	var ok = localize("myhealth.ok");

	var comment = model.comment;
	var showComment = comment.open;
	var closeComment = dispatch("comment:trigger:hide");

	var data = comment.data;
	var type = data.type;

	var history = data.data;

	var schema = SCHEMA_MAP[type] || CommentSchema;

	var cardStyles = [styles.card];
	var dimensions = Dimensions.get("window");
	var width = dimensions.width / 1.5;

	if(width > MAX_WIDTH)
		width = MAX_WIDTH;
	if(width < MIN_WIDTH)
		width = MIN_WIDTH;

	cardStyles.push({
		width: width,
	});

	return (
		<Modal open={showComment}>
			<Card atouchaway scroll>
				<OutputForm schema={schema} data={history} />
				<View style={styles.buttonContainer}>
					<StyledButton emphasized style={styles.okbutton} contrast title={ok} onPress={closeComment} />
				</View>
			</Card>
		</Modal>
	);
}
