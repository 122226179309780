import React from "react";
import { MeetingProvider } from "amazon-chime-sdk-component-library-react";

export default function (props) {
	return (
		<MeetingProvider>
			{props.children}
		</MeetingProvider>
	);
}
