import PropTypes from "prop-types";
import React, {
	PureComponent,
} from "react";
import {
	View,
	Dimensions,
	StyleSheet,
	Animated,
	ImageBackground,
} from "react-native";

import Style from "ae-style";
import StyledIconButton from "ae-base/Base/StyledIconButton";

import Vignette from "../../../Resources/img/vignette.png";
import { useResources } from "@aetonix/hooks";

var styles = StyleSheet.create({
	bar: {
		position: "absolute",
		top: 0,
		right: 0,
		left: 0,
		backgroundColor: "transparent",
		zIndex: 2,
	},
	backButton: {
		padding: Style.layout.paddingSmall,
	},
	videoContainer: {
		flex: 1,
		alignSelf: "stretch",
	},
	controls: {
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "space-between",
		height: 100,
		width: null,
	},
	vignette: {
		resizeMode: "stretch",
	},
	controlsContainer: {
		alignSelf: "stretch",
		alignItems: "center",
		justifyContent: "space-between",
		flexDirection: "row",
		width: null,
		margin: 12,
		marginBottom: 18,
	},
});

var videoStyle = {
	position: "absolute",
	top: 0,
	bottom: 0,
	left: 0,
	right: 0,
	width: "100%",
	height: "100%",
	objectPosition: "center center",
	objectFit: "contain",
};

export default (props) => {
	const { dispatch } = useResources();
	return <VideoViewerWeb dispatch={dispatch} {...props} />;
};

class VideoViewerWeb extends PureComponent{
	constructor(props){
		super(props);
		this.state = {
			opacity: new Animated.Value(1),
		};
		this.video = null;
		this.handle_view = handle_view.bind(this);
		this.handle_pause = handle_pause.bind(this);
		this.handle_time_update = handle_time_update.bind(this);
	}

	componentDidMount(){
		if(this.video){
			this.video.addEventListener("timeupdate", this.handle_time_update, false);
			this.video.addEventListener("pause", this.handle_pause, false);
		}
	}

	componentWillUnmount(){
		this.video.removeEventListener("timeupdate", this.handle_time_update, false);
		this.video.removeEventListener("pause", this.handle_pause, false);
	}

	render(){
		var props = this.props;
		var dispatch = props.dispatch;
		var file = props.file;
		var window = Dimensions.get("window");
		var width = window.width;
		var height = window.height;
		var noDownload = props.noDownload ? "nodownload" : "";

		var opacity = {
			opacity: this.state.opacity,
		};

		var goBack = dispatch("fileviewer:trigger:close");
		return (
			<View style={styles.videoContainer}>
				<Animated.View style={[styles.bar, opacity]}>
					<ImageBackground
						source={Vignette}
						style={[styles.controls]}
						imageStyle={[styles.vignette]}
					>
						<View style={styles.controlsContainer}>
							<StyledIconButton style={styles.backButton} hugeHeading clear contrast icon="times-circle" onPress={goBack} />
						</View>
					</ImageBackground>
				</Animated.View>
				<video height={height} width={width} style={videoStyle} src={file.url} ref={this.handle_view} controls autoPlay controlsList={noDownload} />
			</View>
		);
	}
}

function handle_view(video){
	this.video = video;
}

function handle_pause(){
	Animated.timing(this.state.opacity, {
		toValue: 1, duration: 300,
	}).start();
}

function handle_time_update(){
	Animated.timing(this.state.opacity, {
		toValue: 0, duration: 300,
	}).start();
}
