import React from "react";
import {
	View,
	StyleSheet,
	Dimensions,
} from "react-native";
import Style from "ae-style";
import CircleButton from "ae-base/Base/CircleButton";

import { useResources } from "@aetonix/hooks";

var styles = StyleSheet.create({
	buttons: {
		flexDirection: "column",
		position: "absolute",
		top: 0,
		left: 0,
		justifyContent: "space-around",
	},
	zoomButton: {
		flexDirection: "row",
		justifyContent: "space-around",
		alignContent: "flex-start",
		position: "absolute",
		top: 0,
		left: 0,
		right: 0,
	},
	functionButton: {
		backgroundColor: Style.colors.primary,
	},
	buttonPortrait: {
		bottom: vmin(80),
	},
	buttonLandscape: {
		bottom: 0,
	},
});

export default function LocalVideoButton(props) {
	const {
		dispatch,
	} = useResources();

	var voiceOnly = props.voiceOnly;
	var magnifying = props.magnifying;
	var auidoOutput = props.auidoOutput;
	var orientation = props.orientation;

	var buttonStyle = (orientation === "PORTRAIT") ? [styles.buttons, styles.buttonPortrait] : [styles.buttons, styles.buttonLandscape];
	if(magnifying)
		buttonStyle = styles.zoomButton;


	var callInputSwitcher = props.callInputSwitcher;
	var renderSwitchCamera = null;
	var audio = dispatch("audiooutputselector:trigger:toggle");
	var switchCamera = dispatch("callinputswitcher:trigger:toggle");
	var zoomIn = dispatch("callmagnifier:trigger:toggle");
	var zoomOut = dispatch("callmagnifier:trigger:toggle");
	var iconSize = magnifying ? "smallHeading" : "emphasized";

	if((callInputSwitcher.input.sources.video && callInputSwitcher.input.sources.video.length > 1) && !voiceOnly)
		renderSwitchCamera = (
			<CircleButton
				id={"localCameraSwitch"}
				contrast
				icon={"aeto-camera_flip"}
				onPress={switchCamera}
				localVideoControlButton
				magnifying={magnifying}
				containerColor={styles.functionButton}
				iconSize={iconSize} />
		);

	if(voiceOnly)
		var renderZoomButton = null;
	else if(magnifying)
		renderZoomButton = (
			<CircleButton
				id={"localZoomOut"}
				contrast
				icon={"aeto-expand"}
				onPress={zoomOut}
				localVideoControlButton
				magnifying={magnifying}
				containerColor={styles.functionButton}
				iconSize={iconSize} />
		);
	else
		renderZoomButton = (
			<CircleButton
				id={"localZoomIn"}
				contrast
				icon={"search"}
				onPress={zoomIn}
				localVideoControlButton
				magnifying={magnifying}
				containerColor={styles.functionButton}
				iconSize={iconSize} />
		);


	var renderAudioButton = auidoOutput ? (
		<CircleButton
			id={"localAudio"}
			contrast
			icon={"volume-up"}
			onPress={audio}
			localVideoControlButton
			magnifying={magnifying}
			containerColor={styles.functionButton}
			iconSize={iconSize} />
	) : null;

	return  (
		<View style={buttonStyle}>
			{renderZoomButton}
			{renderSwitchCamera}
			{renderAudioButton}
		</View>
	);
}

function vmin(x) {
	var size = Dimensions.get("window");
	var smaller = Math.min(size.width, size.height);

	return smaller / 100 * x;
}
