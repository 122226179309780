import React from "react";
import {
	StyleSheet,
	View,
} from "react-native";
import Style from "@aetonix/styles";
import StyledText from "ae-base/Base/StyledText";
import OverviewItem from "../../Pages/Managed/OverviewItem";
import { useMeasureUnits } from "@aetonix/hooks";

import { useResources } from "@aetonix/hooks";

var styles = StyleSheet.create({
	container: {
		flex: 1,
		padding: Style.layout.paddingMedium,
		display: "flex",
	},
	title: {
		marginTop: Style.layout.marginSlim,
		marginBottom: Style.layout.marginSmall,
		alignItems: "center",
	},
	overviewContainer: {
		alignItems: "center",
		justifyContent: "center",
		flexDirection: "row",
		flexWrap: "wrap",
	},
});

export default function MyHealthOverview(props) {
	const {
		localize,
		dispatch,
	} = useResources();

	var model = props.model;
	var settings = model.settings;
	var activity = model.activity;
	var bloodsugar = model.bloodsugar;
	var weight = model.weight;
	var temperature = model.bodytemperature;
	var status = model.status || {};
	var indicator_status = status.indicator_status || {};
	var oxygen = model.bloodoxygen;
	var own_bodytemp = temperature.cumulative || [];
	var own_bloodoxygen = oxygen.cumulative || [];
	var own_weight = weight.cumulative || [];
	var bloodpressure = model.bloodpressure;
	var own_bloodpressure = bloodpressure.cumulative || [];
	var sugar = bloodsugar.cumulative || [];
	var steps = activity.cumulative || [];

	const measureUnits = useMeasureUnits();
	const tempUnitKeys = measureUnits.temperatureUnits;
	const weightUnits = measureUnits.weightUnits;
	const glucoseUnitKeys = measureUnits.glucometerUnits;
	var oldGlucoseUnitKeys = glucoseUnitKeys === "mg" ? "meanblood" : "glucose";

	var tempUnits = localize("bodytemperature." + tempUnitKeys);
	var glucoseUnits = localize("bloodsugar." + glucoseUnitKeys);

	var title = localize("myhealth.overview");
	var activity_title = localize("myhealth.activity");
	var bloodsugar_title = localize("myhealth.bloodsugar");
	var weight_title = localize("myhealth.weight");
	var bloodpressure_title = localize("myhealth.bloodpressure");
	var bloodoxygen_title = localize("myhealth.oxygen");
	var bodytemp_title = localize("myhealth.bodytemp");
	var unit = localize("activity.steps_text");

	var jumpToActivity = dispatch("atouchawaymobile:trigger:page", {
		page: "activity",
	});

	var jumpToBloodSugar = dispatch("atouchawaymobile:trigger:page", {
		page: "bloodsugar",
	});

	var jumpToWeight = dispatch("atouchawaymobile:trigger:page", {
		page: "weight",
	});

	var jumpToBloodPressure = dispatch("atouchawaymobile:trigger:page", {
		page: "bloodpressure",
	});

	var jumpToBloodOxygen = dispatch("atouchawaymobile:trigger:page", {
		page: "bloodoxygen",
	});

	var jumpToBodyTemp = dispatch("atouchawaymobile:trigger:page", {
		page: "bodytemperature",
	});

	var display_activity = steps.length ? (steps[steps.length - 1].cumulative) + " " + unit : " ";
	var display_glucose = sugar.length ? (sugar[0].history[glucoseUnitKeys] || sugar[0].history[oldGlucoseUnitKeys]) + " " + glucoseUnits : " ";
	var display_weight = own_weight.length ? (own_weight[0].history[weightUnits]) + " " + weightUnits : " ";
	var display_bloodpressure = own_bloodpressure.length ?  own_bloodpressure[0].history.systolic + "/" + own_bloodpressure[0].history.diastolic + " " + "mmhg\n" + (own_bloodpressure[0].history.bpPulserate ? (own_bloodpressure[0].history.bpPulserate)  : "--") + " bpm"  : " ";
	var display_pulseoximetry = own_bloodoxygen.length ? own_bloodoxygen[0].history.oxygensaturation + "%" + " - " + own_bloodoxygen[0].history.pulserate + "bpm" : " ";
	var display_temperature = own_bodytemp.length ? own_bodytemp[0].history[tempUnitKeys] + " " + tempUnits : " ";

	var sectionPermissions = settings.myhealth_sections || {};
	var overviewItems = [
		{
			title: display_activity, text: activity_title, onPress: jumpToActivity, type: "activity", indicator: "steps",
		},
		{
			title: display_glucose, text: bloodsugar_title, onPress: jumpToBloodSugar, type: "bloodsugar", indicator: "bloodsugar",
		},
		{
			title: display_weight, text: weight_title, onPress: jumpToWeight, type: "weight", indicator: "weight",
		},
		{
			title: display_bloodpressure, text: bloodpressure_title, onPress: jumpToBloodPressure, type: "bloodpressure", indicator: "bloodpressure",
		},
		{
			title: display_pulseoximetry, text: bloodoxygen_title, onPress: jumpToBloodOxygen, type: "bloodoxygen", indicator: "bloodoxygen",
		},
		{
			title: display_temperature, text: bodytemp_title, onPress: jumpToBodyTemp, type: "bodytemperature", indicator: "bodytemperature",
		},
	];
	var renderedItems = overviewItems.reduce(function(acc, itemData) {
		var indicator = itemData.indicator;
		var itemStatus = (indicator_status[indicator] || {}).status;
		if(indicator === "bloodoxygen"){
			var hrStatus = (indicator_status.heartrate || {}).status;
			var oStatus = (indicator_status.oxygensaturation || {}).status;
			itemStatus = hrStatus;
			if(oStatus > hrStatus)
				itemStatus = oStatus;
		}

		if(indicator === "bloodpressure"){
			var bpStatus = (indicator_status.bloodpressure || {}).status;
			var bpPulserateStatus = (indicator_status.bpPulserate || {}).status ?? -1;
			itemStatus = Math.max(bpStatus, bpPulserateStatus);
		}
		if(sectionPermissions[itemData.type] || !(itemData.type in sectionPermissions))
			acc = acc.concat(<OverviewItem key={itemData.text} isAtouchAway={true} title={itemData.title} text={itemData.text} onpress={itemData.onPress} status={itemStatus} />);
		return acc;
	}, []);

	return (
		<View style={styles.container}>
			<View style={styles.title}>
				<StyledText tertiary style={{color:"#4A959B"}} large>{title}</StyledText>
			</View>
			<View style={styles.overviewContainer}>
				{renderedItems}
			</View>
		</View>
	);
}
