
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React, {
	PureComponent
} from "react";

import Breakpoints from "../../Breakpoints";
import ListItem from "ae-base/List/ListItem";
import Avatar from "./Avatar";
import AvailabilityIcon from "./AvailabilityIcon";
import ContactName from "./ContactName";
import Style from "ae-style";

export default class Contact extends PureComponent {
	render(){
		var props = this.props;
		var contact = props.contact;
		var inactive = props.inactive; //determine user's availability
		var showAvailable = props.showAvailable;
		var contrast = props.contrast;
		var teal = props.teal;
		var children = props.children;
		var onPress = props.onPress;
		var title = props.title;
		var backgroundColor = props.backgroundColor;
		var subtitle = props.subtitle;
		const inactiveUser = props.inactiveUser; //determine User Active State
		const textOpacity = "ae-plain ";

		const nameContrast = props.nameContrast;

		var availableIcon = null;
		if(showAvailable)
			availableIcon = (
				<AvailabilityIcon available={!inactive} teal={teal} contrast={contrast} />
			);

		var sizeAvatar = "mini";
		if(Breakpoints.smallerThan("mobile"))
			sizeAvatar = "tiny";
		var backgroundColorStyle = {
			backgroundColor: backgroundColor
		};
		if(inactiveUser) {
			backgroundColorStyle.backgroundColor = Style.colors.semitransRed;
			textOpacity.concat("ae-fade");
		}

		return (
			<ListItem className={textOpacity} margined={props.margined} contrast={contrast} style={backgroundColorStyle} >
				{availableIcon}
				<Avatar person={contact} inactive={inactive || inactiveUser} size={sizeAvatar} />
				<ContactName subtitle={subtitle} title={title} person={contact} contrast={contrast} teal={teal} onPress={onPress} />
				{children}
			</ListItem>
		);
	}
}
