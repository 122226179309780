import { SERVER_URL } from '../native/Api';

/** @deprecated @maybe moved to attendee map function in useCallManager.web.js. Change not confirmed yet */ 
export function withChimeAttendee(attendee, selfAttendeeId, activeSpeakers, getUserType) {
	const info = attendee.externalUserId.split("#")[1];
	const [name, userId] = info.split('^');

	const isSelf = !!selfAttendeeId && selfAttendeeId === attendee.chimeAttendeeId;
	const isMostActiveSpeaker = activeSpeakers[0] === attendee.chimeAttendeeId;
	return {
		...attendee,
		externalId: attendee.chimeAttendeeId,
		isSelf,
		tileId: null,
		name,
		userId,
		userType: getUserType(userId),
		isMostActiveSpeaker,
	}
}

export async function fetchMeeting(meetingId, name, region) {
	const url = encodeURI(SERVER_URL + "join?" + `title=${meetingId}&name=${name}&region=${region}`);
	const data = await fetch(url, {
		method: "POST",
		headers: { "Content-Type": "application/json" }
	})
	.then((res) => res.json())
	.catch(error => console.log(error));

	if (data?.error) {
		throw new Error(`Server error: ${data.error}`);
	}

	return data;
};