import React from "react";
import prop from "prop";

import Item from "../Item";
import Group from "../Group";
import Section from "../Section";
import Title from "../Title";

import { useResources } from "@aetonix/hooks";

var getName = prop("Name");
var getRole = prop("Role");
var getOrganizationName = prop("OrganizationName");
var getPhone = prop("Phone");
var getIsRegularCareTeamMember = prop("IsRegularCareTeamMember");
var getIsLeadCoordinator = prop("IsLeadCoordinator");
var getIsHomeCaregiver = prop("IsHomeCaregiver");
var getFeeling = prop("HomeCaregiverStatus.Name");

export default function Careteam(props) {
	const { localize } = useResources();

	var careplan = props.careplan;

	var careteammemberinformation = localize("careteam.careteammemberinformation");
	var careteam = localize("careteam.careteam");
	var thepeopleirelyonathomearefeeling = localize("careteam.thepeopleirelyonathomearefeeling");

	var careTeamMember = careplan.CareTeamMember || [];

	var renderTeamMember = careTeamMember.map(function(member, index){
		return (
			<CareteamMember key={index} member={member} index={index} />
		);
	});

	return (
		<Section title={careteam}>
			<Title title={careteammemberinformation} />
			{renderTeamMember}
			<Item title={thepeopleirelyonathomearefeeling} value={getFeeling(careplan)} />
		</Section>
	);
}

function CareteamMember(props) {
	const { localize } = useResources();
	var member = props.member;
	var name = localize("careteam.name");
	var roleorrelationship = localize("careteam.roleorrelationship");
	var organizationname = localize("careteam.organizationname");
	var telephonenumber = localize("careteam.telephonenumber");
	var regularcareteammember = localize("careteam.regularcareteammember");
	var leadcarecoordinator = localize("careteam.leadcarecoordinator");
	var irelyonmostathome = localize("careteam.irelyonmostathome");

	return (
		<Group>
			<Item title={name} value={getName(member)} />

			<Item title={roleorrelationship} value={getRole(member)} />

			<Item title={organizationname} value={getOrganizationName(member)} />

			<Item title={telephonenumber} value={getPhone(member)} />

			<Item title={regularcareteammember} value={getIsRegularCareTeamMember(member)} />

			<Item title={leadcarecoordinator} value={getIsLeadCoordinator(member)} />

			<Item title={irelyonmostathome} value={getIsHomeCaregiver(member)} />
		</Group>
	);

}
