import React, {PureComponent} from "react";
import {
	View,
} from "react-native";

import DialogContainer from "ae-base/Base/DialogContainer";
import StyledText from "ae-base/Base/StyledText";
import StyledButton from "ae-base/Base/StyledButton";

import PageContainer from "../../Layout/PageContainer";
import TabBar from "../../Layout/TabBar";

import SessionList from "./SessionList";
import LiveChatHeader from "./LiveChatHeader";

import memoize from "memoizee";

import Style from "ae-style";

import ResponsiveStylesheet from "react-native-responsive-stylesheet";

import { useResources } from "@aetonix/hooks";

var styles = ResponsiveStylesheet.createSized("min-width", {
	0: {
		dialogItem: {
			backgroundColor: Style.colors.white,
			padding: Style.layout.paddingButton - 4,
			borderWidth: 1,
			borderColor: "rgba(0,0,0,0.1)",
			width: 250,
			justifyContent: "center",
			zIndex: 2,
		},
		closeButton: {
			flexDirection: "row",
			justifyContent: "center",
			paddingTop: Style.layout.paddingSmall,
		},
		hoursContainer: {
			flexDirection: "row",
		},
		hoursSection: {
			paddingHorizontal: Style.layout.paddingSmall,
		},
		timezone: {
			paddingVertical: Style.layout.paddingSmall,
		},
	},
	512: {
		dialogItem: {
			padding: Style.layout.paddingLarge,
			width: 350,
		},
	},
});

var DAYS = ["0", "1", "2", "3", "4", "5", "6"];

export default (props) => {
	const { localize, dispatch } = useResources();
	return (
		<OwnLiveChatGroup {...props} localize={localize} dispatch={dispatch} />
	);
};

class OwnLiveChatGroup extends PureComponent{
	constructor(props){
		super(props);
		this.state = {
			page: "active",
			info: false,
		};

		var localize = props.localize;
		var model = props.model;
		var personal = model.personal;
		var group = model.activelivechatgroup;

		var isMember = group.members.indexOf(personal._id) !== -1;

		this.setPageActive = setPage.bind(this, "active");
		this.setPageHistory = setPage.bind(this, "history");
		this.setPagePending = setPage.bind(this, "pending");

		this.showInfo = showInfo.bind(this);
		this.hideInfo = hideInfo.bind(this);

		this.parseSchedule = memoize(parseSchedule.bind(this));

		this.infoButton = [{
			id: "livechat_info",
			title: localize("livechat.info"),
			onPress: this.showInfo,
			icon: "aeto-i",
		}];

		this.tabs = [{
			id: "active",
			label: localize("livechat.active"),
			action: this.setPageActive,
		}, {
			id: "history",
			label: localize("livechat.history"),
			action: this.setPageHistory,
		}];

		if(isMember)
			this.tabs.push({
				id: "pending",
				label: localize("livechat.pending"),
				action: this.setPagePending,
			});
	}
	render(){
		var props = this.props;
		var localize = props.localize;
		var dispatch = props.dispatch;
		var model = props.model;
		var personal = model.personal || {};
		var group = model.activelivechatgroup;
		var latestMessages = model.livechatlatestmessages || {};
		var missedMessages = model.missed || {};
		var page = this.state.page;

		var isMember = group.members.indexOf(personal._id) !== -1;

		var toast = model.toast;
		var saving = model.saving;

		var createSession = isMember ? null : dispatch("livechatsessions:trigger:create");

		var modelName = "";
		var isPending = false;
		var createButton = null;
		if(page === "active"){
			modelName = "livechatsessions";
			createButton = createSession;
		} else if(page === "history")
			modelName = "livechatsessionhistory";
		else if (page === "pending") {
			modelName = "livechatsessionpending";
			isPending = true;
		}

		var useModel = model[modelName] || {};
		var list = useModel.list;
		var end = useModel.isEnd;

		var loadMore = dispatch(modelName + ":trigger:more");

		var buttons = null;
		if(group.hasSchedule)
			buttons = this.infoButton;

		return (
			<PageContainer toast={toast} saving={saving}>
				<LiveChatHeader group={group} backText={localize("livechat.backsessions")} buttons={buttons} />
				<TabBar tabs={this.tabs} active={page} highlightBottom />
				<SessionList
					sessions={list}
					create={createButton}
					isMember={isMember}
					latestMessages={latestMessages}
					missed={missedMessages}
					pending={isPending}
					end={end}
					loadMore={loadMore} />
				<InfoDialog open={this.state.info} group={group} parse={this.parseSchedule} hide={this.hideInfo} />
			</PageContainer>
		);
	}
}

function InfoDialog(props) {
	const {
		localize,
	} = useResources();
	var group = props.group;
	var open = props.open;
	var parse = props.parse;
	var hide = props.hide;

	if(!open || !group) return null;

	var schedule = parse(group.schedule);

	var timezone = localize("livechat.timezone", {
		timezone: schedule.timezone,
	});

	var days = schedule.days || [];
	var renderDays = DAYS.reduce(function(acc, day){
		var range = localize("livechat.offline");
		if(days.indexOf(day) !== -1) range = schedule.range;
		var message = localize("livechat.days." + day);
		acc.days.push(<StyledText>{message}</StyledText>);
		acc.ranges.push(<StyledText>{range}</StyledText>);
		return acc;
	}, {
		days: [],
		ranges: [],
	});

	var hoursText = localize("livechat.hours");
	var name = group.name;

	var currentStatus = group.availability ? localize("livechat.online") : localize("livechat.offline");

	return (
		<DialogContainer>
			<DialogItem>
				<View>
					<StyledText bold smallHeading>{name}</StyledText>
					<StyledText bold>{currentStatus}</StyledText>
					<StyledText style={styles.timezone}>{timezone}</StyledText>
					<StyledText>{hoursText}</StyledText>
					<View style={styles.hoursContainer}>
						<View style={styles.hoursSection}>
							{renderDays.days}
						</View>
						<View style={styles.hoursSection}>
							{renderDays.ranges}
						</View>
					</View>
				</View>
				<View style={styles.closeButton}>
					<StyledButton title={localize("userlist.close")} clear onPress={hide} />
				</View>
			</DialogItem>
		</DialogContainer>
	);
}

function DialogItem(props){
	var children = props.children;
	return (
		<View style={styles.dialogItem}>
			{children}
		</View>
	);
}

function setPage(page){
	this.setState({
		page: page,
	});
}

function showInfo(){
	this.setState({
		info: true,
	});
}

function hideInfo(){
	this.setState({
		info: false,
	});
}

function parseSchedule(schedule){
	var timezone = schedule.timezone;
	var startTime = schedule.startTime;
	var endTime = schedule.endTime;

	var startSplit = startTime.split(" ");
	var endSplit = endTime.split(" ");

	var startMinute = startSplit[0];
	var endMinute = endSplit[0];

	var startHour = startSplit[1];
	var endHour = endSplit[1];

	var days = startSplit[4].split(",");

	return {
		timezone: timezone,
		range: startHour + ":" + startMinute + " - " + endHour + ":" + endMinute,
		days: days,
	};
}
