import React from "react";

import Contact from "../../../../Components/Contacts/Contact";
import StyledButton from "ae-base/Base/StyledButton";

export default function ContactListItem({screen, who, buttonOnPress, buttonIcon, buttonMessage,  ...props}) {

	var id = who._id;  // _id of the person

	return (
		<Contact key={id} contact={who} screen={screen} >
			{props.otherList}
			{props.children}
			{buttonOnPress && <StyledButton clear title={buttonMessage} icon={buttonIcon} onPress={buttonOnPress} />}
		</Contact>
	);

}
