export default {
	title: "UrgencyPreferences",
	type: "object",
	properties: {
		ordered: {
			type: "boolean",
			inputType: "switch",
			description: "managedurgency.callinorder"
		}
	}
};