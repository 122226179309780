import React from "react";
import {
	View,
	StyleSheet,
	TouchableOpacity,
} from "react-native";

import StyledIcon from "ae-base/Base/StyledIcon";
import StyledText from "ae-base/Base/StyledText";

import Style from "ae-style";

import { useResources } from "@aetonix/hooks";

var styles = StyleSheet.create({
	bar: {
		flexDirection: "row",
		alignItems: "stretch",
		backgroundColor: Style.look.background,
	},
	item: {
		flex: 1,
		justifyContent: "center",
		alignItems: "stretch",
		flexDirection: "row",
		padding: Style.layout.paddingSmall,
	},
	itemText: {
		marginLeft: Style.layout.marginSlim,
	},
	highlight: {
		height: Style.layout.marginSlim,
		position: "absolute",
		left: 0,
		right: 0,
		backgroundColor: Style.colors.secondary,
	},
	highlightTop: {
		top: 0,
	},
	highlightBottom: {
		bottom: 0,
	},
});

export default function TabBar(props){
	var highlightBottom = props.highlightBottom;
	var active = props.active;
	var tabs = props.tabs;

	var tabItems = tabs.map(function(item){
		var id = item.id;
		var isActive = id === active;
		return (
			<TabItem key={id} item={item} active={isActive} highlightBottom={highlightBottom} />
		);
	});

	return (
		<View style={styles.bar}>
			{tabItems}
		</View>
	);
}

function TabItem(props) {
	const {
		localize,
		dispatch,
	} = useResources();

	var item = props.item;
	var active = props.active;
	var labelKey = item.label;
	var iconName = item.icon;
	var action = item.action;
	var actionData = item.actionData;

	var onPress = dispatch(action, actionData);
	if(typeof action === "function")
		onPress = action;
	var label = localize(labelKey) || labelKey;

	var highlightBottom = props.highlightBottom;
	var highlightStyle = [styles.highlight];
	if(highlightBottom)
		highlightStyle.push(styles.highlightBottom);
	else
		highlightStyle.push(styles.highlightTop);

	var highlight = active ? (
		<View style={highlightStyle} />
	) : null;

	var renderIcon = iconName ? (
		<StyledIcon name={iconName} />
	) : null;

	return (
		<TouchableOpacity style={styles.item} onPress={onPress}>
			{renderIcon}
			<StyledText style={styles.itemText} >
				{label}
			</StyledText>
			{highlight}
		</TouchableOpacity>
	);
}
