import React from "react";
import {
	StyleSheet,
	View,
} from "react-native";

import BasicState from "../Calling/States/BasicState";

import { useResources } from "@aetonix/hooks";

var styles = StyleSheet.create({
	container: {
		position: "absolute",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
	},
});

export default function CallGroupBusy(props) {
	const {
		localize,
	} = useResources();

	var group = props.group;
	var message = localize("callgroups.busy", group);

	return (
		<View style={styles.container}>
			<BasicState message={message} toast={props.toast} />
		</View>
	);
}
