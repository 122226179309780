import React, { useContext, useMemo } from 'react';
import { variant } from 'styled-system';
import { createStyled } from '@emotion/primitives-core'
import { StyleSheet, View, TouchableOpacity, Platform } from 'react-native';
import { faMicrophoneSlash, faRedo, faCompress, faThumbtack } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import Avatar from '../Avatar/Avatar';
import { asInitials } from '../../libraries/tile.library';
import ResponsiveText from '../Text/ResponsiveText';
import Style from '@aetonix/styles';
import useMeetingSettings from '../../hooks/useMeetingSettings/useMeetingSettings';
import MeetingContext from '../../context/MeetingContext';
import VideoTileErrorBoundary from './VideoTileErrorBoundary';

const styled = createStyled(StyleSheet);

export const ellipsis = `
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const VIDEO_STYLES = {
    width: '100%',
    height: '100%',
    alignSelf: "stretch",
    objectFit: 'cover',
}

export const NATIVE_VIDEO_STYLES = {
    ...Platform.select({
        ios: { height: "100%", resizeMode: "cover" },
        android: {  width: "100%", minHeight: '100%' }
    }),
    aspectRatio: 16 / 9,
};

export const StyledVideoTile = styled(View)({
    width: '100%',
    height: '100%',
    position: 'relative',
    backgroundColor: '#1a1a1d',
    alignSelf: 'stretch',
    flexDirection: 'row',
    overflow: 'hidden',
    justifyContent: 'center',
}, variant({
    prop: 'type',
    variants: {
        featured: { alignSelf: 'stretch' },
        standard: { flexGrow: 1, flexShrink: 0, flexBasis: '50%' },
    }
}));

const Nameplate = styled(View)({
    bottom: 5,
    left: 5,
    height: 'auto',
    padding: 5,
    position: 'absolute',
    borderRadius: 5,
});

const AvatarOverlay = styled(View)({
    position: 'absolute',
    height: '100%',
    width: '100%',
    backgroundColor: '#023020',
    justifyContent: "center",
    alignItems: "center",
});

const FlipIconTouchable = styled(TouchableOpacity)({ height: 30, width: 30 })

const MuteIconContainer = styled(View)({ height: 30, width: 30, marginLeft: 20 })

const PinButton = styled(TouchableOpacity)({
	height: 30,
	width: 30,
	marginLeft: 20,
})

const NameplateText = styled(ResponsiveText)`
    ${ellipsis};
    margin: 0;
    color: white;
    fontFamily: 'Arial'
`;

const TileStates = styled(View)({
    position: 'absolute',
    right: 10,
    bottom: 10,
    height: 30,
    width: 'auto',
    flexDirection: 'row',
    justifyContent: 'flex-end',
});

export default ({ 
    type, 
    nameplate,
    isVideoEnabled,
    isSelf,
    isMuted,
    image,
    videoComponent,
    mode,
    userId,
    onCameraFlip,
    isFeatured = false,
}) => {
    const name = isSelf ? 'You' : nameplate;
    const initials = useMemo(() => asInitials(nameplate));
    const { isSelfMinimized } = useMeetingSettings();
    const { pinning, isAdministrator } = useContext(MeetingContext);
    const isSelfAndMinimized = isSelf && isSelfMinimized;
    const canFlipCamera = isVideoEnabled && mode !== 'desktop' && isSelf;
    const isCurrentlyPinned = !!pinning.pinnedUser && isFeatured && pinning.pinnedUser === userId;

    const onTogglePin = () => {
        if (isCurrentlyPinned) {
            return pinning.onUnpin();
        }

        return pinning.onPin(userId)
    }

    return (
        <StyledVideoTile type={type} testID="video-tile">
            <VideoTileErrorBoundary name={name}>
                { videoComponent }
                {!isVideoEnabled && (!!nameplate || !!image) && (
                    <AvatarOverlay>
                        <Avatar text={initials} image={image} size={isSelfAndMinimized ? 'list' : 'small'}/>
                    </AvatarOverlay>
                )}
                {!!nameplate && (
                    <Nameplate testID="video-tile-nameplate">
                        <NameplateText max={Style.text.small} size={Style.text.large}>{name}</NameplateText>
                    </Nameplate>
                )}

                <TileStates>
                    { !isSelf && !!userId && isAdministrator && (
                        <PinButton onPress={onTogglePin}>
                            <FontAwesomeIcon size={25} color='#F9F7F5' icon={!isCurrentlyPinned ? faThumbtack : faCompress} />
                        </PinButton>
                    )}
                    { canFlipCamera && (
                        <FlipIconTouchable onPress={onCameraFlip} >
                            <FontAwesomeIcon size={25} color='#F9F7F5' icon={faRedo} />
                        </FlipIconTouchable>
                    )}
                    { isMuted && (
                        <MuteIconContainer>
                            <FontAwesomeIcon size={25} color='#F9F7F5' icon={faMicrophoneSlash}/>
                        </MuteIconContainer>
                    )}
                </TileStates>
            </VideoTileErrorBoundary>
        </StyledVideoTile>
    );
};