
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React, {
	Component
} from "react";
import {
	StyleSheet,
	TouchableWithoutFeedback,
	View,
	Platform
} from "react-native";
import {
	KeyboardAwareScrollView
} from "react-native-keyboard-aware-scroll-view";

import ScrollIndicator from "ae-base/Base/ScrollIndicator";
import OfflineBanner from "../../Pages/Homepage/OfflineBanner";

var styles = StyleSheet.create({
	main: {
		flex: 1,
		flexShrink: 1,
	},
	scrollview: {
		flexGrow: 0,
		flexShrink: 1,
	},
});

export default class MainScroll extends Component {
	constructor(props){
		super(props);
		this.scrollViewRef = React.createRef();
		this.scrollToTop = scrollToTop.bind(this);
	}

	render() {
		var props = this.props;
		var style = props.style;
		const reconnecting = (props.online !== undefined && props.online === false)

		return props.atouchaway && Platform.OS === "android" ? (
			<View style={{flex: 1}}>
				<ScrollIndicator style={style}>
						{reconnecting && <OfflineBanner />}
						{props.children}
				</ScrollIndicator>
			</View>
		) : (
			<View style={[styles.main, style]}>
					<KeyboardAwareScrollView
					keyboardDismissMode="none"
					enableResetScrollToCoords={props.enableResetScrollToCoords}
					extraScrollHeight={props.extraScrollHeight}
					keyboardOpeningTime={0}
					style={[styles.scrollview]}
					contentContainerStyle={props.contentContainerStyle}
					ref={this.scrollViewRef}
					showsVerticalScrollIndicator={props.showsVerticalScrollIndicator}
				>
					<View onStartShouldSetResponder={() => true} >
						{reconnecting && <OfflineBanner />}
						{props.children}
					</View>
				</KeyboardAwareScrollView>
			</View>
		);
	}
}

function scrollToTop() {
	this.scrollViewRef?.current?.scrollToPosition(0, 0);
}
