export default {
	title: "Devices",
	description: "schemas_devices_settings",
	type: "object",
	displayDependencies: {
		automatic: {
			required: ["model"],
			properties: {
				model: {
					enum: ["metawear"]
				}
			},
		}
	},
	properties: {
		model: {
			order: 0,
			description: "devices.model",
			value_description: "devices.models",
			type: "string",
			enum: ["a&dweight", "a&dbloodpressure", "ihspo2", "forair20", "foratng", "foraspo2", "ihbloodpressure", "ihweight", "metawear", "nonin"],
		},
		mac: {
			order: 1,
			type: "string",
			format: "macaddress",
			inputType: "bluetooth:scanner",
			description: "devices.mac"
		},
		automatic: {
			order: 2,
			type: "boolean",
			inputType: "switch",
			description: "devices.automatic"
		}
	},
	required: ["model", "mac"]
};
