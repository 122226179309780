
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import {
	Dimensions,
} from "react-native";

import Style from "ae-style";

export default {
	smallerThan: smallerThan,
	largerThan: largerThan
};

function smallerThan(name){
	return size() < Style.breakpoints[name];
}

function largerThan(name) {
	return size() > Style.breakpoints[name];
}

function size() {
	var dimensions = Dimensions.get("window");
	return Math.min(dimensions.width, dimensions.height);
}
