import React from 'react';

export const SelfContext = React.createContext({
    id: null,
    _id: null,
    type: [],
    fname: '',
    lname: '',
});

export const SelfProvider = ({ children, model }) => {

    const personal = model?.personal;
    const {
        id: defaultId,
        _id: defaultUnderscoreId,
        type: defaultType,
        fname: defaultFName,
        lname: defaultLName
    } = personal || {};

    const id = defaultId || defaultUnderscoreId;

    const selfContext = {
        id, _id: id,
        type: defaultType,
        fname: defaultFName,
        lname: defaultLName,
    }

    return (
        <SelfContext.Provider value={selfContext}>
            {children}
        </SelfContext.Provider>
    );
};