import React from "react";

import CallPage from "../CallPage";
import CallControls from "../CallControls";
import CallMessage from "../CallMessage";
import Toast from "ae-base/Base/Toast";

export default function BasicState(props) {
	var toast = props.toast;
	var message = props.message;
	var children = props.children;

	return (
		<CallPage>
			<CallMessage message={message} />
			<CallControls>
				{children}
			</CallControls>
			<Toast toast={toast} id={"call_toast"} />
		</CallPage>
	);
}
