export default {
	title: "BraceletSettings",
	description: "schemas_bracelet_settings_title",
	type: "object",
	properties: {
		relay: {
			order: 1,
			description: "braceletsettings.relay",
			value_description: "braceletsettings",
			type: "boolean",
			inputType: "switch",
			format: "switch",
		},
		model: {
			order: 0,
			description: "braceletsettings.model",
			value_description: "braceletsettings",
			type: "string",
			enum: ["vlrt"],
		},
		disconnect: {
			order: 2,
			description: "braceletsettings.disconnect",
			value_description: "braceletsettings",
			type: "string",
			inputType: "dropdown",
			enum: ["disconnected", "wandering", "none"],
		},
		fall: {
			order: 3,
			description: "braceletsettings.fall",
			value_description: "braceletsettings",
			type: "string",
			inputType: "dropdown",
			enum: ["urgency", "none"],
		},
		press: {
			order: 4,
			description: "braceletsettings.press",
			value_description: "braceletsettings",
			type: "string",
			inputType: "dropdown",
			enum: ["urgency", "contact", "none"],
		},
	}
};
