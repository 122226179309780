import React from "react";
import {
	StyleSheet,
	View,
} from "react-native";

import TimelineNotification from "./TimelineNotification";
import StyledButton from "ae-base/Base/StyledButton";
import StyledText from "ae-base/Base/StyledText";
import Style from "ae-style";
import TimelineHeader from "./TimelineHeader";
import PageContainer from "../../Layout/PageContainer";
import MainScroll from "../../Layout/MainScroll";
import OfflineBanner from "../Homepage/OfflineBanner";

import { useResources } from "@aetonix/hooks";

var styles = StyleSheet.create({
	more: {
		marginVertical: Style.layout.marginContentVertical,
		alignItems: "center",
		justifyContent: "center",
	},
	text: {
		marginVertical: Style.layout.marginMedium,
	},
});

export default function Timeline(props) {
	const {
		localize,
		dispatch,
	} = useResources();

	var model = props.model;
	var saving = model.saving;
	var usernotifications = model.usernotifications;
	var read = usernotifications.read || [];
	var unread = usernotifications.unread || [];
	var getMore = dispatch("readnotifications:trigger:more");
	var loadmore = localize("timeline.loadmore");
	var shouldShowMore = usernotifications.read.length >= 16;
	var contacts = model.contacts.contacts || [];

	var screen = model.size;

	var renderMore = shouldShowMore ? (
		<View style={styles.more}>
			<StyledButton title={loadmore} icon="dropdown" onPress={getMore} />
		</View>
	) : null;

	var renderReadMsgs = read.flatMap(function (notification) {
		if (notification.banner_type === "info:meeting:new")
			return [];
		else
			return (
				<TimelineNotification notification={notification} key={notification._id} screen={screen} model={model} contacts={contacts} />
			);

	});

	var offline = model.online ? null : (
		<OfflineBanner />
	);

	var renderUnreadMsgs = unread.flatMap(function (notification) {
		if (notification.banner_type === "info:meeting:new")
			return [];
		else
			return (
				<TimelineNotification notification={notification} key={notification._id} screen={screen} model={model} />
			);

	});

	var renderEmptyMsgs = !read.length && !unread.length ? <StyledText style={styles.text} centered>{localize("timeline.empty")}</StyledText> : null;

	return (
		<PageContainer  saving={saving}>
			<TimelineHeader screen={screen} />
			{offline}
			<MainScroll>
				{renderUnreadMsgs}
				{renderReadMsgs}
				{renderMore}
				{renderEmptyMsgs}
			</MainScroll>
		</PageContainer>
	);
}
