/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";

import PersonalInfoSchema from "../../../../schemas/PersonalInfo";
import Form from "ae-forms";
import Divider from "../../../Layout/Divider";
import StyledText from "ae-base/Base/StyledText";
import BlockView from "ae-base/Base/BlockView";

import { useIdPrefix, useResources } from "@aetonix/hooks";

export default function PersonalInfo(props) {
	const {
		dispatch,
	} = useResources();
	const idPrefix = useIdPrefix();
	var preferences = props.preferences;

	var loadedKey = Object.keys(preferences || {}).length;

	return (
		<BlockView>
			<StyledText />
			<Form id_prefix={idPrefix + "personalInfoForm"} key={loadedKey} schema={PersonalInfoSchema} defaultData={preferences} onSubmit={dispatch("preferences:trigger:preferences:update")} />
			<Divider />
		</BlockView>
	);
}
