export default {
	title: "ContactSettings",
	description: "contactsettings.title",
	type: "object",
	properties: {
		voice: {
			order: 1,
			type: "boolean",
			description: "contactsettings.voice",
			inputType: "checkbox"
		},
		video: {
			order: 2,
			type: "boolean",
			description: "contactsettings.video",
			inputType: "checkbox"
		},
		message: {
			order: 3,
			type: "boolean",
			description: "contactsettings.message",
			inputType: "checkbox"
		}
	}
};
