import React from "react";
import {
	StyleSheet,
	View,
} from "react-native";

import	StyledText from "ae-base/Base/StyledText";
import	StyledButton from "ae-base/Base/StyledButton";
import BlockView from "ae-base/Base/BlockView";

import Style from "ae-style";

import { useResources } from "@aetonix/hooks";

var styles = StyleSheet.create({
	page: {
		flexDirection: "row",
		padding: Style.layout.paddingSmall * 2,
		justifyContent: "space-between",
		alignItems: "center",
	},
});

export default function SimplifiedHeader(props){
	const { dispatch, localize } = useResources();
	var model = props.model;
	var personal = model.personal;
	var name = personal.fname;
	var hi = localize("atouchawayheader.hi");

	var contents = [];

	if (model.workflowaction && model.workflowaction.collapsed)
		contents = (
			<WorkflowContinueButton action={model.workflowaction} />
		);
	else {
		contents.push((
			<BlockView key={name + "block_nocall"}>
				<StyledText key={name + "nocall"} contrast smallHeading>{hi} {name}{"!"}</StyledText>
			</BlockView>
		));
		contents.push((
			<BlockView key={name + "block_call"}>
				<StyledButton key={name + "call"} smallHeading contrast title="aTouchAway" clear onPress={dispatch("about:trigger:open")} />
			</BlockView>
		));
	}

	return (
		<View style={styles.page}>
			{contents}
		</View>
	);
}

function WorkflowContinueButton(props) {
	const {
		localize,
		dispatch,
	} = useResources();

	var action = props.action;
	var size = props.size;

	var title = localize("workflow.continue");

	var onPress = dispatch("activeworkflowaction:trigger:view", {
		action: action._id,
	});

	return (
		<BlockView>
			<StyledButton stretch contrast largeHeading title={title} onPress={onPress} {...size} />
		</BlockView>
	);
}
