import React, {
	PureComponent,
} from "react";
import {
	View,
} from "react-native";
import ResponsiveStylesheet from "react-native-responsive-stylesheet";

import MainContent from "../../Layout/MainContent";
import ListAdd from "ae-base/List/ListAdd";
import ListItem from "ae-base/List/ListItem";
import {
	StyleSheet,
	TouchableOpacity,
} from "react-native";
import GroupHeading from "../../Groups/GroupHeading";

import StyledText from "ae-base/Base/StyledText";
import NotificationIcon from "ae-base/Base/NotificationIcon";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { createStyled } from "@emotion/primitives-core";
import { faComment } from "@fortawesome/free-solid-svg-icons";

import Style from "ae-style";

import { useResources } from "@aetonix/hooks";

var styles = ResponsiveStylesheet.createSized("min-direction", {
	0: {
		addBox: {
			marginLeft: 24,
		},
		badge: {
			position: "absolute",
			right: -Style.layout.badge / 2,
			top: -Style.layout.badge / 2,
		},
		inviteAction: {
			flexDirection: "column",
		},
		inviteIncoming: {
			backgroundColor: Style.look.contrast.background,
		},
		inviteOutgoing: {
			backgroundColor: Style.colors.tertlight,
		},
		nameContainer: {
			marginLeft: Style.layout.marginContentSmall,
			flexDirection: "column",
			flex: 1,
		},
	},
	512: {
		addBox: {
			marginLeft: 30,
		},
		inviteAction: {
			flexDirection: "row",
		},
	},
});

const styled = createStyled(StyleSheet);

const MessageButton = styled(TouchableOpacity)({
	paddingLeft: 10,
});
export default function GroupList(props){
	var list = props.list  || [];
	var count = props.count;
	var missed = props.missed;
	var screen = props.screen;
	var displaying = props.displaying;

	var groups = list.map(function(group){
		var groupId = group._id;
		var missedGroup = missed[groupId];

		return (
			<OwnGroup key={groupId} group={group} missed={missedGroup} screen={screen} />
		);
	});

	var content = displaying ? groups : null;

	return (
		<MainContent>
			<Header count={count} displaying={displaying} screen={screen} />
			{content}
			<AddGroup />
		</MainContent>
	);
}

function OwnGroup(props){
	const { dispatch } = useResources();
	var group = props.group;
	var screen = props.screen;
	var missed = props.missed;
	var id = group._id;

	var name = group.name;

	var nameId = name.replace(" ", "_");

	var renderMissedMessage = null;
	if(missed && missed.messages)
		renderMissedMessage = (
			<NotificationIcon id={"missedMessage"} style={styles.badge} title={missed.messages} />
		);

	var openMessages = dispatch("groupchat:trigger:view", {
		group: group,
	});

	return (
		<Group group={group} key={id} screen={screen} >
			<MessageButton id={`groupchat_${name}_openMessages`} onPress={openMessages}>
				<FontAwesomeIcon icon={faComment} color="#285E63" size={30} />
				{renderMissedMessage}
			</MessageButton>
		</Group>
	);
}

function Header(props) {
	var count = props.count;
	var screen = props.screen;
	var displaying = props.displaying;

	var section = count ? "groups" : "nogroups";

	return (
		<GroupHeading
			count={count}
			displaying={displaying}
			section={section}
			screen={screen} />
	);
}

function AddGroup(props) {
	const {
		localize,
		dispatch,
	} = useResources();

	var creategroup = localize("groupchat.creategroup");

	var onPress = dispatch("desktop:trigger:page", {
		page: "groupadd",
	});

	return (
		<ListAdd id={"createGroupchat"} style={styles.addBox} title={creategroup} onPress={onPress} />
	);
}

class Group extends PureComponent {
	render(){
		var props = this.props;
		var group = props.group;
		var contrast = props.contrast;
		var children = props.children;
		var name = group.name;
		var description = group.description;

		return (
			<ListItem margined={props.margined} contrast={contrast}>
				<GroupName name={name} description={description} contrast={contrast} />
				{children}
			</ListItem>
		);
	}
}

function GroupName(props){
	var name = props.name;
	var description = props.description;
	var contrast = props.contrast;

	var nameId = name.replace(" ", "_");
	return (
		<View style={styles.nameContainer}>
			<StyledText id={nameId + "_name"} contrast={contrast} emphasized>
				{name}
			</StyledText>
			<StyledText id={nameId + "_description"} contrast={contrast}>
				{description}
			</StyledText>
		</View>
	);
}
