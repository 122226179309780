import { useMemo, useCallback } from 'react';

const asUserWithFullName = (user, getUserType) => ({
    ...user,
    name: `${user?.lname ?? ``}, ${user?.fname ?? ``}`,
    userType: getUserType(user._id),
});

export default (users, meeting, getUserType) => {

    const participants = useMemo(() => Array.from(new Set([
        ...(meeting?.participants ?? []),
        ...(meeting?.recipient ? [meeting.recipient] : []),
        ...(meeting?.invitees ?? []),
    ])), [
        meeting?.participants,
        meeting?.recipient,
        meeting?.invitees,
    ]);

    const isUserNotAlreadyAParticipant = useCallback(contact => !participants.some(participant => participant == contact._id), [participants]);

    const eligible = useMemo(() => users?.filter?.(isUserNotAlreadyAParticipant)?.map?.(item => asUserWithFullName(item, getUserType)) ?? [], [
        users,
        isUserNotAlreadyAParticipant,
        asUserWithFullName
    ]);

    return eligible;
}