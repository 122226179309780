import React, { Component } from "react";
import "./ImageCropViewWeb.css";
import Draggable from "react-draggable";
export default class ImageCropViewWeb extends Component {

	/**
   * @param {object} props
   * @param {number} props.height
   * @param {number} props.width
   * @param {string} props.source
   * @param {number} [props.maxScale]
   * @param {number} [props.size]
   */
	constructor(props) {
		super(props);

		this.state = {
			imageWidth: props.width,
			imageHeight: props.height
		};

		this.xVal = 0;
		this.yVal = 0;
		this.scaleVal = 1;
	}

	componentDidMount() {

	}

	getCurrentDim() {
		return {
			scale: this.scaleVal,
			x: this.xVal,
			y: this.yVal
		};
	}

	render() {
		let baseScale = 0;
		if (this.state.imageHeight > this.state.imageWidth) {
			baseScale = this.props.size / this.state.imageWidth;
		} else {
			baseScale = this.props.size / this.state.imageHeight;
		}

		return (<div style={{ height: this.props.size, width: this.props.size }}>
			<Draggable onDrag={(e, position) => {
				this.xVal = -position.x;
				this.yVal = -position.y;
			}} bounds={{ right: 0, bottom: 0, left: -((this.state.imageWidth * baseScale) - this.props.size), top: -((this.state.imageHeight * baseScale) - this.props.size) }}>
				<img src={this.props.source} style={{
					width: baseScale * this.state.imageWidth,
					height: baseScale * this.state.imageHeight,
				}} className="no-img-drag" />
			</Draggable>
		</div>);
	}
}