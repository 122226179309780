import React from "react";
import {
	View,
} from "react-native";
import ResponsiveStylesheet from "react-native-responsive-stylesheet";

import Style from "@aetonix/styles";
import StyledText from "ae-base/Base/StyledText";
import BlockView from "ae-base/Base/BlockView";
import Avatar from "../../Contacts/Avatar";
import Message from "../../Pages/Messaging/Message";
import { useResources } from "@aetonix/hooks";

var styles = ResponsiveStylesheet.createSized("min-direction", {
	0: {
		container: {
			marginTop: Style.layout.marginSmall,
		},
		avatar: {
			position: "absolute",
			top: Style.layout.marginSmall,
		},
		avatarRegular: {
			right: -Style.layout.marginLarge,
		},
		avatarAlternate: {
			left: -Style.layout.marginLarge,
		},
		name: {
			alignItems: "flex-end",
			marginRight: Style.layout.marginContentVertical / 2,
		},
		nameAlternate: {
			marginLeft: Style.layout.marginContentVertical / 2,
		},
	},
	512: {
		name: {
			alignItems: "flex-end",
			marginRight: Style.layout.marginContentVertical,
		},
		nameAlternate: {
			marginLeft: Style.layout.marginContentVertical,
		},
	},
});

export default function Messages(props){
	const { localize } = useResources();
	var person = props.person || {};
	var useFullName = props.fullName;
	var alternate = props.alternate;
	var messages = props.messages;
	var name = useFullName ? ((person.lname || "") + ", " + (person.fname || "")) : (person.fname || "");
	var isSystem = false;

	var screen = props.screen;

	if(messages[0].from === "system"){
		name = localize("groupchat.system");
		isSystem = true;
	}

	var avatarStyle = [styles.avatar];
	if(alternate)
		avatarStyle.push(styles.avatarAlternate);
	else avatarStyle.push(styles.avatarRegular);

	var nameStyle = alternate ? styles.nameAlternate : styles.name;

	var sizeAvatar = "mini";

	var renderedMessages = messages.map(function(message){
		return (
			<Message alternate={alternate}
				expiry={message.expiry}
				dateExpiry={message.date_expiry}
				timestamp={message.timestamp}
				formattedDate={message.formatted_date}
				file={message.attachment}
				content={message.content}
				key={message._id}
				system={isSystem}
				metadata={message.metadata}
				screen={screen}
				atouchaway />
		);
	});

	var renderAvatar = isSystem ? null : (
		<Avatar person={person} size={sizeAvatar} />
	);

	return (
		<BlockView style={styles.container}>
			<View style={nameStyle} >
				<StyledText light emphasized>
					{name}
				</StyledText>
			</View>
			{renderedMessages}
			<BlockView style={avatarStyle}>
				{renderAvatar}
			</BlockView>
		</BlockView>
	);
}
