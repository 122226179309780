import React from "react";
import {
	View
} from "react-native";
import xtend from "xtend";
import Style from "ae-style";

var IMAGE_STYLE = {
	position: "absolute",
	backgroundPosition: "center",
	backgroundSize: "cover",
	top: 0,
	bottom: 0,
	left: 0,
	right: 0,
	filter: "blur(" + Style.look.blurRadius + "px)",
	zIndex: -1,
	overflow: "hidden",
};

export default function BlurImageWeb(props){
	var children = props.children;
	var source = props.source;
	var uri = source.uri;

	var imageStyle = xtend(IMAGE_STYLE, {
		backgroundImage: "url(" + uri + ")"
	});

	return (
		<View style={props.style}>
			<div style={imageStyle} />
			{children}
		</View>
	);
}
