/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";
import {
	View,
	StyleSheet,
	ImageBackground,
	Text,
} from "react-native";

import LinearGradient from "react-native-linear-gradient";
import StyledText from "ae-base/Base/StyledText";
import StyledButton from "ae-base/Base/StyledButton";
import BlockView from "ae-base/Base/BlockView";
import NotificationIcon from "ae-base/Base/NotificationIcon";

import Breakpoints from "../../../Breakpoints";
import Style from "@aetonix/styles";

import headerTexture from "../../../Resources/img/header_texture_01SC.png";

import { useResources } from "@aetonix/hooks";

var gradientColors = [Style.colors.gradientStart, Style.colors.gradientEnd]; //"rgb(55, 126, 133)", "rgb(61, 129, 133)"
var gradientStart = {
	x: 0.0, y: 0.75,
};
var gradientEnd = {
	x: 1.0, y: 0.25,
};

var styles = StyleSheet.create({
	header: {
		flexDirection: "row",
		alignItems: "stretch",
		justifyContent: "center",
	},
	headerStyles: {
		alignSelf: "flex-end",
		marginBottom: Style.layout.marginMedium,
	},
	headerPhone: {
		alignSelf: "flex-end",
		marginBottom: Style.layout.marginSlim,
	},
	badge: {
		position: "relative",
		marginRight: Style.layout.marginSmall,
	},
	content: {
		maxWidth: Style.layout.maxContent + Style.layout.marginMedium,
		flex: 1,
		flexDirection: "row",
		alignItems: "stretch",
	},
	left: {
		paddingVertical: Style.layout.paddingMedium,
		flex: 1,
		justifyContent: "flex-start",
		flexDirection: "column",
	},
	leftPhone: {
		flex: 1,
		justifyContent: "flex-start",
		flexDirection: "column",
		paddingLeft: Style.layout.marginMedium,
		paddingVertical: Style.layout.marginSmall,
	},
	welcome: {
		flexDirection: "row",
		alignItems: "flex-end",
	},
	welcomMargin: {
		marginBottom: Style.layout.marginMedium,
	},
	welcomMarginSlim: {
		marginBottom: Style.layout.marginSlim,
	},
	welcomeName: {
		position: "relative",
		//bottom: -5,
		marginLeft: Style.layout.marginSmall,
	},
	right: {
		paddingHorizontal: Style.layout.paddingSmall,
		paddingVertical: Style.layout.paddingMedium,
		flex: 1,
		alignItems: "flex-end",
		flexDirection: "column",
	},
	rightPhone: {
		flex: 1,
		alignItems: "flex-end",
		flexDirection: "column",
		paddingRight: Style.layout.marginMedium,
		paddingVertical: Style.layout.marginSmall,
	},
	logoHighlight: {
		color: "#285E63",
		fontWeight: "bold",
	},
	contentSmall: {
		flexDirection: "row",
	},
});

export default function HomeHeader(props){
	const { dispatch, localize } = useResources();
	var unreadnotifications = props.unreadnotifications;
	var isMobile = Breakpoints.smallerThan("mobile");
	var personal = props.personal || {};
	var name = personal.fname || "";
	name = name.length > 10 && isMobile ? name.substring(0, 10) + "..." : name;
	var accountmenu = localize("homeheader.accountmenu");
	var viewhistory = localize("homeheader.viewhistory");

	var onProfile = dispatch("preferences:trigger:view");
	var onHistory = dispatch("desktop:trigger:page", {
		page: "timeline",
	});

	var meetingNotifications = unreadnotifications?.filter(obj => obj.banner_type === "info:meeting:new")?.length;
	var renderMissedNotificationBadge = null;

	if (unreadnotifications?.length - meetingNotifications <= 0)
		renderMissedNotificationBadge = null;

	else if (unreadnotifications && unreadnotifications.length !== 0)
		renderMissedNotificationBadge = (
			<NotificationIcon style={styles.badge} title={unreadnotifications.length - meetingNotifications} />
		);


	if(isMobile) return (
		<LinearGradient colors={gradientColors} start={gradientStart} end={gradientEnd}>
			<View style={[styles.contentSmall]}>
				<View style={styles.leftPhone}>
					<HeaderWelcome name={name} small />
					<StyledButton id={"accountMenu"} paddedSmall secondary title={accountmenu} icon="settings" onPress={onProfile} />
				</View>
				<ImageBackground resizeMethod="resize" style={styles.rightPhone} source={headerTexture}>
					<StyledText style={styles.headerPhone} smallHeading black>{"a"}<Text selectable={false} style={styles.logoHighlight}>{"Touch"}</Text>{"Away"}</StyledText>
					<StyledButton id={"viewHistory"} paddedSmall clear textStyle={{color:"#102543"}} title={viewhistory} icon="counterclockwise" onPress={onHistory}>
						{renderMissedNotificationBadge}
					</StyledButton>
				</ImageBackground>
			</View>
		</LinearGradient>
	);

	return (
		<LinearGradient style={styles.header} colors={gradientColors} start={gradientStart} end={gradientEnd}>
			<View style={styles.content}>
				<View style={styles.left}>
					<HeaderWelcome name={name} />
					<StyledButton secondary title={accountmenu} id={"accountMenu"} icon="gear" onPress={onProfile} />
				</View>
				<ImageBackground resizeMethod="resize" style={styles.right} source={headerTexture}>
					<StyledText
						style={styles.headerStyles}
						smallHeading black bold>{"a"}
						<Text selectable={false} style={styles.logoHighlight}>{"Touch"}</Text>
						{"Away"}
					</StyledText>
					<StyledButton id={"viewHistory"} padded clear textStyle={{color:"#102543"}} title={viewhistory} icon="counterclockwise" onPress={onHistory}>
						{renderMissedNotificationBadge}
					</StyledButton>
				</ImageBackground>
			</View>
		</LinearGradient>
	);
}

function HeaderWelcome(props) {
	const {
		localize,
	} = useResources();

	var name = props.name;
	var small = props.small;

	var style = [styles.welcome];
	if (!small)
		style.push(styles.welcomMargin);
	else style.push(styles.welcomMarginSlim);

	var hello = localize("homeheader.hello");

	return (
		<View style={style}>
			<StyledText black contrast>{hello}</StyledText>
			<BlockView style={styles.welcomeName}>
				<StyledText smallHeading black bold >{name}</StyledText>
			</BlockView>
		</View>
	);
}
