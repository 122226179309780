import React from "react";
import {
	View,
	StyleSheet,
} from "react-native";
import StyledButton from "ae-base/Base/StyledButton";
import StyledText from "ae-base/Base/StyledText";

import MainContent from "../Layout/MainContent";
import BlockView from "ae-base/Base/BlockView";
import Contact from "../Contacts/Contact";

import Style from "ae-style";
import sortNames from "../../utils/SortNames";

import { useResources } from "@aetonix/hooks";

const styles = StyleSheet.create({
	titleTop: {
		marginVertical: Style.layout.marginSlim,
	},
	title: {
		marginTop: Style.layout.marginLarge,
		marginBottom: Style.layout.marginSlim,
	},
	moreButton: {
		alignItems: "center",
		flex: 1,
		margin: Style.layout.marginSmall,
	},
});

export default function ContactList(props) {
	const {
		localize,
		dispatch,
	} = useResources();

	var conferenceInviter = props.conferenceInviter;
	var conferenceList = conferenceInviter.list || [];
	var managedConferenceList = conferenceInviter.managedList || [];
	var managedUsersList = conferenceInviter.managedUsersList || [];
	var managedAccount = props.managedAccount;
	var callRedirector = props.callRedirector;
	var redirectList = callRedirector.list || [];
	var availability = props.availability;
	var voiceCall = props.voiceCall;
	var managedEnd = props.managedEnd;

	var screen = props.screen;

	var inviteMessage = localize("invitecontactlist.inviteMessage");
	var inviteMessageAvailable = localize("invitecontactlist.inviteMessageAvailable");
	var inviteMessageNotAvailable = localize("invitecontactlist.inviteMessageNotAvailable");

	var content = null;

	if (conferenceInviter.open){
		var renderContact = (contact, index) => {
			var id = contact._id;

			var callContact = dispatch("conferenceinviter:trigger:add", {
				who: id,
				voiceOnly: voiceCall,
			});

			return (
				<Contact id={"callContact"} contact={contact} key={id + index} onPress={callContact} showAvailable screen={screen} >
					<BlockView>
						<StyledButton id={"callContactButton"} clear onPress={callContact} title={inviteMessage} />
					</BlockView>
				</Contact>
			);
		};

		const contacts = sortNames(conferenceList).map(renderContact);
		const managedContacts = sortNames(managedConferenceList).map(renderContact);
		const managedUsers = sortNames(managedUsersList).map(renderContact);

		var renderManagedLoadMore = managedEnd ? null : (
			<View style={styles.moreButton}>
				<StyledButton title={localize("userlist.loadmore")} contrast onPress={dispatch("managedusers:trigger:more")} />
			</View>
		);

		var renderManagedUsers = managedUsersList.length || !managedEnd ? (
			<View>
				<StyledText smallHeading style={styles.title}>{localize("managedcontacts.manageduserslist")}</StyledText>
				{managedUsers}
				{renderManagedLoadMore}
			</View>
		) : null;

		var renderManaged = managedConferenceList.length ? (
			<View>
				<StyledText smallHeading style={styles.title}>{localize("managedcontacts.namedmanagedcontacts", {lname: managedAccount.lname, fname: managedAccount.fname})}</StyledText>
				{managedContacts}
			</View>
		) : null;

		content = (
			<View>
				<StyledText smallHeading style={styles.titleTop}>{localize("managedcontacts.contact_s")}</StyledText>
				{contacts}
				{renderManagedUsers}
				{renderManaged}
			</View>
		);
	} else if(callRedirector.open){
		const contacts = redirectList.map(function(contact, index){
			var id = contact._id;
			var isAvailable = availability[id];

			var redirectContact = dispatch("callredirector:trigger:redirect", {
				to: id,
			});

			inviteMessage = isAvailable ? inviteMessageAvailable : inviteMessageNotAvailable;
			return (
				<Contact id={"redirectContact"} contact={contact} key={index} inactive={!isAvailable} onPress={redirectContact} showAvailable screen={screen} >
					<BlockView>
						<StyledButton id={"redirectContactButton"} clear onPress={redirectContact} title={inviteMessage} />
					</BlockView>
				</Contact>
			);
		});

		content = contacts;
	}

	return (
		<MainContent>
			{content}
		</MainContent>
	);
}
