/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React, { useCallback, useContext, useState } from "react";
import { View } from "react-native";

import ResponsiveStylesheet from "react-native-responsive-stylesheet";
import StyledButton from "ae-base/Base/StyledButton";
import Heading from "ae-base/Base/Heading";
import BlockView from "ae-base/Base/BlockView";
import ConfirmationModal from "ae-base/Base/ConfirmationModal";

import Contact from "../../../Contacts/Contact";
import ListAdd from "ae-base/List/ListAdd";

import ManagedSection from "../ManagedSection";
import Style from "ae-style";
import MeetingContext from "../../../../Calling/context/MeetingContext";
import getVersion, { NewCallingVersion } from "../../../../utils/GetVersion";
import useEnabledFeatures from "../../../../hooks/useEnabledFeatures/useEnabledFeatures";
import sortNames from "../../../../utils/SortNames";

import { useResources } from "@aetonix/hooks";

var styles = ResponsiveStylesheet.createSized("min-direction", {
	0: {
		addBox: {
			marginLeft: Style.layout.marginSmall,
			marginTop: Style.layout.marginSmall,
		},
		managers: {
			marginTop: Style.layout.marginLarge,
			marginBottom: Style.layout.marginContentVertical,
		},
		permission: {
			marginRight: Style.layout.marginSlim,
		},
		editButtons: {
			flexDirection: "column",
		},
		contacts: {
			marginTop: Style.layout.marginLarge,
			marginBottom: Style.layout.marginContentVertical,
		},
	},
	512: {
		editButtons: {
			flexDirection: "row",
		},
	},
});

export default function Managedmanagers(props) {
	const {
		localize,
		dispatch,
	} = useResources();

	const [isLoadingCreateGroupCall, setIsLoadingCreateGroupCall] = useState(false);
	var model = props.model;
	var toast = model.toast;
	var group = model.activemanagegroup;
	var manage = model.manage || {};
	var managers = manage.managers;
	var account = manage.account;
	var removing = manage.managers_remove;
	var editCareteamPermissions = manage.permissions.manage;
	var pendingRequests = manage.managedmanagerrequests.outgoing;
	var pendingEmailRequests = manage.managedmanagerrequests.invites;

	var screen = model.size;
	var managedmanagers = localize("managedmanagers.managedcareteam");
	var managersTitle = localize("managedmanagers.managerstitle");
	var inviteanewmanager = localize("managedmanagers.inviteanewmanager");
	var removeTitle = localize("managedmanagers.removetitle");
	var removeManagerLabel = localize("managedmanagers.remove");
	var confirmLabel = localize("managedmanagers.confirmtext1");
	var cancelLabel = localize("managedmanagers.canceltext1");
	var editTitle = localize("managedmanagers.permissions");
	var pendingrequests = localize("managedmanagers.pendingoutgoingrequests");
	var requestcancel = localize("managedmanagers.cancel");

	const version = getVersion(account.versionInfo || {});
	const isNewCallingSupported = version && version.majorVersion >= NewCallingVersion;
	const { isCallingEnabled } = useEnabledFeatures();
	const meetingManager = useContext(MeetingContext);

	const onCreateMeeting = useCallback(() => {
		const recipientId = account._id;
		const callTile = `${account.lname}, ${account.fname}`;
		const callerId = model?.personal?._id;

		const invitees = managers?.map(manager => manager._id)?.filter(manager => manager !== callerId) ?? [];

		setIsLoadingCreateGroupCall(true);
		meetingManager.onCreateMeeting(recipientId, callTile, invitees).finally(() => setIsLoadingCreateGroupCall(false));
	}, [
		managers,
		account._id,
		account.fname,
		account.lname,
		model?.personal?._id,
		meetingManager.onCreateMeeting,
	]);

	var confirmRemove, cancelRemove;
	if(removing){
		confirmRemove = dispatch("management:trigger:remove:manager:confirm", {
			who: removing,
		});
		cancelRemove = dispatch("management:trigger:remove:manager:cancel");
	}

	var modals = [(
		<ConfirmationModal
			open={removing}
			title={removeTitle}
			titleIcon="warning"
			confirmText={confirmLabel}
			cancelText={cancelLabel}
			onConfirm={confirmRemove}
			onCancel={cancelRemove}
		/>
	)];

	var renderedManagers = sortNames(managers).map(function(manager){
		var managerId = manager._id;
		var userId = account._id;
		var removeManager = dispatch("management:trigger:remove:manager:start", {
			who: managerId,
		});
		var renderPermissions = dispatch("managedmanagerpermissions:trigger:view", {
			user: userId,
			manager: managerId,
			group,
		});
		var onPress = dispatch("contactprofile:trigger:profile:open", {
			who: managerId,
			back: "home",
		});

		var margin = styles.permission;
		var editButtons = styles.editButtons;

		var careteamedit = (editCareteamPermissions < 2) ?
			null :
			(
				<View style={editButtons}>
					<StyledButton style={margin} title={editTitle} onPress={renderPermissions} />
					<StyledButton icon="delete" title={removeManagerLabel} alert onPress={removeManager} />
				</View>
			);

		return (
			<Contact contact={manager} key={managerId} screen={screen} onPress={onPress}>
				{careteamedit}
			</Contact>
		);
	});

	var openRequest = dispatch("managedmanagerrequests:viewing", {
		page: "managedmanagerrequests",
	});

	var careteamadd = (editCareteamPermissions < 2)
		? null :
		(<BlockView style={styles.addBox}>
			<ListAdd title={inviteanewmanager} onPress={openRequest} spaced />
		</BlockView>
		);

	var renderedRequests = (editCareteamPermissions >= 2 && pendingRequests.length) ? (
		<View style={styles.contacts}>
			{pendingRequests.map(function(request){
				var requestId = request._id;
				var managee = request.from;
				var cancelRequest = dispatch("managedmanagerrequests:trigger:cancel", {
					request: requestId,
					managee: managee,
				});

				return (
					<Contact contact={request.to} key={requestId} screen={screen} >
						<StyledButton title={requestcancel} icon="delete" alert onPress={cancelRequest} />
					</Contact>
				);
			})}
		</View>
	) : null;

	var renderManageEmailInvite = pendingEmailRequests.length ? (
		<View>
			<View style={styles.contacts}>
				{pendingEmailRequests.map(function(request){
					var id = request._id;
					var managee = request.contact;
					var email = request.email ? request.email : request.to;
					var cancelEmailInvite = dispatch("managedmanagerrequests:trigger:cancel:email", {
						request: id,
						managee: managee,
					});

					var requestContact = {
						fname: email,
					};

					return (
						<Contact contact={requestContact} key={id} screen={screen} >
							<StyledButton title={requestcancel} icon="delete" alert onPress={cancelEmailInvite} />
						</Contact>
					);
				})}
			</View>
		</View>
	) : null;

	var renderPendingHeader = pendingEmailRequests.length || pendingRequests.length ? (<Heading title={pendingrequests} screen={screen} />) : null;

	return (
		<ManagedSection online={model.online} contact={account} title={managedmanagers} external={modals} toast={toast}>
			{renderPendingHeader}
			{renderedRequests}
			{renderManageEmailInvite}
			<Heading title={managersTitle} screen={screen} />
			<View>
				<View key={account._id} style={styles.managers}>
					{renderedManagers}
					{careteamadd}
				</View>
			</View>
			{ isCallingEnabled && <StyledButton
				title="Start Group Call"
				icon={isLoadingCreateGroupCall ? "spinner" : "phone"}
				disabled={!isNewCallingSupported || isLoadingCreateGroupCall}
				onPress={onCreateMeeting}
			/> }
		</ManagedSection>
	);
}
