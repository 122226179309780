
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";

import StyledText from "ae-base/Base/StyledText";
import ListItem from "ae-base/List/ListItem";
import ListIcon from "ae-base/List/ListIcon";
import ListContent from "ae-base/List/ListContent";

export default function Reminder(props){
	var title = props.title;
	var content = props.content;
	var action = props.action;
	var own = props.own;
	var icon = "notifications";
	if(own) icon = "sticky-note";

	return (
		<ListItem>
			<ListIcon name={icon} />
			<ListContent vertical>
				<StyledText bold>{title}</StyledText>
				{content}
			</ListContent>
			{action}
		</ListItem>
	);
}
