import React from "react";
import { View, StyleSheet, Dimensions, TouchableOpacity } from "react-native";

import StyledText from "ae-base/Base/StyledText";
import BlockView from "ae-base/Base/BlockView";

import Modal from "../Layout/Modal";
import Card from "../Layout/Card";

import SquareButton from "./SquareButton";
import Style from "ae-style";

import StyledButton from "ae-base/Base/StyledButton";

import { useResources } from "@aetonix/hooks";
import { useLocalize } from "../../hooks/useLocalize/LocalizeContext";

var styles = StyleSheet.create({
	outer: {
		padding: 8,
		backgroundColor: "white",
		borderRadius: 24,
		flexShrink: 1,
		flexWrap: "wrap",
	},
	alignCenter: {
		alignItems: "center",
	},
	circleButton: {
		justifyContent: "center",
		alignItems: "center",
	},
	selectedButton: {
		backgroundColor: Style.colors.primary,
	},
	arrowDown: {
		width: 0,
		height: 0,
		borderLeftWidth: 8,
		borderRightWidth: 8,
		borderTopWidth: 8,
		borderStyle: "solid",
		borderLeftColor: "transparent",
		borderRightColor: "transparent",
	},
	arrowDownPrimary: {
		borderTopColor: Style.colors.primary,
	},
	arrowDownAlert: {
		borderTopColor: Style.colors.alert,
	},
	cursorRadius: {
		overflow: "hidden",
		borderRadius: 12,
	},
	textCenter: {
		textAlign: "center",
	},
	rowSpace: {
		flexDirection: "row",
		justifyContent: "space-between",
	},
	columnCenter: {
		flexDirection: "column",
		textAlign: "center",
	},
	controlButtons: {
		flexDirection: "row",
		justifyContent: "space-around",
		marginTop: 24,
	},
});

export default function Reminder(props) {
	const { dispatch } = useResources();
	var reminder = props.reminder;
	var responseType = reminder.responseType;
	var content = reminder.content;

	var localize = useLocalize();
	var atouchaway_reminder = localize("atouchawayreminder.reminder");
	var accept = localize("atouchawayreminder.accept");
	var deny = localize("atouchawayreminder.deny");
	var low = localize("atouchawayreminder.low");
	var high = localize("atouchawayreminder.high");
	var yes = localize("common.yes");
	var no = localize("common.no");
	var touchanumberfrom1to10 = localize("atouchawayreminder.touchanumberfrom1to10");
	const touchanumberfrom0to10 = localize("atouchawayreminder.touchanumberfrom0to10");

	var dimensions = Dimensions.get("window");
	var buttonSize =
		(dimensions.width < 400 || dimensions.height < 400) ? {tiny: true} :
			(dimensions.width < 600 || dimensions.height < 600) ? {small: true} :
				null;

	var smallIcons = (dimensions.width < 700 || dimensions.height < 525);
	var verticalMargin = smallIcons ? 8 : 16;

	var onAccept = dispatch("activereminder:trigger:respond", {
		action: "accept",
	});
	var onDeny = dispatch("activereminder:trigger:respond", {
		action: "deny",
	});
	var onEmotionHappy = dispatch("activereminder:trigger:respond", {
		action: "respond",
		response: "😄",
	});
	var onEmotionSad = dispatch("activereminder:trigger:respond", {
		action: "respond",
		response: "😦",
	});
	var Yes = dispatch("activereminder:trigger:respond", {
		action: "respond",
		response: "Yes",
	});
	var No = dispatch("activereminder:trigger:respond", {
		action: "respond",
		response: "No",
	});

	var renderResponse = null;
	var SCALE = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
	const ZEROSCALE = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

	var renderScaleButtons = SCALE.map(function (number) {
		var selected = false;
		return <NumberButton smallIcons={smallIcons} number={number} selected={selected} />;
	});

	var renderZeroScaleButtons = ZEROSCALE.map(function (number) {
		var selected = false;
		return <NumberButton smallIcons={smallIcons} number={number} selected={selected} />;
	});

	if (responseType === "confirm" || !responseType)
		renderResponse = (
			<View style={styles.controlButtons}>
				<SquareButton title={accept} icon="like" onPress={onAccept} contrast secondary {...buttonSize} />
				<SquareButton title={deny} icon="dislike" onPress={onDeny} contrast highlight {...buttonSize} />
			</View>
		);
	if (responseType === "emotion")
		renderResponse = (
			<View style={styles.controlButtons}>
				<SquareButton title={""} icon="smile-o" onPress={onEmotionHappy} contrast secondary {...buttonSize} />
				<SquareButton title={""} icon="frown-o" onPress={onEmotionSad} contrast highlight {...buttonSize} />
			</View>
		);
	if (responseType === "range")
		renderResponse = (
			<View>
				<View style={styles.rowSpace}>
					<ScaleCursor title={low} contrast />
					<ScaleCursor title={high} alert />
				</View>
				<MarginVertical height={verticalMargin} />
				<View style={styles.rowSpace}>{renderScaleButtons}</View>
				<MarginVertical height={verticalMargin} />
				<View style={styles.textCenter}>
					<StyledText bold black {...(smallIcons ? {small: true} : {emphasized: true})}> {touchanumberfrom1to10} </StyledText>
				</View>
			</View>
		);

	if (responseType === "0range")
		renderResponse = (
			<View >
				<View style={styles.rowSpace}>
					<ScaleCursor title={low} contrast />
					<ScaleCursor title={high} alert />
				</View>
				<MarginVertical height={verticalMargin} />
				<View style={styles.rowSpace}>{renderZeroScaleButtons}</View>
				<MarginVertical height={verticalMargin} />
				<View style={styles.textCenter}>
					<StyledText bold black {...(smallIcons ? {small: true} : {emphasized: true})}> {touchanumberfrom0to10} </StyledText>
				</View>
			</View>
		);

	if (responseType === "yesorno")
		renderResponse = (
			<View style={styles.controlButtons}>
				<SquareButton title={yes} icon="check" onPress={Yes} contrast secondary {...buttonSize} />
				<SquareButton title={no} icon="close" onPress={No} contrast highlight {...buttonSize} />
			</View>
		);


	var outerStyles = [styles.outer, {
		maxWidth: (Dimensions.get("window").width / 10) * 8,
	}];

	return (
		<Modal black open>
			<Card scroll style={outerStyles}>
				<View style={styles.columnCenter}>
					<StyledText bold primary {...(smallIcons ? {standard: true} : {smallHeading: true})}> {atouchaway_reminder} </StyledText>
					<MarginVertical height={verticalMargin} />
					<StyledText bold primary {...(smallIcons ? {emphasized: true} : {large: true})}> {content} </StyledText>
				</View>
				<MarginVertical height={verticalMargin} />
				{renderResponse}
				<MarginVertical height={verticalMargin} />
			</Card>
		</Modal>
	);
}

function ScaleCursor(props) {
	var title = props.title;
	var alert = props.alert;
	var contrast = props.contrast;
	var styleList = [styles.arrowDown];
	if (alert) {
		alert = true;
		contrast = false;
		styleList.push(styles.arrowDownAlert);
	}
	if (contrast) {
		alert = false;
		contrast = true;
		styleList.push(styles.arrowDownPrimary);
	}
	return (
		<View style={styles.alignCenter}>
			<View style={styles.cursorRadius}>
				<StyledButton title={title} bold contrast={contrast} alert={alert} emphasized />
			</View>
			<View style={styleList} />
		</View>
	);
}

function NumberButton(props) {
	const { dispatch } = useResources();
	var number = props.number;
	var selected = props.selected;
	var smallIcons = props.smallIcons;
	var contrast = false;

	var screenWidth = Dimensions.get("window").width;

	var styleList = [styles.circleButton, {
		width: screenWidth / 16,
		height: screenWidth / 16,
		borderRadius: screenWidth / 32,
	}];

	var onRangeAccept = dispatch("activereminder:trigger:respond", {
		action: "respond",
		response: number,
	});

	if (selected) {
		styleList.push(styles.selectedButton);
		contrast = true;
	}

	return (
		<TouchableOpacity accessibilityComponentType="button" style={styleList} onPress={onRangeAccept} >
			<View style={styles.textCenter}>
				<StyledText bold primary contrast={contrast} {...(smallIcons ? {emphasized: true} : {smallHeading : true})}> {number} </StyledText>
			</View>
		</TouchableOpacity>
	);
}

function MarginVertical(props) {
	return (<BlockView style={{marginVertical: props.height || 16}} />);
}
