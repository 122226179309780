/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";
import {
	View,
} from "react-native";
import ResponsiveStylesheet from "react-native-responsive-stylesheet";
import Breakpoints from "../../../Breakpoints";

import Style from "ae-style";

import OfflineBanner from "../Homepage/OfflineBanner";
import StyledButton from "ae-base/Base/StyledButton";
import StyledText from "ae-base/Base/StyledText";
import Loader from "ae-base/Base/Loader";
import ConfirmationModal from "ae-base/Base/ConfirmationModal";
import PageContainer from "../../Layout/PageContainer";
import MainScroll from "../../Layout/MainScroll";
import ContactProfileHeader from "./ContactProfileHeader";
import ManagedProfileHeader from "./ManagedProfileHeader";
import ManagedSections from "./ManagedSections";

import ManagedRemoveContactSchema from "../../../schemas/ManagedRemoveContact";
import ContactSettings from "./ContactSettings";

import Form from "ae-forms";
import TestModeBanner from "../Homepage/TestModeBanner";
import { useResources } from "@aetonix/hooks";

var styles = ResponsiveStylesheet.createSized("min-direction", {
	0: {
		removal: {
			justifyContent: "center",
			flexDirection: "row",
			marginTop: Style.layout.marginContentVertical / 2,
			marginBottom: Style.layout.marginContentVertical,
		},
		stopManaging: {
			justifyContent: "center",
			flexDirection: "row",
			marginVertical: Style.layout.marginContentVertical / 2,
		},
		isOfflineStyle: {
			fontSize: Style.text.small,
			textAlign: "center",
			backgroundColor: Style.colors.red,
			alignSelf: "stretch",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			paddingVertical: Style.layout.paddingSlim,
		},
	},
	512: {
		isOfflineStyle: {
			fontSize: Style.text.emphasized,
		},
	},
});

export default (props) => {
	const { localize, dispatch } = useResources();
	return <Contactprofile {...props} localize={localize} dispatch={dispatch} />;
};

class Contactprofile extends React.PureComponent {
	constructor(props){
		super(props);
		this.state = {
			removeContact: false,
		};

		this.shouldRemoveContact = shouldRemoveContact.bind(this);
	}

	render(){
		var props = this.props;
		var dispatch = props.dispatch;
		var localize = props.localize;
		var offlineMessage = localize("contactprofileheader.offline");

		var model = props.model;
		var contactprofile = model.contactprofile;
		var allcontacts = model.contacts;
		var missed = model.missed;
		var saving = model.saving;
		var manage = model.manage;
		var settings = model.contactsettings || {};

		var screen = model.size;

		var isContact = contactprofile.isContact;
		var isManaged = contactprofile.isManaged;
		var contactId = contactprofile._id;
		var isRemoving = allcontacts && (allcontacts.removing === contactId);
		var manageRemoving = model.manage && (model.manage.removing === contactId);
		var activemanagegroup = model.activemanagegroup;

		var account = manage.account;
		var testModeEnabled = manage.account.testModeEnabled;
		if (!contactId)
			return (
				<Loader />
			);

		var managed = null;
		var stopManagingButton = null;
		if (isManaged) {
			var stopManaging = dispatch("management:trigger:remove:managment:start", {
				who: contactId,
			});
			var stopSize;
			if (Breakpoints.smallerThan("mobile"))
				stopSize = "small";

			if (Breakpoints.smallerThan("small"))
				stopSize = "small";


			var stopTitle = <StyledText bold size={stopSize}>{localize("contactprofile.stopmanaging")}</StyledText>;

			stopManagingButton = (
				<View style={styles.stopManaging}>
					<StyledButton id={"stopManaging"} title={stopTitle} icon="ban" onPress={stopManaging} />
				</View>
			);

			managed = (
				<ManagedSections group={activemanagegroup} online={model.online} manage={manage} screen={screen} />
			);
		}

		var removeContactButton;
		if (isContact) {
			var removeContact = dispatch("allcontacts:trigger:remove:contact:start", {
				who: contactId,
			});
			var removeSize;
			if (Breakpoints.smallerThan("mobile"))
				removeSize = "small";

			if (Breakpoints.smallerThan("small"))
				removeSize = "small";


			var removalTitle = <StyledText bold size={removeSize}>{localize("contactprofile.remove")}</StyledText>;

			removeContactButton = (
				<View style={styles.removal}>
					<StyledButton id={"removeContact"} title={removalTitle} icon="ban" onPress={removeContact} />
				</View>
			);
		}

		var cancelRemove,
			confirmRemove;
		if (isRemoving) {
			cancelRemove = dispatch("allcontacts:trigger:remove:contact:cancel");
			confirmRemove = dispatch("allcontacts:trigger:remove:contact:confirm", {
				who: contactId,
			});
		}

		var cancelStopManaging,
			confirmStopManaging;
		if (manageRemoving) {
			cancelStopManaging = dispatch("management:trigger:remove:managment:cancel");
			confirmStopManaging = dispatch("management:trigger:remove:managment:confirm", {
				who: contactId,
				removeContact: this.state.removeContact,
			});
		}

		var contactSettings = isContact && contactprofile.type.indexOf("atouchaway") === -1 ? (
			<ContactSettings contact={contactprofile} />
		) : null;

		var header = isManaged ? (
			<ManagedProfileHeader contact={contactprofile} group={activemanagegroup} missed={missed} manage={manage} screen={screen} settings={settings[contactId]} />
		) : (
			<ContactProfileHeader contact={contactprofile} missed={missed} screen={screen} settings={settings[contactId]} />
		);

		var isTestMode =  testModeEnabled ? (
			<TestModeBanner />
		) : null;

		var isOffline = contactprofile.isAvailable ? (
			null
		)  : (
			<StyledText	style={styles.isOfflineStyle} bold contrast>{offlineMessage}</StyledText>
		);

		var offline = model.online ? null : (
			<OfflineBanner />
		);

		var renderCheckBox = isContact ? (
			<Form schema={ManagedRemoveContactSchema} onSubmit={this.shouldRemoveContact} autoSubmit contrast />
		) : null;

		return (
			<PageContainer key={contactId} saving={saving} toast={model.toast}>
				<MainScroll>
					{header}
					{offline}
					{isTestMode}
					{isOffline}
					{managed}
					{contactSettings}
					{stopManagingButton}
					{removeContactButton}
				</MainScroll>
				<ConfirmationModal open={isRemoving} title={localize("contactprofile.sure")} titleIcon="warning" confirmText={localize("contactprofile.confirmremove")} cancelText={localize("contactprofile.cancelremove")} onConfirm={confirmRemove} onCancel={cancelRemove}>
					<StyledText contrast>{localize("contactprofile.detailremove", {
						lname: contactprofile.lname,
						fname: contactprofile.fname,
					})}
					</StyledText>
				</ConfirmationModal>
				<ConfirmationModal open={manageRemoving} title={localize("contactprofile.sure")} titleIcon="warning" confirmText={localize("contactprofile.confirmpriv")} cancelText={localize("contactprofile.cancelpriv")} onConfirm={confirmStopManaging} onCancel={cancelStopManaging}>
					<StyledText contrast>{localize("contactprofile.detailpriv", {
						lname: contactprofile.lname,
						fname: contactprofile.fname,
					})}
					</StyledText>
					{renderCheckBox}
				</ConfirmationModal>
			</PageContainer>
		);
	}
}

function shouldRemoveContact(data){
	var contact = data.contact;
	this.setState({
		removeContact: contact.length === 1,
	});
}
