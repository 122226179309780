export default {
	title: "Login",
	description: "schemas_login_title",
	type: "object",
	properties: {
		email: {
			order: 0,
			description: "login.email_address",
			type: "string",
			inputType: "text",
			format: "email",
			autoComplete: "email"
		},
		password: {
			order: 1,
			description: "login.password",
			type: "string",
			inputType: "text",
			format: "password",
			autoComplete: "password"
		}
	}
};
