import React from "react";
import {
	StyleSheet,
	TouchableOpacity,
} from "react-native";

import { useResources } from "@aetonix/hooks";

var styles = StyleSheet.create({
	main: {
		flex: 1,
		backgroundColor: "#000000",
	},
});

export default function Hide(props) {
	const {
		dispatch,
	} = useResources();

	return (
		<TouchableOpacity style={styles.main} onPress={dispatch("screenhider:trigger:show")} />
	);
}
