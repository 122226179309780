import React from "react";
import PropTypes from "prop-types";

import AtaContainer from "../aTouchAway/AtaContainer";
import NewReadingModal from "../Pages/MyHealth/HealthSection/NewReadingModal";

export default function SimplifiedNewReadingModal(props) {
	const model = props.model;
	const reading = model.reading;
	const newreading = model.newreading;
	const onWorkflowReadFromDevice = model.workflowaction && model.myhealthdevice;
	const page = reading.deviceType;

	if (!(newreading && newreading.open)) return null;

	if (onWorkflowReadFromDevice) {
		return (
			<NewReadingModal model={model} newreading={newreading} page={page} />
		);
	}

	return (
		<AtaContainer>
			<NewReadingModal model={model} newreading={newreading} page={page} />
		</AtaContainer>
	);
}

SimplifiedNewReadingModal.propTypes = {
	model: PropTypes.object,
};
