/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";

import StyledText from "ae-base/Base/StyledText";
import ManagedSection from "../../Managed/ManagedSection";
import ListAdd from "ae-base/List/ListAdd";
import Schedules from "./Schedules";
import AddSchedule from "./AddSchedule";

import { useResources, useIdPrefix } from "@aetonix/hooks";

export default function Schedule(props) {
	const {
		localize,
		dispatch,
	} = useResources();
	const idPrefix = useIdPrefix();

	var model = props.model;
	var toast = model.toast;
	var schedule = model.schedules;
	var scheduleList = schedule.readable || [];
	var user = model.personal;
	var onAddSchedule = dispatch("schedules:trigger:modify");

	var screen = model.size;

	var scheduleLength = {};
	scheduleLength.length = scheduleList.length;
	var scheduleTitleNumber = localize("schedule.scheduleTitleNumber", scheduleLength);
	var scheduleTitleEmpty = localize("schedule.scheduleTitleEmpty");
	var renderScheduleTitle = scheduleList.length ? scheduleTitleNumber : scheduleTitleEmpty;

	var renderScheduleList = scheduleList.map(function(schedules){
		var id = schedules.action + schedules.schedule;
		return (
			<Schedules key={id} scheduleList={schedules} />
		);
	});

	var scheduleTitle = localize("schedule.scheduleTitle");
	var scheduleAdd = localize("schedule.scheduleAdd");
	var renderSchedule = (
		<ManagedSection online={model.online} contact={user} title={scheduleTitle} toast={toast}>
			<StyledText>{renderScheduleTitle}</StyledText>
			{renderScheduleList}
			<ListAdd id={idPrefix + "scheduleListAddButton"} title={scheduleAdd} onPress={onAddSchedule} />
		</ManagedSection>
	);

	var renderContent = schedule.modify ? (
		<AddSchedule screen={screen} online={model.online} />
	) : renderSchedule;

	return (
		renderContent
	);
}
