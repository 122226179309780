import React from "react";
import TimelineItem from "./TimelineItem";

import { useResources } from "@aetonix/hooks";

export default function UrgencyAccepted(props) {
	const {
		localize,
	} = useResources();

	var notification = props.notification;
	var urgencycallaccepted = localize("banner.urgency_accepted", notification.content.to);
	var screen = props.screen;

	return (
		<TimelineItem content={urgencycallaccepted} iconName="info"  notification={notification} screen={screen} />
	);
}
