import React from "react";

import Loader from "ae-base/Base/Loader";
import StyledButton from "ae-base/Base/StyledButton";
import StyledText from "ae-base/Base/StyledText";
import ConfirmationModal from "ae-base/Base/ConfirmationModal";
import CardPage from "../../../Layout/CardPage";

import Form from "ae-forms";

import { useResources } from "@aetonix/hooks";

var DEFAULT_DATA = {
	title: "",
	body: "",
	files: [],
};

export default function ManagedformsEdit(props) {
	const {
		localize,
		dispatch,
	} = useResources();
	var model = props.model;

	var toast = model.toast;
	var manage = model.manage;
	var forms = manage.forms;
	var schema = forms.schema;
	var updating = forms.updating;
	var removing = forms.removing;
	var defaultData = forms.data || DEFAULT_DATA;
	var hasSchema = (forms.schema && forms.schema.properties);
	var canDelete = forms.canDelete;
	var saving = model.saving;

	var screen = model.size;

	var deletetext = localize("managedformsedit.deletetext");
	var title = localize("managedformsedit.title");
	var confirmtext = localize("managedformsedit.confirmtext");
	var canceltext = localize("managedformsedit.canceltext");
	var text = localize("managedformsedit.text");
	var editnote = localize("managedformsedit.editnote");
	var discardchanges = localize("managedformsedit.discardchanges");

	var deleteSubmission = dispatch("managedforms:trigger:remove:start", {
		submission: updating,
	});

	var deleteButton = canDelete ? (
		<StyledButton alert title={deletetext} icon="delete" onPress={deleteSubmission} />
	) : null;

	if(!hasSchema) return <Loader />;

	var submitUpdate = dispatch("managedforms:trigger:update:submit");
	var goBack = dispatch("managedforms:trigger:cancel");

	var confirmDelete, cancelDelete;

	if(removing){
		confirmDelete = dispatch("managedforms:trigger:remove:accept", {
			submission: updating,
		});
		cancelDelete = dispatch("managedforms:trigger:remove:cancel");
	}

	var confirmation = (
		<ConfirmationModal
			open={removing}
			title={title}
			titleIcon="warning"
			confirmText={confirmtext}
			cancelText={canceltext}
			onConfirm={confirmDelete}
			onCancel={cancelDelete}
			toast={toast}
		>
			<StyledText contrast>{text}</StyledText>
		</ConfirmationModal>
	);

	return (
		<CardPage title={editnote} icon="notebook" edit={deleteButton} external={confirmation} toast={toast} saving={saving} screen={screen} online={model.online}>
			<Form key={updating + forms.updated_at} schema={schema} defaultData={defaultData} onSubmit={submitUpdate} onCancel={goBack} cancelText={discardchanges} />
		</CardPage>
	);
}
