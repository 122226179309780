/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React  from "react";

import Banner from "./Banner";

import Style from "ae-style";

import { useResources } from "@aetonix/hooks";

export default function WanderBanner(props) {
	const {
		localize,
		dispatch,
		config,
	} = useResources();

	var color;
	var message_line1;
	var message_line2;
	var validate;
	var children;

	var banner = props.banner;

	var time = banner.updated_at;
	var date = banner.formatted_date;
	var from = banner.from;
	var banner_type = banner.banner_type;

	var content = banner.content;
	var invalid = content.invalid;
	var wandering = content.wandering;
	var from_id = content.from;
	var acknowledger = content.acknowledge || {};
	if (acknowledger)
		var acknowledger_id = acknowledger._id;


	var personal_id = config.id;

	if (wandering && wandering !== 2) {
		color = Style.colors.red;
		message_line1 = localize("banner.wanderLeftzone", {
			lname: from.lname,
			fname: from.fname,
		});
		validate = dispatch("banner:trigger:acknowledge", {
			from: from_id,
		});
	}

	if (wandering === 2) {
		color = Style.colors.yellow;
		if (!invalid && personal_id === acknowledger_id) {
			message_line1 = localize("banner.wanderLeftzone", {
				lname: from.lname,
				fname: from.fname,
			});
			message_line2 = localize("banner.wander_acknowledge", {
				lname: acknowledger.lname,
				fname: acknowledger.fname,
			});
			validate = dispatch("wanderinvalidator:trigger:start", {
				from: from_id,
			});
		}
		if (!invalid && personal_id !== acknowledger_id) {
			message_line1 = localize("banner.wanderLeftzone", {
				lname: from.lname,
				fname: from.fname,
			});
			message_line2 = localize("banner.wander_acknowledge", {
				lname: acknowledger.lname,
				fname: acknowledger.fname,
			});
		}
	}

	if (!wandering) {
		color = Style.colors.red;
		message_line1 = localize("banner.wanderInzone", {
			lname: from.lname,
			fname: from.fname,
		});
		validate = dispatch("banner:trigger:clear", {
			from: from_id,
			type: banner_type,
		});
	}

	// If the wandering has been acknowledged as 'false', the yellow banners seen by those
	// who _are NOT_ the acknowledgers, will NOT be clearable.
	// The yellow banner will only disappear when reconnection happens.
	if (invalid) {
		color = Style.colors.yellow;
		message_line1 = localize("banner.wanderFalseAlarm", {
			lname: from.lname,
			fname: from.fname,
		});
	}

	return (
		<Banner
			time={time}
			date={date}
			message_line1={message_line1}
			message_line2={message_line2}
			color={color}
			onPress={validate}
			border
		>{children}
		</Banner>
	);
}
