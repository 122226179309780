import React from "react";
import { Platform, View } from "react-native";

import RNWModal from "modal-react-native-web";
import RNModal from "react-native-modal";
import { StyleSheet } from "react-native";
import AeStyles from "ae-style";
const Modal = Platform.OS === "web" ? RNWModal : RNModal;

const ModalOverlay = ({ children, ...props }) => {
	let customStyle = [styles.modal, props.styles];

	return (<Modal {...props} style={customStyle} >
		<View style={styles.background}>{children}</View>
	</Modal>);
};

const styles = StyleSheet.create({
	modal: {
		margin: 0
	},
	background: {
		backgroundColor: AeStyles.colors.overlay,
		flexGrow: 1
	}
});

export default ModalOverlay;