import React, {
	Component,
} from "react";
import {
	View,
	StyleSheet,
	TouchableOpacity,
} from "react-native";

import Style from "ae-style";
import StyledText from "ae-base/Base/StyledText";
import StyledIcon from "ae-base/Base/StyledIcon";
import StyledButton from "ae-base/Base/StyledButton";
import VideoView from "../Calling/VideoView";
import NavBanner from "ae-base/Base/NavBanner";

import Breakpoints from "../../Breakpoints";
import { useResources } from "@aetonix/hooks";

const styles = StyleSheet.create({
	footer: {
		flexDirection: "row",
		backgroundColor: Style.colors.dark,
	},
	nameBar: {
		flexDirection: "row",
		alignItems: "center",
		backgroundColor: Style.colors.black,
		justifyContent: "space-between",
	},
	fullLength: {
		flex: 1,
		flexDirection: "column",
	},
	streams: {
		flexDirection: "row",
		width: Style.imageSize.verysmall,
		justifyContent: "flex-end",
	},
	video: {
		flex: 1,
	},
	videoHidden: {
		position: "absolute",
		width: 0,
		height: 0,
	},
});

export default function ATouchAwayConnectedHeader(props) {
	const { localize, dispatch } = useResources();

	const fullscreen = localize("connectedfooter.fullscreen");
	const minimize = localize("connectedfooter.minimize");
	const connectedto = localize("connectedfooter.connectedto");
	const endcall = localize("connectedfooter.endcall");
	const calling = props.calling;
	const who = calling.who;
	const callgroup = calling.callgroup;

	if (props.page === "calling") {return null;}

	if(!who) {return null;}

	if(calling.state !== "connected") {return null;}

	let name = calling.who.lname + ", " + calling.who.fname;
	if(callgroup) {
		name = callgroup.name;
	}
	const hangUp = dispatch("calling:trigger:hangup");
	const openCalling = dispatch("atouchawaymobile:trigger:page", {
		page: "calling",
	});
	const closeCalling = dispatch("atouchawaymobile:trigger:page", {
		page: "contacts",
	});

	const isConferenceCallConnected = calling.conference_state === "connected";
	const conferenceURL = calling.streams.conference;
	const remoteURL = calling.streams.remote;
	const voiceOnly = calling.streams.voiceOnly;
	const streams = [];
	if (!props.incall) {
		streams.push(
			<Video id={"openCalling"} onPress={openCalling} stream={remoteURL} dispatch={dispatch} />
		);

		if(isConferenceCallConnected) {
			streams.push(
				<Video id={"openCallingConference"} onPress={openCalling} stream={conferenceURL} dispatch={dispatch} />
			);
		}
	}

	const callContent = Breakpoints.smallerThan("mobile") ? (
		<StyledButton id={"hangUp"} title={endcall} icon="aeto-hang_up-2" onPress={hangUp} alert fullwidth />
	) : (
		<View style={styles.nameBar}>
			<TouchableOpacity style={styles.nameBar} onPress={openCalling}>
				<StyledIcon contrast name="aeto-phone" large />
				<StyledText contrast >  {connectedto} {name} </StyledText>
			</TouchableOpacity>
			<StyledButton id={"hangUp"} title={endcall} icon="aeto-hang_up-2" onPress={hangUp} alert />
		</View>
	);

	const streamStyle = [styles.streams];

	if(voiceOnly || props.incall)
		{streamStyle.push(styles.videoHidden);}

	return (
		<View style={styles.footer}>
			<View style={styles.fullLength} >
				{callContent}
				<NavBanner
					id={"NavBannerDown"}
					title={props.incall ? minimize : fullscreen}
					icon={props.incall ? "compress" : "window-maximize"}
					onPress={props.incall ? closeCalling : openCalling}
				/>
			</View>
			<View style={streamStyle} id="ae_remote_video">
				{streams}
			</View>
		</View>
	);
}

class Video extends Component{
	componentDidMount(){
		const dispatch = this.props.dispatch;
		setTimeout(dispatch("buzz:trigger:video:recalculate"), 16);
		setTimeout(dispatch("buzz:trigger:video:show"), 64);
	}

	render(){
		const props = this.props;
		const stream = props.stream;
		const onPress = props.onPress;
		const id = props.id;

		return (
			<TouchableOpacity id={id} style={styles.video} onPress={onPress}>
				<VideoView stream={stream} />
			</TouchableOpacity>
		);
	}
}
