import React, {
	Component,
} from "react";
import {
	View,
	Image,
	StyleSheet,
} from "react-native";
import Toast from "ae-base/Base/Toast";
import StatusBar from "ae-base/Base/StatusBar";
import StyledText from "ae-base/Base/StyledText";
import StyledButton from "ae-base/Base/StyledButton";
import Style from "ae-style";

import CallPage from "../../CallPage";
import CallControls from "../../CallControls";
import CallButton from "../../CallButton";
import InviteList from "../../InviteList";
import RemotePerson from "./RemotePerson";
import LocalPerson from "./LocalPerson";
import CallingBackButton from "../../CallingBackButton";
import LoadingGif from "../../../../Resources/img/loading.gif";

import { useResources } from "@aetonix/hooks";

var styles = StyleSheet.create({
	streamView: {
		flex: 1,
	},
	landscape: {
		flexDirection: "row",
	},
	portrait: {
		flexDirection: "column",
	},
	loadingGif: {
		position: "absolute",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		justifyContent: "center",
		alignItems: "center",
		zIndex: 3,
		flex: 1,
		backgroundColor: Style.colors.primary,
		opacity: 0.8,
	},
});

export default (props) => {
	const { localize, dispatch } = useResources();
	return <Connected localize={localize} dispatch={dispatch} {...props} />;
};

class Connected extends Component {
	componentDidMount(){
		var dispatch = this.props.dispatch;
		setTimeout(dispatch("buzz:trigger:video:recalculate"), 64);
		setTimeout(dispatch("buzz:trigger:video:show"), 64);
	}
	render(){
		var props = this.props;
		var localize = props.localize;
		var dispatch = props.dispatch;

		var calling = props.calling;
		var callRedirector = props.callRedirector;
		var conferenceInviter = props.conferenceInviter;
		var managedAccount = props.managedAccount;
		var availability = props.availability;
		var toast = props.toast;
		var magnifying = props.magnifying;
		var orientation = props.orientation;
		var state = props.state;
		var screen = props.screen;
		var auidoOutput = props.auidoOutput;

		var remoteURL = calling.streams.remote;
		var localURL = calling.streams.local;
		var conferenceURL = calling.streams.conference;

		var conference_state = calling.conference_state;

		var isConference = conference_state !== "closed" && conference_state !== "idle";

		var button = "endCall";
		var callgroup = props.callgroup;

		if(callgroup)
			button = "callgroupEndCall";


		var voiceOnly = calling.streams.voiceOnly;

		var isRenderInviteList = (callRedirector.open || conferenceInviter.open) ? (
			<InviteList callRedirector={callRedirector} conferenceInviter={conferenceInviter} managedAccount={managedAccount} managedEnd={props.managedEnd} availability={availability} voiceCall={voiceOnly} screen={screen} />
		) : null;

		var render3wayButton = !isConference ? (
			<CallButton button={"way3"} />
		) : null;

		var renderForwardButton = !isConference ? (
			<CallButton button={"forward"} />
		) : null;

		var remoteKey = "remote" + !!isRenderInviteList;

		var callingMessage = state === "connected" ? localize("connected.message") : localize("connected.switchCamera");
		var streams = [(
			<RemotePerson callgroup={callgroup} key={remoteKey} person={calling.who} stream={remoteURL} voiceOnly={voiceOnly} conference={isConference} message={callingMessage} id="ae_remote_video" />
		)];

		if(isConference){
			var conferencePerson = calling.conference;
			var conferenceMessage;
			if(conference_state === "connected")
				conferenceMessage = localize("connected.message");
			if(conference_state === "calling")
				conferenceMessage = localize("connected.conferenceMessageCalling");
			if(conference_state === "connecting")
				conferenceMessage = localize("connected.conferenceMessageConnecting");

			var shouldHide = (conferenceURL && conferenceURL.phonertc && !voiceOnly);

			if(!shouldHide)
				streams.push((
					<RemotePerson key="conference" person={conferencePerson} stream={conferenceURL} voiceOnly={voiceOnly} message={conferenceMessage} conference={isConference} magnifying={magnifying} />
				));
		}

		var streamViewStyle = [styles.streamView];

		streamViewStyle.push((orientation === "LANDSCAPE") ? styles.landscape : styles.portrait);

		var streamDisplay = (
			<View style={streamViewStyle}>
				{streams}
			</View>
		);

		var switching = calling.switching;
		var renegotiating = (state === "connecting" && calling.streams.remote) ? true : false;
		var loadingText = switching ? localize("connected.switchCamera") : localize("connected.callDrop");
		var renderCancel = switching ? null : (
			<StyledButton id={"endCall"} alert title={localize("callbutton.endcall")} onPress={dispatch("buzz:trigger:hangup")} />
		);
		var renderLoading = renegotiating ? (
			<View style={styles.loadingGif}>
				<Image resizeMethod="resize" resizeMode="contain" style={styles.canvas} source={LoadingGif} />
				<StyledText contrast emphasized>{loadingText}</StyledText>
				{renderCancel}
			</View>
		) : null;

		return (
			<CallPage>
				<StatusBar />
				<CallingBackButton />
				{streamDisplay}
				<LocalPerson
					stream={localURL}
					voiceOnly={voiceOnly}
					magnifying={magnifying}
					callInputSwitcher={props.callInputSwitcher}
					auidoOutput={auidoOutput}
					orientation={orientation}
					isConference={isConference} />
				<CallControls>
					{render3wayButton}
					{renderForwardButton}
					<CallButton button={button} />
				</CallControls>
				{isRenderInviteList}
				{renderLoading}
				<Toast toast={toast} id={"connected_toast"} />
			</CallPage>
		);
	}
}
