/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";
import {
	StyleSheet,
	View,
	ScrollView,
	TouchableWithoutFeedback,
	Platform,
} from "react-native";

import StyledText from "ae-base/Base/StyledText";
import StyledButton from "ae-base/Base/StyledButton";
import BlockView from "ae-base/Base/BlockView";

import Style from "ae-style";

import Modal from "../../Layout/Modal";

import Markdown from "ae-base/Base/Markdown";

import { useResources } from "@aetonix/hooks";
var styles = StyleSheet.create({
	title: {
		textAlign: "center",
	},
	bottom: {
		flex: 1,
		alignItems: "stretch",
		justifyContent: "flex-end",
		flexDirection: "row",
	},
	container: {
		backgroundColor: Style.colors.white,
		margin: Style.layout.marginSmall,
		marginBottom: 0,
		padding: Style.layout.paddingSmall,
	},
	closeButton: {
		justifyContent: "flex-end",
	},
	marginText: {
		margin: Style.layout.marginSmall,
	},
	box: {
		borderWidth: 2,
		borderStyle: "solid",
		borderColor: Style.colors.primary,
		padding: Style.layout.paddingSmall,
		marginBottom: Style.layout.marginSmall,
	},
});

export default function License(props){

	const { timeoutReset, localize } = useResources();

	const close = localize("license.close");
	const title = localize("license.title");
	const section1 = localize("license.section1");
	const section2 = localize("license.section2");
	const section3 = localize("license.section3");

	var scrollViewProps = {
		style: styles.container,
	};

	if (Platform.OS === "web") {
		scrollViewProps.onScroll = timeoutReset;
	}

	return (
		<Modal open>
			<ScrollView {...scrollViewProps}>
				<TouchableWithoutFeedback>
					<View>
						<Markdown>{title}</Markdown>
						<Markdown>{section1}</Markdown>
						<BoxedSection><Markdown>{section2}</Markdown></BoxedSection>
						<Markdown>{section3}</Markdown>
					</View>
				</TouchableWithoutFeedback>
			</ScrollView>
			<View style={{alignItems: "stretch", width: "100%"}}>
				<StyledButton fullwidth stretch id={"license_close"} centered contrast title={close} onPress={props.close} />
			</View>
		</Modal>
	);
}

const BoxedSection = (props)=>{
	return <View style={styles.box} {...props} />;
};
function MarginText(){
	return <BlockView style={styles.marginText} />;
}
