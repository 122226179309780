import React from "react";
import {
	StyleSheet,
	View,
	Image,
} from "react-native";

import ImageUrl from "ae-base/Base/ImageUrl";

import { useResources } from "@aetonix/hooks";

var styles = StyleSheet.create({
	content: {
		alignItems: "stretch",
		flex: 1,
	},
	image: {
		flex: 1,
		alignItems: "center",
		width: null,
		height: null
	}
});

export default function PersonImage(props) {
	const { config } = useResources();
	var person = props.who || {};

	return (
		<View style={styles.content}>
			<Image resizeMethod="resize" style={styles.image} source={ImageUrl(person.image_medium, config)} />
		</View>
	);
}
