import React, {
	PureComponent,
} from "react";
import {
	View,
} from "react-native";
import prop from "prop";

import Items from "../Items";
import Item from "../Item";
import Label from "../Label";
import Value from "../Value";
import Summarized from "../Summarized";
import GroupItems from "../GroupItems";

import { useResources } from "@aetonix/hooks";

var getNextPlannedDate = prop("NextPlannedDate");
var getSurgicalDevice = prop("SurgicalDevice");
var getAssistiveDevice = prop("AssistiveDevice");
var getHealthEducationCounselling = prop("HealthEducationCounselling");
var getSelfMonitoringRoutines = prop("SelfMonitoringRoutines");
var getOtherTreatments = prop("OtherTreatments");

export default function Othertreatments(props) {
	const {
		localize,
	} = useResources();

	var careplan = props.careplan;
	var currentSection = props.currentSection;
	var permissions = props.permissions;
	var changes = props.changes;
	var showCareplanDetail = props.showCareplanDetail;
	var ownCareplan = props.ownCareplan;
	var othertreatments = localize("othertreatments.othertreatments");
	var significantsurgeriesandorimplateddevices = localize("othertreatments.significantsurgeriesandorimplateddevices");
	var healtheducationorcounselling = localize("othertreatments.healtheducationorcounselling");
	var assistivedevices = localize("othertreatments.assistivedevices");
	var selfmonitoringroutines = localize("othertreatments.selfmonitoringroutines");
	var nextplandate = localize("othertreatments.nextplandate");

	var hasChanged = permissions >= 2;
	var getPlannedDate = getNextPlannedDate(careplan) ? nextplandate + getNextPlannedDate(careplan) : null;

	var SurgicalDevices = getSurgicalDevice(careplan) || [];
	var AssistiveDevice = getAssistiveDevice(careplan) || [];

	var renderSurgicalDevice = SurgicalDevices.map((device, index)=>
		<Device key={index} device={device} />
	);
	var renderAssistiveDevice = AssistiveDevice.map((device, index)=>
		<Device key={index} device={device} />
	);

	return (
		<View>
			<GroupItems>
				<Items hasChanged={hasChanged && changes.SurgicalDevice} data={"SurgicalDevice"}>
					<Item>
						<Label>{significantsurgeriesandorimplateddevices}</Label>
					</Item>
					<Summarized currentSection={currentSection} section={"surgicaldevice"} ownCareplan={ownCareplan} showCareplanDetail={showCareplanDetail}>
						{renderSurgicalDevice}
					</Summarized>
				</Items>
			</GroupItems>

			<GroupItems>
				<Items hasChanged={hasChanged && (changes.HealthEducationCounselling || changes.NextPlannedDate)} data={"HealthEducationCounselling,NextPlannedDate"}>
					<Item alternate>
						<Label>{healtheducationorcounselling}</Label>
						<Value>{getPlannedDate}</Value>
					</Item>
					<Summarized currentSection={currentSection} section={"healtheducation"} showCareplanDetail={showCareplanDetail} ownCareplan={ownCareplan}>
						<Item alternate>
							<Label />
							<Value>{getHealthEducationCounselling(careplan)}</Value>
						</Item>
					</Summarized>
				</Items>
			</GroupItems>

			<GroupItems>
				<Items hasChanged={hasChanged && changes.AssistiveDevice} data={"AssistiveDevice"}>
					<Item>
						<Label>{assistivedevices}</Label>
					</Item>
					<Summarized currentSection={currentSection} section={"assistivedevice"} ownCareplan={ownCareplan} showCareplanDetail={showCareplanDetail}>
						{renderAssistiveDevice}
					</Summarized>
				</Items>
			</GroupItems>

			<Items hasChanged={hasChanged && changes.SelfMonitoringRoutines} data={"SelfMonitoringRoutines"}>
				<Item alternate>
					<Label>{selfmonitoringroutines}</Label>
					<Value>{getSelfMonitoringRoutines(careplan)}</Value>
				</Item>
			</Items>

			<GroupItems>
				<Items hasChanged={hasChanged && changes.OtherTreatments} data={"OtherTreatments"}>
					<Item>
						<Label>{othertreatments}</Label>
					</Item>
					<Summarized currentSection={currentSection} section={"othertretments"} showCareplanDetail={showCareplanDetail} ownCareplan={ownCareplan}>
						<Item>
							<Label />
							<Value>{getOtherTreatments(careplan)}</Value>
						</Item>
					</Summarized>
				</Items>
			</GroupItems>
		</View>
	);
}

class Device extends PureComponent {
	render(){
		var props = this.props;
		var device = props.device;
		return (
			<Item>
				<Label />
				<Value>{device}</Value>
			</Item>
		);
	}
}
