import React from "react";
import {
	View,
	StyleSheet,
	ScrollView,
	TouchableOpacity,
	Image,
	TouchableWithoutFeedback,
} from "react-native";

import StyledText from "ae-base/Base/StyledText";
import ImageUrl from "ae-base/Base/ImageUrl";
import Style from "ae-style";

import { useResources } from "@aetonix/hooks";

var styles = StyleSheet.create({
	cancelButton: {
		backgroundColor: "#AE1010",
	},
	page: {
		flex: 1,
		alignItems: "center",
		marginVertical: Style.layout.marginHuge,
	},
	contactListView: {
		flex: 1,
		flexDirection: "row",
		alignItems: "center",
	},
	image: {
		margin: Style.layout.marginHuge,
		width: 80,
		height: 80,
	},
	name: {
		justifyContent: "center",
	},
	backText: {
		padding: Style.layout.paddingButton,
	},
});

export default function ConferenceInviter(props) {
	const {
		localize,
		dispatch,
	} = useResources();
	var conferenceinviter = props.conferenceinviter;
	var voiceOnly = props.voiceOnly;
	var availability = props.availability;

	var closeText = localize("conferenceinviter.gobackwithoutinvitinganyone");
	var closeActivity = dispatch("conferenceinviter:trigger:close");

	return (
		<View style={styles.page}>
			<ContactList conferenceinviter={conferenceinviter} voiceOnly={voiceOnly} availability={availability} />
			<TouchableOpacity style={styles.cancelButton} onPress={closeActivity}>
				<StyledText style={styles.backText} contrast bold>{closeText}</StyledText>
			</TouchableOpacity>
		</View>
	);
}

function ContactList(props) {
	const {
		dispatch,
		config,
	} = useResources();

	var conferenceinviterList = props.conferenceinviter.list;
	var voiceOnly = props.voiceOnly;

	var renderContactList = conferenceinviterList.map(function(contact){
		var id = contact._id;
		var pressAction = voiceOnly ? dispatch("conferenceinviter:trigger:add", {
			who: id,
			voiceOnly: "true",
		}) : dispatch("conferenceinviter:trigger:add", {
			who: id,
		});
		var name = contact.lname + ", " + contact.fname;

		return (
			<TouchableOpacity key={id} onPress={pressAction} style={styles.contactListView}>
				<Image source={ImageUrl(contact.image, config)} style={styles.image} />
				<StyledText style={styles.name} bold smallHeading contrast>{name}</StyledText>
			</TouchableOpacity>
		);
	});

	return (
		<ScrollView>
			<TouchableWithoutFeedback>
				{renderContactList}
			</TouchableWithoutFeedback>
		</ScrollView>
	);
}
