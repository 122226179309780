// i.e: "Wed, Jan 31, 2050, 02:46 PM"
export const WEEKDAY_DATE_TIME_FORMAT = {
	weekday: "short",
	month: "short",
	day: "2-digit",
	year: "numeric",
	hour: "2-digit",
	minute: "2-digit",
};

// i.e: "Wed, Jan 31, 2:46 PM"
export const WEEKDAY_SHORT_DATE_TIME_FORMAT = {
	weekday: "short",
	month: "short",
	day: "2-digit",
	hour: "2-digit",
	minute: "2-digit",
};

// i.e: "Jan 8, 2024"
export const SHORT_DATE_FORMAT = {
	month: "short",
	day: "2-digit",
	year: "numeric"
};

// i.e: "Jan 31, 2050, 02:46 PM"
export const DATE_TIME_FORMAT = {
	day: "2-digit",
	month: "short",
	year: "numeric",
	hour: "2-digit",
	minute: "2-digit"
};
