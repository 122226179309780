import React from "react";

import ManagedSection from "../Managed/ManagedSection";
import ContactSettingsSchema from "../../../schemas/ContactSettings";
import Form from "ae-forms";

import { useResources } from "@aetonix/hooks";

export default function ContactSettings(props) {
	const {
		localize,
		dispatch,
	} = useResources();

	const model = props.model;

	const contact = model.contactprofile || {};
	const toast = model.toast;
	const defaultData = model.owncontactsettings || {};

	const title = localize("contactsettings.title");

	return (
		<ManagedSection online={model.online} key={"contactsettings"} contact={contact} title={title} toast={toast}>
			<Form schema={ContactSettingsSchema} defaultData={defaultData} onSubmit={dispatch("owncontactsettings:trigger:update")} />
		</ManagedSection>
	);
}
