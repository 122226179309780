import PropTypes from "prop-types";
import React from "react";
import {
	View,
	StyleSheet,
} from "react-native";

import Style from "ae-style";
import StyledText from "ae-base/Base/StyledText";
import StyledIconButton from "ae-base/Base/StyledIconButton";

import { useResources } from "@aetonix/hooks";

var styles = StyleSheet.create({
	items: {
		flexDirection: "row",
		alignItems: "stretch",
		justifyContent: "flex-start",
		marginTop: Style.layout.marginSmall,
		paddingTop: Style.layout.paddingSmall,
		paddingBottom: Style.layout.paddingSmall / 2,
	},
	changed: {},
	editButton: {
		position: "absolute",
		top: 0,
		right: 0,
		zIndex: 1,
		padding: Style.layout.paddingSmall,
	},
});
export default function SectionTitle(props) {
	const {
		dispatch,
	} = useResources();

	var styleList = [styles.items];
	var name = props.name;
	var section = props.section;
	var permissions = props.permissions;
	var changes = props.changes;
	if(changes && changes[section])
		styleList.push(styles.changed);
	var canViewChange = changes && changes[section] && permissions >= 2;

	var action = dispatch("managedcareplanchangeviewer:trigger:view", {
		key: section,
	});

	var button = canViewChange ? (
		<StyledIconButton icon={"alert"} style={styles.editButton} onPress={action} color={"alert"} standard />
	) : null;

	return (
		<View style={styleList}>
			{button}
			<StyledText bold emphasized>{name}</StyledText>
		</View>
	);
}

SectionTitle.propTypes = {
	name: PropTypes.string,
};
