export default {
	fname: {
		order: 0,
		description: "profile.personal.fname",
		type: "string",
		inputType: "text",
		displayType: "text",
		minLength: 1,
	},
	lname: {
		order: 1,
		description: "profile.personal.lname",
		type: "string",
		inputType: "text",
		displayType: "text",
		minLength: 1,
	},
	patientNumber: {
		order: 2,
		description: "profile.personal.patient_number",
		type: "string",
		inputType: "label",
		displayType: "number",
	},
	healthCardNumber: {
		order: 3,
		description: "profile.personal.health_card_number",
		type: "string",
		inputType: "label",
		displayType: "number",
	},
	dob: {
		order: 4,
		description: "profile.personal.dob",
		maxDate: new Date(),
		type: "string",
		inputType: "date",
		displayType: "text",
		anyOf: [
			{
				format: "date-time"
			}, 
			{
				format: "date"
			}
		]
	},
	email: {
		order: 5,
		description: "profile.personal.email",
		type: "string",
		format:"email",
		inputType: "label",
		displayType: "text",
	},
	gender: {
		order: 6,
		description: "profile.personal.gender",
		value_description: "gender",
		type: "string",
		inputType: "dropdown",
		displayType: "text",
		enum: ["male", "female", "other"],
	},
	phone: {
		order: 7,
		description: "profile.personal.phone",
		type: "string",
		inputType: "text",
		displayType: "text",
		format: "number"
	},
	street: {
		order: 8,
		description: "profile.personal.address",
		inputType: "text",
		displayType: "text",
		type: "string"
	},
	postal: {
		order: 9,
		description: "profile.personal.postal",
		inputType: "text",
		displayType: "text",
		type: "string"
	},
	city: {
		order: 10,
		description: "profile.personal.city",
		inputType: "text",
		displayType: "text",
		type: "string"
	},
	province: {
		order: 11,
		description: "profile.personal.province",
		value_description: "regions",
		type: "string",
		inputType: "dropdown",
		displayType: "text",
		enum: [
			"CAC", "AB", "BC", "MB", "NB", "NL", "NS", "NT", "NU", "ON", "PE", "QC", "SK", "YT",
			"USC", "AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY",
			"UKC", "ENG", "NIR", "SCT", "WLS"
		]
	},
	country: {
		order: 12,
		description: "profile.personal.country",
		value_description: "country",
		type: "string",
		inputType: "dropdown",
		displayType: "text",
		enum: ["canada", "united states", "united kingdom"],
	},

	glucometerUnits: {
		order: 13,
		description: "profile.personal.glucometerUnits",
		value_description: "profile.personal.glucometerType",
		type: "string",
		inputType: "dropdown",
		displayType: "text",
		enum: ["mmol", "mg"]
	},
	weightUnits: {
		order: 14,
		description: "profile.personal.weightUnits",
		value_description: "profile.personal.weightUnits",
		type: "string",
		inputType: "dropdown",
		displayType: "text",
		enum: ["kg", "lbs"]
	},
	temperatureUnits: {
		order: 15,
		description: "profile.personal.temperatureUnits",
		value_description: "profile.personal.temperatureUnits",
		type: "string",
		inputType: "dropdown",
		displayType: "text",
		enum: ["celsius", "fahrenheit"]
	}
};
