
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React, {
	PureComponent
} from "react";
import {
	StyleSheet,
} from "react-native";

import Style from "@aetonix/styles";
import BlockView from "ae-base/Base/BlockView";

var styles = StyleSheet.create({
	divider: {
		backgroundColor: Style.colors.light
	},
	contrast: {
		backgroundColor: Style.colors.secondary
	},
	horizontal: {
		height: 2,
	},
	vertical: {
		width: 2,
	},
	valueDivider: {
		backgroundColor: Style.colors.itemLightGray,
	},
	tertiary: {
		backgroundColor: Style.colors.tertiary
	}
});

export default class Divider extends PureComponent {
	render(){
		var props = this.props;

		var styleList = [styles.divider];
		if(props.contrast)
			styleList.push(styles.contrast);
		if(props.tertiary)
			styleList.push(styles.tertiary);

		var vertical = props.vertical;
		styleList.push(
			vertical ? styles.vertical : styles.horizontal
		);

		if(props.valueDivider){
			styleList.push(styles.valueDivider);
		}

		var otherStyle = props.style;
		if (otherStyle) {
			styleList.push(otherStyle);
		}

		return (
			<BlockView style={styleList} />
		);
	}
}
