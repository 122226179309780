import React from "react";
import {View} from "react-native";

import ManagedContact from "../ManagedContact";
import sortNames from "../../../../utils/SortNames";

export default function ManagedListWeb(props){
	const {
		list,
		availability,
		missed,
		managedStatuses,
		contactSettings,
		activemanagegroup,
		screen
	} = props;

	const renderContact = (contact) => {
		var id = contact._id;
		var isAvailable = availability[id];
		var missedContact = missed[id];
		var managedStatus = managedStatuses[id] || {};
		var status = managedStatus.current_status;
		var currentSettings = contactSettings[id];
		return (
			<ManagedContact
				key={`${id}_${isAvailable}_${status}`}
				contact={contact}
				group={activemanagegroup}
				missed={missedContact}
				isAvailable={isAvailable}
				screen={screen}
				status={status}
				settings={currentSettings} />
		);
	};

	const contacts = sortNames(list).map(renderContact);
	return (
		<View>
			{contacts}
		</View>
	);
}
