import React from "react";
import TimelineItem from "./TimelineItem";

import { useResources } from "@aetonix/hooks";

export default function IndicatorBodyTemperature(props) {
	const {
		localize,
	} = useResources();

	var notification = props.notification;
	var locale = "banner.indicator.bodytemperature." + notification.content.status;
	var message = localize(locale, notification.from);
	var screen = props.screen;

	return (
		<TimelineItem content={message} iconName="alert" clearable notification={notification} screen={screen} />
	);
}
