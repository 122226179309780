import React, { useCallback, useMemo, useRef, useState } from 'react';
import { createStyled } from '@emotion/primitives-core'
import { FlatList, Image, StyleSheet, Text, TextInput, TouchableOpacity, View, } from 'react-native'
import MembersItem from './MembersItem';
import { faChevronLeft, faPlus, faCheck, faSpinner, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faSearch  } from '@fortawesome/free-solid-svg-icons'
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons'
import { sortNow } from './trayAddPerson.library';
import { useLocalize } from '../../../hooks/useLocalize/LocalizeContext';
import ResponsiveText from '../Text/ResponsiveText';
import Style from '@aetonix/styles';

const noData = [{ "name": "No Matches" }];

const TrayAddPerson = ({ onBackClick, onClick, mode, persons }) => {

    const searchInputRef = useRef();

    const [invitable, setInvitable] = useState(persons);
    const [searchText, setSearchText] = useState("");

    const isTyping = !!searchText;

    const onInvitePerson = useCallback((item, onClick) => {

        if (item.isInvited || !!(item?.isInvitingStatus ?? false)) {
            return;
        }

        const index = invitable.findIndex(data => data?._id === item?._id);

        if (onClick) {

            setInvitable(prevInvitees => {
                const next = [...prevInvitees];
                next[index] = { ...next[index], isInvitingStatus: 'loading' };
                return next;
            });

            onClick?.(item)?.then(() => {
                setInvitable(prevInvitees => {
                    const next = [...prevInvitees];
                    next[index] = {
                        ...next[index],
                        isInvited: !prevInvitees?.[index]?.isInvited ?? false,
                        isInvitingStatus: 'success'
                    };
                    return next;
                });
            })?.catch(() => {
                setInvitable(prevInvitees => {
                    const next = [...prevInvitees];
                    next[index] = { ...next[index], isInvitingStatus: 'error' };
                    return next;
                });

                setTimeout(() => {
                    setInvitable(prevInvitees => {
                        const next = [...prevInvitees];
                        next[index] = { ...next[index], isInvitingStatus: null };
                        return next;
                    });
                }, 5000);
            });
        }
    }, [invitable]);

    const onClearSearch = useCallback(() => {
        setSearchText("");
        searchInputRef.current.clear();
        searchInputRef.current.focus();
        setInvitable(persons);
    }, [searchInputRef.current, persons]);

    const sortedPersons = useMemo(() => {

        const nextPersons = invitable?.filter(item => {
            return item.name.toLowerCase().includes(searchText.toLowerCase());
        })?.sort(sortNow) ?? [];

        return nextPersons;

    }, [invitable, searchText]);

    const data = sortedPersons?.length ? sortedPersons : noData;

    const localize = useLocalize();
    
    return (
        <Container>
            <AddPersonContainer testID={"back-touchable"} onPress={onBackClick}>
                <IconContainer>
                    <FontAwesomeIcon color="#141823" style={{ fontWeight: 700 }} size={23} icon={faChevronLeft}/>
                </IconContainer>
                <AddPersonLabel size={Style.text.emphasized} max={Style.text.smallHeading}>{localize("meeting.memberstray.three")}</AddPersonLabel>
            </AddPersonContainer>
            {(mode !== "simplified") && <SearchContainer>
                <FontAwesomeIcon testID={"search-icon"} icon={faSearch} size={20} style={{resizeMode: "contain", marginRight: 8 }} onClick={() => searchInputRef.current.focus()} />
                <SearchInput testID={"search-input"} ref={searchInputRef} placeholder={"Search"} onChangeText={search => setSearchText(search)} ></SearchInput>
                <ClearTextTouchable onPress={() => isTyping && onClearSearch()}>
                    { isTyping && <FontAwesomeIcon testID={"clear-text-icon"} size={20} style={{resizeMode: "contain"}} icon={faTimesCircle} /> }
                </ClearTextTouchable>
            </SearchContainer>}
            <AddPersonList
                data={data}
                renderItem={({ item }) => <MembersItem 
                    title={item.name} 
                    userType={item.userType} 
                    actionIcon={item.name === 'No Matches' ? null : getActionIcon(item.isInvitingStatus)} 
                    onClick={() => onInvitePerson(item, onClick)} />}
                showsVerticalScrollIndicator={false}
                keyExtractor={(item, index) => item + index}
                testID={"add-person-list"}
            />
        </Container>
    );
}

const getActionIcon = (isInvitingStatus = null) => {
    const icons = {
        null: <FontAwesomeIcon style={{ color: 'black' }} size={23} icon={faPlus}/>,
        'success': <FontAwesomeIcon style={{ color: 'green' }} size={23} icon={faCheck}/>,
        'loading': <FontAwesomeIcon style={{ color: 'black' }} size={23} icon={faSpinner}/>,
        'error': <FontAwesomeIcon style={{ color: 'red' }} size={23} icon={faExclamationCircle}/>,
    }

    return icons?.[isInvitingStatus] ?? icons[null];
}

const styled = createStyled(StyleSheet);

const IconContainer = styled(View)({ width: 30 })

const Container = styled(View)({
    alignItems: "flex-start",
    padding: 20,
    paddingBottom: 0,
    paddingLeft: 30,
    paddingRight: 30,
    width: "100%",
});

const AddPersonContainer = styled(TouchableOpacity)({
    flexDirection: "row",
    alignItems: "center",
});

const AddPersonLabel = styled(ResponsiveText)({
    fontWeight: 'bold',
    fontFamily: 'Avenir',
    marginLeft: 8,
    color: "#141823",
});

const SearchContainer = styled(View)({
    backgroundColor: "white",
    borderWidth: 1,
    borderRadius: 10,
    height: 40,
    width: "100%",
    padding: 5,
    paddingHorizontal: 10,
    flexDirection: "row",
    alignItems: "center",

    marginTop: 20,
});

const SearchInput = styled(TextInput)({
    padding: 3,
    fontSize: 20,
    fontFamily: "Avenir",
    outlineWidth: 0,
    flex: 1,
});

const ClearTextTouchable = styled(TouchableOpacity)({
    height: 20,
    width: 20,
    marginLeft: 8,
});

const AddPersonList = styled(FlatList)({
    marginTop: 16,
    width: "100%",
    alignContent: "center",
});

export default TrayAddPerson;