/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";
import {View, StyleSheet} from "react-native";
import ChangePwdSchema from "../../../../schemas/ChangePwd";
import Form from "ae-forms";
import Divider from "../../../Layout/Divider";
import BlockView from "ae-base/Base/BlockView";
import StyledText from "ae-base/Base/StyledText";

import { useResources, useIdPrefix } from "@aetonix/hooks";

var styles = StyleSheet.create({
	container: {
		paddingVertical: 10,
	},
	heading: {
		paddingVertical: 5,
	},
});

export default function ChangePassword(props) {
	const {
		localize,
		dispatch,
	} = useResources();
	const idPrefix = useIdPrefix();
	var preferences = props.preferences;
	var dafaultData = {
		account_info: preferences,
	};
	return (
		<BlockView>
			<View style={styles.container}>
				<View style={styles.heading}>
					<StyledText bold emphasized>{localize("passwordrules.header")}</StyledText>
				</View>
				<StyledText>{"\u2022 " + localize("passwordrules.1")}</StyledText>
				<StyledText>{"\u2022 " + localize("passwordrules.2")}</StyledText>
				<StyledText>{"\u2022 " + localize("passwordrules.3")}</StyledText>
				<StyledText>{"\u2022 " + localize("passwordrules.4")}</StyledText>
				<StyledText>{"\u2022 " + localize("passwordrules.5")}</StyledText>
				<StyledText>{"\u2022 " + localize("passwordrules.6")}</StyledText>
				<StyledText>{"\u2022 " + localize("passwordrules.7")}</StyledText>
				<StyledText>{"\u2022 " + localize("passwordrules.8")}</StyledText>
				<StyledText>{"\u2022 " + localize("passwordrules.9")}</StyledText>
			</View>
			<Form
				id_prefix={idPrefix + "pwdChangeForm"}
				schema={ChangePwdSchema}
				onSubmit={dispatch("preferences:trigger:preferences:update:password")}
				defaultData={dafaultData}
			/>
			<Divider />
		</BlockView>
	);
}
