import React from "react";
import {
	View,
} from "react-native";
import ResponsiveStylesheet from "react-native-responsive-stylesheet";

import StyledText from "ae-base/Base/StyledText";
import Style from "ae-style";

var styles = ResponsiveStylesheet.createSized("min-direction", {
	0: {
		ChangeValueFlex: {
			paddingBottom: Style.layout.paddingSmall / 2,
			paddingHorizontal: Style.layout.paddingSmall / 3,
		}
	},
	512: {
		ChangeValueFlex: {
			paddingBottom: Style.layout.paddingSmall * 2,
			paddingHorizontal: Style.layout.paddingSmall * 3,
		}
	}
});

export default function ChangeValue(props){
	return (
		<View style={styles.ChangeValueFlex}>
			<StyledText black emphasized>{props.children}</StyledText>
		</View>
	);
}
