import * as Sentry from '@sentry/react';

export function getSentryEnvironment(type) {

    if (type === 'dev') {
        return 'development';
    } else if (type === 'prod') {
        return 'production';
    }

    return 'local';
}

export function scrubBreadcrumbs(breadcrumb) {
    if (!!breadcrumb?.data?.url) {
        const removeTokenParamRegex = /\?token(\=[^&]*)?(?=&|$)|\&token(\=[^&]*)?(?=&|$)|^token(\=[^&|?]*)?(&|$)/gim;
        const urlWithoutToken = breadcrumb.data.url.replace(removeTokenParamRegex, '');

        breadcrumb.data.url = urlWithoutToken;
        breadcrumb.scrubedDataAction = 'token removed from request'
    }

    if (breadcrumb.category === "console") {
        return null;
    }

    // Don't report warnings
    if (breadcrumb.level === Sentry.Severity.Warning) {
        return null;
    }

    return breadcrumb;
}

export function scrubEvents(event) {
    if (!!event?.data?.url) {
        const removeTokenParamRegex = /\?token(\=[^&]*)?(?=&|$)|\&token(\=[^&]*)?(?=&|$)|^token(\=[^&|?]*)?(&|$)/gim;
        const urlWithoutToken = event.data.url.replace(removeTokenParamRegex, '');

        event.data.url = urlWithoutToken;
        event.scrubedDataAction = 'token removed from request'
    }

    // Don't report warnings
    if (event.level == Sentry.Severity.Warning) {
        return null;
    }

    return event;
}