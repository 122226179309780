
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";
import {
	View,
	TouchableOpacity
} from "react-native";
import ResponsiveStylesheet from "react-native-responsive-stylesheet";

import StyledIcon from "ae-base/Base/StyledIcon";

import Style from "@aetonix/styles";
import StyledText from "ae-base/Base/StyledText";
import { useLocalize } from "../../hooks/useLocalize/LocalizeContext";
import { parseColor } from "../../utils/ParseStatus";

var styles = ResponsiveStylesheet.createSized("min-direction", {
	0: {
		base: {
			borderColor: Style.colors.white,
			padding: Style.layout.paddingSmall,
			paddingLeft: Style.layout.paddingMedium,
			alignItems: "center",
		},
		icon: {
			marginRight: Style.layout.marginLarge
		},
		content: {
			flexDirection: "row",
			justifyContent: "flex-start",
			alignItems: "center"
		},
		addborder: {
			borderColor: Style.colors.tertiary,
			borderWidth: 1
		},
		label: {
			padding: Style.layout.paddingSmall,
			paddingRight : Style.layout.paddingMedium,
		}
	},
	512: {
		icon: {
			marginRight: Style.layout.marginHuge
		},
	},
});

/**  This component takes the following props:
				color = the background color (from Style)
				onPress = a function to fire on click
				children = children components
				icon = an icon
				iconColor = a colour for the icon (from Style)
	**/

export default function Notification(props) {

	var icon = "alert";
	var color = Style.colors.light;
	var iconColor = Style.colors.white;

	const localize = useLocalize();

	if (props.color)
		color = props.color;  // background
	if (props.icon)
		icon = props.icon;  // alternate icon
	if (props.iconColor) {					// alternate color for the icon
		iconColor = Style.colors[props.iconColor] || props.iconColor;
	}

	var onPress = props.onPress; // click function
	var children = props.children;  // children

	var background = {
		backgroundColor: color
	};

	if (color === (Style.colors.yellow || Style.colors.light))
		iconColor = Style.colors.black;
	if (color === Style.colors.white)
		iconColor = Style.colors.primary;
	if (color === Style.colors.darkred)
		iconColor = Style.colors.white
	

	var bannerIcon = <StyledIcon style={styles.icon} name={icon} color={iconColor} hugeHeading />;

	var basicStyle = styles.base.concat(background);

	if(props.border){
		basicStyle.push(styles.addborder);
	}

	var content = (
		<View style={styles.content}>
			<StyledText style={styles.label}>{parseColor(color, localize)}</StyledText>
			{bannerIcon}
			{children}
		</View>
	);

	if(onPress)
		return (
			<TouchableOpacity accessibilityComponentType="button" style={basicStyle} onPress={onPress}>
				{content}
			</TouchableOpacity>
		);

	return (
		<View style={basicStyle}>
			{content}
		</View>
	);
}
