export default {
	title: "ReminderMaker",
	description: "schemas_reminder_maker_title",
	type: "object",
	displayDependencies: {
		repeat: {
			properties: {
				monthSwitch: {
					enum: [false]
				}
			}
		},
		days: {
			properties: {
				monthSwitch: {
					enum: [false]
				}
			}
		},
		months: "monthSwitch"
	},
	properties: {
		content: {
			order: 0,
			description: "remindermaker.content",
			type: "string",
			minLength: 1,
			inputType: "text",
			displayType: "text"
		},
		time: {
			order: 1,
			description: "remindermaker.time",
			type: "object",
			format: "time",
			inputType: "time",
			properties: {
				hours: {
					type: "string",
					value_description: "schedule.hours",
					inputType: "dropdown",
					enum: [
						"0",
						"1",
						"2",
						"3",
						"4",
						"5",
						"6",
						"7",
						"8",
						"9",
						"10",
						"11",
						"12",
						"13",
						"14",
						"15",
						"16",
						"17",
						"18",
						"19",
						"20",
						"21",
						"22",
						"23"
					]
				},
				minutes: {
					type: "string",
					value_description: "schedule.minute",
					inputType: "dropdown",
					enum: ["0", "5", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55"]
				}
			}
		},
		monthSwitch: {
			order: 3,
			description: "remindermaker.month",
			type: "boolean",
			format: "switch",
			inputType: "switch"
		},
		days: {
			order: 4,
			description: "remindermaker.days",
			value_description: "remindermaker.day_values",
			type: "object",
			format: "days",
			inputType: "days",
			properties: {
				mon: {
					type: "boolean"
				},
				tue: {
					type: "boolean"
				},
				wed: {
					type: "boolean"
				},
				thu: {
					type: "boolean"
				},
				fri: {
					type: "boolean"
				},
				sat: {
					type: "boolean"
				},
				sun: {
					type: "boolean"
				},
				all: {
					type: "boolean"
				}
			}
		},
		months: {
			order: 4,
			type: "string",
			description: "remindermaker.months",
			inputType: "date"
		},
		repeat: {
			order: 2,
			description: "remindermaker.repeat",
			value_description: "remindermaker.repeat_values",
			type: "number",
			inputType: "dropdown",
			enum: [0, 1, 2, 4, 8, 12, 24]
		},
		responseType: {
			order: 5,
			description: "remindermaker.response",
			value_description: "remindermaker.response_values",
			type: "string",
			inputType: "dropdown",
			displayType: "selectableinput",
			enum: ["confirm", "emotion", "range", "0range", "yesorno"]
		},
	},
	required: ["content", "time", "repeat", "responseType"]
};
