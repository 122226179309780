import React from "react";
import {
	View,
	StyleSheet,
} from "react-native";
import Style from "ae-style";

var styles = StyleSheet.create({
	itemsMargin: {
		marginBottom: Style.layout.marginSmall,
	}
});

export default function Items(props){
	return (
		<View style={styles.itemsMargin}>
			{props.children}
		</View>
	);
}
