import Style from "@aetonix/styles";

export const parseStatus = (status, localize) => {
	switch (status) {
		case 0 :
			return localize("workflowstatus.normal");
		case 1 :
			return localize("workflowstatus.lowRisk");
		case 2 :
			return localize("workflowstatus.highRisk");
		default:
			return localize("workflowstatus.normal");
	}
};

export const parseColor = (color, localize) => {
	switch (color) {
		case Style.colors.green:
			return localize("workflowstatus.normal");
		case Style.colors.yellow:
			return localize("workflowstatus.lowRisk");
		case Style.colors.red:
			return localize("workflowstatus.highRisk");
		default:
			return "";
	}
};
