import React from "react";
import TimelineItem from "./TimelineItem";

import { useResources } from "@aetonix/hooks";

export default function UrgencyCall(props) {
	const {
		localize,
	} = useResources();

	var notification = props.notification;
	var urgencycallfrom = localize("banner.urgency_from", notification.from);
	var screen = props.screen;
	return (
		<TimelineItem content={urgencycallfrom} iconName="info"  notification={notification} screen={screen} />
	);
}
