import PropTypes from "prop-types";

/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";
import {
	View,
	StyleSheet,
} from "react-native";

import ManagedManagerPermissionsSection from "./ManagedManagerPermissionsSection";
import Heading from "ae-base/Base/Heading";
import StyledButton from "ae-base/Base/StyledButton";
import Divider from "../../../Layout/Divider";
import Form from "ae-forms";

import AetonixPermissionChangeSchema from "../../../../schemas/AetonixPermissionChange";
import CareplanPermissionChangeSchema from "../../../../schemas/CareplanPermissionChange";

import { useResources } from "@aetonix/hooks";

var styles = StyleSheet.create({
	container: {
		marginTop: 8,
		marginBottom: 4,
	},
});

ManagedManagerPermissions.propTypes = {
	model: PropTypes.object,
};

export default function ManagedManagerPermissions(props) {
	const {
		localize,
		dispatch,
	} = useResources();

	var model = props.model;
	var managers = model.manage.managers;
	var account = model.manage.account;
	var toast = model.toast;
	var permissions = model.manage.managerpermissions || [];
	var displayAetonix = model.collapsibles.managedpermissions_aetonix_display;
	var displayCareplan = model.collapsibles.managedpermissions_careplan_display;
	var displayGroupforms = model.collapsibles.managedpermissions_groupforms_display;
	var groupForms = model.manage.managedgroupformlist;
	var aetonixTitle = localize("managedmangerspermissions.aetonix");
	var careplanTitle = localize("managedmangerspermissions.careplan");
	var groupformsTitle = localize("managedmangerspermissions.groupforms");
	var maximizeLabel = localize("managedmangerspermissions.maximize");
	var minimizeLabel = localize("managedmangerspermissions.minimize");

	var iconMinimize = "directup";
	var iconMaximize = "dropdown";
	var minmaxAetonix = displayAetonix ? minimizeLabel : maximizeLabel;
	var iconAetonix = displayAetonix ? iconMinimize : iconMaximize;
	var minmaxCareplan = displayCareplan ? minimizeLabel : maximizeLabel;
	var iconCareplan = displayCareplan ? iconMinimize : iconMaximize;
	var minmaxGroupforms = displayGroupforms ? minimizeLabel : maximizeLabel;
	var iconGroupForms = displayGroupforms ? iconMinimize : iconMaximize;

	var container = styles.container;

	var refreshKey = JSON.stringify(permissions);

	//  Provide separate views for Careplan and Aetonix permissions
	var toggleCareplanDisplay = dispatch("collapsibles:trigger:toggle", {
		section: "managedpermissions_careplan_display",
	});
	var toggleAetonixDisplay = dispatch("collapsibles:trigger:toggle", {
		section: "managedpermissions_aetonix_display",
	});
	var toggleGroupFormsDisplay = dispatch("collapsibles:trigger:toggle", {
		section: "managedpermissions_groupforms_display",
	});

	var carepermissions = displayCareplan ?
		(<Form schema={CareplanPermissionChangeSchema}
			defaultData={permissions}
			key={refreshKey}
			onSubmit={dispatch("managedmanagerpermissions:trigger:change")} />)
		: null;

	var aetonixpermissions = displayAetonix ?
		(<Form schema={AetonixPermissionChangeSchema}
			defaultData={permissions}
			key={refreshKey}
			onSubmit={dispatch("managedmanagerpermissions:trigger:change")} />)
		: null;

	var groupFormsProperties = Object.keys(groupForms).reduce(function(groupFormObj, groupform, index){

		let defaultEnums =  [0, 1, 2];
		const isMulti = groupForms[groupform].formType === "multi";
		if(isMulti)
			defaultEnums =  [0, 1, 2, -2];

		groupFormObj[groupform] = {
			order: index,
			type: "number",
			localization: groupForms[groupform].localization,
			value_description: "groupforms",
			enum: defaultEnums,
		};
		if(!permissions[groupform])
			permissions[groupform] = 0;
		return groupFormObj;
	}, {});

	var groupformSchema = {
		title: "GroupFormsPermissions",
		description: "",
		type: "object",
		properties: groupFormsProperties,
	};

	var groupformpermissions = displayGroupforms ? (
		<Form schema={groupformSchema}
			defaultData={permissions}
			key={refreshKey}
			onSubmit={dispatch("managedmanagerpermissions:trigger:change")} />
	) : null;

	// Create a manager name for meaningful title
	var managerId = permissions._id;
	var managerData = managers.filter(function(manager) {
		return manager._id === managerId;
	});
	if (managerData[0]) {
		var managerName = managerData[0].lname + ", " + managerData[0].fname;
		var permissionsTitle = localize("managedmangerspermissions.title") + " of " + managerName + " for:";
	}

	var manageAllSections = model.manage.allSections;
	var allsectionKeys = Object.keys(manageAllSections);
	var hasCareplan = allsectionKeys.some(function(key) {
		return key.indexOf("careplan_") !== -1;
	});
	var renderCareplan = hasCareplan ? (
		<View>
			<Heading title={careplanTitle}>
				<StyledButton
					title={minmaxCareplan}
					clear
					icon={iconCareplan}
					onPress={toggleCareplanDisplay} />
			</Heading>
			<View>
				{carepermissions}
			</View>
		</View>
	) : null;

	return (
		<ManagedManagerPermissionsSection title={permissionsTitle}
			contact={account}
			toast={toast}>
			<Heading title={aetonixTitle}>
				<StyledButton
					title={minmaxAetonix}
					clear
					icon={iconAetonix}
					onPress={toggleAetonixDisplay} />
			</Heading>
			<View>
				{aetonixpermissions}
			</View>
			<Divider style={container} />
			{renderCareplan}
			<Heading title={groupformsTitle}>
				<StyledButton
					title={minmaxGroupforms}
					clear
					icon={iconGroupForms}
					onPress={toggleGroupFormsDisplay} />
			</Heading>
			<View>
				{groupformpermissions}
			</View>
		</ManagedManagerPermissionsSection>
	);
}
