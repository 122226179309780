/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React, {
	Fragment,
	PureComponent,
} from "react";
import {
	View,
	StyleSheet,
	Platform,
} from "react-native";

import CardPage from "../../../Layout/CardPage";
import ContactListItem from "./ContactListItem";
import Form from "ae-forms";
import SearchManagerSchema from "../../../../schemas/ManagerSearch";
import StyledText from "ae-base/Base/StyledText";
import Divider from "../../../Layout/Divider";
import AetonixPermissionChangeSchema from "../../../../schemas/AetonixPermissionChange";
import CareplanPermissionChangeSchema from "../../../../schemas/CareplanPermissionChange";

import Style from "ae-style";
import xtend from "xtend";
import noop from "noop3";
import { OrderedMap } from "immutable";
import StyledButton from "ae-base/Base/StyledButton";
import Picker from "ae-forms/src/Inputs/Picker";
import { useResources } from "@aetonix/hooks";

import _ from "lodash";
import ModalOverlay from "./ModalOverlay";
var styles = StyleSheet.create({
	searchText: {
		marginVertical: Style.layout.marginLarge,
	},
	searchBox: {
		marginBottom: Style.layout.marginLarge,
	},
	inviteListContainer: {
		marginVertical: Style.layout.marginLarge,
	},
	actionArea: {
		alignItems: "flex-end",
	},
	section: {
		marginTop: Style.layout.marginLarge,
	},
	languageSelectButton: {
		margin: Style.layout.marginSmall,
	},
	languageSelectContainer: {
		flexDirection: Platform.OS === "web" ? "row" : "column",
		flexWrap: "wrap",
		justifyContent: "center",
		alignItems: "center",
		marginTop: Style.layout.marginSmall,
	},
});

var LANGUAGES = [
	"en",
	"fr",
	"cn_s",
	"cn_t",
	"es",
	"pt",
	"ta",
];

const CUSTOM_PERMISSION_KEY = "custom";
const SECTION_HEADER_SIZE = "emphasized";

export default (props) => {
	const { localize, dispatch, config } = useResources();
	return <ManagedManagerRequests {...props} localize={localize} dispatch={dispatch} config={config} />;
};

class ManagedManagerRequests extends PureComponent {
	constructor(props) {
		super(props);

		var model = props.model;

		var presetPermissions = model.manage.presetPermissions || [];
		var defaultSelected = presetPermissions[0] && presetPermissions._id || "";
		var initialCustomPreset = _.cloneDeep(model.manage.defaultPermissions);
		this.state = {
			inviteManagers: OrderedMap(),
			emailRequests: OrderedMap(),
			presetSelected: defaultSelected,
			presetSelectError: false,
			selectManagerError: false,
			presetOptions: [],
			customPreset: initialCustomPreset,
			languageSelect: null,
		};
		this.selectNewManager = this.selectNewManager.bind(this);
		this.clearSelect = this.clearSelect.bind(this);
		this.setPresetSelected = this.setPresetSelected.bind(this);
		this.submit = this.submit.bind(this);
		this.getEmailArray = this.getEmailArray.bind(this);
		this.submitCustom = this.submitCustom.bind(this);
	}

	componentDidUpdate(prevProps) {
		const currPresets = this.props.model.manage.presetPermissions || [];
		const prevPresets = prevProps.model.manage.presetPermissions || [];

		if (this.state.presetSelected !== CUSTOM_PERMISSION_KEY
			&& currPresets.length !== prevPresets.length
			&& !currPresets.some((preset) => preset._id === this.state.presetSelected))
			this.setState({ presetSelected: "" });

	}
	clearSelect() {
		this.setState({
			inviteManagers: this.state.inviteManagers.clear(),
		});
	}

	selectNewManager(manager) {
		this.setState({
			inviteManagers: this.state.inviteManagers.set(manager._id, manager),
			selectManagerError: false,
		});
	}

	deselectNewManager(managerId) {
		this.setState({
			inviteManagers: this.state.inviteManagers.remove(managerId),
		});
	}

	getEmailArray() {
		return this.state.emailRequests.toArray().map((entry) => entry[1]);
	}
	clearEmails() {
		this.setState({
			inviteManagers: this.state.emailRequests.clear(),
		});
	}

	inviteEmail(email, language = "en") {
		this.setState({
			emailRequests: this.state.emailRequests.set(email, { email, language }),
			languageSelect: email,
			selectManagerError: false,
		});
	}

	onLanguageSelect(email, language) {
		this.setState({
			emailRequests: this.state.emailRequests.set(email, { email, language }),
			languageSelect: null,
		});
	}
	removeEmail(email) {
		this.setState({
			emailRequests: this.state.emailRequests.remove(email),
		});
	}

	setPresetSelected(id) {
		this.setState({
			presetSelected: id,
			presetSelectError: false,
		});
	}

	submit() {
		const manage = this.props.model.manage;
		const presets = manage.presetPermissions || [];
		const selectedPreset = presets.find((preset) => preset._id === this.state.presetSelected);
		if (selectedPreset) {
			const permissions = selectedPreset.permissions;
			this.submitCustom(permissions);
		} else
			this.setState({
				presetSelectError: true,
			});

	}

	submitCustom(data) {
		if(this.state.emailRequests.size || this.state.inviteManagers.size){
			const permissions = data;
			//Notes compatibility
			const permissionsCompat = {...permissions, files: permissions.notes, forms: permissions.notes };
			this.props.dispatch("managedmanagerrequests:trigger:requestandinvite:bulk")({
				managerList: this.state.inviteManagers.toArray().map((item) => item[0]),
				emailList: this.getEmailArray(),
				permissions: permissionsCompat,
			});
		}
		else
			this.setState({
				selectManagerError: true,
			});


	}
	render() {
		var props = this.props;
		var dispatch = props.dispatch;
		var ownID = props.config.id;
		var model = props.model;

		var screen = model.size;

		var localize = props.localize;
		var searchcontent = localize("managedmanagerrequest.searchcontent");
		var noresult = localize("managedmanagerrequest.noresult");
		var noemailresult = localize("managedmanagerrequest.noemailresult");
		var sendinvite = localize("managedmanagerrequest.sendinvite");
		var remove = localize("common.remove");
		var gobackcontact = localize("managedmanagerrequest.gobackcontact");
		var addcontact = localize("managedmanagerrequest.addacontact");
		var add = localize("common.add");

		var doSearch = (data) => { data.query ? dispatch("search:trigger:search")(data) : dispatch("search:trigger:clear")(); };

		var search = model.search;
		var searchResults = search.search || [];

		var manage = model.manage;
		var presetPermissions = manage.presetPermissions || [];

		var groupForms = model.manage.managedgroupformlist;

		var manageAllSections = model.manage.allSections;
		var allsectionKeys = Object.keys(manageAllSections);
		var hasCareplan = allsectionKeys.some(function (key) {
			return key.indexOf("careplan_") !== -1;
		});
		var groupFormsProperties = Object.keys(groupForms).reduce(function (groupFormObj, groupform, index) {
			let formEnum = [0, 1, 2];
			//custom read write OWN permission
			if(groupForms[groupform].formType === "multi")
				formEnum =  [0, 1, 2, -2];

			groupFormObj[groupform] = {
				order: index + 1,
				type: "number",
				localization: groupForms[groupform].localization,
				value_description: "groupforms",
				enum: formEnum,
				default: 0,
			};

			return groupFormObj;
		}, {});

		groupFormsProperties.title = {
			type: "string",
			order: 0,
			displayType: "title",
			inputType: "output",
			description: "groupforms.permissions",
		};

		var finalSchema = {
			groupforms: {
				order: 100,
				type: "object",
				properties: groupFormsProperties,
			},
			title: {
				order: 0,
				type: "string",
				displayType: "title",
				inputType: "output",
				description: "managedmangerspermissions.title",
			},
		};

		var permissionsSchema = xtend(hasCareplan ? mergeSchema(AetonixPermissionChangeSchema, CareplanPermissionChangeSchema) : AetonixPermissionChangeSchema.properties, finalSchema);

		var permissionsObject = {
			type: "object",
			properties: permissionsSchema,
		};

		var hasNewManager = this.state.inviteManagers.size || this.state.emailRequests.size;
		var existingrequests = manage.managedmanagerrequests.outgoing
			.map(function (request) {
				return request.to && request.to._id;
			}); // list of existing requests
		var existingmanagers = manage.managers.map(function (manager) {
			return manager._id;
		}); // list of existing managers
		var renderedResults = searchResults.filter((result) => {
			return result._id !== ownID && !this.state.inviteManagers.has(result._id);
		}).map(function (result) {
			var id = result._id;
			var action = function () {
				this.selectNewManager(result);
			}.bind(this);
			var icon = "plus";
			var message = localize("request.selectperson");
			if (existingmanagers && existingmanagers.indexOf(id) !== -1) {
				action = noop;
				message = localize("request.alreadycontact");
				icon = "user";
			}
			if (existingrequests && existingrequests.indexOf(id) !== -1) {
				action = noop;
				message = localize("request.inprogress");
				icon = "sync";
			}
			return (
				<ContactListItem
					who={result}
					buttonOnPress={action}
					buttonIcon={icon}
					buttonMessage={message}
					screen={screen} />
			);
		}.bind(this));

		var searchForm = (
			<View>
				<StyledText style={styles.searchText}>{searchcontent}</StyledText>
				<View style={styles.searchBox}>
					{this.state.selectManagerError && <StyledText red>{`${localize("preset_permission.no_manager_selected")}`}</StyledText>}
					<Form schema={SearchManagerSchema} defaultData={search} autoSubmit onSubmit={doSearch} />
				</View>
			</View>
		);

		var renderSelected = null;
		if (hasNewManager) {
			let inviteContactList = this.state.inviteManagers.toArray().map((entry) => entry[1]).map((manager) => {
				return (
					<ContactListItem
						who={manager}
						screen={screen}
					>
						<StyledButton alert title={remove} icon={"remove"} onPress={() => this.deselectNewManager(manager._id)} />
					</ContactListItem>
				);

			});
			let emailRequestList = this.getEmailArray().map((entry) => {
				const { email, language } = entry;

				return (
					<ContactListItem
						who={{ fname: email }}
						screen={screen}
					>
						<StyledButton onPress={() => {
							this.setState({ languageSelect: email });
						}} title={localize(`languages.${language}`)} style={{ marginRight: 10 }} />
						<StyledButton alert title={remove} icon={"remove"} onPress={() => this.removeEmail(email)} />
					</ContactListItem>
				);
			});
			renderSelected = (
				<View style={styles.inviteListContainer}>

					{!!this.state.inviteManagers.size && <Fragment>
						<StyledText size={SECTION_HEADER_SIZE}>{localize("preset_permission.people_invite")}</StyledText>
						<Divider />
						{
							inviteContactList
						}</Fragment>}

					{!!this.state.emailRequests.size && <View style={{ marginTop: 20 }}>
						<StyledText size={SECTION_HEADER_SIZE}>{localize("preset_permission.email_invite")}</StyledText>
						<Divider />
						{
							emailRequestList
						}</View>}
				</View>
			);
		}
		var noResults = search.query && !searchResults.length;

		var notFound = null;

		if (noResults) {
			const existingEmailInvites = manage.managedmanagerrequests.invites || [];
			let alreadyInList = this.state.emailRequests.has(search.query) || existingEmailInvites.some((invite) => invite.email === search.query);
			let emailAction = null;
			if (search.isEmail) {
				let buttonTitle = localize("preset_permission.invite_email");
				if (alreadyInList)
					buttonTitle = localize("preset_permission.invite_email_exists");

				emailAction = (<StyledButton style={{marginTop: 8}} textStyle={{ textDecorationLine: alreadyInList ?  "none" : "underline" }} disabled={alreadyInList} title={buttonTitle} onPress={() => {
					this.inviteEmail(search.query);
				}} />);
			}
			notFound = <View style={{ alignItems: "center" }}><StyledText centered>{noresult}</StyledText>{emailAction}</View>;
		}

		const presetSelectSchema = {
			displayType: "row",
			enum: presetPermissions.map((preset) => preset._id).concat([CUSTOM_PERMISSION_KEY]),
			valueLocalization: presetPermissions.reduce((acc, preset) => {
				acc[preset._id] = preset.name;
				return acc;
			}, {
				[CUSTOM_PERMISSION_KEY]: localize("common.custom"),
			}),
		};

		const renderPresetSelect =
			(<View style={styles.section}>
				<StyledText size={SECTION_HEADER_SIZE}>{`${localize("preset_permission.section_title")}:`}</StyledText>
				{this.state.presetSelectError && <StyledText red>{`${localize("preset_permission.no_permission_selected")}`}</StyledText>}
				<Picker defaultValue={this.state.presetSelected} schema={presetSelectSchema} onChange={this.setPresetSelected} />
			</View>);

		let renderCustomPermission = null;
		if (this.state.presetSelected === CUSTOM_PERMISSION_KEY)
			//need to find a way to memoize value and stop it from updated when someone updates the defaultInvite
			renderCustomPermission = (<Form
				schema={permissionsObject}
				defaultData={this.state.customPreset}
				onSubmit={this.submitCustom}
				submitText={add}

			/>);


		let renderActions = null;

		if (this.state.presetSelected !== CUSTOM_PERMISSION_KEY)
			renderActions = <Form schema={{}} onSubmit={this.submit} submitText={add} />;


		return (
			<CardPage backTitle={gobackcontact} title={addcontact} icon="contacts">
				{searchForm}
				{notFound}
				{renderedResults}
				{renderSelected}
				{renderPresetSelect}
				{renderCustomPermission}
				{renderActions}
				<SelectLanguageModal
					value={this.state.emailRequests.get(this.state.languageSelect) && this.state.emailRequests.get(this.state.languageSelect).language}
					title={`${localize("preset_permission.select_language_for")} ${this.state.languageSelect}`}
					open={!!this.state.languageSelect}
					onSelect={(lang) => { this.onLanguageSelect(this.state.languageSelect, lang); }} />
			</CardPage>
		);
	}
}

const SelectLanguageModal = ({ onSelect, value, open, title }) => {
	const {localize} = useResources();
	return (<ModalOverlay visible={open}>
		<View style={{ flexGrow: 1, justifyContent: "center", alignItems: "center", paddingHorizontal: Style.layout.paddingSmall  }}>
			<StyledText centered contrast size="emphasized">{title}</StyledText>
			<View style={styles.languageSelectContainer}  >
				{
					LANGUAGES.map((lang) => {
						return (
							<StyledButton style={styles.languageSelectButton} contrast={lang === value} title={localize(`languages.${lang}`)} onPress={() => {
								onSelect && onSelect(lang);
							}} />
						);
					})
				}</View></View>
	</ModalOverlay>);
};

function mergeSchema(schema1, schema2) {
	var newSchema = xtend(schema1.properties, schema2.properties);
	return newSchema;
}
