import React from "react";

import {
	ScrollView,
	Dimensions,
	Platform,
} from "react-native";

import Modal from "../Layout/Modal";
import ImageViewer from "./ImageViewer";
import VideoViewer from "./VideoViewer";
import PDFViewer from "ae-base/Base/PDFViewer";
import {AudioPlayerLarge as AudioPlayer} from "ae-base/Base/AudioPlayer";

import { useResources } from "@aetonix/hooks";

export default function FileViewer(props) {
	const { timeoutReset } = useResources();
	var file = props.file;
	var open = props.open;
	var noDownload = props.noDownload;
	var width = Dimensions.get("window").width - 10;

	var format = file ? file.format : "";

	var content;
	if(format.indexOf("image") !== -1)
		content = (
			<ImageViewer noDownload={noDownload} file={file} />
		);
	else if (format.indexOf("video") !== -1)
		content = (
			<VideoViewer noDownload={noDownload} file={file} />
		);
	else if (format.indexOf("pdf") !== -1 && Platform.OS === "web")
		content = (
			<ScrollView onScroll={timeoutReset}>
				<PDFViewer url={file.url} width={width} noDownload={noDownload} back />
			</ScrollView>
		);
	else if (format.indexOf("pdf") !== -1 && Platform.OS !== "web")
		content = (
			<PDFViewer url={file.url} width={width} noDownload={noDownload} />
		);
	else if (format.indexOf("audio") !== -1)
		content = (
			<AudioPlayer url={file.url} width={width} noDownload={noDownload} disableVolume back />
		);

	return (
		<Modal black open={open}>
			{content}
		</Modal>
	);
}
