export default {
    title: "Privacy Policy Checkbox Schema",
    description: "A schema for the privacy policy checkbox",
    type: "object",
    properties: {
        iAcceptUserPolicy: {
            description: "privacy_policy.iacceptuserpolicy",
            inputType: "checkbox",
            type: "boolean",
            required: true,
        }
    }
};