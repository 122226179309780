import Heading from "ae-base/Base/Heading";
import Form from "ae-forms";
import React, { Component } from "react";
import Card from "../Layout/Card";
import Modal from "../Layout/Modal";
import { StyleSheet, Platform, View } from "react-native";
import { useResources } from "@aetonix/hooks";

var styles = StyleSheet.create({
	card: {
		...Platform.select({
			ios: {
				flexShrink: 1,
				flexWrap: "wrap",
			},
			android: {
				flexShrink: 1,
			},
			default: {
				flexShrink: 1,
				flexWrap: "wrap",
			},
		}),
	},
	container: {
		...Platform.select({
			ios: {},
			android: {},
			default: {
				flexShrink: 1,
				flexWrap: "wrap",
			},
		}),
	},
});

export default (props) => {
	const { localize, dispatch, localizeFrom } = useResources();
	return <WorkflowActionModal {...props} localize={localize} dispatch={dispatch} localizeFrom={localizeFrom} />;
};

class WorkflowActionModal extends Component {
	constructor(props) {
		super(props);
		this.dataId = this.props.action?._id;
	}

	componentDidUpdate(prevProps) {
		const prevId = prevProps.action?._id;
		const newId = this.props.action?._id;

		if (prevId && newId && prevId !== newId)
			this.dataId = newId;

	}

	render() {
		var props = this.props;

		var localize = props.localize;
		var localizeFrom = props.localizeFrom;
		var dispatch = props.dispatch;

		var action = props.action;
		var ongoingWorkflow = action.ongoingWorkflow;
		var workflowDefinition = ongoingWorkflow.workflow;

		var actionLabel = action.action;

		var formSchema = workflowDefinition.actions[actionLabel];
		var defaults = action.defaults;
		var label = ongoingWorkflow.label || "";

		var goBack = null;
		var goBackText = null;
		var cancelable = props.cancelable;
		var externalForms = props.externalForms || {};

		var useExternalForm = externalForms[ongoingWorkflow._id];

		const saveEncryptStorage = (data) => {
			if (this.dataId) {
				action.defaults = data;
				dispatch("persiststorage:save")({ dataId: this.dataId, data });
			}
		};

		function debounce(func, timeout = 500) {
			let timer;
			return (...args) => {
				clearTimeout(timer);
				timer = setTimeout(() => {
					func.apply(this, args);
				}, timeout);
			};
		}

		if (cancelable) {
			goBack = () => {
				dispatch("persiststorage:delete")({ dataId: this.dataId });
				dispatch("activeworkflowaction:trigger:hide")();
			};
			goBackText = localize("common.cancel");
		}

		var title = "";

		if (label) title = label + " - ";

		if (formSchema.localization)
			title += localizeFrom(formSchema.localization, ".") || formSchema.localization;


		var onSubmit = (data) => {
			dispatch("persiststorage:delete")({ dataId: this.dataId });
			dispatch("activeworkflowaction:trigger:submit")(data);
		};

		const cardRef = "_card";
		var scrollToTopOfCard = () => {
			this.refs[cardRef].scrollToTop();
		};

		return (
			<Modal online={props.online} open>
				<Card atouchaway={props.atouchaway} scroll style={styles.card} ref={cardRef}>
					<View style={styles.container}>
						<Heading title={title} />
						<Form
							key={action._id}
							saveEncryptStorage={debounce((data) => saveEncryptStorage(data))}
							dataId={this.dataId}
							id={action._id}
							schema={formSchema}
							onCancel={goBack}
							cancelText={goBackText}
							defaultData={defaults}
							onSubmit={onSubmit}
							onChangePage={scrollToTopOfCard}
							submitText={localize("callsurvey.send")}
							externalForms={useExternalForm}
							isATouchAway={this.props.isATouchAway}
						/>
					</View>
				</Card>
			</Modal>
		);
	}
}
