import React from "react";

import {
	StyleSheet,
	View,
	Dimensions,
} from "react-native";

import global from "global";

import Mockingbird from "./mockingbird.js";

var styles = StyleSheet.create({
	container: {
		flex: 1
	},
	horizontal: {
		flexDirection: "row"
	},
	vertical: {
		flexDirection: "column"
	}
});

// Create multiple Mockingbird instances on a single page
export default function MultiBird() {
	var tokens = [null];

	try {
		var parsed = JSON.parse(global.localStorage.getItem("multibird"));
		// Be more defensive on what sort of input you can get from the parameter.
		if(parsed && parsed.map)
			tokens = parsed;
	} catch(e) {
		console.log("Couldn't parse `multibird` configuration");
		// Couldn't parse tokens
	}

	var renderedInstances = tokens.map(function(token, instance){
		return (
			<Mockingbird id={"multibird_" + instance} token={token} id_prefix={"bird_" + instance + "_"} />
		);
	});

	var styleList = [styles.container];

	var dimensions = Dimensions.get("window");

	if(dimensions.width > dimensions.height) {
		styleList.push(styles.horizontal);
	} else {
		styleList.push(styles.vertical);
	}

	return (
		<View style={styleList}>
			{renderedInstances}
		</View>
	);
}
