import React from "react";
import {
	StyleSheet,
	Image,
} from "react-native";

import Heading from "ae-base/Base/Heading";
import Loader from "ae-base/Base/Loader";
import BlockView from "ae-base/Base/BlockView";
import StyledText from "ae-base/Base/StyledText";
import StyledButton from "ae-base/Base/StyledButton";
import ManagedSection from "../ManagedSection";
import ListItem from "ae-base/List/ListItem";
import ListContent from "ae-base/List/ListContent";

import Style from "ae-style";

import { useResources } from "@aetonix/hooks";

var styles = StyleSheet.create({
	imagePreview: {
		width: 64,
		height: 64,
	},
	viewButton: {
		marginRight: Style.layout.marginSmall,
	},
	itemView: {
		marginTop: Style.layout.marginLarge,
	},
});

export default function Managedformsview(props) {
	var model = props.model;
	var forms = model.manage.forms;
	var title = forms.data.title;
	var schema = forms.schema;
	var screen = model.size;

	if(Object.keys(schema).length === 0)
		return <Loader />;


	var renderItems = Object
		.keys(schema.properties)
		.filter(function(name){
			return (typeof forms.data[name] !== "undefined" && name !== "title");
		}).map(function (name) {
			var property = schema.properties[name];
			var value = forms.data[name];

			if(property.type === "string")
				return (
					<TextItem key={name} schema={property} content={value} screen={screen} />
				);

			if(property.format === "file")
				return (
					<FileItems key={name} schema={property} files={value || []} screen={screen} />
				);

			return null;
		});

	var contact = model.manage.account;

	return (
		<ManagedSection model={model} online={model.online} title={title} contact={contact}>
			{renderItems}
		</ManagedSection>
	);
}

function FileItems(props) {
	const {
		localize,
	} = useResources();

	var files = props.files;
	var schema = props.schema;
	var screen = props.screen;

	var renderedFiles = files.map(function (file) {
		var format = file.format;
		var id = file.id;

		if(format === "image")
			return (
				<ImageItem key={id} file={file} />
			);
		if(format === "video")
			return (
				<VideoItem key={id} file={file} />
			);
		if(format === "document" || format === "pdf" || format === "application/pdf" || format === "audio")
			return (
				<DocumentItem key={id} file={file} />
			);

		return null;
	});

	var renderEmptyFiles = !renderedFiles.length ? <StyledText>{localize("managedforms.emptyFiles")}</StyledText> : null;

	return (
		<ItemView schema={schema} screen={screen} >
			{renderedFiles}
			{renderEmptyFiles}
		</ItemView>
	);
}

function TextItem(props) {
	var schema = props.schema;
	var content = props.content;
	var screen = props.screen;

	return (
		<ItemView schema={schema} screen={screen} >
			<StyledText>{content}</StyledText>
		</ItemView>
	);
}

function ImageItem(props) {
	var file = props.file;
	var source = {
		uri: file.url,
	};

	return (
		<FileItem file={file}>
			<Image resizeMethod="resize" style={styles.imagePreview} resizeMode="stretch" source={source} />
		</FileItem>
	);
}

function VideoItem(props){
	var file = props.file;

	return (
		<FileItem file={file} />
	);
}

function DocumentItem(props) {
	var file = props.file;

	return (
		<FileItem file={file} />
	);
}

function ItemView(props) {
	const {
		localize,
	} = useResources();

	var children = props.children;
	var schema = props.schema;
	var description = localize(schema.description);
	var screen = props.screen;

	return (
		<BlockView style={styles.itemView}>
			<Heading title={description} screen={screen} />
			{children}
		</BlockView>
	);
}

function FileItem(props) {
	const {
		localize,
		dispatch,
	} = useResources();

	var children = props.children;

	var file = props.file;
	var format = file.format;
	var url = file.url;

	var name = (url.split("-").slice(-1)[0]) || "";
	var extension = name.split(".").slice(-1) || "";

	var viewfile = localize("managedformsview.viewfile");

	if(name.length > 32)
		name = name.slice(32 - extension.length - 6, -(extension.length + 3)) + "[...]." + extension;

	var openExternal = dispatch("fileviewer:trigger:view", {
		file: file,
	});

	var icon = "files";
	if(format === "image")
		icon = "picture";
	if(format === "video")
		icon = "video";
	if(format === "document")
		icon = "file";

	return (
		<ListItem>
			<BlockView style={styles.viewButton}>
				<StyledButton title={viewfile} icon={icon} onPress={openExternal} />
			</BlockView>
			<ListContent>
				<StyledText>{name}</StyledText>
			</ListContent>
			{children}
		</ListItem>
	);
}
