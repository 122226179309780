import React from "react";

import Connected from "./States/Connected";
import Connecting from "./States/Connecting";
import Incoming from "./States/Incoming";
import Outgoing from "./States/Outgoing";
import Busy from "./States/Busy";

export default function ATouchAwayCalling(props){
	var model = props.model;
	var calling = model.calling;
	var state = calling.state;
	var who = calling.who;
	var urgency = model.urgency || {};

	var screen = model.size;

	var availability = model.availability;
	var conferenceinviter = model.conferenceinviter;

	if(state === "connected")
		return (
			<Connected calling={calling} conferenceinviter={conferenceinviter} availability={availability} screen={screen} />
		);
	else if(state === "connecting")
		return (
			<Connecting who={who} screen={screen} />
		);
	else if(state === "calling")
		return (
			<Outgoing who={who} urgency={urgency} screen={screen} />
		);
	else if(state === "receiving")
		return (
			<Incoming who={who} screen={screen} />
		);
	else if(state === "busy")
		return (
			<Busy who={who} screen={screen} />
		);

	return null;
}
