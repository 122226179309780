import PropTypes from "prop-types";
import React from "react";
import {
	View,
	StyleSheet,
	Image,
	TouchableOpacity,
} from "react-native";

import StyledText from "ae-base/Base/StyledText";
import ImageUrl from "ae-base/Base/ImageUrl";
import Style from "ae-style";
import { useResources } from "@aetonix/hooks";

var styles = StyleSheet.create({
	name: {
		flex: 1,
		justifyContent: "center",
		flexDirection: "column",
		position: "absolute",
		left: 0,
		right: 0,
		bottom: 0,
		backgroundColor: "rgba(255, 255, 255, 0.7)",
		padding: 8,
		borderBottomLeftRadius: 20,
		borderBottomRightRadius: 20,
	},
	nameOffline: {
		flex: 1,
		justifyContent: "center",
		flexDirection: "column",
		position: "absolute",
		left: 0,
		right: 0,
		bottom: 0,
		padding: 8,
		borderBottomLeftRadius: 20,
		borderBottomRightRadius: 20,
	},
	item: {
		flex: 1,
		alignSelf: "stretch",
		borderRadius: 20,
	},
	itemOffline: {
		borderWidth: 2,
		borderColor: Style.colors.alert,
	},
	content: {
		width: null,
		height: null,
		flex: 1,
		alignItems: "center",
		justifyContent: "center",
		overflow: "hidden",
		borderRadius: 20,
	},
	contentOffline: {
		width: null,
		height: null,
		flex: 1,
		borderRadius: 20,
		alignItems: "center",
		justifyContent: "center",
		overflow: "hidden",
		opacity: 0.2,
	},
	outer: {
		flex: 1,
		overflow: "hidden",
		borderRadius: 20,
	},
});

export default function CallGroup(props) {
	const { config, dispatch } = useResources();

	var members = props.members;
	var group = props.group;
	var disableName = props.disableName;
	var id = group._id;
	var name = group.name;
	var description = group.description;
	const {
		isConfirming = false,
		confirmCall,
	} = props;

	var currentGroupMembers = members ? members[group._id] : [];
	var available = currentGroupMembers && currentGroupMembers.length && currentGroupMembers[0].available;

	const dispatchCallFn = dispatch("callgroupscalling:trigger:call", {
		group: id,
		voiceOnly: false,
	});

	let onCall = () => {
		if (isConfirming) {
			confirmCall(name, dispatchCallFn);
			return;
		}
		dispatchCallFn();
	};

	var containerStyle = [styles.item];
	var nameStyle = styles.name;
	var contentStyle = styles.content;

	if(!available){
		containerStyle.push(styles.itemOffline);
		nameStyle = styles.nameOffline;
		contentStyle = styles.contentOffline;
	}

	return(
		<View style={containerStyle}>
			<TouchableOpacity accessibilityComponentType="button" style={styles.outer} disabled={!available} onPress={onCall}>
				<View style={styles.outer}>
					<Image resizeMethod="resize" source={ImageUrl(group.image, config)} style={contentStyle} />
					{!disableName ? <View style={nameStyle}>
						<StyledText black={available} contrast={!available} bold centered size={props.textSize}>{name}</StyledText>
						<StyledText black={available} contrast={!available} bold centered size={props.textSize}>{description}</StyledText>
					</View> : null}
				</View>
			</TouchableOpacity>
		</View>
	);
}
