import React, {
	PureComponent,
} from "react";
import {
	View,
} from "react-native";
import prop from "prop";

import Item from "../Item";
import Group from "../Group";
import Section from "../Section";
import Title from "../Title";

import { useResources } from "@aetonix/hooks";

var getActionsResponsibleParty = prop("ResponsibleParty");
var getSuggestedBy = prop("SuggestedBy.Name");
var getDescription = prop("Description");
var getReviewDate = prop("ReviewDate");
var getExpectedOutcome = prop("ExpectedOutcome");
var getChallengesDescription = prop("ChallengesDescription");
var getAchievedResults = prop("AchievedResults");
var getConcerns = prop("Concerns");
var getContributingCareTeamMember = prop("ContributingCareTeamMembers");
var getMostImportant = prop("MostImportant");
var getAcpAware = prop("Plan.AcpAware.Name");
var getAcpCompleted = prop("Plan.AcpCompleted.Name");
var getAcpLocation = prop("Plan.AcpLocation");
var getPoaDescription = prop("Plan.PoaDescription");
var getPoaCompleted = prop("Plan.PoaCompleted.Name");
var getPoaLocation = prop("Plan.PoaLocation");
var getPoaContactSurname = prop("Plan.PoaContact.Surname");
var getPoaContactFirstName = prop("Plan.PoaContact.FirstName");
var getPoaContactRelationship = prop("Plan.PoaContact.Relationship");
var getPoaContactRelationshipOther = prop("Plan.PoaContact.RelationshipCodedValue.Name");
var getPoaContactPhone = prop("Plan.PoaContact.Phone.Number");
var getSituationDescription = prop("Description");
var getSituationReviewDate = prop("ReviewDate");
var getSituationWhatWillDo = prop("WhatWillDo");
var getSituationWhoWillHelp = prop("WhoWillHelp");
var getSituationWhatWillNotDo = prop("WhatWillNotDo");
var getSituationTelephone = prop("Telephone");

export default function Goals(props) {
	const {
		localize,
	} = useResources();

	var careplan = props.careplan;
	var caregoals = localize("goals.caregoals");
	var futureSituationsTitle = localize("goals.plansforfuturesituations");
	var careteammemberwhocontributedtothisplan = localize("goals.careteammemberwhocontributedtothisplan");
	var whatismostimportantformerightnow = localize("goals.whatismostimportantformerightnow");
	var whatconcernsmeaboutmyhealthcarerightnow = localize("goals.whatconcernsmeaboutmyhealthcarerightnow");
	var ihaverecievedinformationaboutadvancecareplanning = localize("goals.ihaverecievedinformationaboutadvancecareplanning");
	var ihavecompletedanadvancedcareplan = localize("goals.ihavecompletedanadvancedcareplan");
	var myacpislocatedhere = localize("goals.myacpislocatedhere");
	var asiunderstandmyacpsays = localize("goals.asiunderstandmyacpsays");
	var ihaveapowerofattorneyforpersonalcare = localize("goals.ihaveapowerofattorneyforpersonalcare");
	var mypoadocumentedislocatedhere = localize("goals.mypoadocumentedislocatedhere");
	var nameofpoaattorney = localize("goals.nameofpoaattorney");
	var relationshiptome = localize("goals.relationshiptome");
	var telephone = localize("goals.telephone");

	var careGoals = careplan.SpecificGoal || [];
	var renderCareGoals = careGoals.map(function(caregoal, index) {
		return (
			<CareGoal key={index} defaultData={caregoal} index={index} />
		);
	}
	);

	var futureSituations = careplan.FutureSituation || [];
	var renderFutureSituations = futureSituations.map(function(situation, index){
		return (
			<FutureSituation key={index} situation={situation} index={index} />
		);
	});

	var nameOfPoaValue = (getPoaContactFirstName(careplan) || "") + " " + (getPoaContactSurname(careplan) || "");

	return (
		<Section title={caregoals}>
			<Group>
				<Item title={careteammemberwhocontributedtothisplan} value={getContributingCareTeamMember(careplan)} />

				<Item title={whatismostimportantformerightnow} value={getMostImportant(careplan)} />

				<Item title={whatconcernsmeaboutmyhealthcarerightnow} value={getConcerns(careplan)} />
			</Group>

			{renderCareGoals}

			<Title title={futureSituationsTitle} />
			{renderFutureSituations}

			<Item title={ihaverecievedinformationaboutadvancecareplanning} value={getAcpAware(careplan)} />

			<Item title={ihavecompletedanadvancedcareplan} value={getAcpCompleted(careplan)} />

			<Item title={myacpislocatedhere} value={getAcpLocation(careplan)} />

			<Item title={asiunderstandmyacpsays} value={getPoaDescription(careplan)} />

			<Item title={ihaveapowerofattorneyforpersonalcare} value={getPoaCompleted(careplan)} />

			<Item title={mypoadocumentedislocatedhere} value={getPoaLocation(careplan)} />

			<Item title={nameofpoaattorney} value={nameOfPoaValue} />

			<Item title={relationshiptome} value={getPoaContactRelationship(careplan) || getPoaContactRelationshipOther(careplan)} />

			<Item title={telephone} value={getPoaContactPhone(careplan)} />
		</Section>
	);
}

function CareGoal(props) {
	const { localize } = useResources();
	var defaultData = props.defaultData;

	var actions = defaultData.Actions || [];

	var renderActions = actions.map((action, itemIndex) =>
		<Actions key={itemIndex} actions={action} />
	);

	var careGoals = localize("goals.caregoals");
	var whatihopetoachieve = localize("goals.whatihopetoachieve");
	var suggestedby = localize("goals.suggestedby");
	var whaticandotoachieveit = localize("goals.whaticandotoachieveit");
	var expectedoutcome = localize("goals.expectedoutcome");
	var barriersandchallenges = localize("goals.barriersandchallenges");
	var resultsachievedsofar = localize("goals.resultsachievedsofar");
	var reviewdate = localize("goals.reviewdate");

	return (
		<View>
			<Title title={careGoals} />

			<Item title={whatihopetoachieve} value={getDescription(defaultData)} />

			<Item title={reviewdate} value={getReviewDate(defaultData)} />

			<Group>
				<Item title={suggestedby} value={getSuggestedBy(defaultData)} />

				<Title title={whaticandotoachieveit} />

				{renderActions}

				<Item title={expectedoutcome} value={getExpectedOutcome(defaultData)} />

				<Item title={barriersandchallenges} value={getChallengesDescription(defaultData)} />

				<Item title={resultsachievedsofar} value={getAchievedResults(defaultData)} />
			</Group>
		</View>
	);
}

class Actions extends PureComponent {
	render(){
		var props = this.props;
		var actions = props.actions;
		return (
			<Item title={" • " + actions.Description} value={getActionsResponsibleParty(actions)} />
		);
	}
}

function FutureSituation(props){
	const { localize } = useResources();
	var situation = props.situation;

	var futuresituations = localize("goals.futuresituations");
	var whatiwilldo = localize("goals.whatiwilldo");
	var whatiwillnotdo = localize("goals.whatiwillnotdo");
	var whowillhelpme = localize("goals.whowillhelpme");
	var telephonenumber = localize("goals.telephonenumber");
	var reviewdate = localize("goals.reviewdate");

	return (
		<Group>
			<Item title={futuresituations} value={getSituationDescription(situation)} />

			<Item title={reviewdate} value={getSituationReviewDate(situation)} />

			<Item title={whatiwilldo} value={getSituationWhatWillDo(situation)} />

			<Item title={whatiwillnotdo} value={getSituationWhoWillHelp(situation)} />

			<Item title={whowillhelpme} value={getSituationWhatWillNotDo(situation)} />

			<Item title={telephonenumber} value={getSituationTelephone(situation)} />
		</Group>
	);
}
