import React from "react";
import {
	StyleSheet
} from "react-native";

import StyledButton from "ae-base/Base/StyledButton";
import Style from "ae-style";

var styles = StyleSheet.create({
	textStyle: {
		color: "white",
		padding: Style.layout.paddingSmall
	}
});

export default function PowerButton(props) {
	var buttonText = props.buttonText;
	var buttonAction = props.buttonAction;

	return (
		<StyledButton clear border large title={buttonText} textStyle={styles.textStyle} onPress={buttonAction} />
	);
}
