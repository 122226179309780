import React from "react";
import {
	StyleSheet,
} from "react-native";

import StyledText from "ae-base/Base/StyledText";
import Style from "ae-style";
import NoticePageBase from "./NoticePageBase";

import { useResources } from "@aetonix/hooks";

var styles = StyleSheet.create({
	noticeTitle: {
		margin: Style.layout.marginBordered,
	},
});

export default function NoticePage(props) {
	const {
		localize,
	} = useResources();

	var disconnectedTitl = localize("disconnected.disconnected");
	var disconnectedMessage = localize("disconnected.pleasewaitamonment");

	return (
		<NoticePageBase>
			<StyledText style={styles.noticeTitle} bold black largeHeading>{disconnectedTitl}</StyledText>
			<StyledText style={styles.noticeTitle} bold black emphasized>{disconnectedMessage}</StyledText>
		</NoticePageBase>
	);
}
