/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";

import ListItem from "ae-base/List/ListItem";
import ListIcon from "ae-base/List/ListIcon";
import ListContent from "ae-base/List/ListContent";
import StyledText from "ae-base/Base/StyledText";
import StyledButton from "ae-base/Base/StyledButton";

import { useResources } from "@aetonix/hooks";

export default function ResourceItem(props) {
	const {
		localize,
		localizeFrom,
	} = useResources();
	var view = localize("resources.view");

	var resource = props.resource;
	var name = resource.name;
	var viewResource = props.onClick;

	if(typeof resource.name === "object")
		name = localizeFrom(resource.name, ".");

	return (
		<ListItem>
			<ListIcon name={"newspaper-o"} />
			<ListContent>
				<StyledText bold>{name}</StyledText>
			</ListContent>
			<StyledButton title={view} onPress={viewResource} />
		</ListItem>
	);
}
