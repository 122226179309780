import { useEffect, useState } from 'react';

export default (events, config) => {
    const [callEvent, setCallEvent] = useState(null);

	if (!config?.id) {
		return callEvent;
	}

    useEffect(() => {
		const callEventName = `mqtt:u/${config.id}/meetings/created`
		const callUpdateEventName = `mqtt:u/${config.id}/meetings/updated`;
		const callEndedEventName = `mqtt:u/${config.id}/meetings/ended`;
		const callPushEventName = 'meetings:new';
		const callFromWorkflow = 'meetings:workflow:start';

		events.listen(callEventName, (meeting) => {
			setCallEvent({ name: 'callStartPush', meeting });
			setCallEvent(null);
		});

		events.listen(callPushEventName, (meeting) => {
			setCallEvent({ name: 'callStartEvent', meeting });
			setCallEvent(null);
		});

		events.listen(callUpdateEventName, meeting => {
			setCallEvent({ name: 'callUpdateEvent', meeting });
			setCallEvent(null);
		});

		events.listen(callEndedEventName, (meeting) => {
			setCallEvent({ name: 'callEndedEvent', meeting });
			setCallEvent(null);
		});

		events.listen(callFromWorkflow, (data) => {
			setCallEvent({ name: "callFromWorkflow", who: data.who });
			setCallEvent(null);
		})

		return () => {
			setCallEvent(null);
		};
	}, [config.id, events.listen]);

    return callEvent;
}