import React, { useContext } from 'react';
import MeetingSettingsContext from '../../context/MeetingSettingsContext';

export default () => {
    const meetingSettingsContext = useContext(MeetingSettingsContext);

    if (!meetingSettingsContext) {
        throw new Error('useMeetingStatus must be used within MeetingSettingsProvider');
    }

    return meetingSettingsContext;
}