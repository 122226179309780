import React, { useCallback, useContext } from 'react';
import { Text } from 'react-native';
import MeetingContext from './context/MeetingContext';
import Meeting from './Meeting'; // Picks the correct meeting file based off the platform
import MeetingErrorBoundary from './MeetingErrorBoundary';

export default ({ mode = "desktop" }) => {

    const meetingProvider = useContext(MeetingContext);
    const {
        meeting,
        invitees,
        onMinimize,
        meetingState,
        onLeaveMeeting,
        eligibleMembers,
        onInviteToMeeting,
        invitable,
        members,
        isAdministrator,
        onUpdateMeetingSettings,
        pinning,
    } = meetingProvider;

    const {
        title,
        _id: id,
        recipient,
        description,
        participants,
        administrators,
        externalMeetingId,
    } = meeting || {};

    if (!meeting) {
        return <Text>Loading...</Text>;
    }

    const onAfterError = useCallback(() => {
        meetingState?.onStopMeeting?.();
        meetingProvider?.setError?.(null);
    }, [
        meetingState?.onStopMeeting,
        meetingProvider?.setError,
    ]);

    return (
        <MeetingErrorBoundary
            error={meetingProvider?.error}
            onAfterError={onAfterError}
        >
            <Meeting
                id={id}
                mode={mode}
                title={title}
                invitees={invitees}
                recipient={recipient}
                onMinimize={onMinimize}
                description={description}
                participants={participants}
                administrators={administrators}
                onLeaveMeeting={onLeaveMeeting}
                invitable={invitable}
                membersInMeeting={members}
                eligibleMembers={eligibleMembers}
                externalMeetingId={externalMeetingId}
                onInviteToMeeting={onInviteToMeeting}
                isAdministrator={isAdministrator}
                onUpdateMeetingSettings={onUpdateMeetingSettings}
                pinning={pinning}
                {...meetingState}
            />
        </MeetingErrorBoundary>
    );
}
