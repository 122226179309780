import React, { Component, useRef, useEffect } from 'react';
import { View, Text, StyleSheet, Animated } from 'react-native';
import * as Sentry from '@sentry/react-native';
import { createStyled } from '@emotion/primitives-core'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faPhoneSlash } from '@fortawesome/free-solid-svg-icons';

const styled = createStyled(StyleSheet);

const Wrapper = styled(View)({
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#023020',
});

const ErrorMessage = styled(Text)({ color: 'white' });

export default class VideoTileErrorBoundary extends Component {

    constructor (props) {
        super(props);

        this.state = { error: null, hasError: false };
    }

    static getDerivedStateFromError (error) {
        return { hasError: true, error };
    }

    componentDidCatch (error, _errorInfo) {
        if (!error) {
            return;
        }

        Sentry.captureException(error);
    }

    render () {
        const { hasError } = this.state;
        const { children, name } = this.props;

        return (
            <React.Fragment>
               { ! hasError ? children : (
                    <VideoTileError name={name}/>
                )}
            </React.Fragment>
        );
    }
}

const VideoTileError = ({ name }) => {
    const iconAnimationRef = useRef(new Animated.Value(1));

    const pulse = () => {
        const composite = Animated.sequence([
            Animated.timing(iconAnimationRef?.current, { toValue: 0.1, duration: 1000 }),
            Animated.timing(iconAnimationRef?.current, { toValue: 1, duration: 1000 })
        ]);

        Animated.loop(composite).start();
    };

    useEffect(() => pulse(), []);

    return (
        <Wrapper>
            <ErrorMessage>{`${name}'s connection failed...`}</ErrorMessage>
            <Animated.View style={{ opacity: iconAnimationRef?.current ?? 1 }}>
                <FontAwesomeIcon icon={faPhoneSlash} color="white" size={30} style={{ marginTop: 20 }} />
            </Animated.View>
        </Wrapper>
    );
};