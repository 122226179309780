import React from "react";
import ResponsiveStylesheet from "react-native-responsive-stylesheet";

import BlurImage from "./BlurImage";
import BlockView from "ae-base/Base/BlockView";

import Style from "ae-style";

var normalStyles = ResponsiveStylesheet.createSized("min-direction", {
	0: {
		container: {
			zIndex: 1,
		},
		tint: {
			position: "absolute",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			zIndex: 0,
			backgroundColor: "rgba(6, 91, 97, 0.8)"
		},
	},
	512: {
		container: {
			padding: Style.layout.paddingSlim,
		}
	}
});

export default function HeaderImage(props){
	var tint = normalStyles.tint;

	var source = props.source;
	var children = props.children;

	return (
		<BlurImage source={source} style={normalStyles.container}>
			<BlockView style={tint} />
			{children}
		</BlurImage>
	);
}
