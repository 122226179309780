import React from "react";
import {
	View,
	StyleSheet,
} from "react-native";

import StyledText from "ae-base/Base/StyledText";
import Style from "@aetonix/styles";

var styles = StyleSheet.create({
	item: {
		marginBottom: Style.layout.marginMedium
	},
});

export default function Item(props){
	return (
		<View style={styles.item}>
			<StyledText tertiary smallHeading>{props.title}</StyledText>
		</View>
	);
}
