import React from "react";
import {
	StyleSheet,
} from "react-native";
import StyledIcon from "ae-base/Base/StyledIcon";

import Style from "ae-style";

var styles = StyleSheet.create({
	spaced: {
		marginRight: Style.layout.marginContentSmall,
	}
});

export default function AvailabilityIcon(props) {
	var contrast = props.contrast;
	var available = props.available;
	var teal = props.teal;
	var icon = available ? "circle" : "circle-o";

	return (
		<StyledIcon style={styles.spaced} teal={teal} contrast={contrast} name={icon} small />
	);
}
