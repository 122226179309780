export default function getVersion(versionInfo){
	const version = versionInfo?.version;
	if(!version) return false;
	const versions = version.split(".").map(e => parseInt(e, 10))
	return {
		majorVersion: versions[0],
		minorVersion: versions[1],
		patchVersion: versions[2]
	}
}

export const NewCallingVersion = 14;