import React from 'react';
import { createStyled } from '@emotion/primitives-core'
import { StyleSheet, View } from 'react-native'
import ResponsiveText from '../Text/ResponsiveText';
import Style from '@aetonix/styles';

const styled = createStyled(StyleSheet);

const Container = styled(View)({
    flexDirection: "row",
    justifyContent: "space-around",
    height: 'auto',
    maxHeight: 80,
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 100,
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.15)'
})

const HeadingText = styled(ResponsiveText)({
    flex: 1,
    fontFamily: "Avenir",
    overflow: "hidden",
    textOverflow: "ellipsis",
    margin: 16,
    marginLeft: 20,
    fontWeight: '500',
    alignSelf: "flex-end",
    color: 'white',
});

export default ({ callTitle }) => (
    <Container>
        <HeadingText
            size={Style.text.large}
            max={Style.text.largeHeading}
            numberOfLines={1}
            adjustsFontSizeToFit
        >
            {callTitle}
        </HeadingText>
    </Container>
);
