import React from 'react';
import { createStyled } from '@emotion/primitives-core';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import ResponsiveText from '../Text/ResponsiveText';
import Style from '@aetonix/styles';
import { faStar, faMedal, faUsers } from '@fortawesome/free-solid-svg-icons'

const MembersItem = ({ title, userType, actionIcon, onClick }) => {
    return <Container testID="member-item-container">
        <IconContainer>
            { getUserIcon(userType) }
        </IconContainer>
        <NameText size={Style.text.standard} max={Style.text.smallHeading} numberOfLines={1} adjustsFontSizeToFit>{title}</NameText>
        <ActionTouchable testID="action-touchable" onPress={!!onClick && onClick}>
            { actionIcon }
        </ActionTouchable>
    </Container>
}

const getUserIcon = (userType) => {
    const icons = {
        "patient": <FontAwesomeIcon style={{ marginRight: 10 }} color="gold" size={23} testID="members-item-patient-icon" icon={faStar}/>,
        "administrator": <FontAwesomeIcon style={{ marginRight: 10, borderRadius: 250 }} color="silver" size={23} testID="members-item-adminstrator-icon" icon={faMedal}/>,
        "care-team": <FontAwesomeIcon style={{ marginRight: 10 }} color="#2f3338" size={23} testID="members-item-care-team-icon" icon={faUsers}/>,
    };
    return icons?.[userType] ?? icons['care-team'] ;
}

const styled = createStyled(StyleSheet);

const Container = styled(TouchableOpacity)({
    flex: 1,
    flexDirection: "row",
    alignItems: 'center',
    justifyContent: "space-around",
    width: "100%",
    marginBottom: 10,
    fontFamily: "Avenir",
});

const IconContainer = styled(View)({ width: 30 })

const NameText = styled(ResponsiveText)({
    flex: 1,
    width: "100%",
    color: "#141823",
    fontFamily: "Avenir",
    overflow: "hidden",
    whiteSpace: "noWrap",
    textOverflow: "ellipsis",
    marginTop: 4,
    fontWeight: '500',
    alignSelf: "center",
});

const ActionTouchable = styled(TouchableOpacity)({
    width: 25,
    heigth: 10,
    marginLeft: 10,
})

export default MembersItem