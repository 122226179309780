import React from "react";
import CardPage from "../../Layout/CardPage";
import Form from "ae-forms";

import UserCreationSchema from "../../../schemas/UserCreation";
import UserEditSchema from "../../../schemas/UserEdit";

import { useResources } from "@aetonix/hooks";

const UserTypeToApi = {
	"user:mobile": "Mobile",
	"simplified": "Simplified",
	"atouchaway": "aTouchAway",
};
export default function UserCreate(props) {
	const {
		localize,
		dispatch,
	} = useResources();
	var model = props.model;
	var sources = model.formsources;
	var editing = model.usercreation;
	var managegroups = model.groupmanagegroups;
	var groupSettings = model.groupsettings;

	var onSubmit = dispatch("usercreation:trigger:submit");
	var goBack = dispatch("usercreation:trigger:cancel");

	var discard = localize("usercreation.discard");
	var creationTitle = localize("usercreation.title");
	var editTitle = localize("usercreation.edit");
	var title = creationTitle;

	var defaultUserType = props.model.groupsettings.defaultUserType;
	var defaultUserTypeValue;
	if(defaultUserType)
		defaultUserTypeValue = UserTypeToApi[defaultUserType];

	var defaultData = {"userType": defaultUserTypeValue};

	var schema = {...UserCreationSchema};

	if (editing) {
		title = editTitle;
		schema = {...UserEditSchema};
		defaultData = editing;
		if(editing?.user_notes?.length > 0)
			defaultData.user_note = editing.user_notes[0].comment || "";
		else
			defaultData.user_note = "";

		if (editing.type.indexOf("user:mobile") !== -1)
			defaultData.userType = "Mobile";
		else if (editing.type.indexOf("atouchaway") !== -1)
			defaultData.userType = "aTouchAway";
		else if (editing.type.indexOf("simplified") !== -1)
			defaultData.userType = "Simplified";

	}
	const manageGroupLocalizations = {};
	const manageGroupEnums = managegroups.map((manageGroup) => {
		manageGroupLocalizations[manageGroup._id] = manageGroup.name;
		return manageGroup._id;
	});
	schema.properties.manageGroup = {
		type: "array",
		inputType: "string",
		description: "usercreation.managegroup",
		items: {
			enum: manageGroupEnums,
			value_localization: manageGroupLocalizations,
		},
	};

	if (groupSettings && groupSettings.hideEmrId) {
		var properties = {...schema.properties};
		delete properties.patientNumber;
		schema.properties = properties;
	}

	return (
		<CardPage title={title} icon="user" saving={model.saving} screen={model.size} toast={model.toast} online={model.online}>
			<Form schema={schema} sources={sources} defaultData={defaultData} onSubmit={onSubmit} onCancel={goBack} cancelText={discard} />
		</CardPage>
	);
}
