/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";
import { View, StyleSheet, Platform } from "react-native";
import Breakpoints from "../../../../Breakpoints";

import CardPage from "../../../Layout/CardPage";
import Avatar from "../../../Contacts/Avatar";
import Loader from "ae-base/Base/Loader";

import Form from "ae-forms";
import ChangePicSchema from "../../../../schemas/ChangePic";

import Style from "ae-style";

import { useResources } from "@aetonix/hooks";

const styles = StyleSheet.create({
	image: {
		flexDirection: "row",
		marginVertical: Style.layout.marginLarge,
		justifyContent: "center",
	},
});

export default function ProfilePictureChoose(props) {
	const { localize, dispatch, api } = useResources();
	const [loading, setLoading] = React.useState(false);
	const [image, setImage] = React.useState(null);

	const model = props.model;
	const preferences = model.personal;
	const saving = model.saving;
	const toast = model.toast;
	const screen = model.size;

	const changepictitle = localize("profilepicturechoose.changepictitle");
	const backtitle = localize("profilepicturechoose.backtitle");

	const openCropper = dispatch("personalprofilepicture:trigger:start");
	const uploadImage = async data => {
		setLoading(true);
		try {
			const response = await api.people.picture(data.image, {});
			setImage(response.image);
			dispatch("toast:trigger:show", {
				content: "profilepicturechoose.uploadsuccess",
				style: "success",
			});
		} catch (e) {
			console.error(e);
			dispatch("toast:trigger:show", {
				content: "profilepicturechoose.uploadfails",
				style: "error",
			});
		} finally {
			setLoading(false);
		}
	};
	const onSubmit = Platform.OS === "web" ? openCropper : uploadImage;

	let sizeAvatar = "large";
	if (Breakpoints.smallerThan("mobile")) sizeAvatar = "medium";
	if (Breakpoints.smallerThan("small")) sizeAvatar = "verysmall";

	if (loading) return <Loader />;

	return (
		<CardPage
			title={changepictitle}
			icon="camera"
			backTitle={backtitle}
			saving={saving || loading}
			toast={toast}
			screen={screen}>
			<View style={styles.image}>
				<Avatar
					person={{ image: image || preferences.image, _id: preferences._id }}
					size={sizeAvatar}
				/>
			</View>
			<Form schema={ChangePicSchema} autoSubmit onSubmit={onSubmit} />
		</CardPage>
	);
}
