/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";
import PropTypes from "prop-types";

import Header from "../HealthHeader";
import Markdown from "ae-base/Base/Markdown";
import MainScroll from "../../../Layout/MainScroll/MainScroll";
import MainContent from "../../../Layout/MainContent";

import { useResources } from "@aetonix/hooks";

ResourceView.propTypes = {
	model: PropTypes.object,
};

export default function ResourceView(props) {
	const { localizeFrom } = useResources();
	var model = props.model;
	var resources = model.ownresources;
	var resourcesList = resources.resources;
	var viewingId = resources.viewingResource || {};
	var resourceData = resourcesList.find(function(data) {
		return data._id === viewingId;
	}) || {};

	var resourceContent = resourceData.data || "";
	var resourceTitle = resourceData.name || " ";

	if(typeof resourceContent === "object" && typeof resourceTitle === "object"){
		resourceContent = localizeFrom(resourceData.data, ".");
		resourceTitle = localizeFrom(resourceData.name, ".");
	}

	return (
		<MainScroll>
			<Header title={resourceTitle} model={model} />
			<MainContent>
				<Markdown>
					{resourceContent}
				</Markdown>
			</MainContent>
		</MainScroll>
	);
}
