import React from "react";
import ManagedHealthIndicator from "../../HealthIndicators/ManagedHealthIndicator";
import { useMeasureUnits } from "@aetonix/hooks";
import { useResources } from "@aetonix/hooks";
export default function BodyTempMetrics(props) {
	const {
		localize,
	} = useResources();

	var model = props.model;
	var manage = model.manage;
	var bodytemperature = manage.managedbodytemperature;

	var steps = bodytemperature.cumulative;
	var reverse_steps = bodytemperature.reverse; // needed for chart
	var today = null;
	var section = model.page;
	var pagetitle = localize("bodytemperature.status");

	const { temperatureUnits: tempUnits } = useMeasureUnits();
	var units = localize("bodytemperature." + tempUnits);

	var textformat = "{" + tempUnits + "}";

	// Sets up the format to the 'today' button
	if (steps.length) {
		var graphdata = adjust_time(reverse_steps, null, tempUnits).slice(-10); // for chart
		today = steps[0].history[tempUnits];
	}

	return (
		<ManagedHealthIndicator model={model} section={section} textformat={textformat} units={units} pagetitle={pagetitle}  latestReading={today} reverseEntries={graphdata} activeModel={bodytemperature} xaxis={"x"} yaxis={"y"} managed />
	);
}

function adjust_time(data, forBoxGraphic, tempUnits) {
	var adjusted = data.map(function (item) {
		var time = time_label(item.updated_at, forBoxGraphic);
		var value = (typeof item.history[tempUnits] === "string")
			? parseFloat(item.history[tempUnits])
			: item.history[tempUnits];
		return {
			x: time,
			y: value,
		};
	});
	return adjusted;
}

function time_label(data, forBoxGraphic) {
	var parsed = time_parse(data, forBoxGraphic);

	return (parsed.month + "-" + parsed.day + "  " + parsed.time);
}

function time_parse(data, forBoxGraphic) {
	var date = new Date(data);
	var month = date.getMonth() + 1;
	var day = date.getDate();
	day = two_digit(day);
	month = two_digit(month);
	var hours = two_digit(date.getHours());
	var minutes = two_digit(date.getMinutes());
	var seconds = forBoxGraphic ? "" : ":" + two_digit(date.getSeconds());
	var time = hours + ":" + minutes + seconds;

	return {
		month: month,
		day: day,
		time: time,
	};
}

function two_digit(data) {
	var item = (data < 10)
		? "0" + data
		: data;
	return item;
}
