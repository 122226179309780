import React from "react";
import {
	View,
	StyleSheet,
	TouchableOpacity,
} from "react-native";
import ListItem from "ae-base/List/ListItem";
import StyledText from "ae-base/Base/StyledText";

import Breakpoints from "../../Breakpoints";
import Avatar from "../Contacts/Avatar";
import AvailabilityIcon from "../Contacts/AvailabilityIcon";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { createStyled } from "@emotion/primitives-core";
import { faPhone, faPhoneSlash, faVideo, faVideoSlash } from "@fortawesome/free-solid-svg-icons";
import Style from "ae-style";

import { useResources } from "@aetonix/hooks";

var styles = StyleSheet.create({
	nameContainer: {
		marginLeft: Style.layout.marginContentSmall,
		flexDirection: "column",
		flex: 1,
	},
});

const styled = createStyled(StyleSheet);

const CallButton = styled(TouchableOpacity)({
	paddingLeft:10,
});

export default function CallGroup(props) {
	const {
		dispatch,
	} = useResources();

	var members = props.members;
	var group = props.group;
	var name = group.name;
	var description = group.description;
	var showAvailable = props.showAvailable;
	var currentGroupMembers = members ? members[group._id] : [];

	var sizeAvatar = "mini";
	if(Breakpoints.smallerThan("mobile"))
		sizeAvatar = "tiny";

	var onCallVideo = dispatch("callgroupscalling:trigger:call", {
		group: group._id,
	});

	var onCallVoice = dispatch("callgroupscalling:trigger:call", {
		group: group._id,
		voiceOnly: true,
	});

	var availableIcon = null;
	var available = currentGroupMembers && currentGroupMembers.length && currentGroupMembers[0].available;
	if(showAvailable)
		availableIcon = (<AvailabilityIcon available={available} />);


	return (
		<ListItem style={styles.listItem}>
			{availableIcon}
			<Avatar person={group} size={sizeAvatar} />
			<GroupName name={name} description={description} />
			<CallButton onPress={onCallVoice}>
				<FontAwesomeIcon icon={!available ? faPhoneSlash : faPhone} color="#285E63" size={32} />
			</CallButton>
			<CallButton onPress={onCallVideo}>
				<FontAwesomeIcon icon={!available ? faVideoSlash : faVideo} color="#285E63" size={32} />
			</CallButton>
		</ListItem>
	);
}

function GroupName(props){
	var name = props.name;
	var description = props.description;
	return (
		<View style={styles.nameContainer}>
			<StyledText emphasized>
				{name}
			</StyledText>
			<StyledText>
				{description}
			</StyledText>
		</View>
	);
}
