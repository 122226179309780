import React, { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from "react";
import useCallNavigation from "./hooks/useCallNavigation/useCallNavigation";
import { MeetingWrapper, ToolsWrapper, DesktopNavigationWrapper } from "./components/Containers";
import CallHeader from "./components/Header/CallHeader";
import VideoGrid from "./components/Video/VideoGrid";
import MessagingHeaderIcon from "./components/Header/MessagingHeaderIcon";
import Navigation from "./components/Navigation/Navigation";
import RemoteVideo from "./components/Video/Web/RemoteVideo";
import LocalVideo from "./components/Video/Web/LocalVideo";
import Tray from './components/Tray/Tray';
import TrayMembers from './components/Tray/TrayMembers';
import TrayList from "./components/Tray/TrayList";
import TrayAddPerson from "./components/Tray/TrayAddPerson";
import { faAngleDown, faCog, faTh, faSquare } from '@fortawesome/free-solid-svg-icons';
import Modal from "./components/Modals/Modal";
import { MENUS } from "./libraries/menu.library";
import { createStyled } from '@emotion/primitives-core'
import { StyleSheet, Text, TouchableOpacity } from 'react-native';
import { useLocalize } from "../hooks/useLocalize/LocalizeContext";
import useMeetingSettings from "./hooks/useMeetingSettings/useMeetingSettings";

const styled = createStyled(StyleSheet);

const DevModeTrigger = styled(TouchableOpacity)({
	width: 20,
	height: 20,
	position: 'absolute',
	bottom: 0,
	left: 0,
	backgroundColor: 'rgba(0, 0, 0, 0.5)',
});

const TrayMore = ({ setMenu, onMinimize }) => {
	const localize = useLocalize();

	const options = useMemo(() => ([{
		id: '1',
		title: localize("meeting.traylist_options.one"),
		icon: faAngleDown,
		onClick: () => { setMenu(MENUS.NONE); onMinimize(); },
	}, {
		id: '2',
		title: localize("meeting.traylist_options.two"),
		icon: faCog,
		onClick: () => setMenu(MENUS.OUTPUT),
	}, {
		id: '4',
		key: 4,
		title: localize('meeting.traylist_options.five'),
		icon: faTh,
		onClick: () => setMenu(MENUS.VIEWS),
	}]), [
		faAngleDown,
		faCog,
		faTh,
		onMinimize,
		setMenu,
	]);

	return <TrayList options={options} />;
}

const withDesktopNavigation = (navigation) => <DesktopNavigationWrapper>{navigation}</DesktopNavigationWrapper>

export default (props) => {
	let { mode } = props;
	const {
		isMuted,
		isCameraOn,
		orientation,
		onToggleMute,
		onStopMeeting,
		onToggleCamera,
		title,
		tiles,
		setOrientation,
		pendingIncomingCalls,
	} = props;

	const localize = useLocalize();
	const { layout } = useMeetingSettings();
	const [devMode, setDevMode] = useState('desktop'); //@reest needs renaming
	const isDevMode = localStorage.getItem('CALLING_DEV_MODE') ?? false;

	mode = isDevMode ? devMode : mode;

	// Navigation
	const navigationRef = useRef(null);
	const trayRef = useRef(null);
	const { navigationIndex, onClickNavigation, menu, setMenu } = useCallNavigation();

	// Layout
	const isDesktop = useMemo(() => mode === 'desktop', [mode]);
	const navigationOrientation = useMemo(() => (isDesktop ? "horizontal" : orientation === "horizontal" ? "vertical" : "horizontal"), [orientation, isDesktop]);

	const hasClickedOutsideMenus = useCallback(event => {
		const didClickOnTray = trayRef?.current?.contains?.(event.target);
		const didClickOnNavigation = navigationRef?.current?.contains?.(event.target);

		return !didClickOnTray && !didClickOnNavigation;
	}, [navigationRef, trayRef]);
	
	const renderVideoTile = useCallback((item) =>
		item?.isSelf ? (
			<LocalVideo userId={item?.userId} image={item?.image} nameplate={item?.name ?? ""} isFeatured={item?.isFeatured ?? false} mode={mode}/>
		) : (
			<RemoteVideo userId={item?.userId} image={item?.image} tileId={item?.tileId ?? null} chimeAttendeeID={item?.externalId} isFeatured={item?.isFeatured ?? false} nameplate={item?.name ?? ""} />
		)
	, [mode]);

	const items = useMemo(() => ([
		{ label: localize('meeting.navigation.microphone'), icons: { off: "microphoneOff", on: "microphoneOn" }, isActive: !isMuted, onClick: onToggleMute },
		{ label: localize('meeting.navigation.camera'), icons: { on: "videoOn", off: "videoOff" }, isActive: isCameraOn, onClick: onToggleCamera },
		{ label: localize('meeting.navigation.members'), icon: "users", onClick: () => setMenu(MENUS.MEMBERS) },
		...(mode === 'desktop' ? [
			// { label: "Share", icon: "desktopOn" },
			// { label: "Chat", icon: "message" }
		] : []),
		{ label: localize('meeting.navigation.more'), icon: "ellipsis", onClick: () => setMenu(MENUS.MORE) },
		{ label: localize('meeting.navigation.end'), icon: "phoneOff", onClick: onStopMeeting }
	]), [
		mode,
		isMuted,
		isCameraOn,
		setMenu,
		onStopMeeting,
		onToggleMute,
		onToggleCamera,
		localize,
	]);

	const navigation = <Navigation
		ref={navigationRef}
		mode={mode}
		menu={menu}
		items={items}
		onClick={onClickNavigation}
		selected={navigationIndex}
		orientation={navigationOrientation}
	/>;

	const hasPendingCalls = !!pendingIncomingCalls && !!pendingIncomingCalls.length;

	return (
		<MeetingWrapper id="meeting" orientation={orientation}>
			<ToolsWrapper>
				<CallHeader callTitle={title} headerIcon={<MessagingHeaderIcon onClick={null} />} />
				<VideoGrid tiles={tiles} layout={layout} orientation={isDesktop ? 'vertical' : orientation} renderTile={renderVideoTile} />
				<Trays
					ref={trayRef}
					tray={menu}
					state={props}
					isOpen={!!menu}
					setMenu={setMenu}
					isDesktop={isDesktop}
					tiles={tiles}
					orientation={orientation}
					hasClickedOutside={hasClickedOutsideMenus}
				/>
			</ToolsWrapper>
			{ hasPendingCalls && pendingIncomingCalls.map(pending => <Text>{JSON.stringify(pending)}</Text>) }
			{ isDesktop ? withDesktopNavigation(navigation) : navigation }
			{ isDevMode && <DeveloperTools setDevMode={setDevMode} setOrientation={setOrientation} /> }
		</MeetingWrapper>
	);
};

const DeveloperTools = ({ setDevMode, setOrientation }) => (
	<React.Fragment>
		<DevModeTrigger
			onLongPress={() => setOrientation('vertical')}
			onPress={() => {
				const isHorizontal = confirm('Change orientation to horizontal?');
				setOrientation(isHorizontal ? 'horizontal' : 'vertical');
			}
		}/>
		<DevModeTrigger
			style={{ left: 20, backgroundColor: 'rgba(0, 100, 100, 0.5)'}}
			onLongPress={() => setDevMode('desktop')}
			onPress={() => {
				const isSimplified = confirm('Change mode to simplified?');
				setDevMode(isSimplified ? 'simplified' : 'regular');
			}
		}/>
	</React.Fragment>
)

const Trays = forwardRef((props, ref) => {
	const {
		tray = null,
		state,
		isOpen = false,
		setMenu,
		isDesktop = true,
		orientation,
		hasClickedOutside,
	} = props;

	const localize = useLocalize();
	const meetingSettings = useMeetingSettings();

	const asDevicesOptions = useCallback((audioDevice, index) => ({
		id: `${index}`,
		title: audioDevice.label,
		icon: null,
		isSelected : audioDevice.isSelected,
		onClick: () => { state.onSetAudioDevice(audioDevice.deviceId); setMenu(MENUS.NONE) },
	}), [
		state.onSetAudioDevice,
		setMenu
	]);

	const trayItemRed = '#D21D3D'
	const administratorMeetingViewOptions = [{
        id: 3,
        title: localize('meeting.options.changeEveryone'),
        textColour: trayItemRed,
		iconColour: trayItemRed,
		icon: faAngleDown,
    } , {
		id: 4,
		title: localize('meeting.views.options.teams'),
		icon: faSquare,
        iconColour: trayItemRed,
		onClick: () => { state.onUpdateMeetingSettings({ currentAdminView: 'teams' }); setMenu(MENUS.NONE) },
	}, {
		id: 5,
		title: localize('meeting.views.options.grid'),
		icon: faTh,
        iconColour: trayItemRed,
		onClick: () => { state.onUpdateMeetingSettings({ currentAdminView: 'grid' }); setMenu(MENUS.NONE) },
	}];

	const viewOptions = [{
		id: 1,
		title: localize('meeting.views.options.teams'),
		icon: faSquare,
		onClick: () => { meetingSettings.setLayout('teams'); setMenu(MENUS.NONE) },
	}, {
		id: 2,
		title: localize('meeting.views.options.grid'),
		icon: faTh,
		onClick: () => { meetingSettings.setLayout('grid'); setMenu(MENUS.NONE) },
	}, ...(state.isAdministrator ? administratorMeetingViewOptions : [])]

	const members = useMemo(() => ([
		{ title: localize("meeting.memberstray.one"), data: state.attendees },
		{ title: localize("meeting.memberstray.two"), data: state.invitees }
	]), [
		state.attendees, state.invitees, setMenu, localize
	]);

	var eligibleMembers = state.eligibleMembers;
	var allInvitableMembers = state.invitable;
	var currentAttendess = state.attendees;
	var membersInMeeting = state.membersInMeeting;
	
	const uniqueMembers = useMemo(() => membersInMeeting?.filter(function (obj) {
		return (!currentAttendess?.some(function (obj2) {
			return obj._id === obj2.userId;
		}))
	})
		, [currentAttendess])

	var newEligibleMembers = [...eligibleMembers, ...uniqueMembers]  //eligible members including those who left meeting after joining

	const currentTray = tray === MENUS.MEMBERS ? (
		<TrayMembers onClickAddPerson={() => setMenu(MENUS.ADDPERSON)} members={members} isAddDisabled={!(state.eligibleMembers && state.eligibleMembers?.length > 0)}/>
	) : tray === MENUS.MORE ? (
		<TrayMore setMenu={setMenu} onMinimize={state.onMinimize}/>
	) : tray === MENUS.OUTPUT ? (
		<TrayList options={state.onListAudioDevice().map(asDevicesOptions)}/>
	) : tray === MENUS.VIEWS ? (
		<TrayList options={viewOptions}/>
	) : tray === MENUS.ADDPERSON ? (
		<TrayAddPerson 
			mode={state.mode}
			persons={newEligibleMembers}
			onBackClick={() => setMenu(MENUS.MEMBERS)}
			onClick={invitee => state.onInviteToMeeting(invitee._id)}
		/>
	) : null;

	const onClose = useCallback(() => setMenu(MENUS.NONE), [setMenu]);

	const trays = !isDesktop ? (
		<Tray ref={ref} orientation={orientation} onClose={onClose}>{currentTray}</Tray> 
	) : (
		<Modal ref={ref} onClose={onClose} hasClickedOutside={hasClickedOutside}>{currentTray}</Modal>
	);

	return isOpen && trays;
});