
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";
import {
	View,
	StyleSheet,
} from "react-native";

import SquareButton from "ae-base/Base/SquareButton";
import Heading from "ae-base/Base/Heading";
import Style from "ae-style";
import NotificationIcon from "ae-base/Base/NotificationIcon";

import ResponsiveStylesheet from "react-native-responsive-stylesheet";

import { useResources } from "@aetonix/hooks";

var MAX_BUTTONS = 6;

var styles = StyleSheet.create({
	buttons: {
		flexDirection: "row",
		flexWrap: "wrap",
		justifyContent: "flex-start",
		alignSelf: "center",
	},
	notification: {
		position: "absolute",
		top: 0,
		right: 0,
	},
});

var responsiveStyles = makeIncrements(MAX_BUTTONS);

export default function ButtonGridForms(props){
	var title = props.title;
	var groupformlist = props.forms || {};
	var contact = props.contact || {};
	var newsections = props.newsections || {};
	const group = props.group;

	var who = contact._id;

	var screen = props.screen;

	if(!who) return (
		null
	);

	var renderedButtons = Object.keys(groupformlist).map(function(formId){
		var form = groupformlist[formId];

		var hasNotification = newsections["groupforms_" + formId];

		return (
			<FormButton key={form._id} form={form} contact={contact} group={group} notification={hasNotification} />
		);
	});

	var renderedTitle = Object.keys(groupformlist).length && title  ? (
		<Heading title={title} screen={screen} />
	) : null;

	return (
		<View style={responsiveStyles.container}>
			{renderedTitle}
			<View style={styles.buttons}>
				{renderedButtons}
			</View>
		</View>
	);
}

function FormButton(props) {
	const {
		dispatch,
		localizeFrom,
		language,
	} = useResources();

	var form = props.form;
	var contact = props.contact;
	const group = props.group;

	var localization = form.localization;
	var icon = form.icon;

	var onPress = dispatch("managedgroupformsubmissions:trigger:search", {
		form: form._id,
		who: contact._id,
		group,
	});

	var title = localizeFrom(localization, ".") || form.name || form._id;

	var textSize = 12;
	var iconSize = Style.imageSize.tiny;

	var notification = props.notification ? (
		<NotificationIcon style={styles.notification} title="!" />
	) : null;

	var titleLength = title.length;
	var titleLengthThreshold = 25; // 35 for english seems to be good

	if (titleLength > titleLengthThreshold && language === "ta")
		textSize = 9;


	return (
		<SquareButton
			key={title}
			title={title}
			textSize={textSize}
			icon={icon}
			iconSize={iconSize}
			onPress={onPress}>
			{notification}
		</SquareButton>
	);
}

function makeIncrements(buttons) {
	var i = buttons;
	var increment = Style.layout.squareButton + Style.layout.marginSmall;
	var definition = {};

	while(i){
		var size = increment * i;

		definition[size] = {
			container: {
				width: size,
			},
		};

		i--;
	}

	return ResponsiveStylesheet.createSized("min-width", definition);
}
