export default {
	"type": "object",
	"displayDependencies": {
		"fname": {
			"required": "byemail",
			"properties": {
				"byemail": {
					"enum": [false]
				}
			}
		},
		"lname": {
			"required": "byemail",
			"properties": {
				"byemail": {
					"enum": [false]
				}
			}
		},
		"userType": {
			"required": "byemail",
			"properties": {
				"byemail": {
					"enum": [false]
				}
			}
		},
		"email": {
			"required": "byemail",
			"properties": {
				"byemail": {
					"enum": [true]
				}
			}
		}
	},
	"properties": {
		"byemail": {
			"order": 0,
			"type": "boolean",
			"description": "usercreation.byemail",
			"inputType": "switch",
			"default": false
		},
		"fname": {
			"order": 1,
			"type": "string",
			"description": "usercreation.fname",
			"inputType": "input"
		},
		"lname": {
			"order": 2,
			"type": "string",
			"description": "usercreation.lname",
			"inputType": "input"
		},
		"patientNumber": {
			"order": 3,
			"type": "string",
			"description": "usercreation.patientNum",
			"inputType": "input"
		},
		"language": {
			"order": 4,
			"type": "string",
			"description": "usercreation.language",
			"inputType": "dropdown",
			"value_description": "languages",
			"default": "",
			"enum": [
				"en",
				"fr",
				"cn_s",
				"cn_t",
				"es",
				"pt",
				"ta"
			]
		},
		"userType": {
			"order": 5,
			"type": "string",
			"description": "usercreation.userType",
			"inputType": "dropdown",
			"value_description": "usertypeenum",
			"default": "aTouchAway",
			"enum": [
				"aTouchAway",
				"Mobile",
				"Simplified"
			]
		},
		"email": {
			"order": 1,
			"type": "string",
			"description": "usercreation.email",
			"inputType": "input",
			"format": "email"
		},
		"managers": {
			"order": 7,
			"description": "usercreation.defaultmanager",
			"type": "array",
			"items": {
				"type": "string",
				"inputType": "dropdown",
				"source": "staff"
			}
		},
		"disclaimer": {
			"order": 8,
			"type": "string",
			"inputType": "output",
			"displayType": "markdown",
			"value_description": "usercreation.disclaimer"
		},
		"user_note": {
			"order": 6,
			"type": "string",
			"description": "usercreation.user_note",
			"inputType": "input",
			"multiline": true,
			"maxLength": 30,
		},
	}
};