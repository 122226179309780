import React from "react";
import ImageCrop from "../../../ImageCrop";
import Loader from "ae-base/Base/Loader";

import { useResources } from "@aetonix/hooks";

export default function ProfilePictureCrop(props) {
	const {
		dispatch,
	} = useResources();
	var model = props.model;

	var manage = model.manage;
	var personalpicture = manage.picture;
	var image = personalpicture.image;
	var width = personalpicture.width;
	var height = personalpicture.height;
	var orientation = personalpicture.orientation;
	var saving = model.saving;

	if(!image || saving) return <Loader />;

	var goBack = dispatch("managedprofilepicture:trigger:cancel");

	var finishCrop = dispatch("managedprofilepicture:trigger:crop");

	return (
		<ImageCrop
			source={image.uri}
			width={width}
			height={height}
			orientation={orientation}
			onCancel={goBack}
			onConfirm={finishCrop}
		/>
	);
}
