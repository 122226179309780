import React from "react";
import {
	View,
	StyleSheet,
} from "react-native";
import AtaContainer from "../AtaContainer";
import ATouchAwaySidebar from "../ATouchAwaySidebar";

import CareplanWelcome from "./CareplanWelcome";
import SectionList from "./SectionList";
import Appointments from "./Sections/Appointments";
import Careteam from "./Sections/Careteam";
import Client from "./Sections/Client";
import Goals from "./Sections/Goals";
import Healthassessments from "./Sections/Healthassessments";
import Healthconditions from "./Sections/Healthconditions";
import Hospitalvisits from "./Sections/Hospitalvisits";
import Othertreatments from "./Sections/Othertreatments";
import Situation from "./Sections/Situation";
import Supports from "./Sections/Supports";
import Treatments from "./Sections/Treatments";
import About from "../About";
import Reminder from "../Reminder";

var SECTION_MAP = {
	"Appointments": Appointments,
	"CareTeam": Careteam,
	"Client": Client,
	"Goals": Goals,
	"HealthAssessments": Healthassessments,
	"HealthConditions": Healthconditions,
	"HospitalVisits": Hospitalvisits,
	"OtherTreatments": Othertreatments,
	"Situation": Situation,
	"Supports": Supports,
	"Treatments": Treatments
};

var styles = StyleSheet.create({
	content: {
		flex: 1,
		flexDirection: "row",
	}
});

export default function ATouchAwayCareplan (props){
	var model = props.model;
	var careplan = model.careplan;
	var page = model.page;
	var settings = model.settings;
	var content;
	var options = model.options;
	var trunk = options.trunk;
	var reminder = model.reminders || {};
	var missed = model.missed;
	var pending = model.pendingactions;
	var resources = model.resources;
	var status = model.status || {};
	var forms = model.forms;
	var allowedForms = settings.forms_sections || {};

	if(page === "careplan"){
		content = (
			<CareplanWelcome />
		);
	} else {
		var section = careplan.section;
		var SectionComponent = SECTION_MAP[section] || View;
		content = (
			<SectionComponent careplan={careplan.overlay} />
		);
	}

	var renderTrunk = trunk ? <About options={options} /> : null;
	var renderReminder = reminder.active && !reminder.answered ? <Reminder reminder={reminder} /> : null;
	var formsViewable = checkPermissions(forms, allowedForms);

	return (
		<AtaContainer>
			<View style={styles.content}>
				<ATouchAwaySidebar formsViewable={formsViewable} workflowActions={pending} missed={missed} settings={settings} page={"careplan"} resources={resources} status={status} />
				<SectionList section={section} settings={settings} />
				{content}
			</View>
			{renderTrunk}
			{renderReminder}
		</AtaContainer>
	);
}

function checkPermissions(list, permissions){
	return Object.keys(list).reduce(function(acc, element){
		if(permissions[element])
			acc[element] = list[element];
		return acc;
	}, {});
}
