import React, { useCallback } from 'react';
import { ScrollView, StyleSheet, TouchableOpacity, Platform } from 'react-native';
import { createStyled } from '@emotion/primitives-core';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { variant } from 'styled-system';
import ResponsiveText from '../Text/ResponsiveText';
import Style from '@aetonix/styles';

const styled = createStyled(StyleSheet);

const List = styled(ScrollView) ({ width: "100%" });

const ListItem = styled(TouchableOpacity) ({
    padding: 10,
    marginVertical: 1,
    marginHorizontal: 6,
    justifyContent:'flex-start',
    flexDirection:'row',
    borderBottomWidth: 1,
    borderBottomColor: '#e8e8e8',
    paddingHorizontal: 10,
    alignItems: 'center',
});

const ItemTitle = styled(ResponsiveText) ({
    marginLeft: 10,
    color: "#141823",
    fontFamily: 'Avenir',
    fontWeight: '500',
}, variant({
  variants: { isSelected: { color: '#1E77B3' } }
}));

const Item = ({ title, icon, onClick, id, isSelected, textColour, iconColour }) => ( 
    <ListItem onPress={() => onClick && onClick()} key={id} testID={`item ${id}`}>
        <FontAwesomeIcon color={iconColour} size={Style.text.emphasized} icon={icon}/>
        <ItemTitle style={{ color: textColour }} size={Style.text.standard} max={Style.text.large} variant={isSelected ? "isSelected" : null} >{title}</ItemTitle>
    </ListItem>
);

export default ({ options = []}) => {
   
  const renderItem = useCallback(({ item }) => (
	<Item 
		title={item.title}
		icon={item.icon}
		onClick={item.onClick}
		id={item.id}
		isSelected={item.isSelected}
		textColour={item.textColour}
		iconColour={item.iconColour}
	/>)
  );

  return (
      <List>
        { options.map(option => renderItem({ item: option })) }
      </List>
  );
};