import React from "react";

import Modal from "../Layout/Modal";
import Card from "../Layout/Card";
import Form from "ae-forms";
import Heading from "ae-base/Base/Heading";
import FinishAccountSchema from "../../schemas/FinishAccount";
import LicenseCheckBoxSchema from "../../schemas/LicenseCheckBoxSchema";
import PrivacyPolicyCheckBoxSchema from "../../schemas/PrivacyPolicyCheckBoxSchema";
import StyledText from "ae-base/Base/StyledText";

import StyledButton from "ae-base/Base/StyledButton";

import { useResources } from "@aetonix/hooks";

export default function FinishAccount(props) {
	const {
		localize,
		dispatch,
		localizeFrom,
	} = useResources();

	var model = props.model || {};
	var finishAccount = model.finishaccount || {};

	var formSchema = FinishAccountSchema;
	var title = localize("schemas_finish_account_title");

	if (formSchema.localization)
		title += (localizeFrom(formSchema.localization, ".") || formSchema.localization);


	var onSubmit = dispatch("finishaccount:trigger:submit");

	var error = finishAccount.error;

	var licenseagreement = localize("register.licenseagreement");
	const privacyPolicyAgreement = localize("privacy_policy.privacy_policy_agreement");

	return (
		<Modal open>
			<Card scroll>
				<Heading title={title} />
				<StyledText>{"\u2022 " + localize("passwordrules.1")}</StyledText>
				<StyledText>{"\u2022 " + localize("passwordrules.2")}</StyledText>
				<StyledText>{"\u2022 " + localize("passwordrules.3")}</StyledText>
				<StyledText>{"\u2022 " + localize("passwordrules.4")}</StyledText>
				<StyledText>{"\u2022 " + localize("passwordrules.5")}</StyledText>
				<StyledText>{"\u2022 " + localize("passwordrules.7")}</StyledText>
				<StyledText>{"\u2022 " + localize("passwordrules.8")}</StyledText>
				<StyledText>{"\u2022 " + localize("passwordrules.9")}</StyledText>
				<Form
					key={"finishaccount"}
					style={{ flex: 1 }}
					errors={error}
					schema={formSchema}
					onSubmit={onSubmit}
					submitText={localize("callsurvey.send")}
				>
					<StyledButton id="viewlicense" title={licenseagreement} icon="notebook" onPress={dispatch("license:trigger:show")} />
					<Form schema={LicenseCheckBoxSchema} errors={error} autoSubmit={true} onSubmit={dispatch("finishaccount:trigger:checked")} />
					<StyledButton title={privacyPolicyAgreement} onPress={dispatch("privacypolicy:trigger:show")} icon="notebook" />
					<Form schema={PrivacyPolicyCheckBoxSchema} errors={error} autoSubmit={true} onSubmit={dispatch("finishaccount:trigger:checked")} />
				</Form>
			</Card>
		</Modal>
	);
}
