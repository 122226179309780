import PropTypes from "prop-types";

/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";
import {
	View,
} from "react-native";

import ResponsiveStylesheet from "react-native-responsive-stylesheet";
import BackButton from "ae-base/Base/BackButton";

import Style from "ae-style";

import { useResources } from "@aetonix/hooks";

var styles = ResponsiveStylesheet.createSized("min-direction", {
	0: {
		goBack: {
			top: Style.layout.marginLarge,
			left: Style.layout.marginLarge,
			marginBottom: Style.layout.marginLarge,
		}
	},
	512: {
		goBack: {
			marginBottom: 0,
		}
	}
});

export default function LoginBack(props){
	const { localize } = useResources();
	const goback = localize("login.goback");
	const onPress = props.onPress;

	return (
		<View style={styles.goBack}>
			<BackButton contrast title={goback} onPress={onPress} />
		</View>
	);
}