import PropTypes from "prop-types";
import React, {
	PureComponent,
} from "react";
import {
} from "react-native";

import MessageNotification from "./MessageNotification";
import CallNotification from "./CallNotification";
import ConferenceNotification from "./ConferenceNotification";
import BannerNotification from "./BannerNotification";

export default class TimelineNotification extends PureComponent {
	render(){
		var props = this.props;
		var notification = props.notification;
		var notificationtype = notification.type;
		var screen = props.screen;
		const model = props.model;
		var  contacts = props.contacts || [];
		if (notificationtype.indexOf( "call") + 1 && !(notification.type.indexOf( "conference") + 1))

			return (
				<CallNotification clearable notification={notification} screen={screen} contacts={contacts} />
			);

		else if  (notificationtype.indexOf( "conference") + 1 )
			return (
				<ConferenceNotification clearable notification={notification} screen={screen}  contacts={contacts} />
			);

		else if (notificationtype.indexOf( "text") + 1 )
			return (
				<MessageNotification clearable notification={notification} model={model} screen={screen} />
			);

		else if (notificationtype.indexOf( "banner") + 1)
			return (
				<BannerNotification notification={notification} screen={screen} />
			);
		else
			return null;

	}
}