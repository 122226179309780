/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, March 05, 2018
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";

import CardPage from "../../Layout/CardPage";
import StyledText from "ae-base/Base/StyledText";
import Form from "ae-forms";
import debounce from "lodash.debounce";

import Loader from "ae-base/Base/Loader";

import { useResources } from "@aetonix/hooks";

export default function StaffSubmissionCreation(props) {
	const {
		localize,
		dispatch,
		localizeFrom,
	} = useResources();

	var model = props.model;
	var screen = model.size;

	var saving = model.saving;
	var ownformlist = model.ownformlist;
	var formId = model.ownformsubmissions.form;
	var currentForm = ownformlist[formId];
	var notValid = localize("groupforms.notvalid");
	var back = localize("groupforms.back");

	var schema = currentForm.schema;

	var hasSchema = schema && schema.properties;
	if (!hasSchema)
		return (
			<CardPage title={title} icon="notebook" saving={saving} screen={screen} backTitle={back} backAction={goBack}>
				<StyledText smallHeading bold>
					{notValid}
				</StyledText>
			</CardPage>
		);

	var localization = currentForm.localization;
	var schemaTitle = localizeFrom(localization, ".") || currentForm.name || currentForm._id;
	var title = `${schemaTitle} - ${localize("groupforms.addnew")}`;

	const clearPersistStorage = () => {
		dispatch("persiststorage:delete")({
			dataId: formId,
			targetId: model.personal._id,
		});
		schema.persistStorage = undefined;
	};

	const onSubmit = (data) => {
		clearPersistStorage();
		dispatch("activeownformsubmission:trigger:submit")(data);
	};

	const goBack = () => {
		clearPersistStorage();
		dispatch("activeownformsubmission:trigger:cancel")();
	};

	const saveEncryptStorage = (data) => {
		schema.persistStorage = data;
		dispatch("persiststorage:save")({
			dataId: formId,
			targetId: model.personal._id,
			data,
		});
	};

	var discardchanges = localize("groupforms.discardchanges");
	var prepopulateData = model.activeownformprepopulate;

	if (!prepopulateData) return <Loader />;

	const defaultData = { ...prepopulateData, ...schema.persistStorage };

	return (
		<CardPage title={title} icon="notebook" saving={saving} screen={screen} toast={model.toast}>
			<Form
				saveEncryptStorage={debounce((data) => saveEncryptStorage(data), 500)}
				dataId={formId}
				schema={schema}
				defaultData={defaultData}
				onSubmit={onSubmit}
				onCancel={goBack}
				cancelText={discardchanges}
			/>
		</CardPage>
	);
}
