export default {
	title: "CareplanSections",
	type: "object",
	properties: {
		"careplan_sections": {
			"order": 10,
			type: "object",
			"properties": {
				Appointments: {
					order: 4,
					description: "appointments.appointmentsreferrals",
					inputType: "checkbox",
					type: "boolean"
				},
				CareTeam: {
					order: 4,
					description: "careteam.careteam",
					inputType: "checkbox",
					type: "boolean"
				},
				Client: {
					order: 4,
					description: "client.patientidentifiers",
					inputType: "checkbox",
					type: "boolean"
				},
				Goals: {
					order: 4,
					description: "goals.caregoals",
					inputType: "checkbox",
					type: "boolean"
				},
				HealthAssessments: {
					order: 4,
					description: "healthassessments.healthassessment_s",
					inputType: "checkbox",
					type: "boolean"
				},
				HealthConditions: {
					order: 4,
					description: "healthconditions.healthissues",
					inputType: "checkbox",
					type: "boolean"
				},
				HospitalVisits: {
					order: 4,
					description: "hospitalvisits.hospitalvisit_s",
					inputType: "checkbox",
					type: "boolean"
				},
				OtherTreatments: {
					order: 4,
					description: "othertreatments.othertreatments",
					inputType: "checkbox",
					type: "boolean"
				},
				Situation: {
					order: 4,
					description: "situation.situationlifestyle",
					inputType: "checkbox",
					type: "boolean"
				},
				Supports: {
					order: 4,
					description: "supports.supportsservices",
					inputType: "checkbox",
					type: "boolean"
				},
				Treatments: {
					order: 4,
					description: "treatments.allergiesmedications",
					inputType: "checkbox",
					type: "boolean"
				},
			}
		}
	}
};
