
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React, {
	Component
} from "react";
import {
	Keyboard,
	Dimensions,
	View,
} from "react-native";
import ResponsiveStylesheet from "react-native-responsive-stylesheet";
import MainScroll from "./MainScroll";

import Style from "ae-style";

var styles = ResponsiveStylesheet.createSized("min-direction", {
	0: {
		background: {
			backgroundColor: Style.look.background,
		},
		padding: {
			padding: Style.layout.paddingMedium
		},
		margin: {
			margin: Style.layout.marginBordered,
		},
		rounded: {
			borderRadius: 30
		},
		flex0: {
			flex: 0
		},
		minWidth: {
			minWidth: 250
		}
	},
	512: {
		padding: {
			padding: Style.layout.paddingLarge,
		},
		margin: {
			margin: Style.layout.marginContent,
		},
		minWidth: {
			minWidth: 280
		}
	}
});

export default class Card extends Component{
	constructor(props){
		super(props);
		this.mainScrollRef = "_mainScroll";
		this.scrollToTop = scrollToTop.bind(this);
		this.state = {
			myHeight: 0,
		}
	}

	componentDidMount () {
		this.keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', this._keyboardDidShow.bind(this));
		this.keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', this._keyboardDidHide.bind(this));
	}

	componentWillUnmount () {
		this.keyboardDidShowListener.remove();
		this.keyboardDidHideListener.remove();
	}
	
	_keyboardDidShow () {
		this.setState({keyboardOpen: true});
	}
	
	_keyboardDidHide () {
		this.setState({keyboardOpen: false});
	}

	render() {
		var props = this.props;
		var children = props.children;
		var styleList = [];
		var mainScrollRef = this.mainScrollRef;
		var myHeight = this.state.myHeight;

		styleList.push(styles.background, styles.margin, styles.minWidth);

		if(props.rounded)
			styleList.push(styles.rounded);

		var otherStyles = props.style;
		styleList.push(otherStyles);

		const windowHeight = Dimensions.get("window").height;
		const verticalPadding = styles.padding[0].padding;
		const myPaddedHeight = myHeight + (2 * verticalPadding);
		const extraScrollHeight = -(windowHeight/2 - myPaddedHeight/2);

		if(props.scroll) {
			return (
				<View
					onLayout={(event) => this.setState({ myHeight: event.nativeEvent.layout.height})}
					style={styleList}
				>
					<MainScroll
						atouchaway={props.atouchaway}
						ref={mainScrollRef}
						enableResetScrollToCoords={props.enableResetScrollToCoords}
						style={[styles.flex0]}
						extraScrollHeight={extraScrollHeight}
					>
						<View style={styles.padding}>
							{children}
						</View>
					</MainScroll>
				</View>
			);
		} else {
			styleList.push(styles.padding);
			return (
				<View style={styleList}>
					{children}
				</View>
			);
		}
	}
}

function scrollToTop() {
	this.refs[this.mainScrollRef].scrollToTop();
}
