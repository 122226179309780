
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */
import PropTypes from "prop-types";
import React, {
	Component,
} from "react";
import {
	StyleSheet,
	ScrollView,
} from "react-native";

import OfflineBanner from "../../Pages/Homepage/OfflineBanner";

import { useResources } from "@aetonix/hooks";

var styles = StyleSheet.create({
	main: {
		flex: 1,
		flexGrow: 1,
	},
});

class MainScrollComp extends Component {
	constructor(props){
		super(props);
		this.scrollViewRef = "_scrollView";
		this.scrollToTop = scrollToTop.bind(this);
	}

	render() {
		const reconnecting = (this.props.online !== undefined && this.props.online === false);
		return (
			<ScrollView
				accessible={true}
				inputProps={{"tabindex":"0"}}
				style={[styles.main, this.style, this.props.style]}
				onScroll={this.props.timeoutReset}
				ref={this.scrollViewRef}
			>
				{reconnecting && <OfflineBanner />}
				{this.props.children}
			</ScrollView>
		);
	}
}

function scrollToTop() {
	this.refs[this.scrollViewRef].scrollTo({x: 0, y: 0, animated: true});
}


export default function MainScroll(props){
	const { timeoutReset } = useResources();
	return (
		<MainScrollComp timeoutReset={timeoutReset} {...props} />
	);
}
