export default {
	title: "Finish Account",
	description: "schemas_finish_account_title",
	type: "object",
	properties: {
		email: {
			order: 2,
			description: "register.email",
			type: "string",
			inputType: "text",
			maxLength: 254
		},
		password: {
			order: 3,
			description: "register.password",
			type: "string",
			inputType: "text",
			format: "password"
		},
		password_confirm: {
			order: 4,
			description: "register.password_confirm",
			type: "string",
			inputType: "text",
			format: "password"
		},
	}
};
