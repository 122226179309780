import React, { Component } from 'react';
import CallingError from './components/CallingError';
import * as Sentry from '@sentry/react-native';

const TIMER_DEFAULT = 10;

export default class MeetingErrorBoundary extends Component {

    constructor (props) {
        super(props);

        this.interval = null;

        this.state = {
            seconds: TIMER_DEFAULT,
            error: null,
            hasError: false,
        };
    }

    static getDerivedStateFromProps (error) {
        return { hasError: true , error };
    }

    componentDidUpdate(prevProps, prevState) {
        const { seconds } = prevState ;
        const { error: prevError } = prevProps;
        const { error: nextError, onAfterError } = this.props;

        const isTimerFinishing = seconds === 0;  
        if (isTimerFinishing) {
            this.setState({ error: null, seconds: TIMER_DEFAULT }, () => {
                clearInterval(this.interval);
                onAfterError?.();
            });
        }

        const isNewError = !!nextError && (nextError !== prevError);
        if (isNewError) {
            clearInterval(this.interval);

            const createCountdownInterval = () => {
                this.interval = setInterval(() => this.setState(prevState => ({ ...prevState, seconds: --prevState.seconds })), 1000)
            };

            // Ignore this since its not a real error
            if (nextError?.type !== 'adminEndedCall') {
                Sentry.captureException(nextError);
            }

            this.setState({ error: nextError }, () => createCountdownInterval());
        }
    }

    componentDidCatch (error, _errorInfo) {
        if (error) {
            Sentry.captureException(error);
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render () {
        const { seconds } = this.state;
        const { children, error, onAfterError } = this.props;

        return (
            <React.Fragment>
                { children }
                { !!error && (<CallingError
                    onClick={onAfterError}
                    seconds={seconds}
                    type={error?.type ?? 'default'}

                />)}
            </React.Fragment>
        );
    }
}