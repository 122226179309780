export default {
	title: "Search",
	description: "search_title",
	type: "object",
	properties: {
		managee: {
			type: "string",
			inputType: "hidden"
		},
		query: {
			order: 0,
			description: "request.enter_email",
			type: "string",
			inputType: "text",
			format: "email",
		},
		language: {
			order: 1,
			description: "request.select_language",
			value_description: "languages",
			type: "string",
			inputType: "dropdown",
			enum: [
				"en",
				"fr",
				"cn_s",
				"cn_t",
				"es",
				"pt",
				"ta"
			],
		}
	}
};
