import React from "react";

import ManagedSection from "../ManagedSection";
import DeviceItem from "../../../Devices/DeviceItem";

import ListAdd from "ae-base/List/ListAdd";

import { useResources } from "@aetonix/hooks";

export default function Devices(props) {
	const {
		localize,
		dispatch,
	} = useResources();

	var model = props.model;
	var manage = model.manage;
	var account = manage.account;
	var permissions = manage.permissions;
	var devicesList = manage.devices.list || [];
	var toast = model.toast;

	var isMobile = account.type.indexOf("user:mobile") !== -1;

	var canEdit = false;

	if(!isMobile && permissions.devices && permissions.devices >= 2) canEdit = true;

	var devicesTitle = localize("devices.title");
	var addDevice = localize("devices.add") || "Add a new device";

	var renderDevicesList = devicesList.map(function(device) {
		var onClick = canEdit ? dispatch("manageddevices:trigger:update:start", {
			device: device,
		}) : null;
		return (
			<DeviceItem key={device._id} device={device} onClick={onClick} />
		);
	});

	var add = dispatch("manageddevices:trigger:add:start");
	var renderAdd = canEdit ? (
		<ListAdd onPress={add} title={addDevice} />
	) : null;

	return (
		<ManagedSection online={model.online} title={devicesTitle} contact={account} toast={toast}>
			{renderDevicesList}
			{renderAdd}
		</ManagedSection>
	);
}
