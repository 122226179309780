
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";
import {
	View,
	StyleSheet,
} from "react-native";

import StyledText from "ae-base/Base/StyledText";
import StyledIconButton from "ae-base/Base/StyledIconButton";
import Style from "ae-style";
import Breakpoints from "../../../Breakpoints";
import BackButton from "ae-base/Base/BackButton";
import ButtonSpacer from "ae-base/Base/ButtonSpacer";

import { useResources } from "@aetonix/hooks";

var styles = StyleSheet.create({
	backButton: {
		padding: Style.layout.paddingSmall,
	},
	container: {
		backgroundColor: Style.colors.primary,
		flex: 2,
		paddingTop: Style.layout.paddingSlim,
	},
	editContainer: {
		flexDirection: "row",
		padding: Style.layout.paddingSmall,
		paddingLeft: Style.layout.paddingMedium,
	},
	iconStyle: {
		flexDirection: "row",
		flex: 1,
		justifyContent: "flex-end",
	},
	leftAligned: {
		backgroundColor: Style.colors.primary,
		flex: 2,
		paddingTop: Style.layout.paddingSlim,
	},
	mainContainer: {
		backgroundColor: Style.colors.primary,
		flex: 2,
		paddingHorizontal: Style.layout.paddingMedium,
		paddingTop: Style.layout.paddingSlim,
	},
	mainContent: {
		alignItems: "center",
		backgroundColor: Style.colors.primary,
		flexDirection: "row",
		padding: Style.layout.paddingSmall,
	},
});

export default function GroupFormHeader(props) {
	const {
		localize,
		dispatch,
	} = useResources();

	var isMobile = Breakpoints.smallerThan("mobile");
	var defaultTitle = localize("groupforms.title");
	var title = props.title || defaultTitle;
	var edit = props.edit;
	var backText = localize("common.back");
	var subTitle = props.subTitle;
	var additionalTitles = props.additionalTitles;

	var renderSubTitle = subTitle ? (
		<StyledText emphasized centered={!isMobile} contrast>{subTitle}</StyledText>
	) : null;

	var renderAdditionalTitles = additionalTitles && Array.isArray(additionalTitles) ? additionalTitles.map(e => (
		<StyledText standard centered={!isMobile} contrast>{e}</StyledText>
	)) : null;

	if (isMobile) return (
		(<View style={styles.mainContent}>
			<StyledIconButton clear contrast smallHeading icon="left" style={styles.backButton} onPress={dispatch("desktop:trigger:page:back")} />
			<View style={styles.leftAligned}>
				<StyledText smallHeading bold contrast>{title}</StyledText>
				{renderSubTitle}
				{renderAdditionalTitles}
			</View>
			<View style={styles.iconStyle}>
				{edit}
				<ButtonSpacer />
			</View>
		</View>)
	);
	else return (
		<View style={styles.mainContainer}>
			<BackButton title={backText}>
				<View style={styles.editContainer}>
					{edit}
					<ButtonSpacer />
				</View>
			</BackButton>
			<View style={styles.mainContent}>
				<View style={styles.container}>
					<StyledText centered smallHeading bold contrast>{title}</StyledText>
					{renderSubTitle}
					{renderAdditionalTitles}
				</View>
			</View>
		</View>
	);
}
