import React, {
	PureComponent,
} from "react";
import {
	TouchableWithoutFeedback,
	Image,
	Dimensions,
	StyleSheet,
	View,
} from "react-native";

import StyledButton from "ae-base/Base/StyledButton";

import Style from "ae-style";
import { useResources } from "@aetonix/hooks";

var styles = StyleSheet.create({
	downloadButton: {
		position: "absolute",
		bottom: 0,
		left: 0,
		right: 0,
		zIndex: 2,
		padding: Style.layout.paddingSmall,
		backgroundColor: "rgba(0,0,0,0.7)",
		alignItems: "flex-start",
	},
	closeButton: {
		position: "absolute",
		top: 0,
		right: 0,
		left: 0,
		zIndex: 2,
		padding: Style.layout.paddingSmall,
		backgroundColor: "rgba(0,0,0,0.7)",
		alignItems: "flex-end",
	},
	container: {
		width: "100%",
		height: "100%",
		justifyContent: "center",
		alignItems: "center",
	},
	hiddenButtons: {
		opacity: 0,
	},
});

export default (props) => {
	const { dispatch } = useResources();
	return <ImageViewer dispatch={dispatch} {...props} />;
}

class ImageViewer extends PureComponent{
	constructor(props){
		super(props);
		this.state = {
			width: null,
			height: null,
			opacity: true,
		};
		this.successSize = successSize.bind(this);
		this.fadeButtons = fadeButtons.bind(this);
	}
	render(){
		var props = this.props;
		const dispatch = props.dispatch;
		var file = props.file;
		var noDownload = props.noDownload;

		var dimensions = Dimensions.get("window");
		var screenWidth = dimensions.width;
		var screenHeight = dimensions.height;

		Image.getSize(file.url, this.successSize);

		var imageHeight = this.state.height;
		var imageWidth = this.state.width;
		var style = {
			height: screenHeight,
			width: screenWidth,
			maxWidth: imageWidth + 100,
			maxHeight: imageHeight + 100,
			zIndex: 1,
		};
		var source = {
			uri: file.url,
		};
		var download = dispatch("linkopener:trigger:open", {
			uri: file.url,
		});

		var closeButtonStyle = [styles.closeButton];
		var downloadButtonStyle = [styles.downloadButton];
		if(!this.state.opacity){
			closeButtonStyle.push(styles.hiddenButtons);
			downloadButtonStyle.push(styles.hiddenButtons);
		}

		var renderDownloadButton = noDownload ? null : (
			<View style={downloadButtonStyle}>
				<StyledButton clear contrast largeHeading icon="download" onPress={download} />
			</View>
		);

		var close = dispatch("fileviewer:trigger:close");

		return (
			<View style={styles.container}>
				<View style={closeButtonStyle}>
					<StyledButton clear contrast largeHeading icon="delete" onPress={close} />
				</View>
				<TouchableWithoutFeedback onPress={this.fadeButtons}>
					<Image resizeMode="contain" style={style} source={source} />
				</TouchableWithoutFeedback>
				{renderDownloadButton}
			</View>
		);
	}
}

function successSize(width, height){
	this.setState({
		width: width,
		height: height,
	});
}

function fadeButtons(){
	this.setState({
		opacity: !this.state.opacity,
	});
}