import React from "react";

import StyledText from "ae-base/Base/StyledText";
import StyledButton from "ae-base/Base/StyledButton";
import BlockView from "ae-base/Base/BlockView";

import ScheduleItem from "ae-base/List/ListItem";
import ScheduleIcon from "ae-base/List/ListIcon";
import ScheduleContent from "ae-base/List/ListContent";
import { useIdPrefix, useResources } from "@aetonix/hooks";

export default function Schedules(props){
	const { dispatch, localize } = useResources();
	const idPrefix = useIdPrefix();

	var scheduleList = props.scheduleList;
	var titleaction = scheduleList.action;
	var titlekey = "schedule.action." + [titleaction];
	var title = localize(titlekey);
	var scheduledTime = scheduleList.schedule;

	var remove = dispatch("schedules:trigger:remove", {
		action: scheduleList.action,
		schedule: scheduleList.schedule,
	});

	var removeButton = localize("common.remove");

	return(
		<ScheduleItem>
			<ScheduleContent>
				<ScheduleIcon name="calendar" />
				<BlockView>
					<StyledText>{scheduledTime}</StyledText>
					<StyledText bold>{title}</StyledText>
				</BlockView>
			</ScheduleContent>
			<StyledButton id={idPrefix + "scheduleRemove"} title={removeButton} icon="ban" onPress={remove} />
		</ScheduleItem>
	);
}
