import React from "react";
import {
	View,
	Image,
	StyleSheet,
	TouchableOpacity,
} from "react-native";
import StyledText from "ae-base/Base/StyledText";
import Style from "ae-style";
import BraceletIcon from "../BraceletIcon";

import LOGO_SRC from "../../../Resources/img/Aetonix_icon.png";

import { useResources } from "@aetonix/hooks";

var styles = StyleSheet.create({
	header: {
		padding: Style.layout.paddingSmall,
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		backgroundColor: "#484848",
	},
	centered: {
		alignItems: "center",
	},
	row: {
		alignItems: "center",
		flexDirection: "row",
	},
	weatherIcon: {
		width: Style.text.largeHeading,
		height: Style.text.largeHeading,
		marginLeft: Style.layout.marginSmall,
	},
	logoImage: {
		width: Style.imageSize.mini,
		height: Style.imageSize.mini,
		marginRight: Style.layout.marginSmall,
	},
});

export default function Header(props){
	var footer = props.footer;
	var personal = props.personal;
	var bracelet = props.bracelet || {};

	var renderBraceletIcon = bracelet.exists !== false ? (
		<BraceletIcon bracelet={bracelet} icon="bluetooth" />
	) : (
		null
	);

	return (
		<View style={styles.header}>
			<Logo />
			{renderBraceletIcon}
			<Date footer={footer} />
			<Weather footer={footer} />
			<Name personal={personal} />
		</View>
	);
}

function Logo(props) {
	const {
		dispatch,
	} = useResources();

	var openAbout = dispatch("about:trigger:open");
	return (
		<TouchableOpacity style={styles.row} onPress={openAbout}>
			<Image style={styles.logoImage} source={LOGO_SRC} />
			<StyledText contrast emphasized>
				{"aTouchAway"}
			</StyledText>
		</TouchableOpacity>
	);
}

function Date(props) {
	var footer = props.footer;
	return (
		<View style={styles.centered}>
			<StyledText contrast emphasized>{footer.date}</StyledText>
			<StyledText contrast emphasized>{footer.time}</StyledText>
		</View>
	);
}

function Weather(props) {
	var footer = props.footer;
	var icon = {
		uri: footer.temperature_icon,
	};

	return (
		<View style={styles.centered}>
			<View style={styles.row}>
				<StyledText contrast emphasized>{footer.temperature}</StyledText>
				<Image style={styles.weatherIcon} source={icon} />
			</View>
			<StyledText contrast emphasized>{footer.season}</StyledText>
		</View>
	);
}

function Name(props) {
	var personal = props.personal;
	var name = personal.lname + ", " + personal.fname;

	return (
		<StyledText contrast emphasized>{name}</StyledText>
	);
}
