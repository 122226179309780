export default {
	title: "Login",
	type: "object",
	properties: {
		password: {
			type: "string",
			format: "password",
			inputType: "text",
			placeholder: "atouchawayabout.password"
		}
	}
};
