import PropTypes from "prop-types";
import React from "react";

import Form from "ae-forms";
import FullInfoSchema from "../../../schemas/FullInfo";
import ManagedSection from "./ManagedSection";
import ProfilePictureChoose from "./ProfilePicture/ProfilePictureChoose";

import Divider from "../../Layout/Divider";
import { useResources } from "@aetonix/hooks";
export default function Managedprofile(props) {
	const {
		localize,
		dispatch,
	} = useResources();

	var model = props.model;
	var toast = model.toast;
	var preferences = model.manage.preferences.data;
	var contact = model.manage.account;

	var screen = model.size;

	var personalinformation = localize("managedprofile.personalinformation");

	var loadedKey = Object.keys(preferences || {}).length;

	return (
		<ManagedSection key={loadedKey}
			contact={contact}
			title={personalinformation}
			saving={model.saving}
			online={model.online}
			toast={toast}>
			<Form schema={FullInfoSchema} defaultData={preferences} onSubmit={dispatch("managedpreferences:trigger:update")} />
			<Divider style={{marginTop: 10}} />
			<ProfilePictureChoose account={contact} screen={screen} model={model} />
		</ManagedSection>
	);
}

Managedprofile.propTypes = {
	model: PropTypes.object,
};
