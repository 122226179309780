import React from "react";
import {
	View,
	StyleSheet,
} from "react-native";
import AtaContainer from "../AtaContainer";
import About from "../About";
import Reminder from "../Reminder";
import FormsList from "./FormsList";
import FormSubmission from "./FormSubmission";
import SubmissionsList from "./SubmissionsList";
import StyledText from "ae-base/Base/StyledText";

import { useResources } from "@aetonix/hooks";

var styles = StyleSheet.create({
	content: {
		flex: 1,
		flexDirection: "row",
	},
});

export default function ATouchAwayForms(props) {
	const {
		localize,
		localizeFrom,
	} = useResources();

	var model = props.model;
	var settings = model.settings;
	var options = model.options;
	var trunk = options.trunk;
	var reminder = model.reminders || {};
	var orderedForms = model.orderedforms;
	var forms = model.forms;
	var data = model.formsubmissions;
	var allowedForms = settings.forms_sections || {};
	var activeSubmission = model.activeformsubmission;
	var activeViewing = data.form;
	var activeSubmissionViewing = activeSubmission ? activeSubmission._id : "";
	var currentForm = allowedForms[data.form] ? forms[data.form] : null;
	if(!currentForm) data.form = null;

	var renderTrunk = trunk ? <About options={options} /> : null;
	var renderReminder = reminder.active && !reminder.answered ? <Reminder reminder={reminder} /> : null;

	var submissionData = data.form && (currentForm.formType === "multi") ? activeSubmission : data.submissions[0];

	var renderSubmissionList = (
		<SubmissionsList submissions={data.submissions} active={activeSubmissionViewing} />
	);

	var formsViewable = checkPermissions(orderedForms, allowedForms, forms);
	const renderFormList = (
		<FormsList forms={formsViewable} active={activeViewing}/>
	);

	let renderSubmission = null;
	if (submissionData && data.form)
		renderSubmission = (
			<FormSubmission
				schema={currentForm.schema}
				data={submissionData.data}
				title={localizeFrom(currentForm.localization, ".")}
				isATouchAway
			/>);
	else if (data.form && currentForm.formType !== "multi")
		renderSubmission = <NoFormData localize={localize}/>;


	var renderList = data.form && (currentForm.formType === "multi") ? renderSubmissionList : renderFormList;

	return (
		<AtaContainer>
			<View style={styles.content}>
				{renderList}
				{renderSubmission}
			</View>
			{renderTrunk}
			{renderReminder}
		</AtaContainer>
	);
}

function NoFormData(props) {
	var localize = props.localize;
	return (
		<View style={{flex: 1, alignItems: "center", height: "100%", justifyContent: "center"}}>
			<StyledText bold emphasized style={{alignSelf: "center", color:"#4A959B"}}>
				{localize("atouchawayforms.notfilledyet", ".")}
			</StyledText>
		</View>
	);
}

function checkPermissions(list, permissions, forms){
	return list.reduce(function(acc, element){
		if(permissions[element] && forms[element])
			acc[element] = forms[element];
		return acc;
	}, {});
}
