import React from "react";
import {
	StyleSheet,
	View,
} from "react-native";

import StyledText from "ae-base/Base/StyledText";
import StyledButton from "ae-base/Base/StyledButton";
import Style from "@aetonix/styles";

import { useResources } from "@aetonix/hooks";

var styles = StyleSheet.create({
	version: {
		marginTop: Style.layout.marginContentVertical,
		alignItems: "center",
		justifyContent: "center",
	},
	region: {
		flexDirection: "row",
	},
});

export default function VersionNumber(props) {
	const { config, dispatch, model, localize } = useResources();
	const selectedLanguage = model?.language ||  model?.personal?.language || "en";
	const localization = config.localization;
	const showRegionChange = props.showRegionChange;
	const black = props.black;
	const changeregion = localize("versionnumber.changeregion");
	const localizedRegions = localization[selectedLanguage]?.hosts || {};
	let regionName = localizedRegions[config.name] || config.name;

	let renderRegionChange = null;
	if (showRegionChange) {
		renderRegionChange = (
			<StyledButton contrast small clear title={changeregion} onPress={dispatch("app:trigger:changeregion")} />
		);
		regionName = regionName + " - ";
	}
	if (black)
		return (
			<View style={styles.version}>
				<StyledText black small>
					{"v" + config.version}{" - #"}{config.build}{" "}{config.type}
				</StyledText>
				<View style={styles.region}>
					<StyledText black small>{regionName}</StyledText>
					{renderRegionChange}
				</View>
			</View>
		);
	else
		return (
			<View style={styles.version}>
				<StyledText grey small>
					{"v" + config.version}{" - #"}{config.build}{" "}{config.type}
				</StyledText>
				<View style={styles.region}>
					<StyledText grey small>{regionName}</StyledText>
					{renderRegionChange}
				</View>
			</View>
		);

}
