
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 16, 2018
 * For information or permission request, email info@aetonixsystems.com
 */

export default {
	title: "Body temperature",
	description: "glucose.manual_entry",
	type: "object",
	properties: {
		fahrenheit: {
			type: "number",
			description: "bodytemperature.temperaturedescriptionFahrenheit",
			placeholder: "bodytemperature.messageFahrenheit",
			minimum: 32,
			maximum: 140
		},
		comment: {
			description: "bodytemperature.commenttitle",
			type: "string",
			multiline: true,
			placeholder: "bodytemperature.comment"
		}
	},
	required: ["fahrenheit"]
};
