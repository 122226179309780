import React from "react";
import {
	View,
	StyleSheet,
	Image,
} from "react-native";

import StyledText from "ae-base/Base/StyledText";
import VideoView from "../../../../Calling/VideoView";
import ConferenceInviter from "../ConferenceInviter";
import CallButton from "./CallButton";
import ImageUrl from "ae-base/Base/ImageUrl";
import Style from "ae-style";

import prop from "prop";
import { useResources } from "@aetonix/hooks";
var getVideoInputs = prop("input.sources.video.length");

var styles = StyleSheet.create({
	callingPage: {
		flex: 1,
		flexDirection: "row",
	},
	callimage: {
		flex: 8.5,
	},
	conferenceText: {
		flex: 8.5,
		justifyContent: "center",
		padding: Style.layout.paddingSmall,
	},
	streamName: {
		flex: 1,
		justifyContent: "flex-end",
		alignItems: "center",
	},
	contactImage: {
		position: "absolute",
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
	},
	threecallbutton: {
		flex: 1.5,
		justifyContent: "space-between",
	},
	onecallbutton: {
		flex: 1.5,
		justifyContent: "flex-end",
	},
	localStreamView: {
		flex: 0.5,
	},
});

export default function Connected(props) {
	const {
		localize,
		dispatch,
		config,
	} = useResources();
	var calling = props.calling;
	var conferenceinviter = props.conferenceinviter;
	var inviteOpen = conferenceinviter.open;
	var voiceOnly = calling.streams.voiceOnly;
	var availability = props.availability;
	var callinputswitcher = props.callinputswitcher;

	var stream = calling.streams.remote;
	var who = calling.who.lname + ", " + calling.who.fname;
	var conferenceWho = calling.conference.lname + ", " + calling.conference.fname;
	var localStream = calling.streams.local;
	var conferenceState = calling.conference_state;
	var conferenceConnectingText = localize("atouchawaycalling.connectingto", calling.conference);
	var profileImage = (calling.who.image_medium || calling.who.image);
	var callButtonLayout = styles.threecallbutton;

	if(conferenceState === "idle")
		var renderConferenceStream = null;
	else if(conferenceState === "calling" || conferenceState === "connecting")
		renderConferenceStream = (
			<View style={styles.conferenceText}>
				<StyledText contrast bold>{conferenceConnectingText}</StyledText>
			</View>
		);
	else if(conferenceState === "connected"){
		var conferenceStream = calling.streams.conference;
		renderConferenceStream = (
			<View style={styles.callimage}>
				<VideoView stream={conferenceStream} />
				<View style={styles.streamName}>
					<StyledText bold contrast>{conferenceWho}</StyledText>
				</View>
			</View>
		);
	}

	var renderLocalStream = voiceOnly ? (
		<Image source={ImageUrl(profileImage, config)} style={styles.contactImage} />
	) : (
		<VideoView stream={stream} />
	);

	var hangupAction = dispatch("calling:trigger:hangup", {
		reason: "hangup",
	});
	var inviteButton = dispatch("conferenceinviter:trigger:open");
	var hangUpText = localize("atouchawaycalling.endcall");
	var inviteText = localize("atouchawaycalling.invite");

	var isConference = (conferenceState === "connected" || conferenceState === "connecting" || conferenceState === "calling");
	if(isConference){
		var renderConferenceButton = null;
		callButtonLayout = styles.onecallbutton;
	}else
		renderConferenceButton = (
			<CallButton
				invite
				icon={"plus"}
				buttonAction={inviteButton}
				buttonText={inviteText}
			/>
		);


	var hasVideo = getVideoInputs(callinputswitcher) > 1;
	var canSwitch = hasVideo && (!voiceOnly);
	var onChangeCamera = dispatch("callinputswitcher:trigger:toggle");
	var changecamera = localize("atouchawaycalling.changecamera");
	var renderSwitchCameraButton = canSwitch ? (
		<CallButton
			invite
			icon={"aeto-camera_flip"}
			buttonAction={onChangeCamera}
			buttonText={changecamera}
		/>
	) : null;

	var renderCalling = inviteOpen ? (
		<ConferenceInviter conferenceinviter={conferenceinviter} voiceOnly={voiceOnly} availability={availability} />
	) : (
		<View style={styles.callingPage}>
			<View>
				<View style={styles.localStreamView}>
					<VideoView stream={localStream} muted />
				</View>
				<View style={callButtonLayout}>
					{renderConferenceButton}
					{renderSwitchCameraButton}
					<CallButton
						hangup
						icon={"aeto-hang_up-2"}
						buttonAction={hangupAction}
						buttonText={hangUpText}
					/>
				</View>
			</View>
			<View style={styles.callimage}>
				<View style={styles.streamName}>
					{renderLocalStream}
					<StyledText bold contrast>{who}</StyledText>
				</View>
			</View>
			{renderConferenceStream}
		</View>
	);

	return (
		<View style={styles.callingPage}>
			{renderCalling}
		</View>
	);
}
