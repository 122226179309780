import React from "react";
import {
	View,
} from "react-native";
import prop from "prop";

import Items from "../Items";
import Item from "../Item";
import Label from "../Label";
import Value from "../Value";
import Summarized from "../Summarized";
import SectionTitle from "../SectionTitle";
import GroupItems from "../GroupItems";
import StyledText from "ae-base/Base/StyledText";

import { useResources } from "@aetonix/hooks";

var getEffect = prop("MedicationChangeSubjectiveEffect.Name");
var getMedicationAids = prop("MedicationAids.Name");
var getDrugName = prop("Drug.Name");
var getPharmacyName = prop("Pharmacy.Name");
var getNotes = prop("Notes");
var getMedicationReconciliationDate = prop("MedicationReconciliationDate");
var getMedicationReconciliationPerformedBy = prop("MedicationReconciliationPerformedBy");
var getMedicationChangeNote = prop("MedicationChangeNote");
var getMedicationChallengesNote = prop("MedicationChallengesNote");
var getTreatmentAllergy = prop("Allergy");
var getNoKnownAllergies = prop("NoKnownAllergies");
var getPrescription = prop("Prescription");

var getSubstance = prop("Substance");
var getAllergy = prop("AllergyCategory.Name");
var getSeverity = prop("Severity.Name");
var getSymptoms = prop("Symptoms.Name");
var getRoute = prop("RouteCoded.Name");
var getRouteOther = prop("RouteOther");

var getStrength = prop("Strength");
var getFrequency = prop("Frequency");
var getReason = prop("Reason");
var getStartDate = prop("StartDate");
var getChangeDate = prop("ChangeDate");
var getPrescriber = prop("Prescriber");

export default function Treatments(props) {
	const {
		localize,
	} = useResources();

	var careplan = props.careplan;
	var currentSection = props.currentSection;
	var permissions = props.permissions;
	var changes = props.changes;
	var showCareplanDetail = props.showCareplanDetail;
	var ownCareplan = props.ownCareplan;

	var allergiesandintolerances = localize("treatments.allergiesandintolerances");
	var medications = localize("treatments.medications");
	var lastmedicationreconcilliation = localize("treatments.lastmedicationreconcilliation");
	var performedby = localize("treatments.performedby");
	var mylastmedicationchangewas = localize("treatments.mylastmedicationchangewas");
	var itmademefeel = localize("treatments.itmademefeel");
	var aidsiusetotakemymedications = localize("treatments.aidsiusetotakemymedications");
	var challengesihavetakingmedications = localize("treatments.challengesihavetakingmedications");
	var prescription_s = localize("treatments.prescription");
	var notes = localize("treatments.notes");
	var noknownallergies = localize("treatments.noknownallergies");

	var hasMedicationPermission = permissions >= 2;

	var allergies = getTreatmentAllergy(careplan) || [];
	var renderAllergy = allergies.map(function(allergy, index){
		var isChanged = changes["Allergy." + index] || index === -1;
		var hasChanged = permissions >= 2 && isChanged;
		return (
			<Allergy key={index} allergy={allergy} index={index} currentSection={currentSection} hasChanged={hasChanged} ownCareplan={ownCareplan} showCareplanDetail={showCareplanDetail} />
		);
	}
	);

	if(getNoKnownAllergies(careplan) === "true" || !allergies.length)
		renderAllergy = (
			<StyledText>{noknownallergies}</StyledText>
		);


	var durg = getPrescription(careplan) || [];
	var renderDurg = durg.map(function(prescription, index){
		var isChanged = changes["Prescription." + index] || index === -1;
		var hasChanged = permissions >= 2 && isChanged;
		return (
			<Prescription key={index} prescription={prescription} index={index} currentSection={currentSection} hasChanged={hasChanged} ownCareplan={ownCareplan} showCareplanDetail={showCareplanDetail} />
		);
	});

	return (
		<View>
			<SectionTitle name={allergiesandintolerances} section="Allergy" permissions={permissions} changes={changes} />
			{renderAllergy}
			<SectionTitle name={medications} />
			<Items hasChanged={hasMedicationPermission && changes.MedicationReconciliationDate} data={"MedicationReconciliationDate"}>
				<Item>
					<Label>{lastmedicationreconcilliation}</Label>
					<Value>{getMedicationReconciliationDate(careplan)}</Value>
				</Item>
			</Items>

			<Items hasChanged={hasMedicationPermission && changes.MedicationReconciliationPerformedBy} data={"MedicationReconciliationPerformedBy"}>
				<Item alternate>
					<Label>{performedby}</Label>
					<Value>{getMedicationReconciliationPerformedBy(careplan)}</Value>
				</Item>
			</Items>

			<Items hasChanged={hasMedicationPermission && changes.MedicationChangeNote} data={"MedicationChangeNote"}>
				<Item>
					<Label>{mylastmedicationchangewas}</Label>
					<Value>{getMedicationChangeNote(careplan)}</Value>
				</Item>
			</Items>

			<Items hasChanged={hasMedicationPermission && changes.MedicationChangeSubjectiveEffect} data={"MedicationChangeSubjectiveEffect"}>
				<Item alternate>
					<Label>{itmademefeel}</Label>
					<Value>{getEffect(careplan)}</Value>
				</Item>
			</Items>

			<Items hasChanged={hasMedicationPermission && changes.MedicationAids} data={"MedicationAids"}>
				<Item>
					<Label>{aidsiusetotakemymedications}</Label>
					<Value>{getMedicationAids(careplan)}</Value>
				</Item>
			</Items>

			<Items hasChanged={hasMedicationPermission && changes.MedicationChallengesNote} data={"MedicationChallengesNote"}>
				<Item alternate>
					<Label>{challengesihavetakingmedications}</Label>
					<Value>{getMedicationChallengesNote(careplan)}</Value>
				</Item>
			</Items>

			<SectionTitle name={prescription_s} section="Prescription" permissions={permissions} changes={changes} />
			{renderDurg}
			<SectionTitle name={notes} />

			<Items hasChanged={hasMedicationPermission && changes.Notes} data={"Notes"}>
				<Item alternate>
					<Label>{notes}</Label>
					<Value>{getNotes(careplan)}</Value>
				</Item>
			</Items>
		</View>
	);
}

function Allergy(props){
	const { localize } = useResources();
	var allergy = props.allergy;
	var index = props.index;
	var currentSection = props.currentSection;
	var hasChanged = props.hasChanged;
	var showCareplanDetail = props.showCareplanDetail;
	var ownCareplan = props.ownCareplan;

	var symptoms = getSymptoms(allergy) || "";
	if(symptoms.indexOf("Other") !== -1)
		symptoms = allergy.SymptomsOther;

	var substance = localize("treatments.substance");
	var allergyorintolerance = localize("treatments.allergyorintolerance");
	var symptom_s = localize("treatments.symptoms");
	var severity = localize("treatments.severity");

	return (
		<GroupItems>
			<Items hasChanged={hasChanged} data={"Allergy." + index}>
				<Item>
					<Label>{substance}</Label>
					<Value>{getSubstance(allergy)}</Value>
				</Item>

				<Summarized currentSection={currentSection} section={"Allergy." + index} ownCareplan={ownCareplan} showCareplanDetail={showCareplanDetail}>
					<Item alternate>
						<Label>{allergyorintolerance}</Label>
						<Value>{getAllergy(allergy)}</Value>
					</Item>

					<Item>
						<Label>{symptom_s}</Label>
						<Value>{symptoms}</Value>
					</Item>

					<Item alternate>
						<Label>{severity}</Label>
						<Value>{getSeverity(allergy)}</Value>
					</Item>
				</Summarized>
			</Items>
		</GroupItems>
	);
}

function Prescription(props){
	const { localize } = useResources();
	var prescription = props.prescription;
	var index = props.index;
	var currentSection = props.currentSection;
	var hasChanged = props.hasChanged;
	var showCareplanDetail = props.showCareplanDetail;
	var ownCareplan = props.ownCareplan;

	var drugname = localize("treatments.drugname");
	var dose = localize("treatments.dose");
	var route = localize("treatments.route");
	var direction = localize("treatments.direction");
	var reason = localize("treatments.reason");
	var pharmacy = localize("treatments.pharmacy");
	var startdate = localize("treatments.startdate");
	var changedate = localize("treatments.changedate");
	var prescriber = localize("treatments.prescriber");

	var routeValue = getRouteOther(prescription) || getRoute(prescription) || "";

	return (
		<GroupItems>
			<Items hasChanged={hasChanged} data={"Prescription." + index}>
				<Item>
					<Label>{drugname}</Label>
					<Value>{getDrugName(prescription)}</Value>
				</Item>

				<Summarized currentSection={currentSection} section={"Prescription." + index} ownCareplan={ownCareplan} showCareplanDetail={showCareplanDetail}>
					<Item alternate>
						<Label>{dose}</Label>
						<Value>{getStrength(prescription)}</Value>
					</Item>

					<Item>
						<Label>{route}</Label>
						<Value>{routeValue}</Value>
					</Item>

					<Item alternate>
						<Label>{direction}</Label>
						<Value>{getFrequency(prescription)}</Value>
					</Item>

					<Item>
						<Label>{reason}</Label>
						<Value>{getReason(prescription)}</Value>
					</Item>

					<Item alternate>
						<Label>{pharmacy}</Label>
						<Value>{getPharmacyName(prescription)}</Value>
					</Item>

					<Item>
						<Label>{startdate}</Label>
						<Value>{getStartDate(prescription)}</Value>
					</Item>

					<Item alternate>
						<Label>{changedate}</Label>
						<Value>{getChangeDate(prescription)}</Value>
					</Item>

					<Item>
						<Label>{prescriber}</Label>
						<Value>{getPrescriber(prescription)}</Value>
					</Item>
				</Summarized>
			</Items>
		</GroupItems>
	);
}
