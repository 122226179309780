import React, { useContext } from "react";
import { useResources } from "@aetonix/hooks";
export const LocalizeContext = React.createContext(() => {});

export const LocalizeProvider = (props) => {
	const { localize } = useResources();
	return <LocalizeContext.Provider {...props} value={localize} />;
};

export const LocalizeConsumer = LocalizeContext.Consumer;

export const useLocalize = () => {
	return useContext(LocalizeContext);
};
