import React from "react";
import {
	View,
	StyleSheet,
	Dimensions
} from "react-native";
import VideoView from "../../VideoView";
import Style from "ae-style";
import LocalVideoButton from "../../LocalVideoButton";

var styles = StyleSheet.create({
	hidden: {
		opacity: 0
	},
	view: {
		flex: 1,
		position: "absolute",
		justifyContent: "space-between",
		top: vmin(15),
		right: 0,
		left: 0,
		bottom: 0,
	},
	local: {
		flex: 1,
		position: "absolute",
		justifyContent: "space-between",
		alignContent: "flex-end",
		top: Style.layout.marginSmall,
		right: Style.layout.marginSmall
	},
	big: {
		left: 0,
		right: 0,
		top: 0,
		bottom: 0
	}
});

export default function LocalPerson(props){
	var voiceOnly = props.voiceOnly;
	var stream = props.stream;
	var magnifying = props.magnifying;
	var callInputSwitcher = props.callInputSwitcher;
	var auidoOutput = props.auidoOutput;
	var orientation = props.orientation;

	var style = voiceOnly ? styles.hidden : styles.local;
	var styleList = [style];

	if(magnifying){
		styleList.push(styles.big);
	}else{
		styleList.push({
			width: vmin(30),
			height: vmin(30)
		});
	}

	return  (
		<View style={styles.view}>
			<View style={styleList} key={magnifying}>
				<VideoView stream={stream} id="ae_local_video" muted zOrder={1} />
			</View>
			<LocalVideoButton
				voiceOnly={voiceOnly}
				magnifying={magnifying}
				callInputSwitcher={callInputSwitcher}
				orientation={orientation}
				auidoOutput={auidoOutput} />
		</View>
	);
}

function vmin(x) {
	var size = Dimensions.get("window");
	var smaller = Math.min(size.width, size.height);

	return smaller / 100 * x;
}