export default {
	title: "Register",
	description: "schemas_register_title",
	type: "object",
	properties: {
		email: {
			order: 2,
			description: "register.email",
			type: "string",
			inputType: "text",
			format: "email"
		},
		fname: {
			order: 0,
			description: "register.fname",
			type: "string",
			inputType: "text",
			minLength: 1,
		},
		lname: {
			order: 1,
			description: "register.lname",
			type: "string",
			inputType: "text",
			minlength: 1,
		},
		password: {
			order: 3,
			description: "register.password",
			type: "string",
			inputType: "text",
			format: "password"
		},
		password_confirm: {
			order: 4,
			description: "register.password_confirm",
			type: "string",
			inputType: "text",
			format: "password"
		},
		language: {
			order: 5,
			description: "register.language",
			value_description: "languages",
			type: "string",
			inputType: "dropdown",
			enum: [
				"en",
				"fr",
				"cn_s",
				"cn_t",
				"es",
				"pt",
				"ta"
			],
		},
	}
};
