
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */
import React from "react";
import {
	StyleSheet,
	View,
} from "react-native";

import Style from "ae-style";

var styles = StyleSheet.create({
	container: {
		paddingVertical: Style.layout.marginLarge,
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
		backgroundColor: Style.look.background
	},
	content: {
		flex: 1
	},
	sizedContent: {
		maxWidth: Style.layout.maxContent,
		marginHorizontal: Style.layout.marginMedium,
	},
	contrast: {
		backgroundColor: Style.look.contrast.background
	},
	padBottom: {
		paddingTop: Style.layout.marginLarge,
		paddingBottom: Style.layout.marginHuge
	}
});

export default function MainContent(props){
	var inline = props.inline;

	var contentStyles = [styles.content];
	if(!inline) contentStyles.push(styles.sizedContent);

	var containerStyles = [styles.container];
	if(props.contrast)
		containerStyles.push(styles.contrast);
	if(props.padBottom)
		containerStyles.push(styles.padBottom);

	return (
		<View style={containerStyles}>
			<View style={contentStyles}>
				{props.children}
			</View>
		</View>
	);
}
