import React from "react";

import Connected from "./States/Connected";
import Connecting from "./States/Connecting";
import Incoming from "./States/Incoming";
import Outgoing from "./States/Outgoing";
import Busy from "./States/Busy";

export default function ATouchAwayCalling(props){
	var model = props.model;
	var calling = model.calling;
	var state = calling.state;
	var who = calling.who;
	var urgency = model.urgency || {};

	var availability = model.availability;
	var callinputswitcher = model.callinputswitcher;
	var conferenceinviter = model.conferenceinviter;
	var settings = model.settings;
	var callgroup = model.callgroupscalling.group;

	var screen = model.size;

	if(state === "connected")
		return (
			<Connected
				calling={calling}
				callinputswitcher={callinputswitcher}
				callgroup={callgroup}
				conferenceinviter={conferenceinviter}
				availability={availability}
				settings={settings}
				screen={screen}
				audioDevices={model.audiooutputselector}
				model={model}
			/>
		);
	else if(state === "connecting")
		return (
			<Connecting who={who} screen={screen} callgroup={callgroup} />
		);
	else if(state === "calling" || (state === "busy" && callgroup))
		return (
			<Outgoing who={who} callgroup={callgroup} urgency={urgency} screen={screen} />
		);
	else if(state === "receiving")
		return (
			<Incoming who={who} screen={screen} />
		);
	else if(state === "busy")
		return (
			<Busy who={who} screen={screen} />
		);

	return null;
}
