export default {
	title: "InviteDeny",
	description: "schemas_invite_deny",
	type: "object",
	properties: {
		block: {
			order: 6,
			description: "homepage.block",
			inputType: "checkbox",
			type: "boolean",
		}
	}
};
