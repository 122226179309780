import React from "react";
import {
	StyleSheet,
	TouchableOpacity,
	View,
} from "react-native";
import Style from "ae-style";
import { useResources } from "@aetonix/hooks";

var styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: Style.colors.black,
	},
});

export default function HiddenScreen() {
	const { dispatch } = useResources();
	var onShowUp = dispatch("screenhider:trigger:show");

	return (
		<TouchableOpacity style={styles.container} onPress={onShowUp}>
			<View />
		</TouchableOpacity>
	);
}
