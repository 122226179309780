
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";
import StyledText from "ae-base/Base/StyledText";
import Emoji from "../../Managed/Reminders/Emoji";
import Reminder from "../../Managed/Reminders/Reminder";
import { WEEKDAY_SHORT_DATE_TIME_FORMAT } from "../../../../utils/date-utils";

import { useResources } from "@aetonix/hooks";

export default function HistoryReminder(props) {
	const {
		localize,
		formatDate,
	} = useResources();
	const responseTitle = localize("reminder.responsetitle");

	const history = props.history;
	const updated = history.updated_at;
	const title = formatDate(updated, WEEKDAY_SHORT_DATE_TIME_FORMAT);

	let response = Emoji.check(history.response);
	if (response === "Accepted")
		response = localize("reminder.Accepted");
	else if (response === "Denied")
		response = localize("reminder.Denied");
	else if(response === "Yes")
		response = localize("common.yes");
	else if(response === "No")
		response = localize("common.no");
	const content = history.content;

	const contentElement = [
		(<StyledText>{content}</StyledText>),
		(<StyledText bold>{responseTitle} {response}</StyledText>),
	];

	return (
		<Reminder title={title} content={contentElement} />
	);
}
