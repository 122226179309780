export default {
	title: "AtouchawaySettings",
	description: "schemas_atouchaway_settings_title",
	type: "object",
	displayDependencies: {
		changeAllTo: {
			required: ["forms"],
			properties: {
				forms: {
					enum: [true]
				}
			}
		},
		forms_sections: {
			required: ["forms"],
			properties: {
				forms: {
					enum: [true]
				}
			}
		}
	},
	properties: {
		forms: {
			order: 1,
			description: "atouchawaysettings.forms",
			format: "switch",
			inputType: "switch",
			type: "boolean"
		}
	}
};