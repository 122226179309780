import searchHidden from "./shared/searchHidden";

export default {
	title: "Privacy",
	type: "object",
	description: "privacysettings.header",
	properties: {
		searchHidden: searchHidden,
		autologout: {
			description: "passwordtimeout.title",
			value_description: "passwordtimeout",
			type: "boolean",
			inputType: "switch",
			format: "switch",
		},
		multifactor: {
			description: "multifactor.title",
			value_description: "multifactor",
			type: "boolean",
			inputType: "switch",
			format: "switch",
		}
	}
};
