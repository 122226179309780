export default {
	"type": "object",
	"properties": {
		"fname": {
			"order": 1,
			"type": "string",
			"description": "usercreation.fname",
			"inputType": "input"
		},
		"lname": {
			"order": 2,
			"type": "string",
			"description": "usercreation.lname",
			"inputType": "input"
		},
		"patientNumber": {
			"order": 3,
			"type": "string",
			"description": "usercreation.patientNum",
			"inputType": "input"
		},
		"userType": {
			"order": 4,
			"description": "usercreation.userType",
			"value_description": "usertypeenum",
			"inputType": "dropdown",
			"type": "string",
			"enum": [
				"aTouchAway",
				"Mobile",
				"Simplified"
			]
		},
		"user_note": {
			"order": 5,
			"type": "string",
			"description": "usercreation.user_note",
			"inputType": "input",
			"multiline": true,
			"maxLength": 30,
		},
	}
};