
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 16, 2018
 * For information or permission request, email info@aetonixsystems.com
 */

export default {
	title: "Weight",
	description: "glucose.manual_entry",
	type: "object",
	properties: {
		lbs: {
			type: "number",
			description: "weight.weightdescriptionlbs",
			placeholder: "weight.messagelbs",
			minimum: 1,
			maximum: 900
		},
		comment: {
			description: "weight.commenttitle",
			type: "string",
			multiline: true,
			placeholder: "weight.comment"
		}
	},
	required: ["lbs"]
};
