export default {
	title: "Ringtone",
	description: "schemas_ringtone_title",
	type: "object",
	properties: {
		ringtone: {
			description: "profile.ringtone.title",
			value_description: "ringtone.incomingringtone",
			type: "string",
			inputType: "dropdown",
			enum: ["incoming", "incoming_2", "incoming_3", "incoming_4", "incoming_5", "incoming_6"],
		}
	}
};
