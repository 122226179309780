import { useContext, useMemo } from "react"
import TextSizeContext from "./TextSizeContext";
import Style from "ae-style";

/**
 *  Mostly copied from StyledText. Lots of redundant logic here
 *  @reest
 */

const sizes = Style.text;
const sizeNames = Object.keys(sizes).sort((name1, name2) => sizes[name1] - sizes[name2]);

function getScaledSize(size, multiplier, max){
    const maxPosition = max ? Object.keys(sizes).findIndex(size => sizes[size] === max) : getSizeName(size);
	const position = sizeNames.indexOf(size) + multiplier;

    if (!!max && (maxPosition < position)) {
        return sizeNames[maxPosition];
    }

    const canBeMadeBigger = position > sizeNames.length - 2;
	if (canBeMadeBigger) {
        return getScaledSize(size, --multiplier);
    }
		
	return sizeNames[position];
}

function getSizeName(size) {

    // @reest experimental only. We should be using
    // the consts for styles. Integer case exists only
    // as a proof of concept. 
    // @reest correct: Integer case is now the main case 😂
    // The rounding though is bad, and I already made all
    // the changes to support using the const styles
    const isIntegerSize = Number.isInteger(size);
    if (isIntegerSize) {
        const closestSizeInStyleguide = Object.values(sizes).reduce((prev, curr) => {
            return (Math.abs(curr - size) < Math.abs(prev - size) ? curr : prev);
        });

        const name = Object.keys(sizes).find(size => sizes[size] === closestSizeInStyleguide) || 'standard';
        return name;
    } else {
        // @todo this may not work as intended
        const name = sizeNames.find(sizeName => sizeName === size) ?? 'standard';
        return name;
    }
}

export default (size, max = null) => {
    const scale = useContext(TextSizeContext);

    max = max || null;

    const name = useMemo(() => getSizeName(size), [size]);
    const scaledSize = useMemo(() => sizes[getScaledSize(name, scale, max)], [sizes, name, scale, max]);

    return scaledSize;
}