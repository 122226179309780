import React from "react";
import {
	View,
	Platform,
	TouchableOpacity,
	StyleSheet,
} from "react-native";

import FileAdd from "ae-forms/src/Inputs/FileAdd";
import CameraPicker from "ae-forms/src/Inputs/CameraPicker";
import VideoPicker from "ae-forms/src/Inputs/VideoPicker";
import StyledText from "ae-base/Base/StyledText";
import DialogContainer from "ae-base/Base/DialogContainer";
import DialogItem from "ae-base/Base/DialogItem";
import Link from "ae-base/Base/Link";

import Style from "ae-style";

import { useResources } from "@aetonix/hooks";

const styles = StyleSheet.create({
	cancelButton: {
		alignSelf: "stretch",
		alignItems: "center",
	},
	cancelTextContainer: {
		padding: Style.layout.paddingButton,
	},
});

export default function FileUploadDialog({cancelUpload, onUpload, closeDialog, file, title}){
	const { localize } = useResources();
	const sendPicture = localize("filedialog.sendPicture");
	const sendVideo = localize("filedialog.sendVideo");
	const cancel = localize("filedialog.cancel");
	const sendAttachment = title ? title : localize("filedialog.sendAttachment");

	const completeUpload = (fileData) => {
		if(onUpload) onUpload(fileData);
	};

	const renderMediaPickers = Platform.OS !== "web" ? (
		<View>
			<DialogItem>
				<CameraPicker title={sendPicture} onAdd={completeUpload} stretch />
			</DialogItem>
			<DialogItem>
				<VideoPicker title={sendVideo} onAdd={completeUpload} stretch />
			</DialogItem>
		</View>
	) : null;

	return (
		<DialogContainer>
			{renderMediaPickers}
			<DialogItem>
				{file && Platform.OS === "web" ? <Link title={file.name} uri={file.url} />
					: <FileAdd title={sendAttachment} onAdd={completeUpload} cancelUpload={cancelUpload} stretch /> }
			</DialogItem>
			<DialogItem>
				<TouchableOpacity style={styles.cancelButton} onPress={cancelUpload}>
					<View style={styles.cancelTextContainer}>
						<StyledText>{cancel}</StyledText>
					</View>
				</TouchableOpacity>
				{file &&
				<TouchableOpacity style={styles.cancelButton} onPress={closeDialog}>
					<View style={styles.cancelTextContainer}>
						<StyledText>Save</StyledText>
					</View>
				</TouchableOpacity>}
			</DialogItem>
		</DialogContainer>
	);
}
