import React from "react";
import {
	View,
	StyleSheet,
} from "react-native";

import PageContainer from "../Layout/PageContainer";
import MainScroll from "../Layout/MainScroll";
import ManagedHeader from "../Pages/Managed/ManagedSection/ManagedHeader";
import Style from "ae-style";

import Appointments from "./Sections/Appointments";
import Careteam from "./Sections/Careteam";
import Client from "./Sections/Client";
import Goals from "./Sections/Goals";
import Healthassessments from "./Sections/Healthassessments";
import Healthconditions from "./Sections/Healthconditions";
import Hospitalvisits from "./Sections/Hospitalvisits";
import Othertreatments from "./Sections/Othertreatments";
import Situation from "./Sections/Situation";
import Supports from "./Sections/Supports";
import Treatments from "./Sections/Treatments";

import { useResources } from "@aetonix/hooks";

var styles = StyleSheet.create({
	view: {
		marginHorizontal: Style.layout.marginLarge,
	},
});

var pageMap = {
	"Appointments": Appointments,
	"CareTeam": Careteam,
	"Client": Client,
	"Goals": Goals,
	"HealthAssessments": Healthassessments,
	"HealthConditions": Healthconditions,
	"HospitalVisits": Hospitalvisits,
	"OtherTreatments": Othertreatments,
	"Situation": Situation,
	"Supports": Supports,
	"Treatments": Treatments,
};

var titleMap = {
	"Appointments": "appointments.appointmentsreferrals",
	"CareTeam": "careteam.careteam",
	"Client": "client.patientidentifiers",
	"Goals": "goals.caregoals",
	"HealthAssessments": "healthassessments.healthassessment_s",
	"HealthConditions": "healthconditions.healthissues",
	"HospitalVisits": "hospitalvisits.hospitalvisit_s",
	"OtherTreatments": "othertreatments.othertreatments",
	"Situation": "situation.situationlifestyle",
	"Supports": "supports.supportsservices",
	"Treatments": "treatments.allergiesmedications",
};

export default function CareplanView(props) {
	const {
		localize,
	} = useResources();

	var model = props.model;
	var page = model.page;
	var	section = page.slice(12);

	var Content = pageMap[section] || View;

	var careplan = model.careplan;
	var overlay = careplan.overlay;
	var currentSection = careplan.section;
	var showCareplanDetail = careplan.showDetail;
	var title = localize(titleMap[section]);
	var permissions = false;
	var changes = {};

	return (
		<PageContainer>
			<ManagedHeader title={title} contact={model.personal} />
			<MainScroll>
				<View style={styles.view}>
					<Content careplan={overlay} permissions={permissions} changes={changes} currentSection={currentSection} ownCareplan showCareplanDetail={showCareplanDetail} />
				</View>
			</MainScroll>
		</PageContainer>
	);
}
