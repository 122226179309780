import React from 'react';
import { createStyled } from '@emotion/primitives-core';
import { StyleSheet, ScrollView } from 'react-native';

const styled = createStyled(StyleSheet);

const Container = styled(ScrollView)({
    width: 'auto',
    height: 'auto',
    maxHeight: '90%',
    maxWidth: '95%',
    position: 'absolute',
    bottom: 20,
    right: 15,
    zIndex: 10,
});

export default ({ children }) => (
    <Container>
        { children }
    </Container>
);