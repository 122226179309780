import React from 'react';
import { View, StyleSheet, TouchableOpacity, Text } from 'react-native';
import { createStyled } from '@emotion/primitives-core'
import ResponsiveText from './Text/ResponsiveText';
import Style from '@aetonix/styles';

const styled = createStyled(StyleSheet);

const Container = styled(View)({
    flex: 1,
    width: '100%',
    height: 'auto',
    maxHeight: 80,
    borderTopWidth: 3,
    paddingVertical: 10,
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'flex-end',
    paddingHorizontal: 20,
    borderColor: '#e8e8e8',
    backgroundColor: 'white',
    justifyContent: 'space-between',
})

const MeetingMinimizeText = styled(ResponsiveText)({
    fontWeight: '500',
    whiteSpace: 'noWrap',
    color: "#141823",
});

const ReturnButton = styled(TouchableOpacity)({
    backgroundColor: '#065B61',
    borderRadius: 10,
    padding: 10,
});

export default ({ title, onClick }) => {
    return (
        <Container>
            <MeetingMinimizeText size={Style.text.emphasized} numberOfLines={1} adjustsFontSizeToFit>{title}</MeetingMinimizeText>
            <ReturnButton onPress={onClick} id={'returnToCall'}>
                <MeetingMinimizeText size={Style.text.small} style={{ color: 'white' }}>Return to call</MeetingMinimizeText>
            </ReturnButton>
        </Container>
    )
}